import React from 'react'

const ErrorModal = (props) => {
    const {text, change, buttonText} = props
  return (
    <div className="absolute left-1/2 top-1/2 -translate-x-1/2  -translate-y-1/2 text-center  rounded-xl bg-white p-4 border-2">
        <p className="font-poppins">{text}</p>
        <button className="bg-yellow-500 w-full py-2 rounded-xl font-bold my-3 font-poppins" onClick={()=>change()}>{buttonText}</button>
    </div>
  )
}

export default ErrorModal
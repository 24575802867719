// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBq3PmZzWl_Q0NN7C_UuaT8YTtEta3Zs4s",
  authDomain: "interview-vision.firebaseapp.com",
  projectId: "interview-vision",
  storageBucket: "interview-vision.appspot.com",
  messagingSenderId: "402337480990",
  appId: "1:402337480990:web:06116b7cd6ea846454490f",
  measurementId: "G-N6S4MECJ7P"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export default app;
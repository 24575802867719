import React, {useState, useEffect} from 'react'
import { Link, useLocation } from 'react-router-dom';
import YouTube from 'react-youtube';
import tick from '../images/tick.png'
import dashboard_flip from '../images/dashboard_flip.png';
import axios from 'axios'
import {InfinitySpin} from 'react-loader-spinner'
import {Bar, Doughnut} from 'react-chartjs-2'
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale } from "chart.js/auto";
import * as helpers from 'chart.js/helpers';
import ChartDataLabels from 'chartjs-plugin-datalabels'
import top1 from '../images/top.png'
import star from '../images/star.png'
import increase from '../images/increase.png'
import bar_graph from '../images/bar-graph.png'
import intro1 from '../images/introduction 1.png'
import teamwork1 from'../images/teamwork 1.png'
import data from '../config'
import skill1 from '../images/skill 1.png'
import ReactGA from 'react-ga'
import ear from '../images/ear.png'
import chat from '../images/chat.png'
import enthu from '../images/enthusiasm.png'
import ReactCardFlip from 'react-card-flip';


// const centerPlugin = {
//   id:"sdmkmf",
//   beforeDataSetsDraw(chart, args, pluginOptions){
//     const {ctx, data} = chart;
//     console.log('The plugin is loading');
//     ctx.save();	
//     ctx.font = "bolder 100px sans-serif";
//     ctx.fillText('text',chart.getDatasetMeta(0).data[0].x,chart.getDatasetMeta(0).data[0].y)
//   }
// }



ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels, CategoryScale );

  const Dashboard = () => {
    const user  = localStorage.getItem('email')
    const [choice, setChoice] = useState(0)
    const [link,setLink] = useState('')
    const [open,setOpen] = useState(false)
    const [width,setWidth] = useState(0)
    const [height,setHeight] = useState(0)
    const [result, setResult] = useState([])
    const [filteredData, setFilteredData] = useState([])
    const [status, setStatus] = useState('')
    const [tw, setTw] = useState(0)
  const [gm, setGm] = useState(0)
  const [check, setCheck] = useState(0)
  const [critical, setCritical] = useState(0)
  const [ls, setLs] = useState(0)
  const [comm, setComm] = useState(0)
  const [intro, setIntro] = useState(0)
  const [flag, setFlag]=useState(false)
  const [introMarks, setIntroMarks] = useState([])
  const [criticalMarks, setCriticalMarks] = useState([])
  const [lsMarks, setLsMark] = useState([])
  const [commMarks, setCommMarks] = useState([])
  const [gmMarks, setGmMarks] = useState([])
  const [twMarks, setTwMarks] = useState([])
  const [labels, setLabels] = useState([])
  const [activeTab, setActiveTab] = useState('comm')
  const [meterdata, setMeterData] = useState([])
  const [latestData, setLatestData] = useState([])
  const [penultimateData, setPenultimateData] = useState([])
  const [difference,setDifference] = useState([])
  const [history,setHistory] = useState([])
  const [totalMarks, setTotalMarks] = useState([])
  const [currentData, setCurrentData] = useState([])
  const [total,setTotal] = useState(0)
  const [prevTotal, setPrevTotal] = useState(0)
  const [skills, setSkills] = useState([])
  const [email, setEmail] = useState('')
  const [flip, setFlip] = useState(false)
  const [tab, setTab] = useState(0)
  const percentile = (arr, num) => (arr.filter((item) => item <= num).length / arr.length) * 100;
  
  useEffect(()=>{
    if(activeTab == 'ls'){
      setMeterData(lsMarks)
    }
    else if(activeTab == "intro"){
      setMeterData(introMarks)
    }
    else if(activeTab == "comm"){
      setMeterData(commMarks)
    }
    else if(activeTab=="gm"){
      setMeterData(gmMarks)
    }
    else if(activeTab == "ct"){
      setMeterData(criticalMarks)
    }
    else if(activeTab == "tw"){
      setMeterData(twMarks)
    }
  },[activeTab])
  

  const payment = ()=>{
  axios.put(`https://${data.API}/users/u/${user}`,{
    email:email
  })
  .then((response)=>{
    console.log(response)
    axios.post(`https://${data.API}/payment/pe/create`,{
          username:user,
          amount:69900,
          pack:"standard"
        })
        .then((response)=>{
        console.log(response)
        localStorage.setItem('merchantTransactionId',response.data.merchantTransactionId)
        window.location.href = response.data.url
      })
      .catch((error)=>{
        console.log(error)
      })
  })
  .catch((error)=>{
    console.log(error)
  })
  
  }

  useEffect(()=>{
    var ct=0,tw=0,gm=0,ls=0,comm=0,web=0
    if(history!=null){
      if(history.length>0){
        console.log(history)
        history.map((el)=>{
          if(el.category == 'web'){
            web=web+1;
          }
          else if(el.category == 'ct'||el.category == 'critical thinking'){
            ct=ct+1
          }
          else if(el.category == 'tw'){
            tw=tw+1
          }
          else if(el.category == 'comm'){
            comm=comm+1
          }
          else if(el.category == 'gm'){
            gm=gm+1
          }
          else if(el.category == 'ls'){
            ls=ls+1
          }
        })
        setSkills([web,ct,tw,comm,gm,ls])
        console.log(skills)
      }
    }
  },[history])

  
  const [name1, setName1] = useState('')
    const [url,setUrl]= useState('interviewvision.com')
    useEffect(()=>{
      ReactGA.pageview(window.location.pathname + window.location.search)
    },[])
    
    useEffect(()=>{
        if(window.location.href.includes('122.176.112.216:8999')){
            setUrl('122.176.112.216:8999')
        }
        if(window.location.href.includes('gametechy.com')){
          setUrl('gametechy.com:8999')
        }
    },[])
    const [, updateState] = React.useState();
    const thumbnail1 = 'https://img.youtube.com/vi/dItUGF8GdTw/0.jpg'
    const thumbnail2 = "https://img.youtube.com/vi/x0vUsxhMczI/0.jpg"
    const thumbnail14 = "https://img.youtube.com/vi/iCtoCeFTMME/0.jpg"
    const thumbnail10 = "https://img.youtube.com/vi/cFdCzN7RYbw/0.jpg"
    const thumbnail11 = "https://img.youtube.com/vi/1sSing6ntVA/0.jpg"
    const thumbnail12 = "https://img.youtube.com/vi/UwsMogSQmYI/0.jpg"
    const thumbnail5 = "https://img.youtube.com/vi/rUJkbWNnNy4/0.jpg"
    const thumbnail6 = "https://img.youtube.com/vi/hiiEeMN7vbQ/0.jpg"
    const thumbnail8 = "https://img.youtube.com/vi/7wUCyjiyXdg/0.jpg"
    const thumbnail9 = "https://img.youtube.com/vi/NqgIVZJv-do/0.jpg"
  
    useEffect(()=>{
        if(window.innerWidth<500){
            setWidth(300)
            setHeight(150)
        }
        else{
            setWidth(400)
            setHeight(250)
        }
       
        },[])


        const donutText = {
          id:'donutText',
          beforeDatasetsDraw(chart, args, pluginOptions){
            const {ctx,data, chartArea:{top, bottom, left, right, width, height}} = chart
            ctx.save();
            ctx.fillStyle='black';
            ctx.font = 'bold 30px sans-serif';
            ctx.textAlign = 'center';
            ctx.fillText(data.datasets[0].data[0]+data.datasets[0].data[1]+data.datasets[0].data[2]+data.datasets[0].data[3]+data.datasets[0].data[4]+data.datasets[0].data[5],chart.getDatasetMeta(0).data[0].x,chart.getDatasetMeta(0).data[0].y+10)
          }
        }

        const gaugeText = {
          id:'gaugeText',
          beforeDatasetsDraw(chart, args, pluginOptions){
            const {ctx,data, chartArea:{top, bottom, left, right, width, height}} = chart
            ctx.save();
            ctx.fillStyle='gray';
            ctx.font = 'bold 50px sans-serif';
            ctx.textAlign = 'center';
            // console.log(data)
            ctx.fillText(data.datasets[0].data[0],chart.getDatasetMeta(0).data[0].x,chart.getDatasetMeta(0).data[0].y)
          }
        }
        const gaugeText2 = {
          id:'gaugeText',
          beforeDatasetsDraw(chart, args, pluginOptions){
            const {ctx,data, chartArea:{top, bottom, left, right, width, height}} = chart
            ctx.save();
            ctx.fillStyle='gray';
            ctx.font = ' 15px sans-serif';
            ctx.textAlign = 'center';
            ctx.fillText('Score',chart.getDatasetMeta(0).data[0].x,chart.getDatasetMeta(0).data[0].y)
          }
        }
        const gaugeText3 = {
          id:'gaugeText',
          beforeDatasetsDraw(chart, args, pluginOptions){
            const {ctx,data, chartArea:{top, bottom, left, right, width, height}} = chart
            ctx.save();
            ctx.fillStyle='black';
            ctx.font = 'bold 25px sans-serif';
            ctx.textAlign = 'center';
            ctx.fillText('0',left-7,chart.getDatasetMeta(0).data[0].y)
          }
        }
       
        const gaugeText5 = {
          id:'gaugeText',
          beforeDatasetsDraw(chart, args, pluginOptions){
            const {ctx,data, chartArea:{top, bottom, left, right, width, height}} = chart
            ctx.save();
            ctx.fillStyle='black';
            ctx.font = 'bold 25px sans-serif';
            ctx.textAlign = 'center';
            ctx.fillText('5',right+7,chart.getDatasetMeta(0).data[0].y)
          }
        }
        const gaugeText6 = {
          id:'gaugeText',
          beforeDatasetsDraw(chart, args, pluginOptions){
            const {ctx,data, chartArea:{top, bottom, left, right, width, height}} = chart
            ctx.save();
            ctx.fillStyle='black';
            ctx.font = 'bold 25px sans-serif';
            ctx.textAlign = 'center';
            ctx.fillText('0',left-7,chart.getDatasetMeta(0).data[0].y)
          }
        }
        useEffect(()=>{
              axios.get(`https://${data.API}/users/r/${user}`)
              .then((response)=>{
              console.log(response)
              setName1(response.data.fname+' '+response.data.lname)
              setStatus(response.data.subscribedpack)
              })
              .catch((error)=>{
              console.log(error)
              })
      },[])

      useEffect(()=>{
        axios.get(`https://${data.API}/interview/video/watched?username=${user}`)
        .then((response)=>{
          console.log(response)
          setHistory(response.data)
        })
        .catch((error)=>{
          console.log(error)
        })

      },[])

        useEffect(()=>{  
          axios.get(`https://${data.API}/interview/get/interviews`)
          .then((response)=>{
            setResult(response.data.result)
          })
          .catch((error)=>{
            console.log(error)
            alert('Something went wrong, please try again')
          })
        },[])

        useEffect(()=>{
          var sum = 0
          if(result.length>0) {
             setFilteredData(result.filter((el)=>{
              return ( el.username.toLowerCase().includes(user))
            })
            )
            if(check ===0){
              result.map((el)=>{
                if(el.marks.length>0){
                  var char = el.marks.split('')
                  char.map((e)=>{
                    sum = sum + parseInt(e)
                  })
                  totalMarks.push(sum)
                }
                sum=0
              })
              console.log(totalMarks)
              setCheck(1)
            }
          }
        },[result])


        console.log(filteredData)
        useEffect(()=>{
          if(filteredData.length>0 && flag == false)
          {
            if(filteredData.length>0 && filteredData[filteredData.length-1].marks.length>0 && filteredData[filteredData.length-1].chunk[0].InterviewType=="paid"){
              setLabels([...Array(6).keys()])
              filteredData[filteredData.length-1].chunk.map((chunk,i)=>{
                if(chunk.Category == "i"){
                        setIntro(parseInt(filteredData[filteredData.length-1].marks[i])+intro)
                    }
                    else if(chunk.Category =="c"){
                        setComm(parseInt(filteredData[filteredData.length-1].marks[i])+comm)
                    }
                    else if(chunk.Category == "ls"){
                        setLs(parseInt(filteredData[filteredData.length-1].marks[i])+ls)
                    }
                    else if(chunk.Category == "tw"){
                        setTw(parseInt(filteredData[filteredData.length-1].marks[i])+tw)
                    }
                    else if(chunk.Category == "gm"){
                        setGm(parseInt(filteredData[filteredData.length-1].marks[i])+gm)
                    }
                    else if(chunk.Category == "ct"){
                        setCritical(parseInt(filteredData[filteredData.length-1].marks[i])+critical)
                    }
              })
            }
            else if(filteredData.length>0 && filteredData[filteredData.length-1].chunk[0].InterviewType=="free"){
              filteredData[filteredData.length-1].chunk.map((chunk,i)=>{
                console.log(chunk.MLNumericScore)
                if(chunk.Category == "i"){
                        setIntro(parseInt(chunk.MLNumericScore)+intro)
                    }
                    else if(chunk.Category =="c"){
                        setComm(parseInt(chunk.MLNumericScore)+comm)
                    }
                    else if(chunk.Category == "ls"){
                        setLs(parseInt(chunk.MLNumericScore)+ls)
                    }
                    else if(chunk.Category == "tw"){
                        setTw(parseInt(chunk.MLNumericScore)+tw)
                    }
                    else if(chunk.Category == "gm"){
                        setGm(parseInt(chunk.MLNumericScore)+gm)
                    }
                    else if(chunk.Category == "ct"){
                        setCritical(parseInt(chunk.MLNumericScore)+critical)
                    }
              })
            }
            setFlag(true)
          }
        },[filteredData])

        console.log(intro, comm, ls, tw, gm, critical)

        console.log(labels)

        const getGradient = (chart) => {
          const {ctx, chartArea:{top, bottom, left,right}} = chart;
          const gradientSegment = ctx.createLinearGradient(left,0,right,0)
          gradientSegment.addColorStop(0,'#96E0C7')
          gradientSegment.addColorStop(1,'#1BC5BD')
          return gradientSegment;
        }
        useEffect(()=>{
            var intro1;
            var gm1;
            var ls1;
            var ct1;
            var comm1;
            var tw1;
            if(filteredData.length>0){
              if(filteredData[filteredData.length-1].chunk[0].InterviewType=="paid"){
                filteredData.map((e)=>{
                    intro1 = 0;
                    gm1 = 0;
                    ls1 = 0;
                    ct1 = 0;
                    comm1 = 0;
                    tw1 = 0;
                    e.chunk.map((f)=>{
                        if(f.Category == "i"){
                            intro1 = intro1+f.NumericScore;
                        }
                        else if(f.Category === "c"){
                            comm1 = comm1 + f.NumericScore;
                        }
                        else if(f.Category == "ct"){
                            ct1 = ct1 + f.NumericScore;
                        }
                        else if(f.Category === "tw"){
                            tw1 = tw1 + f.NumericScore;
                        }
                        else if(f.Category === "gm"){
                            gm1 = gm1 + f.NumericScore;
                        }
                        else if(f.Category === "ls"){
                            ls1 = ls1 + f.NumericScore;
                        }
                    })
                    introMarks.push(Math.ceil(intro1/2))
                    criticalMarks.push(ct1)
                    lsMarks.push(ls1)
                    commMarks.push(comm1)
                    gmMarks.push(gm1)
                    twMarks.push(tw1)
                    setMeterData(introMarks)
                })
              }
              else if(filteredData[filteredData.length-1].chunk[0].InterviewType=="free"){
                filteredData.map((e)=>{
                  intro1 = 0;
                  gm1 = 0;
                  ls1 = 0;
                  ct1 = 0;
                  comm1 = 0;
                  tw1 = 0;
                  e.chunk.map((f)=>{
                      if(f.Category == "i"){
                          intro1 = intro1+f.MLNumericScore;
                      }
                      else if(f.Category === "c"){
                          comm1 = comm1 + f.MLNumericScore;
                      }
                      else if(f.Category == "ct"){
                          ct1 = ct1 + f.MLNumericScore;
                      }
                      else if(f.Category === "tw"){
                          tw1 = tw1 + f.MLNumericScore;
                      }
                      else if(f.Category === "gm"){
                          gm1 = gm1 + f.MLNumericScore;
                      }
                      else if(f.Category === "ls"){
                          ls1 = ls1 + f.MLNumericScore;
                      }
                  })
                  introMarks.push(Math.ceil(intro1/2))
                  criticalMarks.push(ct1)
                  lsMarks.push(ls1)
                  commMarks.push(comm1)
                  gmMarks.push(gm1)
                  twMarks.push(tw1)
                  setMeterData(introMarks)
              })
              }
                setLatestData([introMarks[introMarks.length - 1],commMarks[commMarks.length-1],lsMarks[lsMarks.length - 1],twMarks[twMarks.length-1],gmMarks[gmMarks.length - 1],criticalMarks[criticalMarks.length - 1]])
                setPenultimateData([introMarks[introMarks.length - 2],commMarks[commMarks.length-2],lsMarks[lsMarks.length - 2],twMarks[twMarks.length-2],gmMarks[gmMarks.length - 2],criticalMarks[criticalMarks.length - 2]])
              }
              
        },[filteredData])
        useEffect(()=>{
          if(latestData.length>0 && penultimateData.length>0){
            setDifference(latestData.map(function(item, index) {
              return item - penultimateData[index];
            }))
            setCurrentData(latestData)
            latestData.forEach((el)=>{
              setTotal(parseInt(el)+total)
            })
            penultimateData.forEach((el)=>{
              setPrevTotal(parseInt(el)+prevTotal) 
            })
          }
          console.log(difference)
          console.log(percentile(totalMarks,total))
        },[latestData])

        // console.log((introMarks[introMarks.length-1]) - (introMarks[introMarks.length-2]))
        // && currentData.length> 0 && latestData.length>0 &&penultimateData.length>0
    if(result.length>0 ) {
        {
        {
            if(filteredData.length == 0)
            {
              return(    
     <div className="mx-3">
       <div className="bg-white  px-10 py-7  mr-6 rounded-xl my-4">
        <p className="font-semibold font-poppins text-3xl">Please take the test to track your growth.</p>
        
       {/* {
        filteredData.length == 0 ?
           :
           <div>
            {
              filteredData[filteredData.length-1].marks ==='' &&
              <div>
                <span className="text-3xl font-semibold font-poppins mb-4">Congratulations on taking our Soft Skill evaluation test! </span>
                 <p className="font-poppins text-2xl"><br></br> You are already amongst the top 40% candidates by taking this step. Your Skill Score Card will be displayed here within the next 24 hours. We will also send you a copy on your registered email address.</p>
              </div>
            }
           </div>

       } */}
           <p className="font-poppins my-3">Meanwhile, please explore our resources for learning various skills</p>
           <Link  to='/data' >
                <button className="bg-[#E94B60] px-5 lg:px-10 py-3 mt-3 lg:py-3 text-white rounded-xl">Try Free</button>
                </Link>
       </div>
       <div className="my-5">
               <h1 className="font-poppins font-extrabold text-xl ml-4 lg:ml-0 mb-4">Take Inspiration</h1>
               {/* <div className="items-center overflow-auto scrollbar-hide space-x-4 mx-4 flex-nowrap whitespace-nowrap">
                   <button onClick={()=>{setChoice(0)}} className="text-center inline-block">
                       <h1 className={choice == 0 ?"text-[#E94B60] ":"text-[#7272728F]"}>Critical Thinking</h1>
                       <div className={choice == 0 ?"p-1 w-[0.1rem] mx-auto rounded-full bg-[#E94B60] ":"p-1 w-[0.1rem] mx-auto rounded-full bg-[#4899B7] invisible"}></div>
                   </button>
                   <button onClick={()=>{setChoice(1)}} className="text-center inline-block">
                       <h1 className={choice == 1 ?"text-[#E94B60] ":"text-[#7272728F]"}>Communication</h1>
                       <div className={choice == 1 ?"p-1 w-[0.1rem] mx-auto rounded-full bg-[#E94B60] ":"p-1 w-[0.1rem] mx-auto rounded-full bg-[#4899B7] invisible"}></div>
                   </button>
                   <button  onClick={()=>{setChoice(2)}} className="text-center inline-block">
                       <h1 className={choice == 2 ?"text-[#E94B60] ":"text-[#7272728F]"}>Team Play</h1>
                       <div className={choice == 2 ?"p-1 w-[0.1rem] mx-auto rounded-full bg-[#E94B60] ":"p-1 w-[0.1rem] mx-auto rounded-full bg-[#4899B7] invisible"}></div>
                   </button>
                   <button onClick={()=>{setChoice(3)}} className="text-center inline-block">
                       <h1 className={choice == 3 ?"text-[#E94B60] ":"text-[#7272728F]"}>Growth Mindset</h1>
                       <div className={choice == 3 ?"p-1 w-[0.1rem] mx-auto rounded-full bg-[#E94B60] ":"p-1 w-[0.1rem] mx-auto rounded-full bg-[#4899B7] invisible"}></div>
                   </button>
                   <button onClick={()=>{setChoice(4)}} className="text-center inline-block">
                    <h1 className={choice == 4 ?"text-[#E94B60] ":"text-[#7272728F]"}>Active Listening</h1>
                    <div className={choice == 4 ?"p-1 w-[0.1rem] mx-auto rounded-full bg-[#E94B60] ":"p-1 w-[0.1rem] mx-auto rounded-full bg-[#4899B7] invisible"}></div>
                    </button>
               </div> */}
               {
                   choice == 0 
                   &&
                   <div className="lg:grid lg:grid-cols-3 lg:space-y-0 space-y-4 gap-8">
                       <button onClick={()=>{
             setLink('dItUGF8GdTw')
             setOpen(true)
             }} className=" text-left">
           <div className="bg-white px-4 py-5 flex  lg:mr-0 mr-4   space-x-4 rounded-xl">
               <img src={thumbnail1} className="w-1/3 h-full rounded-xl"></img>
                       <div className="space-y-2">
                       <h1 className="lg:text-xl text-lg font-bold">5 tips to improve your critical thinking </h1>
                       <h1 className="lg:text-lg text-lg">by Samantha Agoos</h1>
                       <div className="h-[0.1rem] bg-[#D9D9D9]"></div>
                       <h1 className='text-sm font-extralight'>4 minutes 29 seconds</h1>
               </div>
           </div>
           </button>
           <button onClick={()=>{setLink('x0vUsxhMczI')
         setOpen(true)
         }} className=" text-left">
           <div className="bg-white px-4 py-5 flex  lg:mr-0 mr-4   space-x-4 rounded-xl">
               <img src={thumbnail2} className="w-1/3 h-full rounded-xl"></img>
                       <div className="space-y-2">
                       <h1 className="lg:text-xl text-lg font-bold">The Best Way To Learn Critical Thinking</h1>
                       <h1 className="lg:text-lg text-lg">by Jordan Peterson</h1>
                       <div className="h-[0.1rem] bg-[#D9D9D9]"></div>
                       <h1 className='text-sm font-extralight'>4 minutes 1 seconds</h1>
               </div>
           </div>
           </button>
                   </div>
               }
               {
                   choice == 1
                   &&
                   <div className="lg:grid lg:grid-cols-3 lg:space-y-0 space-y-4 gap-8">
                       <div className="h-full">
             <button onClick={()=>{setLink('iCtoCeFTMME')
         setOpen(true)
         }} className=" text-left h-full w-full">
           <div className="bg-white px-4 py-5 flex h-full   lg:mr-0 mr-4   space-x-4 rounded-xl">
               <img src={thumbnail14} className="w-1/3 h-1/2 rounded-xl"></img>
                       <div className="space-y-2">
                       <h1 className="lg:text-xl text-lg font-bold">14 Ways To Instantly Improve Communication Skills</h1>
                       <h1 className="lg:text-lg text-lg">by Practical Wisdom</h1>
                       <div className="h-[0.1rem] bg-[#D9D9D9]"></div>
                       <h1 className='text-sm font-extralight'>11 minutes 49 seconds</h1>
               </div>
           </div>
           </button>
               </div>
   
               <div className="h-full">
             <button onClick={()=>{setLink('cFdCzN7RYbw')
         setOpen(true)
         }} className=" text-left h-full w-full">
           <div className="bg-white px-4 py-5 flex h-full  lg:mr-0 mr-4   space-x-4 rounded-xl">
               <img src={thumbnail10} className="w-1/3 h-1/2 rounded-xl"></img>
                       <div className="space-y-2">
                       <h1 className="lg:text-xl text-lg font-bold">Science Of Persuasion</h1>
                       <h1 className="lg:text-lg text-lg">by influenceatwork</h1>
                       <div className="h-[0.1rem] bg-[#D9D9D9]"></div>
                       <h1 className='text-sm font-extralight'>11 minutes 50 seconds</h1>
               </div>
           </div>
           </button>
               </div>
                   </div>
               }
               {
                   choice ==2
                   &&
                   <div className="lg:grid lg:grid-cols-3 lg:space-y-0 space-y-4 gap-8">
                       <div className="h-full">
             <button onClick={()=>{setLink('1sSing6ntVA')
         setOpen(true)
         }} className=" text-left h-full">
           <div className="bg-white px-4 py-5 flex  lg:mr-0 mr-4   space-x-4 rounded-xl">
               <img src={thumbnail11} className="w-1/3 h-full rounded-xl"></img>
                       <div className="space-y-2">
                       <h1 className="lg:text-xl text-lg font-bold">How to Improve your Teamwork Skills instead of Stressing Out by Peer Connect</h1>
                       <h1 className="lg:text-lg text-lg">by I Time Travel Well</h1>
                       <div className="h-[0.1rem] bg-[#D9D9D9]"></div>
                       <h1 className='text-sm font-extralight'>3 minutes 2 seconds</h1>
               </div>
           </div>
           </button>
               </div>
   
               <div className="h-full">
             <button onClick={()=>{setLink('UwsMogSQmYI')
         setOpen(true)
         }} className=" text-left h-full w-full">
           <div className="bg-white px-4 py-5 flex h-full   lg:mr-0 mr-4   space-x-4 rounded-xl">
               <img src={thumbnail12} className="w-1/3 h-1/2 rounded-xl"></img>
                       <div className="space-y-2">
                       <h1 className="lg:text-xl text-lg font-bold">Teamwork Reimagined</h1>
                       <h1 className="lg:text-lg text-lg">by Kevin Cahill</h1>
                       <div className="h-[0.1rem] bg-[#D9D9D9]"></div>
                       <h1 className='text-sm font-extralight'>13 minutes 53 seconds</h1>
               </div>
           </div>
           </button>
               </div>
                   </div>
               }
               {
                   choice == 3
                   &&
                   <div className="lg:grid lg:space-y-0 space-y-4 lg:grid-cols-3 gap-8">
                       <button onClick={()=>{setLink('rUJkbWNnNy4')
         setOpen(true)
         }} className=" text-left">
           <div className="bg-white px-4 py-5 flex  lg:mr-0 mr-4   space-x-4 rounded-xl">
               <img src={thumbnail5} className="w-1/3 h-full rounded-xl"></img>
                       <div className="space-y-2">
                       <h1 className="lg:text-xl text-lg font-bold">Developing A Growth Mindset</h1>
                       <h1 className="lg:text-lg text-lg">by ClickView</h1>
                       <div className="h-[0.1rem] bg-[#D9D9D9]"></div>
                       <h1 className='text-sm font-extralight'>4 minutes 51 seconds</h1>
               </div>
           </div>
           </button>
           <button onClick={()=>{setLink('hiiEeMN7vbQ')
         setOpen(true)
         }} className=" text-left">
           <div className="bg-white px-4 py-5 flex  lg:mr-0 mr-4   space-x-4 rounded-xl">
               <img src={thumbnail6} className="w-1/3 h-full rounded-xl"></img>
                       <div className="space-y-2">
                       <h1 className="lg:text-xl text-lg font-bold">Developing a Growth Mindset</h1>
                       <h1 className="lg:text-lg text-lg">by Carol Dweck</h1>
                       <div className="h-[0.1rem] bg-[#D9D9D9]"></div>
                       <h1 className='text-sm font-extralight'>4 minutes 51 seconds</h1>
               </div>
           </div>
           </button>
                   </div>
               }
               {
                choice  == 4
                &&
                <div>
                    <div className="lg:grid lg:space-y-0 space-y-4 lg:grid-cols-3 gap-8 items-stretch ">
          <div className="h-full">
        <button onClick={()=>{setLink('7wUCyjiyXdg')
      setOpen(true)
      }} className="h-full text-left w-full">
        <div className="bg-white px-4 h-full py-5 flex  w-full lg:mr-0 mr-4   space-x-4 rounded-xl">
            <img src={thumbnail8} className="w-1/3 h-1/2 rounded-xl"></img>
                    <div className="space-y-2">
                    <h1 className="lg:text-xl text-lg font-bold">Active Listening Skills</h1>
                    <h1 className="lg:text-lg text-lg">by Alexander Lyon</h1>
                    <div className="h-[0.1rem] bg-[#D9D9D9]"></div>
                    <h1 className='text-sm font-extralight'>6 minutes 13 seconds</h1>
            </div>
        </div>
        </button>
          </div>
          <div className="h-full">
        <button onClick={()=>{setLink('NqgIVZJv-do')
      setOpen(true)
      }} className=" text-left h-full">
        <div className="bg-white px-4 py-5 flex  lg:mr-0 mr-4   space-x-4 rounded-xl">
            <img src={thumbnail9} className="w-1/3 h-full rounded-xl"></img>
                    <div className="space-y-2">
                    <h1 className="lg:text-xl text-lg font-bold">5 Techniques to Improve Active Listening Skills</h1>
                    <h1 className="lg:text-lg text-lg">by English Learning Hub</h1>
                    <div className="h-[0.1rem] bg-[#D9D9D9]"></div>
                    <h1 className='text-sm font-extralight'>6 minutes 13 seconds</h1>
            </div>
        </div>
        </button>
          </div>
          
        </div>
                </div>

            }
               
           </div>
           {
             open &&
           <div className={width===300?"fixed top-0 left-0 lg:w-1/3 px-4 lg:h-1/2 bg-[#2d9dba] rounded-xl shadow-2xl":"fixed  right-0 bottom-0 lg:w-1/3 lg:h-1/2 bg-[#2d9dba] rounded-xl shadow-2xl"}>
             <div className="flex justify-between items-center">
             <p className="text-white ml-4 text-xl mt-4 font-poppins">Now Playing</p>
             <button className="text-white text-xl font-poppins mr-4" onClick={()=>{setOpen(false)}}>x</button>
             </div>
             <div className="mx-auto text-center">
             <YouTube videoId={link} iframeClassName='mx-auto my-4 ' className="" opts={{width:width, height:height}} />
             </div>
           </div>
           }
     </div>
     )
        }
        else if(filteredData.length>0 && filteredData[filteredData.length-1].marks ==='' && filteredData[filteredData.length-1].chunk[0].InterviewType=="paid"){
          return(
            <div className="mx-3">
       <div className="bg-white  px-10 py-7  mr-6 rounded-xl my-4">
        <div>
          <span className="text-3xl font-semibold font-poppins mb-4">Congratulations on taking our Soft Skill evaluation test! </span>
           <p className="font-poppins text-2xl"><br></br> You are already amongst the top 40% candidates by taking this step. Your Skill Score Card will be displayed here within the next 24 hours. We will also send you a copy on your registered email address.</p>
        </div>
           <p className="font-poppins my-3">Meanwhile, please explore our resources for learning various skills</p>
       </div>
       <div className="my-5">
               <h1 className="font-poppins font-extrabold text-xl ml-4 lg:ml-0 mb-4">Take Inspiration</h1>
               {
                   choice == 0 
                   &&
                   <div className="lg:grid lg:grid-cols-3 lg:space-y-0 space-y-4 gap-8">
                       <button onClick={()=>{
             setLink('dItUGF8GdTw')
             setOpen(true)
             }} className=" text-left">
           <div className="bg-white px-4 py-5 flex  lg:mr-0 mr-4   space-x-4 rounded-xl">
               <img src={thumbnail1} className="w-1/3 h-full rounded-xl"></img>
                       <div className="space-y-2">
                       <h1 className="lg:text-xl text-lg font-bold">5 tips to improve your critical thinking </h1>
                       <h1 className="lg:text-lg text-lg">by Samantha Agoos</h1>
                       <div className="h-[0.1rem] bg-[#D9D9D9]"></div>
                       <h1 className='text-sm font-extralight'>4 minutes 29 seconds</h1>
               </div>
           </div>
           </button>
           <button onClick={()=>{setLink('x0vUsxhMczI')
         setOpen(true)
         }} className=" text-left">
           <div className="bg-white px-4 py-5 flex  lg:mr-0 mr-4   space-x-4 rounded-xl">
               <img src={thumbnail2} className="w-1/3 h-full rounded-xl"></img>
                       <div className="space-y-2">
                       <h1 className="lg:text-xl text-lg font-bold">The Best Way To Learn Critical Thinking</h1>
                       <h1 className="lg:text-lg text-lg">by Jordan Peterson</h1>
                       <div className="h-[0.1rem] bg-[#D9D9D9]"></div>
                       <h1 className='text-sm font-extralight'>4 minutes 1 seconds</h1>
               </div>
           </div>
           </button>
                   </div>
               }
               {
                   choice == 1
                   &&
                   <div className="lg:grid lg:grid-cols-3 lg:space-y-0 space-y-4 gap-8">
                       <div className="h-full">
             <button onClick={()=>{setLink('iCtoCeFTMME')
         setOpen(true)
         }} className=" text-left h-full w-full">
           <div className="bg-white px-4 py-5 flex h-full   lg:mr-0 mr-4   space-x-4 rounded-xl">
               <img src={thumbnail14} className="w-1/3 h-1/2 rounded-xl"></img>
                       <div className="space-y-2">
                       <h1 className="lg:text-xl text-lg font-bold">14 Ways To Instantly Improve Communication Skills</h1>
                       <h1 className="lg:text-lg text-lg">by Practical Wisdom</h1>
                       <div className="h-[0.1rem] bg-[#D9D9D9]"></div>
                       <h1 className='text-sm font-extralight'>11 minutes 49 seconds</h1>
               </div>
           </div>
           </button>
               </div>
   
               <div className="h-full">
             <button onClick={()=>{setLink('cFdCzN7RYbw')
         setOpen(true)
         }} className=" text-left h-full w-full">
           <div className="bg-white px-4 py-5 flex h-full  lg:mr-0 mr-4   space-x-4 rounded-xl">
               <img src={thumbnail10} className="w-1/3 h-1/2 rounded-xl"></img>
                       <div className="space-y-2">
                       <h1 className="lg:text-xl text-lg font-bold">Science Of Persuasion</h1>
                       <h1 className="lg:text-lg text-lg">by influenceatwork</h1>
                       <div className="h-[0.1rem] bg-[#D9D9D9]"></div>
                       <h1 className='text-sm font-extralight'>11 minutes 50 seconds</h1>
               </div>
           </div>
           </button>
               </div>
                   </div>
               }
               {
                   choice ==2
                   &&
                   <div className="lg:grid lg:grid-cols-3 lg:space-y-0 space-y-4 gap-8">
                       <div className="h-full">
             <button onClick={()=>{setLink('1sSing6ntVA')
         setOpen(true)
         }} className=" text-left h-full">
           <div className="bg-white px-4 py-5 flex  lg:mr-0 mr-4   space-x-4 rounded-xl">
               <img src={thumbnail11} className="w-1/3 h-full rounded-xl"></img>
                       <div className="space-y-2">
                       <h1 className="lg:text-xl text-lg font-bold">How to Improve your Teamwork Skills instead of Stressing Out by Peer Connect</h1>
                       <h1 className="lg:text-lg text-lg">by I Time Travel Well</h1>
                       <div className="h-[0.1rem] bg-[#D9D9D9]"></div>
                       <h1 className='text-sm font-extralight'>3 minutes 2 seconds</h1>
               </div>
           </div>
           </button>
               </div>
   
               <div className="h-full">
             <button onClick={()=>{setLink('UwsMogSQmYI')
         setOpen(true)
         }} className=" text-left h-full w-full">
           <div className="bg-white px-4 py-5 flex h-full   lg:mr-0 mr-4   space-x-4 rounded-xl">
               <img src={thumbnail12} className="w-1/3 h-1/2 rounded-xl"></img>
                       <div className="space-y-2">
                       <h1 className="lg:text-xl text-lg font-bold">Teamwork Reimagined</h1>
                       <h1 className="lg:text-lg text-lg">by Kevin Cahill</h1>
                       <div className="h-[0.1rem] bg-[#D9D9D9]"></div>
                       <h1 className='text-sm font-extralight'>13 minutes 53 seconds</h1>
               </div>
           </div>
           </button>
               </div>
                   </div>
               }
               {
                   choice == 3
                   &&
                   <div className="lg:grid lg:space-y-0 space-y-4 lg:grid-cols-3 gap-8">
                       <button onClick={()=>{setLink('rUJkbWNnNy4')
         setOpen(true)
         }} className=" text-left">
           <div className="bg-white px-4 py-5 flex  lg:mr-0 mr-4   space-x-4 rounded-xl">
               <img src={thumbnail5} className="w-1/3 h-full rounded-xl"></img>
                       <div className="space-y-2">
                       <h1 className="lg:text-xl text-lg font-bold">Developing A Growth Mindset</h1>
                       <h1 className="lg:text-lg text-lg">by ClickView</h1>
                       <div className="h-[0.1rem] bg-[#D9D9D9]"></div>
                       <h1 className='text-sm font-extralight'>4 minutes 51 seconds</h1>
               </div>
           </div>
           </button>
           <button onClick={()=>{setLink('hiiEeMN7vbQ')
         setOpen(true)
         }} className=" text-left">
           <div className="bg-white px-4 py-5 flex  lg:mr-0 mr-4   space-x-4 rounded-xl">
               <img src={thumbnail6} className="w-1/3 h-full rounded-xl"></img>
                       <div className="space-y-2">
                       <h1 className="lg:text-xl text-lg font-bold">Developing a Growth Mindset</h1>
                       <h1 className="lg:text-lg text-lg">by Carol Dweck</h1>
                       <div className="h-[0.1rem] bg-[#D9D9D9]"></div>
                       <h1 className='text-sm font-extralight'>4 minutes 51 seconds</h1>
               </div>
           </div>
           </button>
                   </div>
               }
               {
                choice  == 4
                &&
                <div>
                    <div className="lg:grid lg:space-y-0 space-y-4 lg:grid-cols-3 gap-8 items-stretch ">
          <div className="h-full">
        <button onClick={()=>{setLink('7wUCyjiyXdg')
      setOpen(true)
      }} className="h-full text-left w-full">
        <div className="bg-white px-4 h-full py-5 flex  w-full lg:mr-0 mr-4   space-x-4 rounded-xl">
            <img src={thumbnail8} className="w-1/3 h-1/2 rounded-xl"></img>
                    <div className="space-y-2">
                    <h1 className="lg:text-xl text-lg font-bold">Active Listening Skills</h1>
                    <h1 className="lg:text-lg text-lg">by Alexander Lyon</h1>
                    <div className="h-[0.1rem] bg-[#D9D9D9]"></div>
                    <h1 className='text-sm font-extralight'>6 minutes 13 seconds</h1>
            </div>
        </div>
        </button>
          </div>
          <div className="h-full">
        <button onClick={()=>{setLink('NqgIVZJv-do')
      setOpen(true)
      }} className=" text-left h-full">
        <div className="bg-white px-4 py-5 flex  lg:mr-0 mr-4   space-x-4 rounded-xl">
            <img src={thumbnail9} className="w-1/3 h-full rounded-xl"></img>
                    <div className="space-y-2">
                    <h1 className="lg:text-xl text-lg font-bold">5 Techniques to Improve Active Listening Skills</h1>
                    <h1 className="lg:text-lg text-lg">by English Learning Hub</h1>
                    <div className="h-[0.1rem] bg-[#D9D9D9]"></div>
                    <h1 className='text-sm font-extralight'>6 minutes 13 seconds</h1>
            </div>
        </div>
        </button>
          </div>
          
        </div>
                </div>

            }
               
           </div>
           {
             open &&
           <div className={width===300?"fixed top-0 left-0 lg:w-1/3 px-4 lg:h-1/2 bg-[#2d9dba] rounded-xl shadow-2xl":"fixed  right-0 bottom-0 lg:w-1/3 lg:h-1/2 bg-[#2d9dba] rounded-xl shadow-2xl"}>
             <div className="flex justify-between items-center">
             <p className="text-white ml-4 text-xl mt-4 font-poppins">Now Playing</p>
             <button className="text-white text-xl font-poppins mr-4" onClick={()=>{setOpen(false)}}>x</button>
             </div>
             <div className="mx-auto text-center">
             <YouTube videoId={link} iframeClassName='mx-auto my-4 ' className="" opts={{width:width, height:height}} />
             </div>
           </div>
           }
     </div>
          );
        }

        else if(filteredData[filteredData.length-1].chunk[0].InterviewType=="free" &&filteredData.length>0 &&currentData.length> 0 && latestData.length>0 &&penultimateData.length>0){
          return(
            <div className="mt-12 relative px-2">
              {/* {
                status.length == 0 &&
                <div className="absolute left-1/2 bg-gray-100 p-4 rounded-xl w-2/3 border-2 lg:w-1/2 top-2/3 lg:top-2/3 z-10 -translate-y-1/2 -translate-x-1/2">
                  <p className="font-poppins text-xl  md:text-2xl text-center">Download full report</p>
                  <p className="text-center font-poppins">and get access to</p>
                  <div className="flex items-start space-x-2 mt-4">
                <PiMonitorPlayFill className="text-3xl text-[#E94B60]"></PiMonitorPlayFill>
                <p>All tutorial videos</p>
            </div>
            <div className="flex items-start space-x-2 mt-4">
                <HiDocumentText className="text-3xl text-[#E94B60]"></HiDocumentText>
                <p>Automated resume builder</p>
            </div>
            <div className="flex items-start space-x-2 mt-4">
                <AiOutlineRise className="text-2xl text-[#E94B60]"></AiOutlineRise>
                <p>Progression tracking</p>
            </div>
            <div className="flex items-start space-x-2 mt-4">
                <FaChalkboardTeacher className="text-2xl text-[#E94B60]"></FaChalkboardTeacher>
                <p>Webinars</p>
            </div>
            <div className="flex items-start space-x-2 mt-4">
                <FaUserGraduate className="text-2xl text-[#E94B60]"></FaUserGraduate>
                <p>Exclusive Masterclass</p>
            </div>
            <div className="w-full my-3 flex space-x-3 justify-center mx-auto ">
              <input className="border outline-none py-2 rounded-lg px-2 w-full lg:w-1/2" value={email} onChange={(e)=>{setEmail(e.target.value)}} placeholder="Enter your email"></input>
            </div>

            <button disabled={!(email.length>0&& email.includes('@'))} className={(email.length>0&& email.includes('@'))?"w-full bg-yellow-500 py-3 rounded-xl my-3 font-poppins":"w-full bg-gray-300 py-3 rounded-xl my-3 font-poppins"} onClick={payment}>Pay now</button>
                </div>
              } */}
              
              <p className="font-poppins text-2xl mt-8">Your Dashboard</p>
              <p className="z-20 mt-2 font-poppins">The below report is an AI-generated report. This report will be refreshed over the next 24 hours to give you an accurate scorecard. You will also get a copy of this report on your registered email id</p>
            
                <div className="lg:flex space-y-4  mr-3 lg:space-x-4 font-poppins">
                    <div className="lg:w-2/3 ">
                        <div className="lg:grid   lg:grid-cols-2 space-y-4 lg:space-y-0 gap-4 my-4">
                            <div className="bg-white p-4 rounded-xl">
                                <div className="flex items-center justify-between">
                                    <p className="font-bold text-lg">Proficiency Score</p>
                                    <div>
                                        <div className='flex space-x-3'>
                                        <select onChange={(e)=>{
                                          if(e.target.value == 'tb1'){
                                            setCurrentData(latestData)
                                          }
                                          else if(e.target.value == 'tb2'){
                                            setCurrentData(penultimateData)
                                          }
                                        }}>
                                            <option value="tb1">This Test</option>
                                            {
                                            filteredData.length > 1 &&  
                                            <option value="tb2">Previous Test</option>
                                            }
                                        </select>
                                        </div>
                                    </div>
                                </div>
                                        <Doughnut
                                        // plugins={[centerPlugin]}
                                        c
                                        plugins={[donutText]}
                                        
                                        data={{
                                            labels:['Introduction','Communication', 'Active Listening','Teamwork','Growth Mindset','Critical Thinking'],
                                            datasets:[{
                                                label:'Number of marks',
                                                backgroundColor:[
                                                  "#1BC5BD",
                                                  "#727CF5",
                                                  "#FECC4C",
                                                  "#FE8C4C",
                                                  "#42AAFF",
                                                  "#E94B60"
                                                ],
                                                // data: [criticalMarks[criticalMarks.length-1], gmMarks[gmMarks.length-1], commMarks[commMarks.length-1], lsMarks[lsMarks.length-1], twMarks[twMarks.length-1], introMarks[introMarks.length-1]],
                                                // data:[3,4,3,2,5,3]
                                                data:currentData
                                            }]
                                        }}
                                        options={{
                                          cutout:60,
                                          responsive:true,
                                          layout: {
                                            padding: {
                                                left: 75,
                                                right: 75,
                                                top: 75,
                                                bottom: 75
                                            }
                                        },
                                          maintainAspectRatio:true,
                                          plugins:{
                                            legend: {
                                              display: false
                                          },
                                            
                                            datalabels:{
                                              anchor:'end',
                                              align:'end',
                                              clip:false,
                                              color:"black",
                                              textAlign:"center",
                                              borderColor:"white",
                                              borderRadius:"20",
                                              borderWidth:"2",
                                              display:function(context){
                                                var index = context.dataIndex;
                                                var value = context.dataset.data[index];
                                                return value
                                              },
                                              formatter:function(value, context){
                                                if(context.dataIndex == 0 )
                                                return 'Introduction \n'+ Math.round(value/(currentData[0]+currentData[1]+currentData[2]+currentData[3]+currentData[4]+currentData[5])*100) + '%';
                                                if(context.dataIndex == 1)
                                                return 'Critical\n Thinking \n' + Math.round(value/(currentData[0]+currentData[1]+currentData[2]+currentData[3]+currentData[4]+currentData[5])*100) + '%';
                                                if(context.dataIndex == 2)
                                                return 'Active\n Listening \n'+ Math.round(value/(currentData[0]+currentData[1]+currentData[2]+currentData[3]+currentData[4]+currentData[5])*100) + '%';
                                                if(context.dataIndex == 3)
                                                return 'Growth\n Mindset \n' + Math.round(value/(currentData[0]+currentData[1]+currentData[2]+currentData[3]+currentData[4]+currentData[5])*100) + '%';
                                                if(context.dataIndex == 4)
                                                return 'Team\nwork \n'+ Math.round(value/(currentData[0]+currentData[1]+currentData[2]+currentData[3]+currentData[4]+currentData[5])*100) + '%';
                                                if(context.dataIndex == 5)
                                                return 'Communication \n'+ Math.round(value/(currentData[0]+currentData[1]+currentData[2]+currentData[3]+currentData[4]+currentData[5])*100) + '%';
                                              }
                                            }
                                          }
                                        }}
                                        ></Doughnut>
                            </div>
                            <ReactCardFlip isFlipped={flip}>
                            <div className="bg-white p-4 rounded-xl">
                                <div className="flex items-center justify-between">
                                    <p className="font-bold text-lg">Skills</p>
                                    <div>
                                        <div className='flex space-x-3'>
                                        <select onChange={(e)=>{
                                          setActiveTab(e.target.value)
                                        }} defaultValue="comm">
                                            <option value="intro">Introduction</option>
                                            <option value="comm">Communication</option>
                                            <option  value="ls">Active Listening</option>
                                            <option value="tw">Teamwork</option>
                                            <option value="gm">Growth Mindset</option>
                                            <option value="ct">Critical Thinking</option>
                                        </select>
                                        <button onClick={()=>{setFlip(!flip)}}>
                                        <img src={dashboard_flip} className="h-5"></img>
                                        </button>
                                        </div>
                                    </div>
                                </div>
                                        <Doughnut
                                        className={flip?"invisible":""}
                                        plugins={[gaugeText, gaugeText5,]}
                                        // plugins={[centerPlugin]}
                                        data={{
                                            labels:['Number of marks'],
                                            datasets:[{
                                                label:'Number of marks',
                                                backgroundColor:(context)=>{
                                                  const chart = context.chart;
                                                  const {ctx, chartArea} = chart;
                                                  if(!chartArea) return null;
                                                  if(context.dataIndex === 0){
                                                    return getGradient(chart);
                                                  }
                                                  else{
                                                    return '#F3F6F9'
                                                  }
                                                },
                                                data:[meterdata[meterdata.length-1],5-meterdata[meterdata.length-1]]
                          
                                            }]
                                        }}
                                        options={{
                                          borderRadius:'100',
                                          cutout:100,
                                          responsive:true,
                                          maintainAspectRatio:true,
                                          circumference:180,
                                          rotation:270,
                                          layout: {
                                            padding: {
                                                left: 20,
                                                right: 20,
                                                top: 20,
                                                bottom: 20
                                            }
                                        },
                                          plugins:{
                                            legend:{
                                              display:false,
                                            },
                                            datalabels:{
                                              display:function(context){
                                                var index = context.dataIndex;
                                                var value = context.dataset.data[index];
                                                return value>10
                                              }
                                            }
                                          }
                                        }}
                                        ></Doughnut>

                                        <div className="bg-[#F3F6FF] -mt-10 rounded-xl p-3 text-center">
                                          <p>Your Best Score</p>
                                          <p className="text-2xl font-bold">{Math.max(...meterdata)}</p>
                                        </div>
                            </div>

                            <div className="bg-white p-4 h-full rounded-xl">
                              <div className="flex justify-end" >
                              <button onClick={()=>{setFlip(!flip)}}>
                                <img src={dashboard_flip} className="h-5"></img>
                              </button>
                              </div>
                              {
                                activeTab == 'ls' &&
                                <div className="p-3">
                                  <div className=' rounded-xl '>
                                  Active listening refers to the attentive and intentional process of fully comprehending and understanding what someone is saying. It involves not only hearing the words being spoken but also actively engaging with the speaker by focusing on their message, observing their body language, and providing verbal and nonverbal feedback to demonstrate understanding.
                                  </div>
                        
                                </div>
                              }
                              {
                                activeTab == 'intro' &&
                                <div className="p-3">
                                  Introduction is simply the art of introducing yourself. It typically includes sharing personal details, such as name, educational background, professional experience, and relevant skills. The purpose of the introduction is to create a positive first impression, highlight key qualifications, and set the tone for the rest of the interview.
                                </div>
                              }
                              {
                                activeTab=="comm" &&
                                <div className="p-3">
                                  Communication refers to the process of exchanging information, ideas, thoughts, and feelings between individuals or groups through various mediums such as spoken or written words, gestures, body language, or visual and auditory signals. It involves both the transmission and reception of messages, with the goal of conveying meaning and understanding between the sender and receiver. 
                                </div>
                              }
                              {
                                activeTab=="gm" &&
                                <div className="p-3">
                                  Growth mindset refers to the belief that one's abilities and intelligence can be developed and improved through effort, perseverance, and a willingness to learn. It is a mindset that embraces challenges, sees failures as opportunities for growth, and values the process of learning and development. 
                                </div>
                              }
                              {
                                activeTab=="ct" &&
                                <div className="p-3">
                                  Critical thinking refers to the ability to objectively analyse, evaluate, and interpret information or situations in a logical and systematic manner. It involves actively and carefully considering evidence, arguments, and various perspectives to form well-reasoned judgments or decisions.
                                </div>
                              }
                              {
                                activeTab=="tw" &&
                                <div className="p-3">
                                  Teamwork refers to the collaborative effort of individuals working together towards a common goal or objective. It involves the coordination, cooperation, and integration of individual strengths, skills, and efforts to achieve a shared outcome.
                                </div>
                              }

                            </div>
                            </ReactCardFlip>
                        </div>
                        <div className={status.length>0?"my-4 grid grid-cols-2 lg:grid-cols-4 gap-4":"my-4 grid grid-cols-2 lg:grid-cols-4 gap-4 "}>
                          <div className="bg-white p-4 rounded-xl relative flex flex-col justify-around">
                            <div className=" ">
                              <img src={top1} className="h-10 rounded-xl p-2 bg-[#EE9D01]"></img>
                            </div>
                            <p className="mt-2 text-sm text-[#464E5F]">Your Percentile <br></br> Rank</p>
                            <p className="lg:text-md my-3 font-bold text-xl text-[#464E5F]">{Math.round(percentile(totalMarks,total))}<span>
                            {
                                Math.round(percentile(totalMarks,total))%10 == 1 &&
                                "st"
                              }
                              {
                                Math.round(percentile(totalMarks,total))%10 == 2 &&
                                "nd"
                              }
                              {
                                Math.round(percentile(totalMarks,total))%10 == 3 &&
                                "rd"
                              }
                              {
                                Math.round(percentile(totalMarks,total))%10> 4 &&
                                "th"
                              }
                              </span></p>
                            {
                              (filteredData.length>1 && penultimateData.length>1 && penultimateData!=null) &&
                            <p className=" text-sm text-[#464E5F] my-1  bottom-4"><span className={Math.round(percentile(totalMarks,total)) - Math.round(percentile(totalMarks,prevTotal))>0?"text-[#4AB58E] font-bold":"text-[#E94B60] font-bold"}>{Math.round(percentile(totalMarks,total)) - Math.round(percentile(totalMarks,prevTotal))>0?"+":""}{Math.round(percentile(totalMarks,total)) - Math.round(percentile(totalMarks,prevTotal))}</span> Latest Test</p>
                            }
                          </div>
                          <div className="bg-white p-4 rounded-xl relative flex flex-col justify-around">
                            <div className=" ">
                              <img src={star} className="h-10 rounded-xl p-2 bg-[#5F5CF1]"></img>
                            </div>
                            <p className=" text-sm text-[#464E5F]">Best<br></br> Skill</p>
                            <p className="lg:text-md my-3 font-bold text-xl text-[#464E5F] truncate">
                              {
                                latestData.indexOf(Math.max(...latestData)) == 0 &&
                                <span>Introduction</span>
                              }
                              {
                                latestData.indexOf(Math.max(...latestData)) == 1 &&
                                <span>Communication</span>
                              }
                              {
                                latestData.indexOf(Math.max(...latestData)) == 2 &&
                                <span>Active Listening</span>
                              }
                              {
                                latestData.indexOf(Math.max(...latestData)) == 3 &&
                                <span>Teamwork</span>
                              }
                              {
                                latestData.indexOf(Math.max(...latestData)) == 4 &&
                                <span>Growth Mindset</span>
                              }
                              {
                                latestData.indexOf(Math.max(...latestData)) == 5 &&
                                <span>Critical Thinking</span>
                              }
                            </p>
                            {
                              (filteredData.length>1 && penultimateData.length>1 && penultimateData.length!=null) &&
                            <p className=" text-sm text-[#464E5F] my-1  bottom-4"><span className="text-[#4AB58E]  font-bold">
                              {
                                latestData.indexOf(Math.max(...latestData)) == 0 &&
                                <span>{
                                  (introMarks[introMarks.length-1]) - (introMarks[introMarks.length-2])>=0?"+":""
                                  }{(introMarks[introMarks.length-1]) - (introMarks[introMarks.length-2])}</span>
                              }
                              {
                                latestData.indexOf(Math.max(...latestData)) == 1 &&
                                <span>{
                                  (commMarks[commMarks.length-1]) - (commMarks[commMarks.length-2])>=0?"+":""
                                  }{(commMarks[commMarks.length-1]) - (commMarks[commMarks.length-2])}</span>
                              }
                              {
                                latestData.indexOf(Math.max(...latestData)) == 2 &&
                                <span>
                                  {(lsMarks[lsMarks.length-1]) - (lsMarks[lsMarks.length-2])>=0?"+":""}
                                  {(lsMarks[lsMarks.length-1]) - (lsMarks[lsMarks.length-2])}</span>
                              }
                              {
                                latestData.indexOf(Math.max(...latestData)) == 3 &&
                                <span>{(twMarks[twMarks.length-1]) - (twMarks[twMarks.length-2])>0?"+":""}{(twMarks[twMarks.length-1]) - (twMarks[twMarks.length-2])}</span>
                              }
                              {
                                latestData.indexOf(Math.max(...latestData)) == 4 &&
                                <span>{(gmMarks[gmMarks.length-1]) - (gmMarks[gmMarks.length-2])>0?"+":""}{(gmMarks[gmMarks.length-1]) - (gmMarks[gmMarks.length-2])}</span>
                              }
                              {
                                latestData.indexOf(Math.max(...latestData)) == 5 &&
                                <span>{(criticalMarks[criticalMarks.length-1]) - (criticalMarks[criticalMarks.length-2])>0?"+":""}{(criticalMarks[criticalMarks.length-1]) - (criticalMarks[criticalMarks.length-2])}</span>
                              }
                            </span> Latest Test</p>
                            }
                          </div>
                          <div className="bg-white p-4 rounded-xl relative flex flex-col justify-around h-[16rem]">
                            <div className=" ">
                              <img src={increase} className="h-10 rounded-xl p-2 bg-[#1BC5BD]"></img>
                            </div>
                            <p className=" text-sm text-[#464E5F]">Needs <br></br> Improvement</p>
                            <p className="lg:text-md my-3 font-bold text-xl text-[#464E5F] truncate">
                            {
                                latestData.indexOf(Math.min(...latestData)) == 0 &&
                                <span>Introduction</span>
                              }
                              {
                                latestData.indexOf(Math.min(...latestData)) == 1 &&
                                <span>Communication</span>
                              }
                              {
                                latestData.indexOf(Math.min(...latestData)) == 2 &&
                                <span>Active Listening</span>
                              }
                              {
                                latestData.indexOf(Math.min(...latestData)) == 3 &&
                                <span>Teamwork</span>
                              }
                              {
                                latestData.indexOf(Math.min(...latestData)) == 4 &&
                                <span>Growth Mindset</span>
                              }
                              {
                                latestData.indexOf(Math.max(...latestData)) == 5 &&
                                <span>Critical Thinking</span>
                              }
                            </p>
                            {
                              (filteredData.length>1 && penultimateData.length>1 && penultimateData.length!=null) &&
                            <p className=" text-sm text-[#464E5F]  bottom-4 my-1"><span className="text-[#4AB58E] font-bold">
                            {
                                latestData.indexOf(Math.min(...latestData)) == 0 &&
                                <span>{
                                  (introMarks[introMarks.length-1]) - (introMarks[introMarks.length-2])>=0?"+":""
                                  }{(introMarks[introMarks.length-1]) - (introMarks[introMarks.length-2])}</span>
                              }
                              {
                                latestData.indexOf(Math.min(...latestData)) == 1 &&
                                <span>{
                                  (commMarks[commMarks.length-1]) - (commMarks[commMarks.length-2])>=0?"+":""
                                  }{(commMarks[commMarks.length-1]) - (commMarks[commMarks.length-2])}</span>
                              }
                              {
                                latestData.indexOf(Math.min(...latestData)) == 2 &&
                                <span>
                                  {(lsMarks[lsMarks.length-1]) - (lsMarks[lsMarks.length-2])>=0?"+":""}
                                  {(lsMarks[lsMarks.length-1]) - (lsMarks[lsMarks.length-2])}</span>
                              }
                              {
                                latestData.indexOf(Math.min(...latestData)) == 3 &&
                                <span>{(twMarks[twMarks.length-1]) - (twMarks[twMarks.length-2])>0?"+":""}{(twMarks[twMarks.length-1]) - (twMarks[twMarks.length-2])}</span>
                              }
                              {
                                latestData.indexOf(Math.min(...latestData)) == 4 &&
                                <span>{(gmMarks[gmMarks.length-1]) - (gmMarks[gmMarks.length-2])>0?"+":""}{(gmMarks[gmMarks.length-1]) - (gmMarks[gmMarks.length-2])}</span>
                              }
                              {
                                latestData.indexOf(Math.min(...latestData)) == 5 &&
                                <span>{(criticalMarks[criticalMarks.length-1]) - (criticalMarks[criticalMarks.length-2])>0?"+":""}{(criticalMarks[criticalMarks.length-1]) - (criticalMarks[criticalMarks.length-2])}</span>
                              }
                              </span> Latest Test</p>
                            }
                          </div>
                          <div className="bg-white p-4 rounded-xl relative  flex flex-col justify-around">
                            <div className=" ">
                              <img src={bar_graph} className="h-10 rounded-xl -mt-2 p-2 bg-[#E94B60]"></img>
                            </div>
                            <p className="-mt-1 text-sm text-[#464E5F]">Maximum<br></br> Improvement</p>
                            <p className="lg:text-md my-3 font-bold text-xl text-[#464E5F]">
                            {
                                difference.indexOf(Math.max(...difference)) == 0 &&
                                <span>Introduction</span>
                              }
                              {
                                difference.indexOf(Math.max(...difference)) == 1 &&
                                <span>Communication</span>
                              }
                              {
                                difference.indexOf(Math.max(...difference)) == 2 &&
                                <span>Active Listening</span>
                              }
                              {
                                difference.indexOf(Math.max(...difference)) == 3 &&
                                <span>Teamwork</span>
                              }
                              {
                                difference.indexOf(Math.max(...difference)) == 4 &&
                                <span>Growth Mindset</span>
                              }
                              {
                                difference.indexOf(Math.max(...difference)) == 5 &&
                                <span>Critical Thinking</span>
                              }
                            </p>
                            {
                              (filteredData.length>1 && penultimateData.length>1 && penultimateData.length!=null) &&
                            <p className=" text-sm text-[#464E5F]  bottom-4 my-1"><span className="text-[#4AB58E] font-bold">
                            {
                                difference.indexOf(Math.max(...difference)) == 0 &&
                                <span>{
                                  (introMarks[introMarks.length-1]) - (introMarks[introMarks.length-2])>=0?"+":""
                                  }{(introMarks[introMarks.length-1]) - (introMarks[introMarks.length-2])}</span>
                              }
                              {
                                difference.indexOf(Math.max(...difference)) == 1 &&
                                <span>{
                                  (commMarks[commMarks.length-1]) - (commMarks[commMarks.length-2])>=0?"+":""
                                  }{(commMarks[commMarks.length-1]) - (commMarks[commMarks.length-2])}</span>
                              }
                              {
                                difference.indexOf(Math.max(...difference)) == 2 &&
                                <span>
                                  {(lsMarks[lsMarks.length-1]) - (lsMarks[lsMarks.length-2])>=0?"+":""}
                                  {(lsMarks[lsMarks.length-1]) - (lsMarks[lsMarks.length-2])}</span>
                              }
                              {
                                difference.indexOf(Math.max(...difference)) == 3 &&
                                <span>{(twMarks[twMarks.length-1]) - (twMarks[twMarks.length-2])>0?"+":""}{(twMarks[twMarks.length-1]) - (twMarks[twMarks.length-2])}</span>
                              }
                              {
                                difference.indexOf(Math.max(...difference)) == 4 &&
                                <span>{(gmMarks[gmMarks.length-1]) - (gmMarks[gmMarks.length-2])>0?"+":""}{(gmMarks[gmMarks.length-1]) - (gmMarks[gmMarks.length-2])}</span>
                              }
                              {
                                difference.indexOf(Math.max(...difference)) == 5 &&
                                <span>{(criticalMarks[criticalMarks.length-1]) - (criticalMarks[criticalMarks.length-2])>0?"+":""}{(criticalMarks[criticalMarks.length-1]) - (criticalMarks[criticalMarks.length-2])}</span>
                              }
                            </span> Latest Test</p>
                            }
                          </div>
                        </div>
                        <div className={status.length>0?"bg-white p-4 rounded-xl":"bg-white p-4 rounded-xl "}>
                          <div className="flex mb-5 justify-between items-center ">
                          <p className="font-bold text-xl ">Growth Map</p>
                          <p>Last 2 tests</p>
                          </div>
                          <Bar
                          data={{
                            labels:["Introduction","Communication","Active Listening","Teamwork","Growth Mindset","Critical Thinking"],
                            datasets:[
                              {
                                label:"Previous test",
                                data:penultimateData,
                                backgroundColor: color=>{
                                  if(color.index === 0){
                                    return "#9BE8CE" //light blue
                                  }
                                  else if(color.index ===1){
                                    return "#CCCFFB" //light violet
                                  }
                                  else if(color.index ===2){
                                    return "#F9E6B7" //light yellow
                                  }
                                  else if(color.index === 3){
                                    return "#FFDCC8" //light orange
                                  }
                                  else if(color.index === 4){
                                    return "#D4ECFF" //light blue
                                  }
                                  else if(color.index == 5){
                                    return "#FBDBDF" //light red
                                  }
                                },
                              },
                              {
                                label: 'This Test',
                                data: latestData,
                                backgroundColor: color=>{
                                  if(color.index == 0){
                                    return "#1BC5BD"
                                  }
                                  else if (color.index===1){
                                    return "#727CF5"
                                  }
                                  else if(color.index === 2){
                                    return "#FECC4C"
                                  }
                                  else if(color.index === 3){
                                    return "#FE8C4C"
                                  }
                                  else if(color.index === 4){
                                    return "#42AAFF"
                                  }
                                  else if(color.index === 5){
                                    return "#E94B60"
                                  }
                                },
                              },
                            ]
                          }}
                          options={{
                            responsive:true,
                            maintainAspectRatio:true,
                            y:{
                              min:0,
                              max:5,
                              ticks:{
                                stepSize:1
                              }
                            },
                            scales:{
                              x:{
                                grid:{
                                  display:false
                                }
                              }
                            },
                            plugins:{
                              legend: {
                                display:false,
                              },
                              datalabels:{
                                display:false,
                              }
                            },
                            borderRadius:5,
                          }}
                          ></Bar>
                        </div>
                    </div>
                    {
                      history!=null ?
                    <div className={status.length>0?"lg:w-1/3  m-4 bg-white rounded-xl":"lg:w-1/3  m-4 bg-white rounded-xl "}>
                      <div className="bg-[#383838] rounded-t-xl p-4 text-white">
                        <p className="text-2xl text-center ">Resources</p>
                        <p className="font-extrabold text-4xl text-center my-3 ">{
                          history==null
                          ?
                          '0'
                          :
                          history.length
                        }</p>
                        <p className="text-lg text-center ">Total Resources Consumed</p>
                      </div>
                      <div className="flex items-center mx-4 justify-between my-3">
                        <p className="font-extrabold text-lg">Resources</p>
                      </div>
                      <div className="my-3 grid grid-cols-2 text-center items-center px-2 py-1 bg-[#F3F6FF] rounded-xl mx-4">
                        <button onClick={()=>{setTab(0)}}>
                        <div className={tab==0&&"bg-white py-1 rounded-xl"}>
                          <p>Total</p>
                        </div>
                        </button>
                        <button onClick={()=>{setTab(1)}}>
                        <div className={tab==1 &&"bg-white py-1 rounded-xl"}>
                          <p>History</p>
                        </div>
                        </button>
                      </div>
                      {
                        tab===0 &&
                      <div>
                      <div>
                      <Doughnut
                                        className=" w-full"
                                        data={{
                                            labels:['Webinars','Critical Thinking', 'Teamwork','Communication','Growth Mindset','Active Listening'],
                                            datasets:[{
                                                label:'Number of marks',
                                                backgroundColor:[
                                                    "#1BC5BD",
                                                    "#E94B60",
                                                    "#FE8C4C",
                                                    "#727CF5",
                                                    "#FECC4C",
                                                    "#42AAFF",
                                                ],
                                                // data: [criticalMarks[criticalMarks.length-1], gmMarks[gmMarks.length-1], commMarks[commMarks.length-1], lsMarks[lsMarks.length-1], twMarks[twMarks.length-1], introMarks[introMarks.length-1]],
                                                // data:[3,4,3,2,5,3]
                                                data:skills
                                            }]
                                        }}
                                        options={{
                                          responsive:true,
                                          maintainAspectRatio: true,
                                          layout: {
                                            padding: {
                                                left: 108,
                                                right: 100,
                                                top: 40,
                                                bottom: 60
                                            }
                                        },
                                          maintainAspectRatio:true,
                                          plugins:{
                                            legend: {
                                              display: false
                                          },
                                            
                                            datalabels:{
                                              anchor:'end',
                                              align:'end',
                                              clip:false,
                                              color:"black",
                                              textAlign:"center",
                                              borderColor:"white",
                                              borderRadius:"20",
                                              borderWidth:"2",
                                              display:function(context){
                                                var index = context.dataIndex;
                                                var value = context.dataset.data[index];
                                                return value
                                              },
                                              formatter:function(value, context){
                                                if(context.dataIndex == 0 )
                                                return 'Webinars';
                                                if(context.dataIndex == 1)
                                                return 'Critical\n Thinking ' 
                                                if(context.dataIndex == 2)
                                                return 'Teamwork \n'
                                                if(context.dataIndex == 3)
                                                return 'Communication \n'
                                                if(context.dataIndex == 4)
                                                return 'Growth\nMindset'
                                                if(context.dataIndex == 5)
                                                return 'Active\nListening'
                                              }
                                            }
                                          }
                                        }}
                                        ></Doughnut>

                      </div>
                      <div className="flex flex-col space-y-8 mx-4">
                        <div className="flex items-center space-x-3">
                          <div className="bg-[#1BC5BD] p-2 rounded-xl">
                            <img src={intro1} className="h-6"></img>
                          </div>
                          <div className="flex flex-col w-full">
                          <div className="flex justify-between w-full">
                            <p className="font-poppins font-bold">Webinar</p>
                            <p className="font-poppins">{skills[0]}</p>
                          </div>
                          <div>
                            <meter className="w-full background-none metr1" min={0.0} max={history.length} value={skills[0]}></meter>
                          </div>
                          </div>
                        </div>
                        <div className="flex items-center space-x-3">
                          <div className="bg-[#E94B60] p-2 rounded-xl">
                            <img src={skill1} className="h-6"></img>
                          </div>
                          <div className="flex flex-col w-full ">
                          <div className="flex justify-between w-full">
                            <p className="font-poppins font-bold">Critical Thinking</p>
                            <p className="font-poppins">{skills[1]}</p>
                          </div>
                          <div>
                            <meter className="w-full background-none metr2 outline-none border-none" min={0.0} max={history.length} value={skills[1]}></meter>
                          </div>
                          </div>
                        </div>
                        <div className="flex items-center space-x-3">
                          <div className="bg-[#FE8C4C] p-2 rounded-xl">
                            <img src={teamwork1} className="h-6"></img>
                          </div>
                          <div className="flex flex-col w-full">
                          <div className="flex justify-between w-full">
                            <p className="font-poppins font-bold">Teamwork</p>
                            <p className="font-poppins">{skills[2]}</p>
                          </div>
                          <div>
                            <meter className="w-full background-none metr3" min={0.0} max={history.length} value={skills[2]}></meter>
                          </div>
                          </div>
                        </div>
                        <div className="flex items-center space-x-3">
                          <div className="bg-[#727CF5] p-2 rounded-xl">
                            <img src={chat} className="h-6"></img>
                          </div>
                          <div className="flex flex-col w-full">
                          <div className="flex justify-between w-full">
                            <p className="font-poppins font-bold">Communication</p>
                            <p className="font-poppins">{skills[3]}</p>
                          </div>
                          <div>
                            <meter className="w-full background-none metr4" min={0.0} max={history.length} value={skills[3]}></meter>
                          </div>
                          </div>
                        </div>
                        <div className="flex items-center space-x-3">
                          <div className="bg-[#42AAFF] p-2 rounded-xl">
                            <img src={enthu} className="h-6"></img>
                          </div>
                          <div className="flex flex-col w-full">
                          <div className="flex justify-between w-full">
                            <p className="font-poppins font-bold">Growth Mindset</p>
                            <p className="font-poppins">{skills[4]}</p>
                          </div>
                          <div>
                            <meter className="w-full background-none metr5" min={0.0} max={history.length} value={skills[4]}></meter>
                          </div>
                          </div>
                        </div>
                        <div className="flex items-center space-x-3">
                          <div className="bg-[#FECC4C] p-2 rounded-xl">
                            <img src={ear} className="h-6"></img>
                          </div>
                          <div className="flex flex-col w-full">
                          <div className="flex justify-between w-full">
                            <p className="font-poppins font-bold">Active Listening</p>
                            <p className="font-poppins">{skills[5]}</p>
                          </div>
                          <div>
                            <meter className="w-full background-none metr6" min={0.0} max={history.length} value={skills[5]}></meter>
                          </div>
                          </div>
                        </div>
                      </div>
                      </div>
                      }
                      {
                        tab===1
                        &&
                        <div className="mx-3 space-y-6 overflow-y-scroll h-[55rem] scrollbar-hide">
                          {
                            history.slice(0).reverse().map((el)=>{
                              return(
                                <div className="my-2 p-4 rounded-xl bg-gray-100 flex items-center space-x-3">
                                  <img src={`https://img.youtube.com/vi/${el.videocode}/0.jpg`} className="h-16 rounded-xl"></img>
                                  <p>{el.title}</p>
                                </div>
                              )
                            })
                          }
                        </div>
                      }
                    </div>
                    :
                    <div className={status.length>0?"bg-white p-4 rounded-xl":"bg-white p-4 rounded-xl "}>
                      <p>Check out our resources section to see this information</p>
                    </div>
                    }
                </div>
                </div>
          );
        }

        else if(filteredData.length>0 && filteredData[filteredData.length-1].marks.length>0 && filteredData[filteredData.length-1].chunk[0].InterviewType=="paid" && currentData.length> 0 && latestData.length>0 &&penultimateData.length>0){
          return(
            <div className="mt-12">
              <p className="font-poppins text-2xl mt-8">Your Dashboard</p>
                <div className="lg:flex space-y-4  mr-3 lg:space-x-4 font-poppins">
                    <div className="lg:w-2/3 ">
                        <div className="lg:grid   lg:grid-cols-2 space-y-4 lg:space-y-0 gap-4 my-4">
                            <div className="bg-white p-4 rounded-xl">
                                <div className="flex items-center justify-between">
                                    <p className="font-bold text-lg">Proficiency Score</p>
                                    <div>
                                        <div className='flex space-x-3'>
                                        <select onChange={(e)=>{
                                          if(e.target.value == 'tb1'){
                                            setCurrentData(latestData)
                                          }
                                          else if(e.target.value == 'tb2'){
                                            setCurrentData(penultimateData)
                                          }
                                        }}>
                                            <option value="tb1">This Test</option>
                                            {
                                            filteredData.length > 1 &&  
                                            <option value="tb2">Previous Test</option>
                                            }
                                        </select>
                                        </div>
                                    </div>
                                </div>
                                        <Doughnut
                                        // plugins={[centerPlugin]}
                                        className=" w-full"
                                        plugins={[donutText]}
                                        
                                        data={{
                                            labels:['Introduction','Communication', 'Active Listening','Teamwork','Growth Mindset','Critical Thinking'],
                                            datasets:[{
                                                label:'Number of marks',
                                                backgroundColor:[
                                                  "#1BC5BD",
                                                  "#727CF5",
                                                  "#FECC4C",
                                                  "#FE8C4C",
                                                  "#42AAFF",
                                                  "#E94B60"
                                                ],
                                                // data: [criticalMarks[criticalMarks.length-1], gmMarks[gmMarks.length-1], commMarks[commMarks.length-1], lsMarks[lsMarks.length-1], twMarks[twMarks.length-1], introMarks[introMarks.length-1]],
                                                // data:[3,4,3,2,5,3]
                                                data:currentData
                                            }]
                                        }}
                                        options={{
                                          cutout:60,
                                          responsive:true,
                                          layout: {
                                            padding: {
                                                left: 75,
                                                right: 75,
                                                top: 75,
                                                bottom: 75
                                            }
                                        },
                                          maintainAspectRatio:true,
                                          plugins:{
                                            legend: {
                                              display: false
                                          },
                                            
                                            datalabels:{
                                              anchor:'end',
                                              align:'end',
                                              clip:false,
                                              color:"black",
                                              textAlign:"center",
                                              borderColor:"white",
                                              borderRadius:"20",
                                              borderWidth:"2",
                                              display:function(context){
                                                var index = context.dataIndex;
                                                var value = context.dataset.data[index];
                                                return value
                                              },
                                              formatter:function(value, context){
                                                if(context.dataIndex == 0 )
                                                return 'Introduction \n'+ Math.round(value/(currentData[0]+currentData[1]+currentData[2]+currentData[3]+currentData[4]+currentData[5])*100) + '%';
                                                if(context.dataIndex == 1)
                                                return 'Critical\n Thinking \n' + Math.round(value/(currentData[0]+currentData[1]+currentData[2]+currentData[3]+currentData[4]+currentData[5])*100) + '%';
                                                if(context.dataIndex == 2)
                                                return 'Active\n Listening \n'+ Math.round(value/(currentData[0]+currentData[1]+currentData[2]+currentData[3]+currentData[4]+currentData[5])*100) + '%';
                                                if(context.dataIndex == 3)
                                                return 'Growth\n Mindset \n' + Math.round(value/(currentData[0]+currentData[1]+currentData[2]+currentData[3]+currentData[4]+currentData[5])*100) + '%';
                                                if(context.dataIndex == 4)
                                                return 'Team\nwork \n'+ Math.round(value/(currentData[0]+currentData[1]+currentData[2]+currentData[3]+currentData[4]+currentData[5])*100) + '%';
                                                if(context.dataIndex == 5)
                                                return 'Communication \n'+ Math.round(value/(currentData[0]+currentData[1]+currentData[2]+currentData[3]+currentData[4]+currentData[5])*100) + '%';
                                              }
                                            }
                                          }
                                        }}
                                        ></Doughnut>
                            </div>
                            <ReactCardFlip isFlipped={flip}>
                            <div className="bg-white p-4 rounded-xl">
                                <div className="flex items-center justify-between">
                                    <p className="font-bold text-lg">Skills</p>
                                    <div>
                                        <div className='flex space-x-3'>
                                        <select onChange={(e)=>{
                                          setActiveTab(e.target.value)
                                        }} defaultValue="comm">
                                            <option value="intro">Introduction</option>
                                            <option value="comm">Communication</option>
                                            <option  value="ls">Active Listening</option>
                                            <option value="tw">Teamwork</option>
                                            <option value="gm">Growth Mindset</option>
                                            <option value="ct">Critical Thinking</option>
                                        </select>
                                        <button onClick={()=>{setFlip(!flip)}}>
                                        <img src={dashboard_flip} className="h-5"></img>
                                        </button>
                                        </div>
                                    </div>
                                </div>
                                        <Doughnut
                                        className={flip?"invisible":""}
                                        plugins={[gaugeText, gaugeText5,]}
                                        // plugins={[centerPlugin]}
                                        data={{
                                            labels:['Number of marks'],
                                            datasets:[{
                                                label:'Number of marks',
                                                backgroundColor:(context)=>{
                                                  const chart = context.chart;
                                                  const {ctx, chartArea} = chart;
                                                  if(!chartArea) return null;
                                                  if(context.dataIndex === 0){
                                                    return getGradient(chart);
                                                  }
                                                  else{
                                                    return '#F3F6F9'
                                                  }
                                                },
                                                data:[meterdata[meterdata.length-1],5-meterdata[meterdata.length-1]]
                          
                                            }]
                                        }}
                                        options={{
                                          borderRadius:'100',
                                          cutout:100,
                                          responsive:true,
                                          maintainAspectRatio:true,
                                          circumference:180,
                                          rotation:270,
                                          layout: {
                                            padding: {
                                                left: 20,
                                                right: 20,
                                                top: 20,
                                                bottom: 20
                                            }
                                        },
                                          plugins:{
                                            legend:{
                                              display:false,
                                            },
                                            datalabels:{
                                              display:function(context){
                                                var index = context.dataIndex;
                                                var value = context.dataset.data[index];
                                                return value>10
                                              }
                                            }
                                          }
                                        }}
                                        ></Doughnut>

                                        <div className="bg-[#F3F6FF] -mt-10 rounded-xl p-3 text-center">
                                          <p>Your Best Score</p>
                                          <p className="text-2xl font-bold">{Math.max(...meterdata)}</p>
                                        </div>
                            </div>
                            <div className="bg-white p-4 h-full rounded-xl">
                              <div className="flex justify-end" >
                              <button onClick={()=>{setFlip(!flip)}}>
                                <img src={dashboard_flip} className="h-5"></img>
                              </button>
                              </div>
                              {
                                activeTab == 'ls' &&
                                <div className="p-3">
                                  <div className=' rounded-xl '>
                                  Active listening refers to the attentive and intentional process of fully comprehending and understanding what someone is saying. It involves not only hearing the words being spoken but also actively engaging with the speaker by focusing on their message, observing their body language, and providing verbal and nonverbal feedback to demonstrate understanding.
                                  </div>
                        
                                </div>
                              }
                              {
                                activeTab == 'intro' &&
                                <div className="p-3">
                                  Introduction is simply the art of introducing yourself. It typically includes sharing personal details, such as name, educational background, professional experience, and relevant skills. The purpose of the introduction is to create a positive first impression, highlight key qualifications, and set the tone for the rest of the interview.
                                </div>
                              }
                              {
                                activeTab=="comm" &&
                                <div className="p-3">
                                  Communication refers to the process of exchanging information, ideas, thoughts, and feelings between individuals or groups through various mediums such as spoken or written words, gestures, body language, or visual and auditory signals. It involves both the transmission and reception of messages, with the goal of conveying meaning and understanding between the sender and receiver. 
                                </div>
                              }
                              {
                                activeTab=="gm" &&
                                <div className="p-3">
                                  Growth mindset refers to the belief that one's abilities and intelligence can be developed and improved through effort, perseverance, and a willingness to learn. It is a mindset that embraces challenges, sees failures as opportunities for growth, and values the process of learning and development. 
                                </div>
                              }
                              {
                                activeTab=="ct" &&
                                <div className="p-3">
                                  Critical thinking refers to the ability to objectively analyse, evaluate, and interpret information or situations in a logical and systematic manner. It involves actively and carefully considering evidence, arguments, and various perspectives to form well-reasoned judgments or decisions.
                                </div>
                              }
                              {
                                activeTab=="tw" &&
                                <div className="p-3">
                                  Teamwork refers to the collaborative effort of individuals working together towards a common goal or objective. It involves the coordination, cooperation, and integration of individual strengths, skills, and efforts to achieve a shared outcome.
                                </div>
                              }

                            </div>
                            </ReactCardFlip>
                        </div>
                        <div className="my-4 grid grid-cols-2 lg:grid-cols-4 gap-4">
                          <div className="bg-white p-4 rounded-xl relative flex flex-col justify-around">
                            <div className=" ">
                              <img src={top1} className="h-10 rounded-xl p-2 bg-[#EE9D01]"></img>
                            </div>
                            <p className="mt-2 text-sm text-[#464E5F]">Your Percentile <br></br> Rank</p>
                            <p className="lg:text-md my-3 font-bold text-xl text-[#464E5F]">{Math.round(percentile(totalMarks,total))}<span>
                            {
                                Math.round(percentile(totalMarks,total))%10 == 1 &&
                                "st"
                              }
                              {
                                Math.round(percentile(totalMarks,total))%10 == 2 &&
                                "nd"
                              }
                              {
                                Math.round(percentile(totalMarks,total))%10 == 3 &&
                                "rd"
                              }
                              {
                                Math.round(percentile(totalMarks,total))%10> 4 &&
                                "th"
                              }
                              </span></p>
                            {
                              (filteredData.length>1 && penultimateData.length>1 && penultimateData!=null) &&
                            <p className=" text-sm text-[#464E5F] my-1  bottom-4"><span className={Math.round(percentile(totalMarks,total)) - Math.round(percentile(totalMarks,prevTotal))>0?"text-[#4AB58E] font-bold":"text-[#E94B60] font-bold"}>{Math.round(percentile(totalMarks,total)) - Math.round(percentile(totalMarks,prevTotal))>0?"+":""}{Math.round(percentile(totalMarks,total)) - Math.round(percentile(totalMarks,prevTotal))}</span> Latest Test</p>
                            }
                          </div>
                          <div className="bg-white p-4 rounded-xl relative flex flex-col justify-around">
                            <div className=" ">
                              <img src={star} className="h-10 rounded-xl p-2 bg-[#5F5CF1]"></img>
                            </div>
                            <p className=" text-sm text-[#464E5F]">Best<br></br> Skill</p>
                            <p className="lg:text-md my-3 font-bold text-xl text-[#464E5F] truncate">
                              {
                                latestData.indexOf(Math.max(...latestData)) == 0 &&
                                <span>Introduction</span>
                              }
                              {
                                latestData.indexOf(Math.max(...latestData)) == 1 &&
                                <span>Communication</span>
                              }
                              {
                                latestData.indexOf(Math.max(...latestData)) == 2 &&
                                <span>Active Listening</span>
                              }
                              {
                                latestData.indexOf(Math.max(...latestData)) == 3 &&
                                <span>Teamwork</span>
                              }
                              {
                                latestData.indexOf(Math.max(...latestData)) == 4 &&
                                <span>Growth Mindset</span>
                              }
                              {
                                latestData.indexOf(Math.max(...latestData)) == 5 &&
                                <span>Critical Thinking</span>
                              }
                            </p>
                            {
                              (filteredData.length>1 && penultimateData.length>1 && penultimateData.length!=null) &&
                            <p className=" text-sm text-[#464E5F] my-1  bottom-4"><span className="text-[#4AB58E]  font-bold">
                              {
                                latestData.indexOf(Math.max(...latestData)) == 0 &&
                                <span>{
                                  (introMarks[introMarks.length-1]) - (introMarks[introMarks.length-2])>=0?"+":""
                                  }{(introMarks[introMarks.length-1]) - (introMarks[introMarks.length-2])}</span>
                              }
                              {
                                latestData.indexOf(Math.max(...latestData)) == 1 &&
                                <span>{
                                  (commMarks[commMarks.length-1]) - (commMarks[commMarks.length-2])>=0?"+":""
                                  }{(commMarks[commMarks.length-1]) - (commMarks[commMarks.length-2])}</span>
                              }
                              {
                                latestData.indexOf(Math.max(...latestData)) == 2 &&
                                <span>
                                  {(lsMarks[lsMarks.length-1]) - (lsMarks[lsMarks.length-2])>=0?"+":""}
                                  {(lsMarks[lsMarks.length-1]) - (lsMarks[lsMarks.length-2])}</span>
                              }
                              {
                                latestData.indexOf(Math.max(...latestData)) == 3 &&
                                <span>{(twMarks[twMarks.length-1]) - (twMarks[twMarks.length-2])>0?"+":""}{(twMarks[twMarks.length-1]) - (twMarks[twMarks.length-2])}</span>
                              }
                              {
                                latestData.indexOf(Math.max(...latestData)) == 4 &&
                                <span>{(gmMarks[gmMarks.length-1]) - (gmMarks[gmMarks.length-2])>0?"+":""}{(gmMarks[gmMarks.length-1]) - (gmMarks[gmMarks.length-2])}</span>
                              }
                              {
                                latestData.indexOf(Math.max(...latestData)) == 5 &&
                                <span>{(criticalMarks[criticalMarks.length-1]) - (criticalMarks[criticalMarks.length-2])>0?"+":""}{(criticalMarks[criticalMarks.length-1]) - (criticalMarks[criticalMarks.length-2])}</span>
                              }
                            </span> Latest Test</p>
                            }
                          </div>
                          <div className="bg-white p-4 rounded-xl relative flex flex-col justify-around h-[16rem]">
                            <div className=" ">
                              <img src={increase} className="h-10 rounded-xl p-2 bg-[#1BC5BD]"></img>
                            </div>
                            <p className=" text-sm text-[#464E5F]">Needs <br></br> Improvement</p>
                            <p className="lg:text-md my-3 font-bold text-xl text-[#464E5F] truncate">
                            {
                                latestData.indexOf(Math.min(...latestData)) == 0 &&
                                <span>Introduction</span>
                              }
                              {
                                latestData.indexOf(Math.min(...latestData)) == 1 &&
                                <span>Communication</span>
                              }
                              {
                                latestData.indexOf(Math.min(...latestData)) == 2 &&
                                <span>Active Listening</span>
                              }
                              {
                                latestData.indexOf(Math.min(...latestData)) == 3 &&
                                <span>Teamwork</span>
                              }
                              {
                                latestData.indexOf(Math.min(...latestData)) == 4 &&
                                <span>Growth Mindset</span>
                              }
                              {
                                latestData.indexOf(Math.max(...latestData)) == 5 &&
                                <span>Critical Thinking</span>
                              }
                            </p>
                            {
                              (filteredData.length>1 && penultimateData.length>1 && penultimateData.length!=null) &&
                            <p className=" text-sm text-[#464E5F]  bottom-4 my-1"><span className="text-[#4AB58E] font-bold">
                            {
                                latestData.indexOf(Math.min(...latestData)) == 0 &&
                                <span>{
                                  (introMarks[introMarks.length-1]) - (introMarks[introMarks.length-2])>=0?"+":""
                                  }{(introMarks[introMarks.length-1]) - (introMarks[introMarks.length-2])}</span>
                              }
                              {
                                latestData.indexOf(Math.min(...latestData)) == 1 &&
                                <span>{
                                  (commMarks[commMarks.length-1]) - (commMarks[commMarks.length-2])>=0?"+":""
                                  }{(commMarks[commMarks.length-1]) - (commMarks[commMarks.length-2])}</span>
                              }
                              {
                                latestData.indexOf(Math.min(...latestData)) == 2 &&
                                <span>
                                  {(lsMarks[lsMarks.length-1]) - (lsMarks[lsMarks.length-2])>=0?"+":""}
                                  {(lsMarks[lsMarks.length-1]) - (lsMarks[lsMarks.length-2])}</span>
                              }
                              {
                                latestData.indexOf(Math.min(...latestData)) == 3 &&
                                <span>{(twMarks[twMarks.length-1]) - (twMarks[twMarks.length-2])>0?"+":""}{(twMarks[twMarks.length-1]) - (twMarks[twMarks.length-2])}</span>
                              }
                              {
                                latestData.indexOf(Math.min(...latestData)) == 4 &&
                                <span>{(gmMarks[gmMarks.length-1]) - (gmMarks[gmMarks.length-2])>0?"+":""}{(gmMarks[gmMarks.length-1]) - (gmMarks[gmMarks.length-2])}</span>
                              }
                              {
                                latestData.indexOf(Math.min(...latestData)) == 5 &&
                                <span>{(criticalMarks[criticalMarks.length-1]) - (criticalMarks[criticalMarks.length-2])>0?"+":""}{(criticalMarks[criticalMarks.length-1]) - (criticalMarks[criticalMarks.length-2])}</span>
                              }
                              </span> Latest Test</p>
                            }
                          </div>
                          <div className="bg-white p-4 rounded-xl relative  flex flex-col justify-around">
                            <div className=" ">
                              <img src={bar_graph} className="h-10 rounded-xl -mt-2 p-2 bg-[#E94B60]"></img>
                            </div>
                            <p className="-mt-1 text-sm text-[#464E5F]">Maximum<br></br> Improvement</p>
                            <p className="lg:text-md my-3 font-bold text-xl text-[#464E5F]">
                            {
                                difference.indexOf(Math.max(...difference)) == 0 &&
                                <span>Introduction</span>
                              }
                              {
                                difference.indexOf(Math.max(...difference)) == 1 &&
                                <span>Communication</span>
                              }
                              {
                                difference.indexOf(Math.max(...difference)) == 2 &&
                                <span>Active Listening</span>
                              }
                              {
                                difference.indexOf(Math.max(...difference)) == 3 &&
                                <span>Teamwork</span>
                              }
                              {
                                difference.indexOf(Math.max(...difference)) == 4 &&
                                <span>Growth Mindset</span>
                              }
                              {
                                difference.indexOf(Math.max(...difference)) == 5 &&
                                <span>Critical Thinking</span>
                              }
                            </p>
                            {
                              (filteredData.length>1 && penultimateData.length>1 && penultimateData.length!=null) &&
                            <p className=" text-sm text-[#464E5F]  bottom-4 my-1"><span className="text-[#4AB58E] font-bold">
                            {
                                difference.indexOf(Math.max(...difference)) == 0 &&
                                <span>{
                                  (introMarks[introMarks.length-1]) - (introMarks[introMarks.length-2])>=0?"+":""
                                  }{(introMarks[introMarks.length-1]) - (introMarks[introMarks.length-2])}</span>
                              }
                              {
                                difference.indexOf(Math.max(...difference)) == 1 &&
                                <span>{
                                  (commMarks[commMarks.length-1]) - (commMarks[commMarks.length-2])>=0?"+":""
                                  }{(commMarks[commMarks.length-1]) - (commMarks[commMarks.length-2])}</span>
                              }
                              {
                                difference.indexOf(Math.max(...difference)) == 2 &&
                                <span>
                                  {(lsMarks[lsMarks.length-1]) - (lsMarks[lsMarks.length-2])>=0?"+":""}
                                  {(lsMarks[lsMarks.length-1]) - (lsMarks[lsMarks.length-2])}</span>
                              }
                              {
                                difference.indexOf(Math.max(...difference)) == 3 &&
                                <span>{(twMarks[twMarks.length-1]) - (twMarks[twMarks.length-2])>0?"+":""}{(twMarks[twMarks.length-1]) - (twMarks[twMarks.length-2])}</span>
                              }
                              {
                                difference.indexOf(Math.max(...difference)) == 4 &&
                                <span>{(gmMarks[gmMarks.length-1]) - (gmMarks[gmMarks.length-2])>0?"+":""}{(gmMarks[gmMarks.length-1]) - (gmMarks[gmMarks.length-2])}</span>
                              }
                              {
                                difference.indexOf(Math.max(...difference)) == 5 &&
                                <span>{(criticalMarks[criticalMarks.length-1]) - (criticalMarks[criticalMarks.length-2])>0?"+":""}{(criticalMarks[criticalMarks.length-1]) - (criticalMarks[criticalMarks.length-2])}</span>
                              }
                            </span> Latest Test</p>
                            }
                          </div>
                        </div>
                        <div className="bg-white p-4 rounded-xl">
                          <div className="flex mb-5 justify-between items-center ">
                          <p className="font-bold text-xl ">Growth Map</p>
                          <p>Last 2 tests</p>
                          </div>
                          <Bar
                          data={{
                            labels:["Introduction","Communication","Active Listening","Teamwork","Growth Mindset","Critical Thinking"],
                            datasets:[
                              {
                                label:"Previous test",
                                data:penultimateData,
                                backgroundColor: color=>{
                                  if(color.index === 0){
                                    return "#9BE8CE" //light blue
                                  }
                                  else if(color.index ===1){
                                    return "#CCCFFB" //light violet
                                  }
                                  else if(color.index ===2){
                                    return "#F9E6B7" //light yellow
                                  }
                                  else if(color.index === 3){
                                    return "#FFDCC8" //light orange
                                  }
                                  else if(color.index === 4){
                                    return "#D4ECFF" //light blue
                                  }
                                  else if(color.index == 5){
                                    return "#FBDBDF" //light red
                                  }
                                },
                              },
                              {
                                label: 'This Test',
                                data: latestData,
                                backgroundColor: color=>{
                                  if(color.index == 0){
                                    return "#1BC5BD"
                                  }
                                  else if (color.index===1){
                                    return "#727CF5"
                                  }
                                  else if(color.index === 2){
                                    return "#FECC4C"
                                  }
                                  else if(color.index === 3){
                                    return "#FE8C4C"
                                  }
                                  else if(color.index === 4){
                                    return "#42AAFF"
                                  }
                                  else if(color.index === 5){
                                    return "#E94B60"
                                  }
                                },
                              },
                            ]
                          }}
                          options={{
                            responsive:true,
                            maintainAspectRatio:true,
                            y:{
                              min:0,
                              max:5,
                              ticks:{
                                stepSize:1
                              }
                            },
                            scales:{
                              x:{
                                grid:{
                                  display:false
                                }
                              }
                            },
                            plugins:{
                              legend: {
                                display:false,
                              },
                              datalabels:{
                                display:false,
                              }
                            },
                            borderRadius:5,
                          }}
                          ></Bar>
                        </div>
                    </div>
                    {
                      history!=null ?
                    <div className="lg:w-1/3  m-4 bg-white rounded-xl">
                      <div className="bg-[#383838] rounded-t-xl p-4 text-white">
                        <p className="text-2xl text-center ">Resources</p>
                        <p className="font-extrabold text-4xl text-center my-3 ">{
                          history==null
                          ?
                          '0'
                          :
                          history.length
                        }</p>
                        <p className="text-lg text-center ">Total Resources Consumed</p>
                      </div>
                      <div className="flex items-center mx-4 justify-between my-3">
                        <p className="font-extrabold text-lg">Resources</p>
                      </div>
                      <div className="my-3 grid grid-cols-2 text-center items-center px-2 py-1 bg-[#F3F6FF] rounded-xl mx-4">
                        <button onClick={()=>{setTab(0)}}>
                        <div className={tab==0&&"bg-white py-1 rounded-xl"}>
                          <p>Total</p>
                        </div>
                        </button>
                        <button onClick={()=>{setTab(1)}}>
                        <div className={tab==1 &&"bg-white py-1 rounded-xl"}>
                          <p>History</p>
                        </div>
                        </button>
                      </div>
                      {
                        tab===0 &&
                      <div>
                      <div>
                      <Doughnut
                                        className=" w-full"
                                        data={{
                                            labels:['Webinars','Critical Thinking', 'Teamwork','Communication','Growth Mindset','Active Listening'],
                                            datasets:[{
                                                label:'Number of marks',
                                                backgroundColor:[
                                                    "#1BC5BD",
                                                    "#E94B60",
                                                    "#FE8C4C",
                                                    "#727CF5",
                                                    "#FECC4C",
                                                    "#42AAFF",
                                                ],
                                                data:skills
                                            }]
                                        }}
                                        options={{
                                          responsive:true,
                                          maintainAspectRatio: true,
                                          layout: {
                                            padding: {
                                                left: 108,
                                                right: 100,
                                                top: 40,
                                                bottom: 60
                                            }
                                        },
                                          maintainAspectRatio:true,
                                          plugins:{
                                            legend: {
                                              display: false
                                          },
                                            
                                            datalabels:{
                                              anchor:'end',
                                              align:'end',
                                              clip:false,
                                              color:"black",
                                              textAlign:"center",
                                              borderColor:"white",
                                              borderRadius:"20",
                                              borderWidth:"2",
                                              display:function(context){
                                                var index = context.dataIndex;
                                                var value = context.dataset.data[index];
                                                return value
                                              },
                                              formatter:function(value, context){
                                                if(context.dataIndex == 0 )
                                                return 'Webinars';
                                                if(context.dataIndex == 1)
                                                return 'Critical\n Thinking ' 
                                                if(context.dataIndex == 2)
                                                return 'Teamwork \n'
                                                if(context.dataIndex == 3)
                                                return 'Communication \n'
                                                if(context.dataIndex == 4)
                                                return 'Growth\nMindset'
                                                if(context.dataIndex == 5)
                                                return 'Active\nListening'
                                              }
                                            }
                                          }
                                        }}
                                        ></Doughnut>

                      </div>
                      <div className="flex flex-col space-y-8 mx-4">
                        <div className="flex items-center space-x-3">
                          <div className="bg-[#1BC5BD] p-2 rounded-xl">
                            <img src={intro1} className="h-6"></img>
                          </div>
                          <div className="flex flex-col w-full">
                          <div className="flex justify-between w-full">
                            <p className="font-poppins font-bold">Webinar</p>
                            <p className="font-poppins">{skills[0]}</p>
                          </div>
                          <div>
                            <meter className="w-full background-none metr1" min={0.0} max={history.length} value={skills[0]}></meter>
                          </div>
                          </div>
                        </div>
                        <div className="flex items-center space-x-3">
                          <div className="bg-[#E94B60] p-2 rounded-xl">
                            <img src={skill1} className="h-6"></img>
                          </div>
                          <div className="flex flex-col w-full ">
                          <div className="flex justify-between w-full">
                            <p className="font-poppins font-bold">Critical Thinking</p>
                            <p className="font-poppins">{skills[1]}</p>
                          </div>
                          <div>
                            <meter className="w-full background-none metr2 outline-none border-none" min={0.0} max={history.length} value={skills[1]}></meter>
                          </div>
                          </div>
                        </div>
                        <div className="flex items-center space-x-3">
                          <div className="bg-[#FE8C4C] p-2 rounded-xl">
                            <img src={teamwork1} className="h-6"></img>
                          </div>
                          <div className="flex flex-col w-full">
                          <div className="flex justify-between w-full">
                            <p className="font-poppins font-bold">Teamwork</p>
                            <p className="font-poppins">{skills[2]}</p>
                          </div>
                          <div>
                            <meter className="w-full background-none metr3" min={0.0} max={history.length} value={skills[2]}></meter>
                          </div>
                          </div>
                        </div>
                        <div className="flex items-center space-x-3">
                          <div className="bg-[#727CF5] p-2 rounded-xl">
                            <img src={chat} className="h-6"></img>
                          </div>
                          <div className="flex flex-col w-full">
                          <div className="flex justify-between w-full">
                            <p className="font-poppins font-bold">Communication</p>
                            <p className="font-poppins">{skills[3]}</p>
                          </div>
                          <div>
                            <meter className="w-full background-none metr4" min={0.0} max={history.length} value={skills[3]}></meter>
                          </div>
                          </div>
                        </div>
                        <div className="flex items-center space-x-3">
                          <div className="bg-[#42AAFF] p-2 rounded-xl">
                            <img src={enthu} className="h-6"></img>
                          </div>
                          <div className="flex flex-col w-full">
                          <div className="flex justify-between w-full">
                            <p className="font-poppins font-bold">Growth Mindset</p>
                            <p className="font-poppins">{skills[4]}</p>
                          </div>
                          <div>
                            <meter className="w-full background-none metr5" min={0.0} max={history.length} value={skills[4]}></meter>
                          </div>
                          </div>
                        </div>
                        <div className="flex items-center space-x-3">
                          <div className="bg-[#FECC4C] p-2 rounded-xl">
                            <img src={ear} className="h-6"></img>
                          </div>
                          <div className="flex flex-col w-full">
                          <div className="flex justify-between w-full">
                            <p className="font-poppins font-bold">Active Listening</p>
                            <p className="font-poppins">{skills[5]}</p>
                          </div>
                          <div>
                            <meter className="w-full background-none metr6" min={0.0} max={history.length} value={skills[5]}></meter>
                          </div>
                          </div>
                        </div>
                      </div>
                      </div>
                      }
                      {
                        tab===1
                        &&
                        <div className="mx-3 space-y-6 overflow-y-scroll h-[55rem] scrollbar-hide">
                          {
                            history.slice(0).reverse().map((el)=>{
                              return(
                                <div className="my-2 p-4 rounded-xl bg-gray-100 flex items-center space-x-3">
                                  <img src={`https://img.youtube.com/vi/${el.videocode}/0.jpg`} className="h-16 rounded-xl"></img>
                                  <p>{el.title}</p>
                                </div>
                              )
                            })
                          }
                        </div>
                      }
                    </div>
                    :
                    <div className="bg-white p-4 rounded-xl">
                      <p>Check out our resources section to see this information</p>
                    </div>
                    }
                </div>
            </div>
          );
        }
        
    }
}

    }
  else return(
    <div className="absolute left-1/2 top-1/2 -translate-x-1/2  text-center -translate-y-1/2">
                    <InfinitySpin
                    width='200'
                    color='#4899B7'
                    ></InfinitySpin>
                </div>
  )
}

export default Dashboard
import React, { useState, useEffect} from 'react'
import signupgirl from '../images/signup_girl.png'
import interviewguru_logo from '../images/interview_guru_logo.png'
import google_logo from '../images/google_logo.png'
import {Link, useNavigate} from 'react-router-dom'
import axios from 'axios'
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { getAuth } from "firebase/auth";
import app from '../firebase'
import Helmet from 'react-helmet'
import {InfinitySpin} from 'react-loader-spinner'
import ReactGA from 'react-ga'
const Signup = () => {
    const [email, setEmail]  = useState('')
    const date = new Date()
    const [pass, setPass] = useState('')
    const [phone, setPhone] = useState('')
    const [disable, setDisable] = useState(true)
    const [phonestatus,setPhonestatus] = useState(true)
    const [checkbox, setCheckbox] = useState(false)
    const [datetime, setDatetime] = useState(date)
    const [confpass, setConfPass] = useState('')
    const [flag, setFlag] = useState(false)
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const provider = new GoogleAuthProvider()
    const auth = getAuth(app);
    auth.languageCode = 'it';
    
    useEffect(()=>{
        if(email.includes('@')  && pass && checkbox && confpass){
            setDisable(false)
        }
        else{
            setDisable(true)
        }
        
        if(confpass === pass){
            setFlag(false)
        }
        else{
            setFlag(true)
        }

    },[email, phone, pass, checkbox, confpass])

    useEffect(()=>{
        setDatetime(date)
    },[])
    
    
    useEffect(()=>{
        ReactGA.pageview(window.location.pathname + window.location.search)
      },[])
      
      console.log(datetime.getDate())

      

    const handleSubmit = (e)=>{
        setLoading(true)
        e.preventDefault()
        // console.log(email,pass)
        axios.post(`https://interviewvision.com/users/c`,{
            username:email,
            password:pass,
            email:email,
            portal_name:"Interview Vision Main"
        })
        .then((response)=>{
            console.log(response)
            localStorage.setItem('email',email)
            navigate('/signup/onboarding/')
            setLoading(false)
        })
        .catch((error)=>{
            console.log(error)
            if(error.response.status === 409){
                alert('User already exists')
                navigate('/login')
            }
            setLoading(false)
            // navigate('/signup/onboarding/')
        })
        setEmail('')
        setPass('')
        setPhone('')
    }

    const googleLogin = ()=>{
    signInWithPopup(auth, provider)
  .then((result) => {
    // This gives you a Google Access Token. You can use it to access the Google API.
    const credential = GoogleAuthProvider.credentialFromResult(result);
    const token = credential.accessToken;
    // The signed-in user info.
    const user = result.user;
    console.log(user);
    setLoading(true)
    axios.post(`https://interviewvision.com/users/c`,{
        username:user.email,
        password:user.uid,
        email:user.email,
        portal_name:"Interview Vision Main"
    },
    )
    .then((response)=>{
        console.log(response);
        localStorage.setItem('email',user.email);
        setLoading(false)
        navigate('/signup/onboarding')
    })
    .catch((error)=>{
        console.log(error);
        setLoading(false);
        alert('This email id is already registered with us, please sign in')
        navigate('/login')
    })
    // navigate('/signup/onboarding', {state:{pass:user.uid, phone:user.email}})
  }).catch((error) => {
    // Handle Errors here.
    const errorCode = error.code;
    setLoading(false);
    console.log(error)
    const errorMessage = error.message;
    // The email of the user's account used.
    const email = error.customData.email;
    // The AuthCredential type that was used.
    const credential = GoogleAuthProvider.credentialFromError(error);
    // alert('Something went wrong! Please try again')
    // ...
  });
    }
    if(loading){
        return(
         <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
             <InfinitySpin
             width='200'
             color='#4899B7'
             ></InfinitySpin>
         </div>   
        )
    }
    else{
  return (
    <>
    
    <div className="">
        <div className="flex">
            <img src={signupgirl} className="h-screen lg:block hidden"></img>
            <div className="w-full">
            <div className="flex justify-between items-center mt-3 mx-4">
                <Link to="/">
                <img src={interviewguru_logo} className="h-20 "></img>
                </Link>
            </div>
            <div className="mt-12 lg:w-1/2  mx-auto">
                <h1 className="text-2xl lg:text-left text-center font-poppins font-semibold">Sign up free</h1>
               <div className="flex space-x-3 items-center my-4 lg:justify-start justify-center">
                
                <button onClick={googleLogin} className="flex bg-[#4285F4] text-white font-poppins items-center px-4 py-3 space-x-4 xl:space-x-20 rounded-xl">
                    <img src={google_logo} className="h-5"></img>
                    <h1>Sign up with google</h1>
                    <h1 className="invisible">a</h1>
                </button>
          
               
                </div> 
                <div className="flex justify-center  items-center space-x-3">
                    {/* <div className="bg-[#DCDBDD] px-16 lg:px-32 py-[0.01rem]" ></div> */}
                    <h1 className="text-[#84818A] ">or</h1>
                    {/* <div className="bg-[#DCDBDD] px-16 lg:px-32 py-[0.01rem]" ></div> */}
                </div>
                <div class="relative my-3 lg:mx-0 mx-5">
                    <input type="email" value={email} autoComplete="off" autoSave="off"  onChange={(e)=>{setEmail(e.target.value)}}  id="floating_outlined" class="block px-2.5 border-2 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
                    <label for="floating_outlined" class="absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">Email ID </label>
                </div>
                {
                    email && !email.includes('@') &&
                    <p className=" font-poppins -mt-3 ml-4 lg:ml-0 text-sm mb-3">Enter a valid email ID</p>
                }
                
                <div class="relative lg:mx-0 mx-5">
                    <input type="password" value={pass} autoComplete="off" autoSave="off" onChange={(e)=>{setPass(e.target.value)}} id="floating_outlined" class="block px-2.5 border-2 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
                    <label for="floating_outlined" class="absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">Enter Password</label>
                </div>
                {
                    pass.length<8 &&
                    <p className=" font-poppins  ml-4 lg:ml-0 text-sm mb-3">The length of the password should be atleast 8 characters long</p>
                }
                <div class="relative mt-4 lg:mx-0 mx-5">
                    <input type="password" value={confpass} autoComplete="off" autoSave="off" onChange={(e)=>{setConfPass(e.target.value)}} id="floating_outlined" class="block px-2.5 border-2 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
                    <label for="floating_outlined" class="absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">Confirm Password</label>
                </div>
                {
                        flag &&
                        <p className=" font-poppins ml-4 lg:ml-0 text-sm mb-3">The passwords do not match</p>
                }
                {/* <h1 className="font-poppins text-sm underline mr-5 lg:mr-0 mt-4 text-gray-500 text-right">Resend</h1> */}
                    
                <div className="flex items-center mt-3 space-x-4 mx-5 lg:mx-0">
                <input required type="checkbox" value={checkbox} onChange={(e)=>{setCheckbox(e.target.value)}} className="accent-[#E94B60] h-7 w-7  lg:h-5 lg:w-5" ></input>
                <p className="text-sm">By clicking sign up, I agree that I have read and accepted the Terms of Use and Privacy Policy.</p>
                </div>
                <div className="mx-5 lg:mx-0">
                <button className={disable?`px-10 w-full bg-slate-400 text-slate-200 text-lg font-poppins rounded-xl py-3 my-4 lg:mx-0 `:`px-10 w-full bg-[#4899B7] text-white text-lg font-poppins rounded-xl py-3 my-4 lg:mx-0 ` } disabled={disable} onClick={handleSubmit} >Sign up</button>
                </div>
                <Link to="/login">
                <h1 className="font-poppins  my-3 text- text-center"> Already have an account? <span className="text-red-500 font-bold">Sign in</span></h1>
                </Link>
            </div>

            </div>
        </div>
    </div>
    </>
  )
    }
}

//write headings in numeric interviews

export default Signup
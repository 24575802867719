import qoutes from '../components/Quotes'
import idea from '../images/idea.png'
import { useRecordWebcam } from 'react-record-webcam'
import {ThreeDots} from 'react-loader-spinner'
import axios from 'axios'
import React, {useState, useEffect} from 'react'
import logo from '../images/logo_white.png'
import testpic1 from '../images/test_pic1.png'
import testpic2 from '../images/test_pic2.png'
import clip from '../images/Clip.png'
import { Link , useLocation} from 'react-router-dom'
import {InfinitySpin} from 'react-loader-spinner'
import {useNavigate} from 'react-router-dom'
import uuid from 'react-uuid'
import 'video.js/dist/video-js.min.css';
import {
  MediaPermissionsErrorType,
  requestMediaPermissions
} from 'mic-check';
import RecordRTC from 'recordrtc';
import 'videojs-record/dist/css/videojs.record.css';
import WaveSurfer from 'wavesurfer.js';
import MicrophonePlugin from 'wavesurfer.js/dist/plugin/wavesurfer.microphone.js';
// register videojs-wavesurfer plugin
import 'videojs-wavesurfer/dist/css/videojs.wavesurfer.css';
import ReactGA from 'react-ga';

function Demo() {
  WaveSurfer.microphone = MicrophonePlugin;
  const recordWebcam = useRecordWebcam({ frameRate: 60 , height:500, width:480});
  const [choice1, setChoice1] = useState(0)
  const [choice2, setChoice2] = useState(0)
  const [choice3, setChoice3] = useState(0)
  const [choice4, setChoice4] = useState(0)
  const [choice5, setChoice5] = useState(0)
  const [choice6, setChoice6] = useState(0)
  const [index, setIndex] = useState(0)
  const [comm, setComm] = useState(0)
  const [crit, setCrit] = useState(0)
  const [leader, setLeader] = useState(0)
  const [team, setTeam] = useState(0)
  const [intro, setIntro] = useState(0)
  const [grow, setGrow] = useState(0)
  const [status, setStatus] = useState(true)
  const [ques, setQues] = useState(0)
  const [mcqs, setMcqs] = useState([])
  const email = localStorage.getItem('email')
  const [loader, setLoader] = useState(false)
  const [qoutesindex, setQoutesIndex] = useState([])
  const [i, setI] = useState(0)
  const [j, setJ] = useState(0)
  const location  = useLocation()
  const [mcom, setMcom] = useState(0)
  const [mls, setMls] = useState(0)
  const [mtw, setMtw] = useState(0)
  const [mgm, setMgm] = useState(0)
  const [mct, setMct] = useState(0)
  const [uid, setUid] = useState('')
  const [loader1, setLoader1] = useState(false)
  const [loader2, setLoader2] = useState(false)
  const [loader3, setLoader3] = useState(false)
  const [loader4, setLoader4] = useState(false)
  const [loader5, setLoader5] = useState(false)
  const [loader6, setLoader6] = useState(false)
  const [loader7, setLoader7] = useState(false)
  const [loader8, setLoader8] = useState(false)
  const [feed, setFeed] = useState([])
  const [visibleVideo, setVisibleVideo] = useState(true)
  const [visibleVideo2, setVisibleVideo2] = useState(true)
  const navigate = useNavigate()
  const name = location.state.name
  const pass = location.state.pass
  const [questions, setQuestions] = useState([])
  const [visible, setvisible] = useState(false)
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const user  = localStorage.getItem('email')

  useEffect(()=>{
    setFeed([choice1, choice2, choice3, choice4, choice5, choice6])
  },[choice1, choice2, choice3, choice4, choice5, choice6])

  const skip = ()=>{
    axios.post('https://interviewvision.com/interview/feedback',{
      username: user,
      feedback:feed
    })
    .then((response) =>{
      console.log(response)
    })
    .catch((error) =>{
      console.log(error)
    })
    recordWebcam.close()
    navigate('/firstSignup',{state:{name:name, pass:pass}})
  }

  const feedback_save = ()=>{
    if(choice1 == 0 || choice2 == 0 || choice3 == 0 || choice4 == 0 || choice5 == 0 || choice6 == 0){
      console.log(choice1,choice2,choice3,choice4,choice5,choice6)
      alert('Enter a valid choice')
    }
    else{
      console.log(feed)
      axios.post('https://interviewvision.com/interview/feedback',{
        username: user,
        feedback:feed
      })
      .then((response) =>{
        console.log(response)
      })
      .catch((error) =>{
        console.log(error)
      })
      recordWebcam.close()
      navigate('/firstSignup',{state:{name:name, pass:pass}})
    }
  }
  const feedback=[
    {
      statement:"How would you rate the user interface of the platform?",
      options:[
        "Excellent",
        "Good",
        "Average",
        "Poor"
      ]
    },
    {
      statement:"How effective was the platform in simulating a real interview experience?",
      options:[
        "Very effective, it felt like a real interview",
        "Moderately effective, but it could be improved",
        "Not very effective, it didn’t resemble a real interview",
        "I did not find the simulation useful"
      ]
    },
    {
      statement:"Were the available interview questions relevant and challenging?",
      options:[
        "Yes, the questions were highly relevant and challenging",
        "Yes, but some questions could be improved",
        "No, the questions were not relevant or challenging enough",
        "I did not find the available questions useful"
      ]
    },
    {
      statement:"Would you recommend this platform to others who want to improve their interview skills?",
      options:[
        "1",
        "2",
        "3",
        "4",
        "5"
      ]
    },
    {
      statement:"How satisfied were you with the overall functionality and features of the platform?",
      options:[
        "Very satisfied",
        "Satisfied",
        "Neutral",
        "Dissatisfied",
        "Very dissatisfied"
      ]
    },
    {
      statement:"Would you consider using this platform again in the future?",
      options:[
        "Definitely",
        "Likely",
        "Undecided",
        "Unlikely",
        "Definitely not"
      ]
    }
  ]

  useEffect(()=>{
    setLoader(true)
    // navigator.mediaDevices.getUserMedia({audio: true, video: true}) 
    requestMediaPermissions()
    .then(()=>{
      recordWebcam.open()
    })
    .catch((error) =>{
      const { type, name, message } = error;
      if(type === MediaPermissionsErrorType.SystemPermissionDenied){
        alert('Please allow permission from system')
      }
      else if(type === MediaPermissionsErrorType.UserPermissionDenied){
        alert('You denied the permission in browser, please allow permission from system')
      }
      else if(type === MediaPermissionsErrorType.CouldNotStartVideoSource){
        alert('Your video was busy in other places, please close all tabs to enable camera')
      }
      else{
        alert('Something went wrong, please try again')
      }
    })
      axios.get('https://interviewvision.com/interview/questions/v2')
      .then((response)=>{
          console.log(response);
          setQuestions(response.data.result)
          console.log(questions)
          setLoader(false)
      })
      .catch((err)=>{
          console.log(err);
      })
      // setI(localStorage.getItem('i'))
      // setQues(localStorage.getItem('ques'))
    },[])
    
    useEffect(()=>{
      if(loader8 == true) {
        setInterval(()=>{
          var ind = (Math.floor(Math.random()*(124)))
          setIndex(ind)
        },5000)
      }
    },[loader8])

//   let player = videojs('myVideo', options, function() {
//     // print version information at startup
//     const msg = 'Using video.js ' + videojs.VERSION +
//         ' with videojs-record ' + videojs.getPluginVersion('record');
//     videojs.log(msg);

//     console.log("videojs-record is ready!");
// });
  useEffect(()=>{
    recordWebcam.open();
    // localStorage.setItem('ques',ques)
    if(recordWebcam.status == "ERROR")
    {
      alert('Please try again later')
      navigate('/')
    }
    
    if(ques == 1){
      ReactGA.event({
        category:"Answering_MCQ",
        action:"User started givong MCQ interview"
      })
    }
    else if(ques>=5){
      ReactGA.event({
        category:"ANSWERING_VIDEO",
        action:"User started givong VIDEO interview"
      })
    }
  },[ques])

  console.log(qoutesindex)

  const shuffle  = () =>{
    return (Math.floor(Math.random()*(124)))
  }

  useEffect(()=>{
    // loader = true
    // recordWebcam.open();
    recordWebcam.open();
    setUid(uuid())
    axios.get('https://interviewvision.com/interview/mcqs')
    .then((response)=>{
      console.log(response)
      setMcqs(response.data.result)
      console.log(response.data.result[0].score[0])
      // console.log(mcqs)
      // loader = false
    })
    .catch((error)=>{
      console.log(error)
    })
  },[])

  useEffect(()=>{
    console.log(mcqs)
  },[mcqs])


  useEffect(()=>{
    // localStorage.setItem('i',i)
    if(i==10){
      setQues(4)
    }
  },[i])

  const convert = (ans)=>{
    if(ans == 'Bad'){
      return 1;
    }
    else if(ans == "Below Average"){
      return 2;
    }
    else if(ans == "Average"){
      return 3;
    }
    else if(ans == "Above Average"){
      return 4;
    }
    else if(ans == "Good"){
      return 5;
    }
  }

  const save = (cat, ans)=>{
    if(cat == "ct"){
      setCrit(crit+convert(ans));
    }
    if(cat == "l"){
      setLeader(leader+convert(ans));
    }
    if(cat=="t"){
      setTeam(team+convert(ans));
    }
    if(cat=="i"){
      setIntro(intro+convert(ans));
    }
    if(cat == "g"){
      setGrow(grow+convert(ans));
    }
    if(cat == "c"){
      setComm(comm+(convert(ans)))
    }
  }

  const stop = ()=>{
    recordWebcam.stop()
    setStatus(false)
  }

  const retake = ()=>{
    recordWebcam.retake()
    setStatus(true)
  }

  // useEffect(()=>{
  //   forceUpdate()
  // },[crit, team,leader,grow, comm, intro])

  console.log(crit, team,leader,grow, comm, intro)

  const upload =  (data, cat, q)=>{
    // console.log('res sent')
     
  }
  
  const saveFile1 = async () => {
    const blob = await recordWebcam.getRecording();
    const data = new FormData()
    data.append('file',blob)
    data.append('questionText',questions[0].statement)
    data.append('questionId',questions[0].index)
    data.append('username',email)
    data.append('interviewid', uid)
    data.append('category',questions[0].category)
    axios.post('https://interviewvision.com/interview/video?flag=false',data,
     {
      headers:{
        "Content-Type":"multipart/form-data"
      }
    })
    .then((response) =>{
      console.log(response);
      console.log(response.data);
      // // setRank(response.data.result.rank)
      // save(cat,response.data.result.rank)
      setLoader1(false)
      
    })
    .catch((error) =>{
      console.log(error)
    })
    // upload(data, 'i',5)
    setQues(5)
    console.log(intro)
    recordWebcam.open()
    ReactGA.event({
      category:"Answered_q1",
      action:"User answers q1"
    })
    console.log(loader)
    // loader = false
  };

  const saveFile2 = async () => {
    // loader = true
    const blob = await recordWebcam.getRecording();
    const data = new FormData()
    // forceUpdate()
    data.append('file',blob)
    data.append('questionText',questions[1].statement)
    data.append('questionId',questions[1].index)
    data.append('username',email)
    data.append('interviewid', uid)
    data.append('category',questions[1].category)
    axios.post('https://interviewvision.com/interview/video?flag=false',data,
     {
      headers:{
        "Content-Type":"multipart/form-data"
      }
    })
    .then((response) =>{
      console.log(response);
      console.log(response.data);
      // // setRank(response.data.result.rank)
      // save(cat,response.data.result.rank)
      setLoader2(false)
      
    })
    .catch((error) =>{
      console.log(error)
    })
    // upload(data, 'i',6)
    setQues(6)
    ReactGA.event({
      category:"Answered_q2",
      action:"User answers q2"
    })
    recordWebcam.open()
    console.log(blob)
    // loader = false
  };

  const saveFile3 = async () => {
    // loader = true
    const blob = await recordWebcam.getRecording();
    const data = new FormData()
    // forceUpdate()
    data.append('file',blob)
    data.append('questionId',questions[2].index)
    data.append('questionText',questions[2].statement)
    data.append('username',email)
    data.append('interviewid', uid)
    data.append('category',questions[2].category)
    axios.post('https://interviewvision.com/interview/video?flag=false',data,
     {
      headers:{
        "Content-Type":"multipart/form-data"
      }
    })
    .then((response) =>{
      console.log(response);
      console.log(response.data);
      // // setRank(response.data.result.rank)
      // save(cat,response.data.result.rank)
      setLoader3(false)
      
    })
    .catch((error) =>{
      console.log(error)
    })
    // upload(data,'i',7)
    setQues(7)
    ReactGA.event({
      category:"Answered_q3",
      action:"User answers q3"
    })
    recordWebcam.open()
    console.log(blob)
    // loader = false
  };

  const saveFile4 = async () => {
    // loader = true
    const blob = await recordWebcam.getRecording();
    const data = new FormData()
    // forceUpdate()
    data.append('file',blob)
    data.append('questionText',questions[3].statement)
    data.append('questionId',questions[3].index)
    data.append('username',email)
    data.append('interviewid', uid)
    data.append('category',questions[3].category)
    axios.post('https://interviewvision.com/interview/video?flag=false',data,
     {
      headers:{
        "Content-Type":"multipart/form-data"
      }
    })
    .then((response) =>{
      console.log(response);
      console.log(response.data);
      // // setRank(response.data.result.rank)
      // save(cat,response.data.result.rank)
      setLoader4(false)
      
    })
    .catch((error) =>{
      console.log(error)
    })
    // upload(data, 'c',8)
    setQues(8)
    ReactGA.event({
      category:"Answered_q4",
      action:"User answers q4"
    })
    recordWebcam.open()
    console.log(blob)
    // loader = false
  };
  const saveFile5 = async () => {
    // loader = true
    console.log('start')
    const blob = await recordWebcam.getRecording();
    const data = new FormData()
    // forceUpdate()
    data.append('file',blob)
    data.append('questionText',questions[4].statement)
    data.append('questionId',questions[4].index)
    data.append('username',email)
    data.append('interviewid', uid)
    data.append('category',questions[4].category)
    axios.post('https://interviewvision.com/interview/video?flag=false',data,
     {
      headers:{
        "Content-Type":"multipart/form-data"
      }
    })
    .then((response) =>{
      console.log(response);
      console.log(response.data);
      // // setRank(response.data.result.rank)
      // save(cat,response.data.result.rank)
      setLoader5(false)
      
    })
    .catch((error) =>{
      console.log(error)
    })
    // upload(data, 'l',9)
    setQues(9)
    recordWebcam.open()
    console.log(blob)
    ReactGA.event({
      category:"Answered_q5",
      action:"User answers q5"
    })
    console.log('stop')
    // loader = false
  };
  const saveFile6 = async () => {
    // loader = true
    const blob = await recordWebcam.getRecording();
    const data = new FormData()
    // forceUpdate()
    data.append('file',blob)
    data.append('questionText',questions[5].statement)
    data.append('questionId',questions[5].index)
    data.append('username',email)
    data.append('interviewid', uid)
    data.append('category',questions[5].category)
    axios.post('https://interviewvision.com/interview/video?flag=false',data,
     {
      headers:{
        "Content-Type":"multipart/form-data"
      }
    })
    .then((response) =>{
      console.log(response);
      console.log(response.data);
      // // setRank(response.data.result.rank)
      // save(cat,response.data.result.rank)
      setLoader6(false)
      
    })
    .catch((error) =>{
      console.log(error)
    })
    // upload(data,'t',10)
    setQues(10)
    ReactGA.event({
      category:"Answered_q6",
      action:"User answers q6"
    })
    recordWebcam.open()
    console.log(blob)
    // loader = false
  };
  
  const saveFile8 = async () => {
    // loader = true
    const blob = await recordWebcam.getRecording();
    const data = new FormData()
    // forceUpdate()
    data.append('file',blob)
    data.append('questionText',questions[6].statement)
    data.append('questionId',questions[6].index)
    data.append('username',email)
    data.append('interviewid', uid)
    data.append('category',questions[6].category)
    axios.post('https://interviewvision.com/interview/video?flag=false',data,
     {
      headers:{
        "Content-Type":"multipart/form-data"
      }
    })
    .then((response) =>{
      console.log(response);
      console.log(response.data);
      // // setRank(response.data.result.rank)
      // save(cat,response.data.result.rank)
      setLoader7(false)
      
    })
    .catch((error) =>{
      console.log(error)
    })
    // upload(data,'g',11)
    setQues(11)
    ReactGA.event({
      category:"Answered_q7",
      action:"User answers q7"
    })
    recordWebcam.open()
    console.log(blob)
    // loader = false
  };
  
  const saveFile9 = async () => {
    // loader = true
    const blob = await recordWebcam.getRecording();
    const data = new FormData()
    // forceUpdate()
    data.append('file',blob)
    data.append('questionText',questions[7].statement)
    data.append('questionId',questions[7].index)
    data.append('username',email)
    data.append('interviewid', uid)
    data.append('category',questions[7].category)
    axios.post('https://interviewvision.com/interview/video?flag=false',data,
     {
      headers:{
        "Content-Type":"multipart/form-data"
      }
    })
    .then((response) =>{
      console.log(response);
      ReactGA.event({
        category:"Answered_q8",
        action:"User answers q8"
      })
      axios.put('https://interviewvision.com/users/u/interview',{
        username:user
      })
      .then((response)=>{
        console.log(response)
        setLoader8(false)
        setQues(12)
      })
      .catch((error)=>{
        console.log(error);
      })
      
    })
    .catch((error) =>{console.log(error)})
    recordWebcam.close()
    // recordWebcam.open()
  };

  const nav = ()=>{
    navigate('/firstSignup',{state:{name:name, pass:pass}})
  }

  if(mcqs.length === 0 ){
    return(
      <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
             <InfinitySpin
             width='200'
             color='#4899B7'
             ></InfinitySpin>
      </div>  
    )
  }
  else return (
    <div>
      {
        visible &&
        <div className="absolute left-1/2 top-1/2 px-4 py-3  rounded-xl space-y-3 bg-white z-40 -translate-x-1/2 backdrop-blur-sm -translate-y-1/2 " >
          <p className="text-xl">Are you sure you want quit now?</p>
          <p>If you quit now, all your answers submitted till now will be lost and you will have to start from the beginning again</p>
        </div>
      }
    
    {
      loader8?
      <div className="flex w-full h-full justify-center items-center min-h-screen ">
        <div className="flex flex-col p-6 items-center font-poppins space-y-5">
          <img src={idea} className="h-10 text-center animate-bounce "></img>
          <p className="text-xl font-extrabold">Super! You have completed your answers. Give us a few moments to upload your answers to our knowledge cloud.</p>
          <p className="font-extrabold text-xl">Pro Tip</p>
        <p className="text-lg font-extrabold "><span className="font-mono hidden lg:block text-6xl">"</span>{qoutes[index]}<span className="font-mono hidden text-6xl">"</span></p>
             <ThreeDots
             width='50'
             color='#4899B7'
             className="mx-auto"
             ></ThreeDots>
             <p>interviewvision.com</p>
        </div>
      </div> 
      :
    <div className="bg-cover min-h-screen" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/Bg.png'})`}}>
      <img src={logo} className="lg:h-28 h-16 p-4 "></img>
      {
        ques == 0 &&
        <div className="relative">
          
          <div className="lg:w-3/6  mx-auto font-poppins">
            <p className=" mx-3">Please read the following instructions carefully before starting with your test interview:</p>
            <div className="lg:grid lg:grid-cols-2  mx-4 space-y-4 lg:space-y-0 py-4 gap-x-6">
              <div className="bg-white rounded-xl py-3 px-3">
                <img src={testpic1} className="h-48 mx-auto my-4"></img>
                <p className="font-bold my-4">Instructions for Recording:</p>
                <ul className="list-disc ml-6">
                  <li>Please use a phone/computer with a camera and a microphone</li>
                  <li>Ensure to give the necessary permissions to allow recording if prompted</li>
                  <li>Please make sure there is no background noise</li>
                  <li>Try to sit in a place with sufficient light</li>
                  <li>It is better to use a headphone for clear voice</li>
                  <li>Try to speak slowly and clearly for audio clarity</li>
                </ul>
              </div>
              <div className="bg-white rounded-xl py-3 px-3">
                <img src={testpic2} className="h-48 mx-auto my-4 "></img>
                <p className="font-bold my-4">How you will be tested?</p>
                <ul className="list-disc ml-6">
                  <li>This will be a video interview</li>
                  <li>Once you see yourself on the screen, click on start and begin answering the question</li>
                  <li>After completing your answer, click on submit to upload your answer to the AI evaluator</li>
                  <li>Please complete all the answers in the interview to get a valid report</li>
                  <li>A detailed report will be sent to your registered email ID with your scores</li>
                </ul>
              </div>
            </div>
            <div className="flex justify-between items-center pb-5 mx-3">
              <Link to='/firstSignup' state={{name:name, pass:pass}}>
              <button className="lg:py-4 py-2 px-6 lg:px-12 text-slate-600 bg-[#FFFFFF] rounded-lg ">Back</button>
              </Link>
              <button className="lg:py-4 py-2 px-6 lg:px-12 text-slate-200 bg-gradient-to-r from-[#E94B60] to-[#F0939F] rounded-lg " onClick={()=>{setQues(4)}}>Lets Go</button>
            </div>
          </div>
        </div>
      }
      
      
      {
        ques == 4 &&
        <div className="lg:w-2/3 mx-3 lg:mx-auto bg-cover rounded-xl py-4 px-3" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/Rectangle5.png'})`}}>
        <div className="flex bg-[#39435A] items-center space-x-3 px-3 py-3 rounded-xl">
              <p className="bg-white rounded-full p-2 px-4 font-bold font-poppins">1</p>
              <p className="text-white font-poppins">{questions[0].statement}</p>
            </div>
      <div className="mx-auto text-center py-3 ">
        <div className="relative lg:w-1/2  mx-auto">
        <video playsInline ref={recordWebcam.previewRef} className={recordWebcam.status !="PREVIEW" ? "mx-auto my-4 rounded-xl hidden":"mx-auto my-4 rounded-xl"} autoPlay muted={status}  loop />
          {
            recordWebcam.status !=="PREVIEW" &&
            <video playsInline ref={recordWebcam.webcamRef} className="mx-auto my-4 rounded-xl " autoPlay muted />
          }
          {
            recordWebcam.status == "RECORDING" &&
            <p className="absolute top-0 text-white w-full mx-autp left-0 right-0">Recording</p>
          }
        </div>
      <div className="space-x-3">
        {
          recordWebcam.status =="OPEN" &&
          <button onClick={recordWebcam.start} className="bg-green-500 px-6 py-3 rounded-xl text-white">Start recording</button>
        }
      {
        recordWebcam.status =="RECORDING" &&
        <button onClick={stop} className="bg-red-600 px-6 py-3 rounded-xl text-white">Stop recording</button>
      }
      {
        recordWebcam.status == "PREVIEW" &&
        <div className="lg:space-x-3 flex flex-col space-y-3 lg:block lg:space-y-0">
          <button onClick={retake} className="bg-blue-600 px-6 py-3 rounded-xl text-white">Retake recording</button>
          <button onClick={recordWebcam.download} className="bg-blue-600 px-6 py-3 rounded-xl text-white">Download recording</button>
          <button onClick={()=>{
            setLoader1(true);
            setQues(5)
          }} className="bg-blue-600 px-6 py-3 rounded-xl text-white">Submit</button>
        </div>
      }
      <button className="bg-transparent px-6 py-3 rounded-xl text-white" onClick={recordWebcam.open}>If you are unable to see your video, click here</button>
      </div>
      </div>
      {
            // recordWebcam.status == "PREVIEW" &&
            
      }
      </div>
      }
      {
        ques == 5 &&
        <div className="lg:w-2/3 mx-3 lg:mx-auto bg-cover  rounded-xl py-4 px-3" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/Rectangle5.png'})`}}> 
        <div className="flex bg-[#39435A] items-center space-x-3 px-3 py-3 rounded-xl">
              <p className="bg-white rounded-full p-2 px-4 font-bold font-poppins">2</p>
              <p className="text-white font-poppins">{questions[1].statement}</p>
            </div>
      <div className="mx-auto text-center py-3 ">
        <div className="relative lg:w-1/2 mx-auto">
        <video playsInline ref={recordWebcam.previewRef} className={recordWebcam.status !="PREVIEW" ? "mx-auto my-4 rounded-xl hidden":"mx-auto my-4 rounded-xl"} autoPlay muted={status}  loop />
          {
            recordWebcam.status !=="PREVIEW" &&
            <video playsInline ref={recordWebcam.webcamRef} className="mx-auto my-4 rounded-xl " autoPlay muted />
          }
          {
            recordWebcam.status == "RECORDING" &&
            <p className="absolute top-0 text-white w-full mx-autp left-0 right-0">Recording</p>
          }
        </div>
      <div className="space-x-3">
        {
          recordWebcam.status =="OPEN" &&
        <button onClick={recordWebcam.start} className="bg-green-500 px-6 py-3 rounded-xl text-white">Start recording</button>
        }
      {
        recordWebcam.status =="RECORDING" &&
        <button onClick={stop} className="bg-red-600 px-6 py-3 rounded-xl text-white">Stop recording</button>
      }
      {
        recordWebcam.status == "PREVIEW" &&
        <div className="lg:space-x-3 flex flex-col space-y-3 lg:block lg:space-y-0">
          <button onClick={retake} className="bg-blue-600 px-6 py-3 rounded-xl text-white">Retake recording</button>
          <button onClick={recordWebcam.download} className="bg-blue-600 px-6 py-3 rounded-xl text-white">Download recording</button>
          <button onClick={()=>{
            setLoader2(true)
            setQues(6)
          }} className="bg-blue-600 px-6 py-3 rounded-xl text-white">Submit</button>
        </div>
      }
      <button className="bg-transparent px-6 py-3 rounded-xl text-white" onClick={recordWebcam.open}>If you are unable to see your video, click here</button>
      </div>
      </div>
      {
            // recordWebcam.status == "PREVIEW" &&
            
      }
      </div>
      }
      {
        ques == 6 &&
        <div className="lg:w-2/3 mx-3 lg:mx-auto bg-cover rounded-xl py-4 px-3" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/Rectangle5.png'})`}}>
        <div className="flex bg-[#39435A] items-center space-x-3 px-3 py-3 rounded-xl">
              <p className="bg-white rounded-full p-2 px-4 font-bold font-poppins">3</p>
              <p className="text-white font-poppins">{questions[2].statement}</p>
            </div>
      <div className="mx-auto text-center py-3 ">
        <div className="relative lg:w-1/2 mx-auto">
        <video playsInline ref={recordWebcam.previewRef} className={recordWebcam.status !="PREVIEW" ? "mx-auto my-4 rounded-xl hidden":"mx-auto my-4 rounded-xl"} autoPlay muted={status}  loop />
          {
            recordWebcam.status !=="PREVIEW" &&
            <video playsInline ref={recordWebcam.webcamRef} className="mx-auto my-4 rounded-xl " autoPlay muted />
          }
          {
            recordWebcam.status == "RECORDING" &&
            <p className="absolute top-0 text-white w-full mx-autp left-0 right-0">Recording</p>
          }
        </div>
      <div className="space-x-3">
        {
          recordWebcam.status =="OPEN" &&
        <button onClick={recordWebcam.start} className="bg-green-500 px-6 py-3 rounded-xl text-white">Start recording</button>
        }
      {
        recordWebcam.status =="RECORDING" &&
        <button onClick={stop} className="bg-red-600 px-6 py-3 rounded-xl text-white">Stop recording</button>
      }
      {
        recordWebcam.status == "PREVIEW" &&
        <div className="lg:space-x-3 flex flex-col space-y-3 lg:block lg:space-y-0">
          <button onClick={retake} className="bg-blue-600 px-6 py-3 rounded-xl text-white">Retake recording</button>
          <button onClick={recordWebcam.download} className="bg-blue-600 px-6 py-3 rounded-xl text-white">Download recording</button>
          <button onClick={()=>{
            setLoader3(true)
            setQues(12)
          }} className="bg-blue-600 px-6 py-3 rounded-xl text-white">Submit</button>
        </div>
      }
      <button className="bg-transparent px-6 py-3 rounded-xl text-white" onClick={recordWebcam.open}>If you are unable to see your video, click here</button>
      </div>
      </div>
      {
            // recordWebcam.status == "PREVIEW" &&
            
      }
      </div>
      }
      {
        ques == 7 &&
        <div className="lg:w-2/3 mx-3 lg:mx-auto bg-cover rounded-xl py-4 px-3" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/Rectangle5.png'})`}}>
        <div className="flex bg-[#39435A] items-center space-x-3 px-3 py-3 rounded-xl">
              <p className="bg-white rounded-full p-2 px-4 font-bold font-poppins">4</p>
              <p className="text-white font-poppins">{questions[3].statement}</p>
            </div>
      <div className="mx-auto text-center py-3 ">
        <div className="relative lg:w-1/2 mx-auto">
        <video playsInline ref={recordWebcam.previewRef} className={recordWebcam.status !="PREVIEW" ? "mx-auto my-4 rounded-xl hidden":"mx-auto my-4 rounded-xl"} autoPlay muted={status}  loop />
          {
            recordWebcam.status !=="PREVIEW" &&
            <video playsInline ref={recordWebcam.webcamRef} className="mx-auto my-4 rounded-xl " autoPlay muted />
          }
          {
            recordWebcam.status == "RECORDING" &&
            <p className="absolute top-0 text-white w-full mx-autp left-0 right-0">Recording</p>
          }
        </div>
      <div className="space-x-3">
        {
          recordWebcam.status =="OPEN" &&
        <button onClick={recordWebcam.start} className="bg-green-500 px-6 py-3 rounded-xl text-white">Start recording</button>
        }
      {
        recordWebcam.status =="RECORDING" &&
        <button onClick={stop} className="bg-red-600 px-6 py-3 rounded-xl text-white">Stop recording</button>
      }
      {
        recordWebcam.status == "PREVIEW" &&
        <div className="lg:space-x-3 flex flex-col space-y-3 lg:block lg:space-y-0">
          <button onClick={retake} className="bg-blue-600 px-6 py-3 rounded-xl text-white">Retake recording</button>
          <button onClick={recordWebcam.download} className="bg-blue-600 px-6 py-3 rounded-xl text-white">Download recording</button>
          <button onClick={()=>{
            setLoader4(true)
            saveFile4()
          }} className="bg-blue-600 px-6 py-3 rounded-xl text-white">Submit</button>
        </div>
      }
      <button className="bg-transparent px-6 py-3 rounded-xl text-white" onClick={recordWebcam.open}>If you are unable to see your video, click here</button>
      </div>
      </div>
      </div>
      }
      {
        ques == 8 &&
        <div className="lg:w-2/3 mx-3 lg:mx-auto bg-cover rounded-xl py-4 px-3" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/Rectangle5.png'})`}}>
        <div className="flex bg-[#39435A] items-center space-x-3 px-3 py-3 rounded-xl">
              <p className="bg-white rounded-full p-2 px-4 font-bold font-poppins">5</p>
              <p className="text-white font-poppins">{questions[4].statement}</p>
            </div>
      <div className="mx-auto text-center py-3 ">
        <div className="relative lg:w-1/2 mx-auto">
        <video playsInline ref={recordWebcam.previewRef} className={recordWebcam.status !="PREVIEW" ? "mx-auto my-4 rounded-xl hidden":"mx-auto my-4 rounded-xl"} autoPlay muted={status}  loop />
          {
            recordWebcam.status !=="PREVIEW" &&
            <video playsInline ref={recordWebcam.webcamRef} className="mx-auto my-4 rounded-xl " autoPlay muted />
          }
          {
            recordWebcam.status == "RECORDING" &&
            <p className="absolute top-0 text-white w-full mx-autp left-0 right-0">Recording</p>
          }
        </div>
      <div className="space-x-3">
        {
          recordWebcam.status =="OPEN" &&
        <button onClick={recordWebcam.start} className="bg-green-500 px-6 py-3 rounded-xl text-white">Start recording</button>
        }
      {
        recordWebcam.status =="RECORDING" &&
        <button onClick={stop} className="bg-red-600 px-6 py-3 rounded-xl text-white">Stop recording</button>
      }
      {
        recordWebcam.status == "PREVIEW" &&
        <div className="lg:space-x-3 flex flex-col space-y-3 lg:block lg:space-y-0">
          <button onClick={retake} className="bg-blue-600 px-6 py-3 rounded-xl text-white">Retake recording</button>
          <button onClick={recordWebcam.download} className="bg-blue-600 px-6 py-3 rounded-xl text-white">Download recording</button>
          <button onClick={()=>{
            setLoader5(true)
            saveFile5()
          }} className="bg-blue-600 px-6 py-3 rounded-xl text-white">Submit</button>
        </div>
      }
      <button className="bg-transparent px-6 py-3 rounded-xl text-white" onClick={recordWebcam.open}>If you are unable to see your video, click here</button>
      </div>
      </div>
      </div>
      }
       
      {
        ques == 9 &&
        <div className="lg:w-2/3 mx-3 lg:mx-auto bg-cover rounded-xl py-4 px-3" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/Rectangle5.png'})`}}>
        <div className="flex bg-[#39435A] items-center space-x-3 px-3 py-3 rounded-xl">
              <p className="bg-white rounded-full p-2 px-4 font-bold font-poppins">6</p>
              <p className="text-white font-poppins">{questions[5].statement}</p>
            </div>
      <div className="mx-auto text-center py-3 ">
        <div className="relative lg:w-1/2 mx-auto">
        <video playsInline ref={recordWebcam.previewRef} className={recordWebcam.status !="PREVIEW" ? "mx-auto my-4 rounded-xl hidden":"mx-auto my-4 rounded-xl"} autoPlay muted={status}  loop />
          {
            recordWebcam.status !=="PREVIEW" &&
            <video playsInline ref={recordWebcam.webcamRef} className="mx-auto my-4 rounded-xl " autoPlay muted />
          }
          {
            recordWebcam.status == "RECORDING" &&
            <p className="absolute top-0 text-white w-full mx-autp left-0 right-0">Recording</p>
          }
        </div>
      <div className="space-x-3">
        {
          recordWebcam.status =="OPEN" &&
        <button onClick={recordWebcam.start} className="bg-green-500 px-6 py-3 rounded-xl text-white">Start recording</button>
        }
      {
        recordWebcam.status =="RECORDING" &&
        <button onClick={stop} className="bg-red-600 px-6 py-3 rounded-xl text-white">Stop recording</button>
      }
      {
        recordWebcam.status == "PREVIEW" &&
        <div className="lg:space-x-3 flex flex-col space-y-3 lg:block lg:space-y-0">
          <button onClick={retake} className="bg-blue-600 px-6 py-3 rounded-xl text-white">Retake recording</button>
          <button onClick={recordWebcam.download} className="bg-blue-600 px-6 py-3 rounded-xl text-white">Download recording</button>
          <button onClick={()=>{
            setLoader6(true)
            saveFile6()
            }} className="bg-blue-600 px-6 py-3 rounded-xl text-white">Submit</button>
        </div>
      }
      <button className="bg-transparent px-6 py-3 rounded-xl text-white" onClick={recordWebcam.open}>If you are unable to see your video, click here</button>
      </div>
      </div>
      </div>
      }
      
      {
        ques == 10 &&
        <div className="lg:w-2/3 mx-3 lg:mx-auto rounded-xl bg-cover py-4 px-3" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/Rectangle5.png'})`}}>
        <div className="flex bg-[#39435A] items-center space-x-3 px-3 py-3 rounded-xl">
              <p className="bg-white rounded-full p-2 px-4 font-bold font-poppins">7</p>
              <p className="text-white font-poppins">{questions[6].statement}</p>
            </div>
      <div className="mx-auto text-center py-3 ">
        <div className="relative lg:w-1/2 mx-auto">
        <video playsInline ref={recordWebcam.previewRef} className={recordWebcam.status !="PREVIEW" ? "mx-auto my-4 rounded-xl hidden":"mx-auto my-4 rounded-xl"} autoPlay muted={status}  loop />
          {
            recordWebcam.status !=="PREVIEW" &&
            <video playsInline ref={recordWebcam.webcamRef} className="mx-auto my-4 rounded-xl " autoPlay muted />
          }
          {
            recordWebcam.status == "RECORDING" &&
            <p className="absolute top-0 text-white w-full mx-autp left-0 right-0">Recording</p>
          }
        </div>
      <div className="space-x-3">
        {
          recordWebcam.status =="OPEN" &&
        <button onClick={recordWebcam.start} className="bg-green-600 px-6 py-3 rounded-xl text-white">Start recording</button>
        }
      {
        recordWebcam.status =="RECORDING" &&
        <button onClick={stop} className="bg-red-600 px-6 py-3 rounded-xl text-white">Stop recording</button>
      }
      {
        recordWebcam.status == "PREVIEW" &&
        <div className="lg:space-x-3 lg:block lg:space-y-0 flex flex-col space-y-3">
          <button onClick={retake} className="bg-blue-600 px-6 py-3 rounded-xl text-white">Retake recording</button>
          <button onClick={recordWebcam.download} className="bg-blue-600 px-6 py-3 rounded-xl text-white">Download recording</button>
          <button onClick={()=>{
            setLoader7(true)
            saveFile8()
          }} className="bg-blue-600 px-6 py-3 rounded-xl text-white">Submit</button>
        </div>
      }
      <button className="bg-transparent px-6 py-3 rounded-xl text-white" onClick={recordWebcam.open}>If you are unable to see your video, click here</button>
      </div>
      </div>
      </div>
      }
      {
        ques == 11 &&
        <div className="lg:w-2/3 mx-3 lg:mx-auto rounded-xl bg-cover py-4 px-3" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/Rectangle5.png'})`}}>
        <div className="flex bg-[#39435A] items-center space-x-3 px-3 py-3 rounded-xl">
              <p className="bg-white rounded-full p-2 px-4 font-bold font-poppins">8</p>
              <p className="text-white font-poppins">{questions[7].statement}</p>
            </div>
      <div className="mx-auto text-center py-3 ">
        <div className="relative lg:w-1/2 mx-auto">
        <video playsInline ref={recordWebcam.previewRef} className={recordWebcam.status !="PREVIEW" ? "mx-auto my-4 rounded-xl hidden":"mx-auto my-4 rounded-xl"} autoPlay muted={status}  loop />
          {
            recordWebcam.status !=="PREVIEW" &&
            <video playsInline ref={recordWebcam.webcamRef} className="mx-auto my-4 rounded-xl " autoPlay muted />
          }
          {
            recordWebcam.status == "RECORDING" &&
            <p className="absolute top-0 text-white w-full mx-autp left-0 right-0">Recording</p>
          }
        </div>
      <div className="space-x-3">
        {
          recordWebcam.status =="OPEN" &&
        <button onClick={recordWebcam.start} className="bg-green-500 px-6 py-3 rounded-xl text-white">Start recording</button>
        }
      {
        recordWebcam.status =="RECORDING" &&
        <button onClick={stop} className="bg-red-600 px-6 py-3 rounded-xl text-white">Stop recording</button>
      }
      {
        recordWebcam.status == "PREVIEW" &&
        <div className="lg:space-x-3 lg:block lg:space-y-0 flex flex-col space-y-3">
          <button onClick={retake} className="bg-blue-600 px-6 py-3 rounded-xl text-white">Retake recording</button>
          <button onClick={recordWebcam.download} className="bg-blue-600 px-6 py-3 rounded-xl text-white">Download recording</button>
          <button onClick={()=>{
            setLoader8(true)
            saveFile9()
          }} className="bg-blue-600 px-6 py-3 rounded-xl text-white">Submit</button>
        </div>
      }
      <button className="bg-transparent px-6 py-3 rounded-xl text-white" onClick={recordWebcam.open}>If you are unable to see your video, click here</button>
      </div>
      </div>
      </div>
      }
      
      {
        ques == 14 && 
        <div className="my-20">
        <div className="lg:w-2/3 mx-3 lg:mx-auto bg-cover  rounded-xl py-4 px-4 lg:px-20" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/Rectangle5.png'})`}}>
        <p className="text-center font- font-poppins my-5 lg:text-4xl lg:leading-10 text-white">Congratulations!<br></br>You have completed the first section. <br></br>Click next to begin your video interview section</p>
        </div>
        <div className="flex justify-between lg:w-2/3 mr-3  lg:mx-auto items-center py-3 pb-5">
          <button className="lg:py-4 py-2 px-6 lg:px-12 text-slate-600 bg-[#FFFFFF] rounded-lg " onClick={()=>{setQues(3)}}>Back</button>
        <button className="lg:py-4 py-2 px-6 lg:px-12 text-slate-200 bg-gradient-to-r from-[#E94B60] to-[#F0939F] rounded-lg " onClick={()=>{setQues(4)}}>Next</button>
        </div>
        </div>
        
      }
      {
        ques!=13 && ques!==12 && ques!==13 && ques!==0 && ques!=14 && ques!=15
        &&
      <input className="mt-4 bottom-0 w-full h-16 outline-none px-2 py-2" placeholder="You can write your notes here"></input>
      }
      {
        ques == 12 &&
        <div className="relative space-y-10" >
          
          <div className="my-20">
        <div className="lg:w-2/3 mx-3 lg:mx-auto bg-cover  rounded-xl py-4 px-4 lg:px-12" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/Rectangle5.png'})`}}>
        <p className="text-center font- font-poppins my-5 lg:text-2xl lg:leading-10">Congratulations for successfully completing your demo interview. <br></br></p>
      
        </div>
        
      </div>
          <div className="lg:w-2/3 mx-3 lg:mx-auto">
            <div className=" py-3 lg:py-8 px-4 lg:px-10 rounded-lg bg-cover" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/Rectangle5.png'})`}}>
              <p className="text-[#888888] my-1">Question</p>
              <div className="flex bg-[#39435A] items-center space-x-3 px-3 py-3 rounded-xl">
                <p className="bg-white rounded-full p-2 px-4 font-bold font-poppins">1</p>
                <p className="text-white font-poppins">{feedback[0].statement}</p>
              </div>
              <div className=" my-4  text-center flex items-center sm:space-x-9 space-x-4 sm:scale-100 scale-[0.4] lg:space-x-9 mx-auto justify-center text-lg font-poppins ">
              <button onClick={()=>{setChoice1(1)}} className={choice1>=1?"bg-blue-500 py-4  rounded-full px-7 flex space-x-3 items-center text-white":"bg-[#F3F3F3]   py-4 rounded-full px-6 flex space-x-3 items-center" }>
                  {/* <div className="border-4 p-3 border-white rounded-full "></div> */}
                  <h1>{feedback[3].options[0]}</h1>
              </button>
              <button onClick={()=>{setChoice1(2)}} className={choice1>=2?"bg-blue-500 py-4 rounded-full px-6 flex  space-x-3 items-center text-white":"bg-[#F3F3F3]  py-4 rounded-full px-6 flex  space-x-3 items-center" }>
              {/* <div className="border-4 p-3 border-white rounded-full "></div> */}
                  <h1>{feedback[3].options[1]}</h1>
              </button>
              <button onClick={()=>{setChoice1(3)}} className={choice1>=3?"bg-blue-500 py-4 rounded-full px-6 flex  space-x-3 items-center text-white":"bg-[#F3F3F3]  py-4 rounded-full px-6 flex  space-x-3 items-center" }>
              {/* <div className="border-4 p-3 border-white rounded-full "></div> */}
                  <h1>{feedback[3].options[2]}</h1>
              </button>
              <button onClick={()=>{setChoice1(4)}} className={choice1>=4?"bg-blue-500 py-4 rounded-full px-6 flex  space-x-3 items-center text-white":"bg-[#F3F3F3]  py-4 rounded-full px-6 flex  space-x-3 items-center" }>
              {/* <div className="border-4 p-3 border-white rounded-full "></div> */}
                  <h1 className="text-center">{feedback[3].options[3]}</h1>
              </button>
              <button onClick={()=>{setChoice1(5)}} className={choice1>=5?"bg-blue-500 py-4 rounded-full px-6 flex  space-x-3 items-center text-white":"bg-[#F3F3F3]  py-4 rounded-full px-6 flex  space-x-3 items-center" }>
              {/* <div className="border-4 p-3 border-white rounded-full "></div> */}
                  <h1 className="text-center">{feedback[3].options[4]}</h1>
              </button>
              <button onClick={()=>{setChoice1(6)}} className={choice1>=6?"bg-blue-500 py-4 rounded-full px-6 flex  space-x-3 items-center text-white":"bg-[#F3F3F3]  py-4 rounded-full px-6 flex  space-x-3 items-center" }>
              {/* <div className="border-4 p-3 border-white rounded-full "></div> */}
                  <h1 className="text-center">6</h1>
              </button>
              <button onClick={()=>{setChoice1(7)}} className={choice1>=7?"bg-blue-500 py-4 rounded-full px-6 flex  space-x-3 items-center text-white":"bg-[#F3F3F3]  py-4 rounded-full px-6 flex  space-x-3 items-center" }>
              {/* <div className="border-4 p-3 border-white rounded-full "></div> */}
                  <h1 className="text-center">7</h1>
              </button>
              <button onClick={()=>{setChoice1(8)}} className={choice1>=8?"bg-blue-500 py-4 rounded-full px-6 flex  space-x-3 items-center text-white":"bg-[#F3F3F3]  py-4 rounded-full px-6 flex  space-x-3 items-center" }>
              {/* <div className="border-4 p-3 border-white rounded-full "></div> */}
                  <h1 className="text-center">8</h1>
              </button>
              <button onClick={()=>{setChoice1(9)}} className={choice1>=9?"bg-blue-500 py-4 rounded-full px-6 flex  space-x-3 items-center text-white":"bg-[#F3F3F3]  py-4 rounded-full px-6 flex  space-x-3 items-center" }>
              {/* <div className="border-4 p-3 border-white rounded-full "></div> */}
                  <h1 className="text-center">9</h1>
              </button>
              <button onClick={()=>{setChoice1(10)}} className={choice1>=10?"bg-blue-500 py-4 rounded-full px-5 flex  space-x-3 items-center text-white":"bg-[#F3F3F3]  py-4 rounded-full px-5 flex  space-x-3 items-center" }>
              {/* <div className="border-4 p-3 border-white rounded-full "></div> */}
                  <h1 className="text-center">10</h1>
              </button>
              
          </div>
          <div className="flex justify-between mx-4">
            <p className="text-white">Poor</p>
            <p className="text-white">Excellent</p>
            
          </div>
            </div>
          </div>
          <div className="lg:w-2/3 mx-3 lg:mx-auto">
            <div className=" py-3 lg:py-8 px-4 lg:px-10 rounded-lg bg-cover" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/Rectangle5.png'})`}}>
              <p className="text-[#888888] my-1">Question</p>
              <div className="flex bg-[#39435A] items-center space-x-3 px-3 py-3 rounded-xl">
                <p className="bg-white rounded-full p-2 px-4 font-bold font-poppins">2</p>
                <p className="text-white font-poppins">{feedback[1].statement}</p>
              </div>
              <div className=" my-4  text-center flex items-center sm:space-x-9 space-x-4 sm:scale-100 scale-[0.4] mx-auto justify-center text-lg font-poppins ">
              <button onClick={()=>{setChoice2(1)}} className={choice2>=1?"bg-blue-500 py-4  rounded-full px-7 flex space-x-3 items-center text-white":"bg-[#F3F3F3]   py-4 rounded-full px-6 flex space-x-3 items-center" }>
                  {/* <div className="border-4 p-3 border-white rounded-full "></div> */}
                  <h1>{feedback[3].options[0]}</h1>
              </button>
              <button onClick={()=>{setChoice2(2)}} className={choice2>=2?"bg-blue-500 py-4 rounded-full px-6 flex  space-x-3 items-center text-white":"bg-[#F3F3F3]  py-4 rounded-full px-6 flex  space-x-3 items-center" }>
              {/* <div className="border-4 p-3 border-white rounded-full "></div> */}
                  <h1>{feedback[3].options[1]}</h1>
              </button>
              <button onClick={()=>{setChoice2(3)}} className={choice2>=3?"bg-blue-500 py-4 rounded-full px-6 flex  space-x-3 items-center text-white":"bg-[#F3F3F3]  py-4 rounded-full px-6 flex  space-x-3 items-center" }>
              {/* <div className="border-4 p-3 border-white rounded-full "></div> */}
                  <h1>{feedback[3].options[2]}</h1>
              </button>
              <button onClick={()=>{setChoice2(4)}} className={choice2>=4?"bg-blue-500 py-4 rounded-full px-6 flex  space-x-3 items-center text-white":"bg-[#F3F3F3]  py-4 rounded-full px-6 flex  space-x-3 items-center" }>
              {/* <div className="border-4 p-3 border-white rounded-full "></div> */}
                  <h1 className="text-center">{feedback[3].options[3]}</h1>
              </button>
              <button onClick={()=>{setChoice2(5)}} className={choice2>=5?"bg-blue-500 py-4 rounded-full px-6 flex  space-x-3 items-center text-white":"bg-[#F3F3F3]  py-4 rounded-full px-6 flex  space-x-3 items-center" }>
              {/* <div className="border-4 p-3 border-white rounded-full "></div> */}
                  <h1 className="text-center">{feedback[3].options[4]}</h1>
              </button>
              <button onClick={()=>{setChoice2(6)}} className={choice2>=6?"bg-blue-500 py-4 rounded-full px-6 flex  space-x-3 items-center text-white":"bg-[#F3F3F3]  py-4 rounded-full px-6 flex  space-x-3 items-center" }>
              {/* <div className="border-4 p-3 border-white rounded-full "></div> */}
                  <h1 className="text-center">6</h1>
              </button>
              <button onClick={()=>{setChoice2(7)}} className={choice2>=7?"bg-blue-500 py-4 rounded-full px-6 flex  space-x-3 items-center text-white":"bg-[#F3F3F3]  py-4 rounded-full px-6 flex  space-x-3 items-center" }>
              {/* <div className="border-4 p-3 border-white rounded-full "></div> */}
                  <h1 className="text-center">7</h1>
              </button>
              <button onClick={()=>{setChoice2(8)}} className={choice2>=8?"bg-blue-500 py-4 rounded-full px-6 flex  space-x-3 items-center text-white":"bg-[#F3F3F3]  py-4 rounded-full px-6 flex  space-x-3 items-center" }>
              {/* <div className="border-4 p-3 border-white rounded-full "></div> */}
                  <h1 className="text-center">8</h1>
              </button>
              <button onClick={()=>{setChoice2(9)}} className={choice2>=9?"bg-blue-500 py-4 rounded-full px-6 flex  space-x-3 items-center text-white":"bg-[#F3F3F3]  py-4 rounded-full px-6 flex  space-x-3 items-center" }>
              {/* <div className="border-4 p-3 border-white rounded-full "></div> */}
                  <h1 className="text-center">9</h1>
              </button>
              <button onClick={()=>{setChoice2(10)}} className={choice2>=10?"bg-blue-500 py-4 rounded-full px-5 flex  space-x-3 items-center text-white":"bg-[#F3F3F3]  py-4 rounded-full px-5 flex  space-x-3 items-center" }>
              {/* <div className="border-4 p-3 border-white rounded-full "></div> */}
                  <h1 className="text-center">10</h1>
              </button>
              
          </div>
          <div className="flex justify-between mx-4">
            <p className="text-white">Poor</p>
            <p className="text-white">Excellent</p>
          </div>
            </div>
          </div>

          <div className="lg:w-2/3 mx-3 lg:mx-auto">
            <div className=" py-3 lg:py-8 px-4 lg:px-10 rounded-lg bg-cover" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/Rectangle5.png'})`}}>
              <p className="text-[#888888] my-1">Question</p>
              <div className="flex bg-[#39435A] items-center space-x-3 px-3 py-3 rounded-xl">
                <p className="bg-white rounded-full p-2 px-4 font-bold font-poppins">3</p>
                <p className="text-white font-poppins">{feedback[2].statement}</p>
              </div>
              <div className=" my-4  text-center flex items-center sm:space-x-9 space-x-4 sm:scale-100 scale-[0.4] mx-auto justify-center text-lg font-poppins ">
              <button onClick={()=>{setChoice3(1)}} className={choice3>=1?"bg-blue-500 py-4  rounded-full px-7 flex space-x-3 items-center text-white":"bg-[#F3F3F3]   py-4 rounded-full px-6 flex space-x-3 items-center" }>
                  {/* <div className="border-4 p-3 border-white rounded-full "></div> */}
                  <h1>{feedback[3].options[0]}</h1>
              </button>
              <button onClick={()=>{setChoice3(2)}} className={choice3>=2?"bg-blue-500 py-4 rounded-full px-6 flex  space-x-3 items-center text-white":"bg-[#F3F3F3]  py-4 rounded-full px-6 flex  space-x-3 items-center" }>
              {/* <div className="border-4 p-3 border-white rounded-full "></div> */}
                  <h1>{feedback[3].options[1]}</h1>
              </button>
              <button onClick={()=>{setChoice3(3)}} className={choice3>=3?"bg-blue-500 py-4 rounded-full px-6 flex  space-x-3 items-center text-white":"bg-[#F3F3F3]  py-4 rounded-full px-6 flex  space-x-3 items-center" }>
              {/* <div className="border-4 p-3 border-white rounded-full "></div> */}
                  <h1>{feedback[3].options[2]}</h1>
              </button>
              <button onClick={()=>{setChoice3(4)}} className={choice3>=4?"bg-blue-500 py-4 rounded-full px-6 flex  space-x-3 items-center text-white":"bg-[#F3F3F3]  py-4 rounded-full px-6 flex  space-x-3 items-center" }>
              {/* <div className="border-4 p-3 border-white rounded-full "></div> */}
                  <h1 className="text-center">{feedback[3].options[3]}</h1>
              </button>
              <button onClick={()=>{setChoice3(5)}} className={choice3>=5?"bg-blue-500 py-4 rounded-full px-6 flex  space-x-3 items-center text-white":"bg-[#F3F3F3]  py-4 rounded-full px-6 flex  space-x-3 items-center" }>
              {/* <div className="border-4 p-3 border-white rounded-full "></div> */}
                  <h1 className="text-center">{feedback[3].options[4]}</h1>
              </button>
              <button onClick={()=>{setChoice3(6)}} className={choice3>=6?"bg-blue-500 py-4 rounded-full px-6 flex  space-x-3 items-center text-white":"bg-[#F3F3F3]  py-4 rounded-full px-6 flex  space-x-3 items-center" }>
              {/* <div className="border-4 p-3 border-white rounded-full "></div> */}
                  <h1 className="text-center">6</h1>
              </button>
              <button onClick={()=>{setChoice3(7)}} className={choice3>=7?"bg-blue-500 py-4 rounded-full px-6 flex  space-x-3 items-center text-white":"bg-[#F3F3F3]  py-4 rounded-full px-6 flex  space-x-3 items-center" }>
              {/* <div className="border-4 p-3 border-white rounded-full "></div> */}
                  <h1 className="text-center">7</h1>
              </button>
              <button onClick={()=>{setChoice3(8)}} className={choice3>=8?"bg-blue-500 py-4 rounded-full px-6 flex  space-x-3 items-center text-white":"bg-[#F3F3F3]  py-4 rounded-full px-6 flex  space-x-3 items-center" }>
              {/* <div className="border-4 p-3 border-white rounded-full "></div> */}
                  <h1 className="text-center">8</h1>
              </button>
              <button onClick={()=>{setChoice3(9)}} className={choice3>=9?"bg-blue-500 py-4 rounded-full px-6 flex  space-x-3 items-center text-white":"bg-[#F3F3F3]  py-4 rounded-full px-6 flex  space-x-3 items-center" }>
              {/* <div className="border-4 p-3 border-white rounded-full "></div> */}
                  <h1 className="text-center">9</h1>
              </button>
              <button onClick={()=>{setChoice3(10)}} className={choice3>=10?"bg-blue-500 py-4 rounded-full px-5 flex  space-x-3 items-center text-white":"bg-[#F3F3F3]  py-4 rounded-full px-5 flex  space-x-3 items-center" }>
              {/* <div className="border-4 p-3 border-white rounded-full "></div> */}
                  <h1 className="text-center">10</h1>
              </button>
              
          </div>
          <div className="flex justify-between mx-4">
            <p className="text-white">Strongly Disagree</p>
            <p className="text-white">Strongly Agree</p>
            
          </div>
            </div>
          </div>

          <div className="lg:w-2/3 mx-3 lg:mx-auto">
            <div className=" py-3 lg:py-8 px-4 lg:px-10 rounded-lg bg-cover" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/Rectangle5.png'})`}}>
              <p className="text-[#888888] my-1">Question</p>
              <div className="flex bg-[#39435A] items-center space-x-3 px-3 py-3 rounded-xl">
                <p className="bg-white rounded-full p-2 px-4 font-bold font-poppins">4</p>
                <p className="text-white font-poppins">{feedback[3].statement}</p>
              </div>
              <div className=" my-4  text-center flex items-center sm:space-x-9 space-x-4 sm:scale-100 scale-[0.4] mx-auto justify-center text-lg font-poppins ">
              <button onClick={()=>{setChoice4(1)}} className={choice4>=1?"bg-blue-500 py-4  rounded-full px-7 flex space-x-3 items-center text-white":"bg-[#F3F3F3]   py-4 rounded-full px-6 flex space-x-3 items-center" }>
                  {/* <div className="border-4 p-3 border-white rounded-full "></div> */}
                  <h1>{feedback[3].options[0]}</h1>
              </button>
              <button onClick={()=>{setChoice4(2)}} className={choice4>=2?"bg-blue-500 py-4 rounded-full px-6 flex  space-x-3 items-center text-white":"bg-[#F3F3F3]  py-4 rounded-full px-6 flex  space-x-3 items-center" }>
              {/* <div className="border-4 p-3 border-white rounded-full "></div> */}
                  <h1>{feedback[3].options[1]}</h1>
              </button>
              <button onClick={()=>{setChoice4(3)}} className={choice4>=3?"bg-blue-500 py-4 rounded-full px-6 flex  space-x-3 items-center text-white":"bg-[#F3F3F3]  py-4 rounded-full px-6 flex  space-x-3 items-center" }>
              {/* <div className="border-4 p-3 border-white rounded-full "></div> */}
                  <h1>{feedback[3].options[2]}</h1>
              </button>
              <button onClick={()=>{setChoice4(4)}} className={choice4>=4?"bg-blue-500 py-4 rounded-full px-6 flex  space-x-3 items-center text-white":"bg-[#F3F3F3]  py-4 rounded-full px-6 flex  space-x-3 items-center" }>
              {/* <div className="border-4 p-3 border-white rounded-full "></div> */}
                  <h1 className="text-center">{feedback[3].options[3]}</h1>
              </button>
              <button onClick={()=>{setChoice4(5)}} className={choice4>=5?"bg-blue-500 py-4 rounded-full px-6 flex  space-x-3 items-center text-white":"bg-[#F3F3F3]  py-4 rounded-full px-6 flex  space-x-3 items-center" }>
              {/* <div className="border-4 p-3 border-white rounded-full "></div> */}
                  <h1 className="text-center">{feedback[3].options[4]}</h1>
              </button>
              <button onClick={()=>{setChoice4(6)}} className={choice4>=6?"bg-blue-500 py-4 rounded-full px-6 flex  space-x-3 items-center text-white":"bg-[#F3F3F3]  py-4 rounded-full px-6 flex  space-x-3 items-center" }>
              {/* <div className="border-4 p-3 border-white rounded-full "></div> */}
                  <h1 className="text-center">6</h1>
              </button>
              <button onClick={()=>{setChoice4(7)}} className={choice4>=7?"bg-blue-500 py-4 rounded-full px-6 flex  space-x-3 items-center text-white":"bg-[#F3F3F3]  py-4 rounded-full px-6 flex  space-x-3 items-center" }>
              {/* <div className="border-4 p-3 border-white rounded-full "></div> */}
                  <h1 className="text-center">7</h1>
              </button>
              <button onClick={()=>{setChoice4(8)}} className={choice4>=8?"bg-blue-500 py-4 rounded-full px-6 flex  space-x-3 items-center text-white":"bg-[#F3F3F3]  py-4 rounded-full px-6 flex  space-x-3 items-center" }>
              {/* <div className="border-4 p-3 border-white rounded-full "></div> */}
                  <h1 className="text-center">8</h1>
              </button>
              <button onClick={()=>{setChoice4(9)}} className={choice4>=9?"bg-blue-500 py-4 rounded-full px-6 flex  space-x-3 items-center text-white":"bg-[#F3F3F3]  py-4 rounded-full px-6 flex  space-x-3 items-center" }>
              {/* <div className="border-4 p-3 border-white rounded-full "></div> */}
                  <h1 className="text-center">9</h1>
              </button>
              <button onClick={()=>{setChoice4(10)}} className={choice4>=10?"bg-blue-500 py-4 rounded-full px-5 flex  space-x-3 items-center text-white":"bg-[#F3F3F3]  py-4 rounded-full px-5 flex  space-x-3 items-center" }>
              {/* <div className="border-4 p-3 border-white rounded-full "></div> */}
                  <h1 className="text-center">10</h1>
              </button>
              
          </div>
          <div className="flex justify-between mx-4">
            <p className="text-white">Highly unlikely</p>
            <p className="text-white">Highly Likely</p>
          </div>
            </div>
            
          </div>

          <div className="lg:w-2/3 mx-3 lg:mx-auto">
            <div className=" py-3 lg:py-8 px-4 lg:px-10 rounded-lg bg-cover" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/Rectangle5.png'})`}}>
              <p className="text-[#888888] my-1">Question</p>
              <div className="flex bg-[#39435A] items-center space-x-3 px-3 py-3 rounded-xl">
                <p className="bg-white rounded-full p-2 px-4 font-bold font-poppins">5</p>
                <p className="text-white font-poppins">{feedback[4].statement}</p>
              </div>
              <div className=" my-4  text-center flex items-center sm:space-x-9 space-x-4 sm:scale-100 scale-[0.4] mx-auto justify-center text-lg font-poppins ">
              <button onClick={()=>{setChoice5(1)}} className={choice5>=1?"bg-blue-500 py-4  rounded-full px-7 flex space-x-3 items-center text-white":"bg-[#F3F3F3]   py-4 rounded-full px-6 flex space-x-3 items-center" }>
                  {/* <div className="border-4 p-3 border-white rounded-full "></div> */}
                  <h1>{feedback[3].options[0]}</h1>
              </button>
              <button onClick={()=>{setChoice5(2)}} className={choice5>=2?"bg-blue-500 py-4 rounded-full px-6 flex  space-x-3 items-center text-white":"bg-[#F3F3F3]  py-4 rounded-full px-6 flex  space-x-3 items-center" }>
              {/* <div className="border-4 p-3 border-white rounded-full "></div> */}
                  <h1>{feedback[3].options[1]}</h1>
              </button>
              <button onClick={()=>{setChoice5(3)}} className={choice5>=3?"bg-blue-500 py-4 rounded-full px-6 flex  space-x-3 items-center text-white":"bg-[#F3F3F3]  py-4 rounded-full px-6 flex  space-x-3 items-center" }>
              {/* <div className="border-4 p-3 border-white rounded-full "></div> */}
                  <h1>{feedback[3].options[2]}</h1>
              </button>
              <button onClick={()=>{setChoice5(4)}} className={choice5>=4?"bg-blue-500 py-4 rounded-full px-6 flex  space-x-3 items-center text-white":"bg-[#F3F3F3]  py-4 rounded-full px-6 flex  space-x-3 items-center" }>
              {/* <div className="border-4 p-3 border-white rounded-full "></div> */}
                  <h1 className="text-center">{feedback[3].options[3]}</h1>
              </button>
              <button onClick={()=>{setChoice5(5)}} className={choice5>=5?"bg-blue-500 py-4 rounded-full px-6 flex  space-x-3 items-center text-white":"bg-[#F3F3F3]  py-4 rounded-full px-6 flex  space-x-3 items-center" }>
              {/* <div className="border-4 p-3 border-white rounded-full "></div> */}
                  <h1 className="text-center">{feedback[3].options[4]}</h1>
              </button>
              <button onClick={()=>{setChoice5(6)}} className={choice5>=6?"bg-blue-500 py-4 rounded-full px-6 flex  space-x-3 items-center text-white":"bg-[#F3F3F3]  py-4 rounded-full px-6 flex  space-x-3 items-center" }>
              {/* <div className="border-4 p-3 border-white rounded-full "></div> */}
                  <h1 className="text-center">6</h1>
              </button>
              <button onClick={()=>{setChoice5(7)}} className={choice5>=7?"bg-blue-500 py-4 rounded-full px-6 flex  space-x-3 items-center text-white":"bg-[#F3F3F3]  py-4 rounded-full px-6 flex  space-x-3 items-center" }>
              {/* <div className="border-4 p-3 border-white rounded-full "></div> */}
                  <h1 className="text-center">7</h1>
              </button>
              <button onClick={()=>{setChoice5(8)}} className={choice5>=8?"bg-blue-500 py-4 rounded-full px-6 flex  space-x-3 items-center text-white":"bg-[#F3F3F3]  py-4 rounded-full px-6 flex  space-x-3 items-center" }>
              {/* <div className="border-4 p-3 border-white rounded-full "></div> */}
                  <h1 className="text-center">8</h1>
              </button>
              <button onClick={()=>{setChoice5(9)}} className={choice5>=9?"bg-blue-500 py-4 rounded-full px-6 flex  space-x-3 items-center text-white":"bg-[#F3F3F3]  py-4 rounded-full px-6 flex  space-x-3 items-center" }>
              {/* <div className="border-4 p-3 border-white rounded-full "></div> */}
                  <h1 className="text-center">9</h1>
              </button>
              <button onClick={()=>{setChoice5(10)}} className={choice5>=10?"bg-blue-500 py-4 rounded-full px-5 flex  space-x-3 items-center text-white":"bg-[#F3F3F3]  py-4 rounded-full px-5 flex  space-x-3 items-center" }>
              {/* <div className="border-4 p-3 border-white rounded-full "></div> */}
                  <h1 className="text-center">10</h1>
              </button>
          </div>
          <div className="flex justify-between mx-4">
            <p className="text-white">Highly unsatisfied</p>
            <p className="text-white">Highly satisfied</p>
          </div>
            </div>
            
          </div>

          <div className="lg:w-2/3 mx-3 lg:mx-auto">
            <div className=" py-3 lg:py-8 px-4 lg:px-10 rounded-lg bg-cover" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/Rectangle5.png'})`}}>
              <p className="text-[#888888] my-1">Question</p>
              <div className="flex bg-[#39435A] items-center space-x-3 px-3 py-3 rounded-xl">
                <p className="bg-white rounded-full p-2 px-4 font-bold font-poppins">6</p>
                <p className="text-white font-poppins">{feedback[5].statement}</p>
              </div>
              <div className=" my-4  text-center flex items-center space-x-3 sm:space-x-9 sm:scale-100 scale-[0.4] mx-auto justify-center text-lg font-poppins ">
              <button onClick={()=>{setChoice6(1)}} className={choice6>=1?"bg-blue-500 py-4  rounded-full px-7 flex space-x-3 items-center text-white":"bg-[#F3F3F3]   py-4 rounded-full px-6 flex space-x-3 items-center" }>
                  {/* <div className="border-4 p-3 border-white rounded-full "></div> */}
                  <h1>{feedback[3].options[0]}</h1>
              </button>
              <button onClick={()=>{setChoice6(2)}} className={choice6>=2?"bg-blue-500 py-4 rounded-full px-6 flex  space-x-3 items-center text-white":"bg-[#F3F3F3]  py-4 rounded-full px-6 flex  space-x-3 items-center" }>
              {/* <div className="border-4 p-3 border-white rounded-full "></div> */}
                  <h1>{feedback[3].options[1]}</h1>
              </button>
              <button onClick={()=>{setChoice6(3)}} className={choice6>=3?"bg-blue-500 py-4 rounded-full px-6 flex  space-x-3 items-center text-white":"bg-[#F3F3F3]  py-4 rounded-full px-6 flex  space-x-3 items-center" }>
              {/* <div className="border-4 p-3 border-white rounded-full "></div> */}
                  <h1>{feedback[3].options[2]}</h1>
              </button>
              <button onClick={()=>{setChoice6(4)}} className={choice6>=4?"bg-blue-500 py-4 rounded-full px-6 flex  space-x-3 items-center text-white":"bg-[#F3F3F3]  py-4 rounded-full px-6 flex  space-x-3 items-center" }>
              {/* <div className="border-4 p-3 border-white rounded-full "></div> */}
                  <h1 className="text-center">{feedback[3].options[3]}</h1>
              </button>
              <button onClick={()=>{setChoice6(5)}} className={choice6>=5?"bg-blue-500 py-4 rounded-full px-6 flex  space-x-3 items-center text-white":"bg-[#F3F3F3]  py-4 rounded-full px-6 flex  space-x-3 items-center" }>
              {/* <div className="border-4 p-3 border-white rounded-full "></div> */}
                  <h1 className="text-center">{feedback[3].options[4]}</h1>
              </button>
              <button onClick={()=>{setChoice6(6)}} className={choice6>=6?"bg-blue-500 py-4 rounded-full px-6 flex  space-x-3 items-center text-white":"bg-[#F3F3F3]  py-4 rounded-full px-6 flex  space-x-3 items-center" }>
              {/* <div className="border-4 p-3 border-white rounded-full "></div> */}
                  <h1 className="text-center">6</h1>
              </button>
              <button onClick={()=>{setChoice6(7)}} className={choice6>=7?"bg-blue-500 py-4 rounded-full px-6 flex  space-x-3 items-center text-white":"bg-[#F3F3F3]  py-4 rounded-full px-6 flex  space-x-3 items-center" }>
              {/* <div className="border-4 p-3 border-white rounded-full "></div> */}
                  <h1 className="text-center">7</h1>
              </button>
              <button onClick={()=>{setChoice6(8)}} className={choice6>=8?"bg-blue-500 py-4 rounded-full px-6 flex  space-x-3 items-center text-white":"bg-[#F3F3F3]  py-4 rounded-full px-6 flex  space-x-3 items-center" }>
              {/* <div className="border-4 p-3 border-white rounded-full "></div> */}
                  <h1 className="text-center">8</h1>
              </button>
              <button onClick={()=>{setChoice6(9)}} className={choice6>=9?"bg-blue-500 py-4 rounded-full px-6 flex  space-x-3 items-center text-white":"bg-[#F3F3F3]  py-4 rounded-full px-6 flex  space-x-3 items-center" }>
              {/* <div className="border-4 p-3 border-white rounded-full "></div> */}
                  <h1 className="text-center">9</h1>
              </button>
              <button onClick={()=>{setChoice6(10)}} className={choice6>=10?"bg-blue-500 py-4 rounded-full px-5 flex  space-x-3 items-center text-white":"bg-[#F3F3F3]  py-4 rounded-full px-5 flex  space-x-3 items-center" }>
              {/* <div className="border-4 p-3 border-white rounded-full "></div> */}
                  <h1 className="text-center">10</h1>
              </button>
              
          </div>
          <div className="flex justify-between mx-4">
            <p className="text-white">Highly unlikely</p>
            <p className="text-white">Highly Likely</p>
          </div>
            </div>
            <div className="flex justify-between items-center py-3 pb-5">
                <button className="lg:py-4 py-2 px-6 lg:px-12 text-slate-600 bg-[#FFFFFF] rounded-lg " onClick={skip}>Skip</button>
                <button className="lg:py-4 py-2 px-6 lg:px-12 text-slate-200 bg-gradient-to-r from-[#E94B60] to-[#F0939F] rounded-lg " onClick={feedback_save}>Next</button>
              </div>
          </div>
        </div>

      }
    </div>
}
</div>
  );
}

export default Demo;

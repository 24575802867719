import React, {useEffect} from 'react'
import { Link } from 'react-router-dom'
import {BiArrowBack} from 'react-icons/bi'

const Contact = () => {
  useEffect(()=>{
    window.scroll(0,0)
  },[])
  return (
    <div className="mt-3 mx-3"><Link to="/">
    <button className="bg-green-500 text-white rounded-xl p-4">
      <BiArrowBack></BiArrowBack>
    </button>
    </Link>
      <h2 className="text-center bg-red-500 text-white  py-8 rounded-xl my-4"><b>Contact Us</b></h2>
      <div className="my-4">
      <p className="text-2xl font-poppins ">Address:</p>
      <p className="font-poppins ">Novelio Technologies Private Limited
        <br></br>
7th Floor, Vatika Triangle, Mehrauli-Gurgaon Road, Block B, Sushant Lok Phase I, Gurugram</p>
      </div>

      <div className="my-4">
        <p className="text-2xl font-poppins">Phone:</p>
        <a href="tel:9871802395" className="text-blue-600 hover:underline">+91 98718 02395</a>
      </div>

            <div className="my-4">
        <p className="text-2xl font-poppins">Email us at:</p>
        <a href="mailto:contact@interviewvision.com " className="text-blue-600 hover:underline">contact@interviewvision.com</a>
      </div>

    </div>
  )
}

export default Contact
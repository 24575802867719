import React, {useEffect, useState} from 'react'
import interviewguru_logo from '../images/interview_guru_logo.png'
import block from '../images/84.png'
import document from '../images/Document.png'
import { useRecordWebcam } from 'react-record-webcam'
import dashboard from '../images/22.png'
import history from '../images/history.png'
import gear from '../images/gear.png'
import Taketest from '../components/Taketest'
import darkcategory from '../images/Category.png'
import Activity from '../images/Activity.svg'
import darkdocument from '../images/Document.svg'
import gear_white from '../images/gear_white.png'
import {Link, useNavigate, useLocation} from 'react-router-dom'
import RepTakeTest from '../components/RepTakeTest'
import {HiOutlineMenuAlt2} from 'react-icons/hi'
import {GrClose} from 'react-icons/gr'
import Resources from '../components/Resources'
import Dashboard from '../components/Dashboard'
import data from '../config'

import Settings from '../components/Settings'
import coin_white from '../images/coin.png'
import coin_black from '../images/coin (3).png'
import Courses from '../pages/Courses'
import resume_black from '../images/resume (2).png'
import resume_white from '../images/resume.png'
import axios from 'axios'
import Resume from '../pages/Resume'
import InternalPricing from '../components/InternalPricing'
import ReactGA from 'react-ga'
import Inhouse from './Inhouse'
import Webinars from '../components/Webinars'
import Coaches from './Coaches'
import Coach_details from './Coach_details'
import Bookings from './Bookings'
const Home = () => {

    
    const recordWebcam = useRecordWebcam({ frameRate: 60 , height:500, width:480});
    const [open, setOpen] = useState(false)
    const [fname, setFname] = useState('')
    const [loader,setLoader] = useState(false)
    const [im1,setIm1] = useState(block)
    const [im2,setIm2] = useState(document)
    const [im3,setIm3] = useState(dashboard)
    const [im4,setIm4] = useState(gear)
    const [im5, setIm5] = useState(resume_black)
    const [im6, setIm6] = useState(coin_black)
    const [refresh, setRefresh] = useState(true)
    const [pack, setPack] = useState('')
    const location  = useLocation()
    // console.log(name,pass)
    const navigate = useNavigate()

    

    useEffect(()=>{
        ReactGA.pageview(window.location.pathname + window.location.search)
        recordWebcam.close()
      },[])
      
  
    useEffect(()=>{
        const email = localStorage.getItem('email')
        axios.get(`https://${data.API}/users/r/${email}`)
        .then((response)=>{
            setFname(response.data.fname)
            setPack(response.data.subscribedpack)
            // setLoader(false)
        })
        .catch((error)=>{
            alert('Something went wrong! Please try again')
            console.log(error)
        })
    },[])

    useEffect(()=>{
        if(pack.length==0){
            setPack("FREE")
        }
        if(pack.length>0){
            setLoader(false)
        }
    },[pack])

    useEffect(()=>{
        if(location.pathname == "/home"){
            setIm1(block)
            setIm2(document)
            setIm3(dashboard)
            setIm4(gear)
            setIm5(resume_black)
            setIm6(coin_black)
        }
        else if((location.pathname=="/courses") || (location.pathname=="/resources")){
            setIm1(darkcategory)
            setIm2(darkdocument)
            setIm3(dashboard)
            setIm4(gear)
            setIm5(resume_black)
            setIm6(coin_black)
        }
        else if(location.pathname=="/dashboard"){
            setIm1(darkcategory)
            setIm2(document)
            setIm3(Activity)
            setIm4(gear)
            setIm5(resume_black)
            setIm6(coin_black)
        }
        else if(location.pathname=="/settings"){
            setIm1(darkcategory)
            setIm2(document)
            setIm3(dashboard)
        }
        else if(location.pathname=="/resume"){
            setIm1(darkcategory)
            setIm2(document)
            setIm3(dashboard)
            setIm5(resume_white)
            setIm6(coin_black)
        }
        else if(location.pathname=="/pricing"){
            setIm1(darkcategory)
            setIm2(document)
            setIm3(dashboard)
            setIm5(resume_black)
            setIm6(coin_white)
        }
    },[location.pathname])

    const signout = ()=>{
        localStorage.clear()
        navigate('/')
    }
  return (
    <div>
        {
            !loader &&
    <div className="bg-gray-100  min-h-screen">
        <div className="">
            {
                open &&
                <div className="absolute z-50 h-screen flex flex-col  bg-white shadow-2xl w-2/3">
                    <Link to="/home" > 
                    <img src={interviewguru_logo} className="p-5"></img>
                    </Link>
                <div className="h-[0.1rem] bg-[#D9D9D9]"></div>
                <div className="mx-4">
                <Link to="/home" >
                <div className={location.pathname == "/home"?"flex bg-[#E94B60] items-center text-white my-3 py-3 px-3 rounded-xl space-x-3 font-poppins":"flex bg-transparent items-center text-[#888888] my-3 py-3 px-3 rounded-xl space-x-3 font-poppins"}>
                    <img src={im1} className="h-7 "></img>
                    <h1>Take the Test</h1>
                </div>
                    </Link>
                    <Link to="/courses" >
                <div className={(location.pathname.includes("/courses") )?"flex bg-[#E94B60] items-center text-white my-3 py-3 px-3 rounded-xl space-x-3 font-poppins":"flex bg-transparent items-center text-[#888888] my-3 py-3 px-3 rounded-xl space-x-3 font-poppins"}>
                    <img src={im2} className="h-7 "></img>
                    <h1 className="">Courses</h1>
                </div>
                    </Link>
                    <Link to='/dashboard' >
                <div className={location.pathname == "/dashboard"?"flex bg-[#E94B60] items-center text-white my-3 py-3 px-3 rounded-xl space-x-3 font-poppins":"flex bg-transparent items-center text-[#888888] my-3 py-3 px-3 rounded-xl space-x-3 font-poppins"}>
                    <img src={im3} className="h-8 -ml-1 "></img>
                    <h1 className="">Dashboard</h1>
                </div>
                    </Link>
                
                <Link to='/resume' >
                <div className={location.pathname == "/resume"?"flex bg-[#E94B60] items-center text-white my-3 py-3 px-3 rounded-xl space-x-3 font-poppins":"flex bg-transparent items-center text-[#888888] my-3 py-3 px-3 rounded-xl space-x-3 font-poppins"}>
                    <img src={im5} className="h-8 "></img>
                    <h1 className="">Resume Builder</h1>
                </div>
                </Link>
                <Link to='/pricing' >
                <div className={location.pathname == "/pricing"?"flex bg-[#E94B60] items-center text-white my-3 py-3 px-3 rounded-xl space-x-3 font-poppins":"flex bg-transparent items-center text-[#888888] my-3 py-3 px-3 rounded-xl space-x-3 font-poppins"}>
                    <img src={im6} className="h-8 "></img>
                    <h1 className="">Pricing</h1>
                </div>
                </Link>
                <button className="w-full" onClick={()=>{setOpen(false)}}>    
                <div>
                    <GrClose className="text-xl text-center my-3 mx-auto"></GrClose>
                </div>
                </button>
                </div>
                </div>
            }
        </div>
        <div className=" flex h-full">
        <div className="">
            <div className=" lg:block sticky top-0 hidden h-screen pt-4 bg-white px-4 w-[20rem]">
            <Link to="/home"> 
                <img src={interviewguru_logo} className="h-24 mt-3 px-8  mx-auto "></img>
                </Link>
                <div className={"h-[0.1rem] bg-white"}></div>
                <div className="mx-4">
                    <Link to="/home" >
                <div className={location.pathname == "/home"?"flex bg-[#E94B60] items-center text-white my-3 py-3 px-3 rounded-xl space-x-3 font-poppins":"flex bg-transparent items-center text-[#888888] my-3 py-3 px-3 rounded-xl space-x-3 font-poppins"}>
                    <img src={im1} className="h-7 "></img>
                    <h1>Take the Test</h1>
                </div>
                    </Link>
                    <Link to="/courses" >
                <div className={(location.pathname.includes("/courses"))?"flex bg-[#E94B60] items-center text-white my-3 py-3 px-3 rounded-xl space-x-3 font-poppins":"flex bg-transparent items-center text-[#888888] my-3 py-3 px-3 rounded-xl space-x-3 font-poppins"}>
                    <img src={im2} className="h-7 "></img>
                    <h1 className="">Courses</h1>
                </div>
                    </Link>
                    <Link to='/dashboard' >
                <div className={location.pathname == "/dashboard"?"flex bg-[#E94B60] items-center text-white my-3 py-3 px-3 rounded-xl space-x-3 font-poppins":"flex bg-transparent items-center text-[#888888] my-3 py-3 px-3 rounded-xl space-x-3 font-poppins"}>
                    <img src={im3} className="h-8 -ml-1 "></img>
                    <h1 className="">Dashboard</h1>
                </div>
                    </Link>
                
                <Link to='/resume' >
                <div className={location.pathname == "/resume"?"flex bg-[#E94B60] items-center text-white my-3 py-3 px-3 rounded-xl space-x-3 font-poppins":"flex bg-transparent items-center text-[#888888] my-3 py-3 px-3 rounded-xl space-x-3 font-poppins"}>
                    <img src={im5} className="h-8 "></img>
                    <h1 className="">Resume Builder</h1>
                </div>
                </Link>
                <Link to='/pricing' >
                <div className={location.pathname == "/pricing"?"flex bg-[#E94B60] items-center text-white my-3 py-3 px-3 rounded-xl space-x-3 font-poppins":"flex bg-transparent items-center text-[#888888] my-3 py-3 px-3 rounded-xl space-x-3 font-poppins"}>
                    <img src={im6} className="h-8 "></img>
                    <h1 className="">Pricing</h1>
                </div>
                </Link>
                </div>
            </div>
            </div>
            <div className=" ml-3 py-5 w-screen ">
            <div className="flex justify-between items-center  mr-8">
            <div className="flex items-center justify-start space-x-3 mr-8">
                    <button onClick={()=>{setOpen(true)}}>
                    <HiOutlineMenuAlt2 className="lg:hidden block text-xl"></HiOutlineMenuAlt2>
                    </button>
               
                <h1 className="font-poppins text-xl">Hello {fname.split(" ")[0]}</h1>
                <div className="bg-yellow-400 font-poppins font-bold text-sm rounded-xl px-3 py-2">
                    {pack.toUpperCase()}
                </div>
            </div>
            <div className="flex items-center space-x-3">
            <Link to='/settings' >
            <img src={im4} className="h-6 hover:rotate-[100deg] transition-all"></img>    
            </Link>    
            <button onClick={signout}>Sign Out</button>
            </div>
            </div>    
            {
                location.pathname == '/home' &&
                <Taketest></Taketest>
            }
            {
                location.pathname == '/courses' &&
                <Courses></Courses>
            }
            {
                location.pathname == '/courses/resources' &&
                <Resources></Resources>
            }
            {
                location.pathname=="/courses/webinars" &&
                <Webinars/>
            }
            {
                location.pathname=="/courses/learn" &&
                <Inhouse></Inhouse>
            }
            {
                location.pathname == "/dashboard" &&
                <Dashboard/>
            }
            {
                location.pathname === "/settings" &&
                <Settings></Settings>
            }
            {
                location.pathname === "/resume" &&
                <Resume></Resume>
            }
            {

                location.pathname==="/courses/coaches" &&
                <Coaches/>
            }
            {
                location.pathname==="/courses/coaches/info" &&
                <Coach_details/>
            }
            {
                location.pathname==="/courses/coaches/bookings" &&
                <Bookings></Bookings>
            }
            {
                location.pathname=="/pricing" &&
                <InternalPricing/>
            }
            </div>
        </div>
    </div>
        }
    </div>
  )
}

export default Home
import { useRecordWebcam } from 'react-record-webcam'
import axios from 'axios'
import React, {useState, useEffect} from 'react'
import logo from '../images/logo_white.png'
import testpic1 from '../images/test_pic1.png'
import testpic2 from '../images/test_pic2.png'
import clip from '../images/Clip.png'
import { Link , useLocation} from 'react-router-dom'
import {InfinitySpin} from 'react-loader-spinner'
import {useNavigate} from 'react-router-dom'
import uuid from 'react-uuid'
function Video() {
  const recordWebcam = useRecordWebcam({ frameRate: 60 , height:300, width:480});
  const [count, setCount] = useState(0);
  const [start, setStart] = useState(false)
  const [choice, setChoice] = useState(0)
  const [choice2, setChoice2] = useState(0)
  const [choice3, setChoice3] = useState(0)
  const [comm, setComm] = useState(0)
  const [crit, setCrit] = useState(0)
  const [leader, setLeader] = useState(0)
  const [team, setTeam] = useState(0)
  const [intro, setIntro] = useState(0)
  const [grow, setGrow] = useState(0)
  const [status, setStatus] = useState(true)
  const [ques, setQues] = useState(0)
  const [mcqs, setMcqs] = useState([])
  const email = localStorage.getItem('email')
  const [loader, setLoader] = useState(false)
  const [i, setI] = useState(0)
  const location  = useLocation()
  const [mcom, setMcom] = useState(0)
  const [mls, setMls] = useState(0)
  const [mtw, setMtw] = useState(0)
  const [mgm, setMgm] = useState(0)
  const [mct, setMct] = useState(0)
  const [marksCom, setMarksCom] = useState([])
  const [marksLs, setMarksLs] = useState([])
  const [marksTw, setMarksTw] = useState([])
  const [marksGm, setMarksGm] = useState([])
  const [marksCt, setMarksCt] = useState([])
  const [marksIn, setMarksIn] = useState([])
  const [uid, setUid] = useState('')
  const navigate = useNavigate()
  const user  = localStorage.getItem('email')
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  useEffect(()=>{
    recordWebcam.open();
    if(recordWebcam.status == "ERROR")
    {
      alert('Please try again later')
      navigate('/')
    }
  },[ques])

  // console.log(recordWebcam.status)

  useEffect(()=>{
    // loader = true
    recordWebcam.open();
    setUid(uuid())
    axios.get('https://interviewvision.com/interview/mcqs')
    .then((response)=>{
      console.log(response)
      setMcqs(response.data.result)
      console.log(response.data.result[0].score[0])
      // console.log(mcqs)
      // loader = false
    })
    .catch((error)=>{
      console.log(error)
    })
  },[])

  useEffect(()=>{
    console.log(mcqs)
  },[mcqs])


  useEffect(()=>{
    if(i==10){
      setQues(4)
    }
  },[i])

  const convert = (ans)=>{
    if(ans == 'Bad'){
      return 1;
    }
    else if(ans == "Below Average"){
      return 2;
    }
    else if(ans == "Average"){
      return 3;
    }
    else if(ans == "Above Average"){
      return 4;
    }
    else if(ans == "Good"){
      return 5;
    }
  }

  const save = (cat, ans)=>{
    if(cat == "ct"){
      setCrit(crit+convert(ans));
    }
    if(cat == "l"){
      setLeader(leader+convert(ans));
    }
    if(cat=="t"){
      setTeam(team+convert(ans));
    }
    if(cat=="i"){
      setIntro(intro+convert(ans));
    }
    if(cat == "g"){
      setGrow(grow+convert(ans));
    }
    if(cat == "c"){
      setComm(comm+(convert(ans)))
    }
  }

  const stop = ()=>{
    recordWebcam.stop()
    setStatus(false)
  }

  const retake = ()=>{
    recordWebcam.retake()
    setStatus(true)
  }

  // useEffect(()=>{
  //   forceUpdate()
  // },[crit, team,leader,grow, comm, intro])

  console.log(crit, team,leader,grow, comm, intro)

  const upload =  (data, cat, q)=>{
    // console.log('res sent')
     axios.post('https://interviewvision.com/interview/video?flag=true',data,
     {
      headers:{
        "Content-Type":"multipart/form-data"
      }
    })
    .then((response) =>{
      console.log(response);
      console.log(response.data.result.rank);
      // setRank(response.data.result.rank)
      save(cat,response.data.result.rank)
      setQues(q)
      setLoader(false)
    })
    .catch((error) =>{console.log(error)})
  }
  
  const saveFile1 = async () => {
    const blob = await recordWebcam.getRecording();
    const data = new FormData()
    // forceUpdate()
    data.append('file',blob)
    data.append('questionId',1)
    data.append('username',email)
    data.append('interviewid', uid)
    upload(data, 'i',5)
    console.log(intro)
    recordWebcam.open()
    console.log(loader)
    // loader = false
  };

  const saveFile2 = async () => {
    // loader = true
    const blob = await recordWebcam.getRecording();
    const data = new FormData()
    // forceUpdate()
    data.append('file',blob)
    data.append('questionId',2)
    data.append('username',email)
    data.append('interviewid', uid)
    upload(data, 'i',6)
    recordWebcam.open()
    console.log(blob)
    // loader = false
  };

  const saveFile3 = async () => {
    // loader = true
    const blob = await recordWebcam.getRecording();
    const data = new FormData()
    // forceUpdate()
    data.append('file',blob)
    data.append('questionId',3)
    data.append('username',email)
    data.append('interviewid', uid)
    upload(data,'i',7)
    recordWebcam.open()
    console.log(blob)
    // loader = false
  };

  const saveFile4 = async () => {
    // loader = true
    const blob = await recordWebcam.getRecording();
    const data = new FormData()
    // forceUpdate()
    data.append('file',blob)
    data.append('questionId',4)
    data.append('username',email)
    data.append('interviewid', uid)
    upload(data, 'c',8)
    recordWebcam.open()
    console.log(blob)
    // loader = false
  };
  const saveFile5 = async () => {
    // loader = true
    console.log('start')
    const blob = await recordWebcam.getRecording();
    const data = new FormData()
    // forceUpdate()
    data.append('file',blob)
    data.append('questionId',5)
    data.append('username',email)
    data.append('interviewid', uid)
    upload(data, 'l',9)
    recordWebcam.open()
    console.log(blob)
    console.log('stop')
    // loader = false
  };
  const saveFile6 = async () => {
    // loader = true
    const blob = await recordWebcam.getRecording();
    const data = new FormData()
    // forceUpdate()
    data.append('file',blob)
    data.append('questionId',6)
    data.append('username',email)
    data.append('interviewid', uid)
    upload(data,'t',10)
    recordWebcam.open()
    console.log(blob)
    // loader = false
  };
  
  const saveFile8 = async () => {
    // loader = true
    const blob = await recordWebcam.getRecording();
    const data = new FormData()
    // forceUpdate()
    data.append('file',blob)
    data.append('questionId',7)
    data.append('username',email)
    data.append('interviewid', uid)
    upload(data,'g',11)
    recordWebcam.open()
    console.log(blob)
    // loader = false
  };
  
  const saveFile9 = async () => {
    // loader = true
    const blob = await recordWebcam.getRecording();
    const data = new FormData()
    // forceUpdate()
    data.append('file',blob)
    data.append('questionId',8)
    data.append('username',email)
    data.append('interviewid', uid)
    axios.post('https://interviewvision.com/interview/video?flag=true',data,
     {
      headers:{
        "Content-Type":"multipart/form-data"
      }
    })
    .then((response) =>{
      console.log(response);
      console.log(response.data.result.rank);
      // setRank(response.data.result.rank)
      save('ct',response.data.result.rank)
      setLoader(false)
      setQues(12)
    })
    .catch((error) =>{console.log(error)})
    recordWebcam.close()
    // recordWebcam.open()
  };
  
  if(loader == false) {
    
  }
  console.log()

  
  const saveMCQ = ()=>{
    if(choice == 0){
      alert('please enter a choice')
    }
    else{
    if(i<9){
      if(mcqs[i].area =="c"){
        if(choice == 1){
          setMcom(mcom+parseInt(mcqs[i].score[0]))
        }
        else if(choice == 2){
          setMcom(mcom+parseInt(mcqs[i].score[2]))
        }
        else if(choice == 3){
          setMcom(mcom+parseInt(mcqs[i].score[4]))
        }
        else if(choice == 4){
          setMcom(mcom+parseInt(mcqs[i].score[6]))
        }
      }
      else if(mcqs[i].area =="ls"){
        if(choice == 1){
          setMls(mls+parseInt(mcqs[i].score[0]))
        }
        else if(choice == 2){
          setMls(mls+parseInt(mcqs[i].score[2]))
        }
        else if(choice == 3){
          setMls(mls+parseInt(mcqs[i].score[4]))
        }
        else if(choice == 4){
          setMls(mls+parseInt(mcqs[i].score[6]))
        }
      }
      else if(mcqs[i].area =="tw"){
        if(choice == 1){
          setMtw(mtw+parseInt(mcqs[i].score[0]))
        }
        else if(choice == 2){
          setMtw(mtw+parseInt(mcqs[i].score[2]))
        }
        else if(choice == 3){
          setMtw(mtw+parseInt(mcqs[i].score[4]))
        }
        else if(choice == 4){
          setMtw(mtw+parseInt(mcqs[i].score[6]))
        }
      }
      else if(mcqs[i].area =="gm"){
        if(choice == 1){
          setMgm(mgm+parseInt(mcqs[i].score[0]))
        }
        else if(choice == 2){
          setMgm(mgm+parseInt(mcqs[i].score[2]))
        }
        else if(choice == 3){
          setMgm(mgm+parseInt(mcqs[i].score[4]))
        }
        else if(choice == 4){
          setMgm(mgm+parseInt(mcqs[i].score[6]))
        }
      }
      if(mcqs[i].area =="ct"){
        if(choice == 1){
          setMct(mct+parseInt(mcqs[i].score[0]))
        }
        else if(choice == 2){
          setMct(mct+parseInt(mcqs[i].score[2]))
        }
        else if(choice == 3){
          setMct(mct+parseInt(mcqs[i].score[4]))
        }
        else if(choice == 4){
          setMct(mct+parseInt(mcqs[i].score[6]))
        }
      }
      forceUpdate()
      setI(i+1)
      setChoice(0)
      
    }
    else{
      console.log(mct, mgm, mls, mtw, mcom, )
      setQues(14)
    }
  }
  }

  const nav = ()=>{
      marksCom.push(mcom)
      marksCom.push(comm)
      marksLs.push(mls)
      marksLs.push(leader)
      marksTw.push(mtw)
      marksTw.push(team)
      marksGm.push(mgm)
      marksGm.push(grow)
      marksCt.push(mct)
      marksCt.push(crit)
      marksIn.push(0)
      marksIn.push(intro)
      console.log(marksTw, marksIn, marksLs, marksGm, marksCt, marksCom)
      axios.post(`https://interviewvision.com/interview/marks?username=${user}`,{
        confidence:marksIn,
        critical:marksCt,
        leader:marksLs,
        growth:marksGm,
        team:marksTw,
        comm:marksCom
      })
      .then((response) => {
        console.log(response)
        navigate('/dashboard')
      })
      .catch((error) => {
        console.log(error)
      })
  }
  

  if(mcqs.length === 0 ){
    return(
      <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
             <InfinitySpin
             width='200'
             color='#4899B7'
             ></InfinitySpin>
      </div>  
    )
  }
  else return (
    <div>

    
    {
      loader?
      <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
             <InfinitySpin
             width='200'
             color='#4899B7'
             ></InfinitySpin>
      </div>  
      :
    <div className="bg-cover min-h-screen" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/Bg.png'})`}}>
      <img src={logo} className="h-28 p-4 "></img>
      {
        ques == 0 &&
        <div>
          <div className="lg:w-3/6  mx-auto font-poppins">
            <p className=" mx-3">Please read the following instructions carefully before starting with your test interview:</p>
            <div className="lg:grid lg:grid-cols-2  mx-4 space-y-4 lg:space-y-0 py-4 gap-x-6">
              <div className="bg-white rounded-xl py-3 px-3">
                <img src={testpic1} className="h-48 mx-auto my-4"></img>
                <p className="font-bold my-4">Instructions for Recording:</p>
                <ul className="list-disc ml-6">
                  <li>Please use a phone/computer with a camera and a microphone</li>
                  <li>Ensure to give the necessary permissions to allow recording if prompted</li>
                  <li>Please make sure there is no background noise</li>
                  <li>Try to sit in a place with sufficient light</li>
                  <li>It is better to use a headphone for clear voice</li>
                  <li>Try to speak slowly and clearly for audio clarity</li>
                </ul>
              </div>
              <div className="bg-white rounded-xl py-3 px-3">
                <img src={testpic2} className="h-48 mx-auto my-4 "></img>
                <p className="font-bold my-4">How you will be tested?</p>
                <ul className="list-disc ml-6">
                  <li>This test has two parts. A multiple choice questions (MCQ) section followed by a Video Interview section</li>
                  <li>Choose the answer for each MCQ question and click on submit</li>
                  <li>Once you have completed the MCQ section, the video interview will start</li>
                  <li>Once the video interview is over you can go to the dashboard to check your report</li>
                </ul>
              </div>
            </div>
            <div className="flex justify-between items-center pb-5 mx-3">
              <Link to='/home'>
              <button className="lg:py-4 py-2 px-6 lg:px-12 text-slate-600 bg-[#FFFFFF] rounded-lg ">Back</button>
              </Link>
              <button className="lg:py-4 py-2 px-6 lg:px-12 text-slate-200 bg-gradient-to-r from-[#E94B60] to-[#F0939F] rounded-lg " onClick={()=>{setQues(1)}}>Lets Go</button>
            </div>
          </div>
        </div>
      }
      {
        ques == 1 &&
        <div className="relative" >
          <div className="lg:w-2/3 mx-3 lg:mx-auto">
            <div className=" py-3 lg:py-8 px-4 lg:px-10 rounded-lg bg-cover" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/Rectangle5.png'})`}}>
              <p className="text-[#888888] my-1">Question</p>
              <div className="flex bg-[#39435A] items-start space-x-3 px-3 py-3 rounded-xl">
                <p className="bg-white rounded-full p-2 px-4 font-bold font-poppins">{i+1}</p>
                <p className="text-white font-poppins">{mcqs[i].statement}</p>
              </div>
              <div className="flex-col my-4 flex text-lg font-poppins lg:w-2/3 space-y-4">
              <button onClick={()=>{setChoice(1)}} className={choice==1?"bg-blue-500 py-4 rounded-2xl px-5 flex  space-x-3 items-center text-white":"bg-[#F3F3F3] w-full  py-4 rounded-2xl px-5 flex space-x-3 items-center" }>
                  <div className="border-4 p-3 border-white rounded-full "></div>
                  <h1>Always</h1>
              </button>
              <button onClick={()=>{setChoice(2)}} className={choice==2?"bg-blue-500 py-4 rounded-2xl px-5 flex  space-x-3 items-center text-white":"bg-[#F3F3F3]  py-4 rounded-2xl px-5 flex  space-x-3 items-center" }>
              <div className="border-4 p-3 border-white rounded-full "></div>
                  <h1>Rarely</h1>
              </button>
              <button onClick={()=>{setChoice(3)}} className={choice==3?"bg-blue-500 py-4 rounded-2xl px-5 flex  space-x-3 items-center text-white":"bg-[#F3F3F3]  py-4 rounded-2xl px-5 flex  space-x-3 items-center" }>
              <div className="border-4 p-3 border-white rounded-full "></div>
                  <h1>Sometimes</h1>
              </button>
              <button onClick={()=>{setChoice(4)}} className={choice==4?"bg-blue-500 py-4 rounded-2xl px-5 flex  space-x-3 items-center text-white":"bg-[#F3F3F3]  py-4 rounded-2xl px-5 flex  space-x-3 items-center" }>
              <div className="border-4 p-3 border-white rounded-full "></div>
                  <h1 className="text-left">Usually</h1>
              </button>
              
          </div>
            </div>
            <div className="flex justify-between items-center py-3 pb-5">
                <button className="lg:py-4 py-2 px-6 lg:px-12 text-slate-600 bg-[#FFFFFF] rounded-lg " onClick={()=>{setQues(ques-1)}}>Back</button>
                <button className="lg:py-4 py-2 px-6 lg:px-12 text-slate-200 bg-gradient-to-r from-[#E94B60] to-[#F0939F] rounded-lg " onClick={saveMCQ}>Next</button>
              </div>
          </div>
        </div>

      }
      
      {
        ques == 4 &&
        <div className="lg:w-2/3 mx-3 lg:mx-auto bg-cover rounded-xl py-4 px-3" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/Rectangle5.png'})`}}>
        <div className="flex bg-[#39435A] items-center space-x-3 px-3 py-3 rounded-xl">
              <p className="bg-white rounded-full p-2 px-4 font-bold font-poppins">1</p>
              <p className="text-white font-poppins">Tell me a bit about yourself.</p>
            </div>
      <div className="mx-auto text-center py-3 ">
        <div className="relative lg:w-1/2  mx-auto">
        <video playsInline ref={recordWebcam.previewRef} className={recordWebcam.status !="PREVIEW" ? "mx-auto my-4 rounded-xl hidden":"mx-auto my-4 rounded-xl"} autoPlay muted={status}  loop />
          {
            recordWebcam.status !=="PREVIEW" &&
            <video playsInline ref={recordWebcam.webcamRef} className="mx-auto my-4 rounded-xl " autoPlay muted />
          }
          {
            recordWebcam.status == "RECORDING" &&
            <p className="absolute top-0 text-white w-full mx-autp left-0 right-0">Recording</p>
          }
        </div>
      <div className="space-x-3">
        {
          recordWebcam.status =="OPEN" &&
          <button onClick={recordWebcam.start} className="bg-green-500 px-6 py-3 rounded-xl text-white">Start recording</button>
        }
      {
        recordWebcam.status =="RECORDING" &&
        <button onClick={stop} className="bg-red-600 px-6 py-3 rounded-xl text-white">Stop recording</button>
      }
      {
        recordWebcam.status == "PREVIEW" &&
        <div className="lg:space-x-3 flex flex-col space-y-3 lg:block lg:space-y-0">
          <button onClick={retake} className="bg-blue-600 px-6 py-3 rounded-xl text-white">Retake recording</button>
          <button onClick={recordWebcam.download} className="bg-blue-600 px-6 py-3 rounded-xl text-white">Download recording</button>
          <button onClick={()=>{
            setLoader(true);
            saveFile1();
          }} className="bg-blue-600 px-6 py-3 rounded-xl text-white">Submit</button>
        </div>
      }
      <button className="bg-transparent px-6 py-3 rounded-xl text-white" onClick={recordWebcam.open}>If you are unable to see your video, click here</button>
      </div>
      </div>
      {
            // recordWebcam.status == "PREVIEW" &&
            
      }
      </div>
      }
      {
        ques == 5 &&
        <div className="lg:w-2/3 mx-3 lg:mx-auto bg-cover  rounded-xl py-4 px-3" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/Rectangle5.png'})`}}> 
        <div className="flex bg-[#39435A] items-center space-x-3 px-3 py-3 rounded-xl">
              <p className="bg-white rounded-full p-2 px-4 font-bold font-poppins">2</p>
              <p className="text-white font-poppins">What are your strenghts?</p>
            </div>
      <div className="mx-auto text-center py-3 ">
        <div className="relative lg:w-1/2 mx-auto">
        <video playsInline ref={recordWebcam.previewRef} className={recordWebcam.status !="PREVIEW" ? "mx-auto my-4 rounded-xl hidden":"mx-auto my-4 rounded-xl"} autoPlay muted={status}  loop />
          {
            recordWebcam.status !=="PREVIEW" &&
            <video playsInline ref={recordWebcam.webcamRef} className="mx-auto my-4 rounded-xl " autoPlay muted />
          }
          {
            recordWebcam.status == "RECORDING" &&
            <p className="absolute top-0 text-white w-full mx-autp left-0 right-0">Recording</p>
          }
        </div>
      <div className="space-x-3">
        {
          recordWebcam.status =="OPEN" &&
        <button onClick={recordWebcam.start} className="bg-green-500 px-6 py-3 rounded-xl text-white">Start recording</button>
        }
      {
        recordWebcam.status =="RECORDING" &&
        <button onClick={stop} className="bg-red-600 px-6 py-3 rounded-xl text-white">Stop recording</button>
      }
      {
        recordWebcam.status == "PREVIEW" &&
        <div className="lg:space-x-3 flex flex-col space-y-3 lg:block lg:space-y-0">
          <button onClick={retake} className="bg-blue-600 px-6 py-3 rounded-xl text-white">Retake recording</button>
          <button onClick={recordWebcam.download} className="bg-blue-600 px-6 py-3 rounded-xl text-white">Download recording</button>
          <button onClick={()=>{
            setLoader(true)
            saveFile2()
          }} className="bg-blue-600 px-6 py-3 rounded-xl text-white">Submit</button>
        </div>
      }
      <button className="bg-transparent px-6 py-3 rounded-xl text-white" onClick={recordWebcam.open}>If you are unable to see your video, click here</button>
      </div>
      </div>
      {
            // recordWebcam.status == "PREVIEW" &&
            
      }
      </div>
      }
      {
        ques == 6 &&
        <div className="lg:w-2/3 mx-3 lg:mx-auto bg-cover rounded-xl py-4 px-3" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/Rectangle5.png'})`}}>
        <div className="flex bg-[#39435A] items-center space-x-3 px-3 py-3 rounded-xl">
              <p className="bg-white rounded-full p-2 px-4 font-bold font-poppins">3</p>
              <p className="text-white font-poppins">What are your weaknesses?</p>
            </div>
      <div className="mx-auto text-center py-3 ">
        <div className="relative lg:w-1/2 mx-auto">
        <video playsInline ref={recordWebcam.previewRef} className={recordWebcam.status !="PREVIEW" ? "mx-auto my-4 rounded-xl hidden":"mx-auto my-4 rounded-xl"} autoPlay muted={status}  loop />
          {
            recordWebcam.status !=="PREVIEW" &&
            <video playsInline ref={recordWebcam.webcamRef} className="mx-auto my-4 rounded-xl " autoPlay muted />
          }
          {
            recordWebcam.status == "RECORDING" &&
            <p className="absolute top-0 text-white w-full mx-autp left-0 right-0">Recording</p>
          }
        </div>
      <div className="space-x-3">
        {
          recordWebcam.status =="OPEN" &&
        <button onClick={recordWebcam.start} className="bg-green-500 px-6 py-3 rounded-xl text-white">Start recording</button>
        }
      {
        recordWebcam.status =="RECORDING" &&
        <button onClick={stop} className="bg-red-600 px-6 py-3 rounded-xl text-white">Stop recording</button>
      }
      {
        recordWebcam.status == "PREVIEW" &&
        <div className="lg:space-x-3 flex flex-col space-y-3 lg:block lg:space-y-0">
          <button onClick={retake} className="bg-blue-600 px-6 py-3 rounded-xl text-white">Retake recording</button>
          <button onClick={recordWebcam.download} className="bg-blue-600 px-6 py-3 rounded-xl text-white">Download recording</button>
          <button onClick={()=>{
            setLoader(true)
            saveFile3()
          }} className="bg-blue-600 px-6 py-3 rounded-xl text-white">Submit</button>
        </div>
      }
      <button className="bg-transparent px-6 py-3 rounded-xl text-white" onClick={recordWebcam.open}>If you are unable to see your video, click here</button>
      </div>
      </div>
      {
            // recordWebcam.status == "PREVIEW" &&
            
      }
      </div>
      }
      {
        ques == 7 &&
        <div className="lg:w-2/3 mx-3 lg:mx-auto bg-cover rounded-xl py-4 px-3" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/Rectangle5.png'})`}}>
        <div className="flex bg-[#39435A] items-center space-x-3 px-3 py-3 rounded-xl">
              <p className="bg-white rounded-full p-2 px-4 font-bold font-poppins">4</p>
              <p className="text-white font-poppins">You are working in a new team.The new team is unable to understand what you want to convey. They say they have trouble in understanding you. How will you manage this situation?</p>
            </div>
      <div className="mx-auto text-center py-3 ">
        <div className="relative lg:w-1/2 mx-auto">
        <video playsInline ref={recordWebcam.previewRef} className={recordWebcam.status !="PREVIEW" ? "mx-auto my-4 rounded-xl hidden":"mx-auto my-4 rounded-xl"} autoPlay muted={status}  loop />
          {
            recordWebcam.status !=="PREVIEW" &&
            <video playsInline ref={recordWebcam.webcamRef} className="mx-auto my-4 rounded-xl " autoPlay muted />
          }
          {
            recordWebcam.status == "RECORDING" &&
            <p className="absolute top-0 text-white w-full mx-autp left-0 right-0">Recording</p>
          }
        </div>
      <div className="space-x-3">
        {
          recordWebcam.status =="OPEN" &&
        <button onClick={recordWebcam.start} className="bg-green-500 px-6 py-3 rounded-xl text-white">Start recording</button>
        }
      {
        recordWebcam.status =="RECORDING" &&
        <button onClick={stop} className="bg-red-600 px-6 py-3 rounded-xl text-white">Stop recording</button>
      }
      {
        recordWebcam.status == "PREVIEW" &&
        <div className="lg:space-x-3 flex flex-col space-y-3 lg:block lg:space-y-0">
          <button onClick={retake} className="bg-blue-600 px-6 py-3 rounded-xl text-white">Retake recording</button>
          <button onClick={recordWebcam.download} className="bg-blue-600 px-6 py-3 rounded-xl text-white">Download recording</button>
          <button onClick={()=>{
            setLoader(true)
            saveFile4()
          }} className="bg-blue-600 px-6 py-3 rounded-xl text-white">Submit</button>
        </div>
      }
      <button className="bg-transparent px-6 py-3 rounded-xl text-white" onClick={recordWebcam.open}>If you are unable to see your video, click here</button>
      </div>
      </div>
      </div>
      }
      {
        ques == 8 &&
        <div className="lg:w-2/3 mx-3 lg:mx-auto bg-cover rounded-xl py-4 px-3" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/Rectangle5.png'})`}}>
        <div className="flex bg-[#39435A] items-center space-x-3 px-3 py-3 rounded-xl">
              <p className="bg-white rounded-full p-2 px-4 font-bold font-poppins">5</p>
              <p className="text-white font-poppins">Tell me about a time when you made a mistake because you didn’t listen carefully to instructions. How did you fix the situation?</p>
            </div>
      <div className="mx-auto text-center py-3 ">
        <div className="relative lg:w-1/2 mx-auto">
        <video playsInline ref={recordWebcam.previewRef} className={recordWebcam.status !="PREVIEW" ? "mx-auto my-4 rounded-xl hidden":"mx-auto my-4 rounded-xl"} autoPlay muted={status}  loop />
          {
            recordWebcam.status !=="PREVIEW" &&
            <video playsInline ref={recordWebcam.webcamRef} className="mx-auto my-4 rounded-xl " autoPlay muted />
          }
          {
            recordWebcam.status == "RECORDING" &&
            <p className="absolute top-0 text-white w-full mx-autp left-0 right-0">Recording</p>
          }
        </div>
      <div className="space-x-3">
        {
          recordWebcam.status =="OPEN" &&
        <button onClick={recordWebcam.start} className="bg-green-500 px-6 py-3 rounded-xl text-white">Start recording</button>
        }
      {
        recordWebcam.status =="RECORDING" &&
        <button onClick={stop} className="bg-red-600 px-6 py-3 rounded-xl text-white">Stop recording</button>
      }
      {
        recordWebcam.status == "PREVIEW" &&
        <div className="lg:space-x-3 flex flex-col space-y-3 lg:block lg:space-y-0">
          <button onClick={retake} className="bg-blue-600 px-6 py-3 rounded-xl text-white">Retake recording</button>
          <button onClick={recordWebcam.download} className="bg-blue-600 px-6 py-3 rounded-xl text-white">Download recording</button>
          <button onClick={()=>{
            setLoader(true)
            saveFile5()
          }} className="bg-blue-600 px-6 py-3 rounded-xl text-white">Submit</button>
        </div>
      }
      <button className="bg-transparent px-6 py-3 rounded-xl text-white" onClick={recordWebcam.open}>If you are unable to see your video, click here</button>
      </div>
      </div>
      </div>
      }
       
      {
        ques == 9 &&
        <div className="lg:w-2/3 mx-3 lg:mx-auto bg-cover rounded-xl py-4 px-3" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/Rectangle5.png'})`}}>
        <div className="flex bg-[#39435A] items-center space-x-3 px-3 py-3 rounded-xl">
              <p className="bg-white rounded-full p-2 px-4 font-bold font-poppins">6</p>
              <p className="text-white font-poppins">What is the toughest group that you have had to work with? How did you manage working with them . </p>
            </div>
      <div className="mx-auto text-center py-3 ">
        <div className="relative lg:w-1/2 mx-auto">
        <video playsInline ref={recordWebcam.previewRef} className={recordWebcam.status !="PREVIEW" ? "mx-auto my-4 rounded-xl hidden":"mx-auto my-4 rounded-xl"} autoPlay muted={status}  loop />
          {
            recordWebcam.status !=="PREVIEW" &&
            <video playsInline ref={recordWebcam.webcamRef} className="mx-auto my-4 rounded-xl " autoPlay muted />
          }
          {
            recordWebcam.status == "RECORDING" &&
            <p className="absolute top-0 text-white w-full mx-autp left-0 right-0">Recording</p>
          }
        </div>
      <div className="space-x-3">
        {
          recordWebcam.status =="OPEN" &&
        <button onClick={recordWebcam.start} className="bg-green-500 px-6 py-3 rounded-xl text-white">Start recording</button>
        }
      {
        recordWebcam.status =="RECORDING" &&
        <button onClick={stop} className="bg-red-600 px-6 py-3 rounded-xl text-white">Stop recording</button>
      }
      {
        recordWebcam.status == "PREVIEW" &&
        <div className="lg:space-x-3 flex flex-col space-y-3 lg:block lg:space-y-0">
          <button onClick={retake} className="bg-blue-600 px-6 py-3 rounded-xl text-white">Retake recording</button>
          <button onClick={recordWebcam.download} className="bg-blue-600 px-6 py-3 rounded-xl text-white">Download recording</button>
          <button onClick={()=>{
            setLoader(true)
            saveFile6()
            }} className="bg-blue-600 px-6 py-3 rounded-xl text-white">Submit</button>
        </div>
      }
      <button className="bg-transparent px-6 py-3 rounded-xl text-white" onClick={recordWebcam.open}>If you are unable to see your video, click here</button>
      </div>
      </div>
      </div>
      }
      
      {
        ques == 10 &&
        <div className="lg:w-2/3 mx-3 lg:mx-auto rounded-xl bg-cover py-4 px-3" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/Rectangle5.png'})`}}>
        <div className="flex bg-[#39435A] items-center space-x-3 px-3 py-3 rounded-xl">
              <p className="bg-white rounded-full p-2 px-4 font-bold font-poppins">7</p>
              <p className="text-white font-poppins">Give me an example when you were unable or unwilling to make a necessary adjustments to achieve a goal.</p>
            </div>
      <div className="mx-auto text-center py-3 ">
        <div className="relative w-1/2 mx-auto">
        <video playsInline ref={recordWebcam.previewRef} className={recordWebcam.status !="PREVIEW" ? "mx-auto my-4 rounded-xl hidden":"mx-auto my-4 rounded-xl"} autoPlay muted={status}  loop />
          {
            recordWebcam.status !=="PREVIEW" &&
            <video playsInline ref={recordWebcam.webcamRef} className="mx-auto my-4 rounded-xl " autoPlay muted />
          }
          {
            recordWebcam.status == "RECORDING" &&
            <p className="absolute top-0 text-white w-full mx-autp left-0 right-0">Recording</p>
          }
        </div>
      <div className="space-x-3">
        {
          recordWebcam.status =="OPEN" &&
        <button onClick={recordWebcam.start} className="bg-green-600 px-6 py-3 rounded-xl text-white">Start recording</button>
        }
      {
        recordWebcam.status =="RECORDING" &&
        <button onClick={stop} className="bg-red-600 px-6 py-3 rounded-xl text-white">Stop recording</button>
      }
      {
        recordWebcam.status == "PREVIEW" &&
        <div className="lg:space-x-3 lg:block lg:space-y-0 flex flex-col space-y-3">
          <button onClick={retake} className="bg-blue-600 px-6 py-3 rounded-xl text-white">Retake recording</button>
          <button onClick={recordWebcam.download} className="bg-blue-600 px-6 py-3 rounded-xl text-white">Download recording</button>
          <button onClick={()=>{
            setLoader(true)
            saveFile8()
          }} className="bg-blue-600 px-6 py-3 rounded-xl text-white">Submit</button>
        </div>
      }
      <button className="bg-transparent px-6 py-3 rounded-xl text-white" onClick={recordWebcam.open}>If you are unable to see your video, click here</button>
      </div>
      </div>
      </div>
      }
      {
        ques == 11 &&
        <div className="lg:w-2/3 mx-3 lg:mx-auto rounded-xl bg-cover py-4 px-3" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/Rectangle5.png'})`}}>
        <div className="flex bg-[#39435A] items-center space-x-3 px-3 py-3 rounded-xl">
              <p className="bg-white rounded-full p-2 px-4 font-bold font-poppins">8</p>
              <p className="text-white font-poppins">Describe a time when you anticipated potential problems and developed preventive measures.</p>
            </div>
      <div className="mx-auto text-center py-3 ">
        <div className="relative lg:w-1/2 mx-auto">
        <video playsInline ref={recordWebcam.previewRef} className={recordWebcam.status !="PREVIEW" ? "mx-auto my-4 rounded-xl hidden":"mx-auto my-4 rounded-xl"} autoPlay muted={status}  loop />
          {
            recordWebcam.status !=="PREVIEW" &&
            <video playsInline ref={recordWebcam.webcamRef} className="mx-auto my-4 rounded-xl " autoPlay muted />
          }
          {
            recordWebcam.status == "RECORDING" &&
            <p className="absolute top-0 text-white w-full mx-autp left-0 right-0">Recording</p>
          }
        </div>
      <div className="space-x-3">
        {
          recordWebcam.status =="OPEN" &&
        <button onClick={recordWebcam.start} className="bg-green-500 px-6 py-3 rounded-xl text-white">Start recording</button>
        }
      {
        recordWebcam.status =="RECORDING" &&
        <button onClick={stop} className="bg-red-600 px-6 py-3 rounded-xl text-white">Stop recording</button>
      }
      {
        recordWebcam.status == "PREVIEW" &&
        <div className="lg:space-x-3 lg:block lg:space-y-0 flex flex-col space-y-3">
          <button onClick={retake} className="bg-blue-600 px-6 py-3 rounded-xl text-white">Retake recording</button>
          <button onClick={recordWebcam.download} className="bg-blue-600 px-6 py-3 rounded-xl text-white">Download recording</button>
          <button onClick={()=>{
            setLoader(true)
            saveFile9()
          }} className="bg-blue-600 px-6 py-3 rounded-xl text-white">Submit</button>
        </div>
      }
      <button className="bg-transparent px-6 py-3 rounded-xl text-white" onClick={recordWebcam.open}>If you are unable to see your video, click here</button>
      </div>
      </div>
      </div>
      }
      {
        ques == 12 &&
        <div className="my-20">
        <div className="lg:w-2/3 mx-3 lg:mx-auto bg-cover  rounded-xl py-4 px-4 lg:px-20" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/Rectangle5.png'})`}}>
        <p className="text-center font- font-poppins my-5 lg:text-4xl lg:leading-10">Congratulations!<br></br>You have completed your interview successfully.<br></br>Please visit Dashboard to view your report</p>
         <div className="text-center bg-blue-400 font-poppins p-4 text-white rounded-xl ">
        <button onClick={nav}>Go to dashboard</button>
          </div>
        </div>
        
      </div>
      }
      {
        ques == 14 && 
        <div className="my-20">
        <div className="lg:w-2/3 mx-3 lg:mx-auto bg-cover  rounded-xl py-4 px-4 lg:px-20" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/Rectangle5.png'})`}}>
        <p className="text-center font- font-poppins my-5 lg:text-4xl lg:leading-10 text-white">Congratulations!<br></br>You have completed the first section. <br></br>Click next to begin your video interview section</p>
        </div>
        <div className="flex justify-between lg:w-2/3 mr-3  lg:mx-auto items-center py-3 pb-5">
          <button className="lg:py-4 py-2 px-6 lg:px-12 text-slate-600 bg-[#FFFFFF] rounded-lg " onClick={()=>{setQues(3)}}>Back</button>
        <button className="lg:py-4 py-2 px-6 lg:px-12 text-slate-200 bg-gradient-to-r from-[#E94B60] to-[#F0939F] rounded-lg " onClick={()=>{setQues(4)}}>Next</button>
        </div>
        </div>
        
      }
      {
        ques!=13 && ques!==12 && ques!==13 && ques!==0 && ques!=14
        &&
      <input className="mt-4 bottom-0 w-full h-16 outline-none px-2 py-2" placeholder="You can write your notes here"></input>
      }
    </div>
}
</div>
  );
}

export default Video;

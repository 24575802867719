import React from 'react'
import path from '../images/Path.png'
import badge from '../images/Shape.png'
import tick from '../images/tick.png'
import shield from '../images/badge.png'
import cup from '../images/cup.png'
import {Link} from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import axios from 'axios'


const buy = ()=>{
    axios.post('https://test.ccavenue.com/transaction/transaction.do?command=initiateTransaction',{
        encRequest:"869d34080b771655a7454711137def682fc65b9962647a507a4dcc59baa79d424570863a59ba26333817d95514018d39d5004455eecce40871dea41e72413db2437adbc6bdf52791d2651186ffa242e5ce29089cc841f882aac7429d3ee9c45c2d6814f83bf5d2c45b4797cd4fec309b0e8dff8fca7f3675f2ecfc467328a92de19305b9cae01e8baa45c4072687fbb1599349ced5021374e85f6f2024175106c63d142111c2e81a6188166c7c9978978aca1bc953e0685d38e6f92fb7a6ef8c",
        accessCode:"AVRL61KE16AQ14LRQA" 
    })
    .then((response) => {
        console.log(response)
    })
    .catch((error) => {
        console.log(error)
    })
}
const Pricing = () => {
  
  return (
    <div>
        <div className="font-poppins lg:p-8 p-5 mx-auto bg-gray-100 min-h-screen">
            {/* <p className=" text-center text-2xl  ml-4 lg:ml-0 font-serif">Choose a plan</p> */}
            <div className="lg:grid  space-y-4 lg:w-3/4 lg:mx-auto  lg:space-y-0 lg:grid-cols-2 mt-10 gap-4">
            
                <div className="px-5 py-4 shadow-xl rounded-xl border-4 border-red-200 ">
                    <img src={path} className="h-24 float-right -mt-5 -mr-5"></img>
                    <img src={shield} className="h-12 mt-3"></img>
                    <p className="my-3 font-bold">Three Interviews Pack</p>
                    {/* <p className="my-3">All the basics for businesses that are just getting started.</p> */}
                    <p className="text-5xl font-extrabold text-[#E94B60] mb-10">₹ 1299 <span className="text-lg">One Time</span></p>
                    
                    <button onClick={buy} className="bg-transparent hover:bg-[#E94B60] rounded-full text-[#E94B60] hover:text-white py-3 border w-full border-[#E94B60]">Buy Now</button>
                    
                    <div className="flex items-center space-x-2 mt-4">
                        <img src={tick} className="h-3"></img>
                        <p>3 Interview Assessments</p>
                    </div>
                    <div className="flex items-center space-x-2 mt-4">
                        <img src={tick} className="h-3"></img>
                        <p>Detailed Report on 5 soft skill parameters</p>
                    </div>
                    <div className="flex items-center space-x-2 mt-4">
                        <img src={tick} className="h-3"></img>
                        <p>Progression tracking and Improvement report</p>
                    </div>
                    <div className="flex items-center space-x-2 mt-4">
                        <img src={tick} className="h-3"></img>
                        <p>Free curated self-paced learning resources</p>
                    </div>
                    <div className="flex items-center space-x-2 mt-4">
                        <img src={tick} className="h-3"></img>
                        <p>Access to Resume Builder tool</p>
                    </div>
                </div>
                <div className="px-5 py-4 shadow-xl rounded-xl border-4 border-red-200 ">
                    <img src={path} className="h-24 float-right -mt-5 -mr-5"></img>
                    <img src={cup} className="h-12 mt-3"></img>
                    <p className="my-3 font-bold">Five Interviews Pack</p>
                    {/* <p className="my-3">All the basics for businesses that are just getting started.</p> */}
                    <p className="text-5xl font-extrabold text-[#E94B60] mb-10">₹ 1499 <span className="text-lg">One Time</span></p>
                    <Link to='/signup' >
                    <button className="bg-transparent hover:bg-[#E94B60] rounded-full text-[#E94B60] hover:text-white py-3 border w-full border-[#E94B60]">Buy Now</button>
                    </Link>
                    <div className="flex items-center space-x-2 mt-4">
                        <img src={tick} className="h-3"></img>
                        <p>5 Interview Assessments</p>
                    </div>
                    <div className="flex items-center space-x-2 mt-4">
                        <img src={tick} className="h-3"></img>
                        <p>Detailed Report on 5 soft skill parameters</p>
                    </div>
                    <div className="flex items-center space-x-2 mt-4">
                        <img src={tick} className="h-3"></img>
                        <p>Progression tracking and Improvement report</p>
                    </div>
                    <div className="flex items-center space-x-2 mt-4">
                        <img src={tick} className="h-3"></img>
                        <p>Free curated self-paced learning resources</p>
                    </div>
                    <div className="flex items-center space-x-2 mt-4">
                        <img src={tick} className="h-3"></img>
                        <p>Access to Specialised Learning material created by our in-house experts</p>
                    </div>
                    <div className="flex items-center space-x-2 mt-4">
                        <img src={tick} className="h-3"></img>
                        <p>Access to Resume Builder tool</p>
                    </div>
                    <div className="flex items-center space-x-2 mt-4">
                        <img src={tick} className="h-3"></img>
                        <p>Resume Assessment</p>
                    </div>
                    
                </div>
            </div>
            Test
        </div>
    </div>
  )
}

export default Pricing
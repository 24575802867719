import React, { useEffect, useState } from 'react'
import ReactStars from 'react-stars'
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import maya from '../images/Maya.JPG'
import Vijaya from '../images/Vijaya.jpeg'
import rajiv from '../images/Rajiv.png'
import { useCalendlyEventListener,  } from "react-calendly";
import { InfinitySpin } from 'react-loader-spinner';
import data from '../config'

const Coach_details = () => {

    useCalendlyEventListener({
        onEventScheduled: (e) => console.log(e),
    })
    
    // eyJraWQiOiIxY2UxZTEzNjE3ZGNmNzY2YjNjZWJjY2Y4ZGM1YmFmYThhNjVlNjg0MDIzZjdjMzJiZTgzNDliMjM4MDEzNWI0IiwidHlwIjoiUEFUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiJodHRwczovL2F1dGguY2FsZW5kbHkuY29tIiwiaWF0IjoxNjkzMzI3MDcxLCJqdGkiOiJhMmMzOTg4MS02OGJhLTQyZDktOTJhYS01NGIwNzA5YzQ1ZjIiLCJ1c2VyX3V1aWQiOiI1OTUzYWEzZC0yOWFhLTQwNWQtYTNmMy1jNzU1NDZjZWMwMWEifQ.Ny3_1aJ5-Zu8g3pm-xzDN5qsSpJzmth-xIJ4lZTF__w7iqCUuObYu1rC4IUWLl-du-z5DncHuo1T6MW6tDolYQ
    const location = useLocation()
    const name = location.state.name;
    const [coachData, setCoachData] = useState({})
    const user = localStorage.getItem('email')

    const buy=()=>{
        axios.post(`https://${data.API}/payment/pe/create`,{
            username:user,
            amount:119900,
            coachname:name
        })
        .then((response)=>{
            console.log(response)
            window.location.href = response.data.url
        })
        .catch((error)=>{
            console.log(error)
        })
    }
    useEffect(()=>{
        if(name){
            axios.get(`https://${data.API}/coach/co/${name}`)
            .then((response)=>{
                console.log(response)
                setCoachData(response.data)
            })
            .catch((error)=>{
                console.log(error)
            })
        }
    },[name])
  return (
    <div className="">
    {
     coachData ?   
    <div className="my-16 font-poppins mx-5">
        <div className="w-full p-4  rounded-xl bg-white">
            <div className="md:flex justify-between items-start ">
                <div className="order-last mx-auto text-center">
            {
                name.includes('Maya')&& 
                <img className="w-60 h-52 mx-auto rounded-xl" src={maya}></img>
            }
            {
                name.includes('Rajiv')&& 
                <img className="w-60 h-52 mx-auto rounded-xl" src={rajiv}></img>
                }
                {
                    name.includes('Vijaya')&& 
                    <img className="w-60 h-52 mx-auto rounded-xl" src={Vijaya}></img>
                }
                </div>

                <div className="w-full mx-5">
                <div className="flex justify-between mt-4  items-center">
                <p className="text-3xl font-bold">{coachData.Name}</p>
                <div className="md:flex items-center hidden space-x-3">
                <ReactStars
                    className="pointer-events-none"
                    count={5}
                    value={5}
                    isHalf={true}
                    edit={false}
                    size={22}
                ></ReactStars>
                <p>5.0</p>
                </div>
                </div>
                <pre className="my-4 mr-4 font-poppins">{coachData.Designation}</pre>
                
                </div>

                

            </div>
        </div>
        <div>
            <p className=" my-7 text-2xl font-bold">About the Coach</p>
            <div className="md:flex items-start w-full md:space-x-5 space-y-5 md:space-y-0" >
                <div className="bg-white p-5 rounded-xl md:w-2/3">
                    <p className="font-bold text-xl my-3">Profile</p>
                    <pre className="font-poppins">{coachData.Profile}</pre>
                </div>
                <div className="md:w-1/3 rounded-xl p-4 text-center bg-white">
                   <p className="text-3xl ">Book a slot </p>
                   <p>for ₹1199</p>
                   <button className="px-5 py-2 my-4 bg-[#E94B60] text-white rounded-xl" onClick={buy}>Pay Now</button>
                </div>
            </div>
        </div>
    </div>
    :
    <div>
        <div className="absolute left-1/2 top-1/2 -translate-x-1/2  text-center -translate-y-1/2">
                    <InfinitySpin
                    width='200'
                    color='#4899B7'
                    ></InfinitySpin>
                </div>
    </div>
    
      }  
    </div>
  )
}

export default Coach_details
import axios from 'axios'
import React, {useState, useEffect} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import interview_guru_logo from '../images/Interview vision logo.png'
import success from '../images/success.png'
import failed from '../images/failed.png'
import {InfinitySpin} from 'react-loader-spinner'
import data from '../config'
import { Helmet } from 'react-helmet'
const Success = () => {
    const navigate = useNavigate()
    const [tid, setTid] = useState('')
    const [successStatus, setSuccessStatus] = useState(null)

    function useQuery() {
        const { search } = useLocation();
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }

    let query = useQuery()

    const [loader, setLoader] = useState(true)
    const [username, setUsername] = useState('')
    const [pack, setPack] = useState('')
    useEffect(()=>{
        setTid(query.get('tranId'))
    },[])
    useEffect(()=>{
        if(tid.length>0){
            axios.get(`https://${data.API}/payment/pe/status/${tid}`)
            .then((response)=>{
                console.log(response)
                if(response.data.status === "PAYMENT_SUCCESS"){
                    axios.post(`https://${data.API}/subscription`,{
                        transId:tid
                    })
                    .then((response)=>{
                        setUsername(response.data.username)
                        setPack(response.data.pack)
                        console.log(response)
                        setSuccessStatus(true)
                        setLoader(false)
                    })
                    .catch((error)=>{
                        console.log(error)
                    })
                    
                }
                else{
                    setSuccessStatus(false)
                    setLoader(false)
                }
            })

        }
    },[tid])

    useEffect(()=>{
        if(username){
            localStorage.setItem('email',username)
        }
    },[username])

    console.log(loader)

  return (
    <div>
        <Helmet>
        <script> 
            {`gtag('event', 'conversion', { 'send_to': 'AW-11249740142/6vD_CJLJi84YEO7SpfQp', 'transaction_id': '' });`} 
        </script>
        </Helmet>
        {
            loader?
            <div className="absolute left-1/2 top-1/2 -translate-x-1/2  text-center -translate-y-1/2">
                    <InfinitySpin
                    width='200'
                    color='#4899B7'
                    ></InfinitySpin>
                </div>
            :
            <div>
                {
                    successStatus?
                    <div>
                        {
                            username.length>0
                            &&
                            <div>
                <img src={interview_guru_logo} className="h-32"></img>
                    <img src={success} className="mx-auto text-center h-36"></img>
                    <p className="text-center mt-8 font-poppins text-2xl">Payment Success</p>
                    <div className="flex flex-col font-poppins w-[20rem] space-y-3 mx-auto mt-6">

                    <div className="flex justify-between  items-center">
                    <p className="text-2xl font-poppins">₹{
                        pack == "basic"
                        && 
                        '999'
                    }{
                        pack == "standard"
                        &&
                        '1499'
                    }
                    {
                        pack=="premium"
                        &&
                        '1999'
                    }
                    </p>
                    <p className="bg-red-500 text-white rounded-xl px-3 py-1 text-sm font-poppins">Saved 50%</p>
                    </div>

                    <div className="flex justify-between  items-center">
                        <p>Transaction ID</p>
                        <p>{tid}</p>
                    </div>
                    <div className="flex justify-between  items-center">
                        <p>Username</p>
                        <p>{username}</p>
                    </div>
                    <div className="flex justify-between text-right  items-center">
                        <p>Merchant Name</p>
                        <p>Novelio Technologies<br></br>Pvt. Ltd.</p>
                    </div>
                    <div className="flex justify-between  items-center">
                        <p>Discount</p>
                        <p>₹{
                            pack == "basic"
                            && 
                            '500'
                        }{
                            pack == "standard"
                            &&
                            '600'
                        }
                        {
                            pack=="premium"
                            &&
                            '750'
                        }</p>
                    </div>
                    </div>
                    <div className="text-center">
                    <button onClick={()=>{navigate('/home')}} className="w-[20rem] mx-auto bg-red-500 mt-7 text-white rounded-xl py-5  ">
                        Back to Home
                    </button>
                    </div>
                            </div>
                        }
                    </div>
                    :
                    <div>
                        <img src={interview_guru_logo} className="h-32"></img>
                        <img src={failed} className="mx-auto text-center h-36"></img>
                        
                    <p className="text-center mt-8 font-poppins text-2xl">Payment Failed</p>
                    <p className="text-center font-poppins mt-4">Don't worry, it happens with the best of us</p>
                    <div className="text-center">
                    <button onClick={()=>{navigate('/home')}} className="w-[20rem] mx-auto bg-red-500 mt-7 text-white rounded-xl py-5  ">
                        Back to Payments Page
                    </button>
                    </div>
                    </div>

                }
            </div>
        }
    </div>
  )
}

export default Success 
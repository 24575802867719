import React, { useState, useEffect } from "react";
import jsPDF from "jspdf";
import ReactSwitch from "react-switch";
import { RxHamburgerMenu } from "react-icons/rx";
import { GrClose } from "react-icons/gr";
import { Link, useLocation } from "react-router-dom";
import ReactGA from 'react-ga'
const Template6 = () => {
  const location = useLocation();
  const [isContactInfo, setContactInfo] = useState(false);
  const [name, setName] = useState("Enter your name here");
  const [city, setCity] = useState(" City");
  const [state, setState] = useState(" State");
  const [openObj, setOpenObj] = useState(false);
  const [openSkills, setOpenSkills] = useState(false);
  const [education, setEducation] = useState([]);
  const [openEdu, setOpenEdu] = useState(false);
  const [educationField, setEducationField] = useState({});
  const [schoolName, setSchoolName] = useState("");
  const [openexp, setOpenExp] = useState(false);
  const [openProj, setOpenProj] = useState(false);
  const [projects, setProjects] = useState([]);
  const [certificates, setCertificates] = useState([]);
  const [openCerts, setOpenCerts] = useState(false);
  const [openAwards, setOpenAwards] = useState("");
  const [awards, setAwards] = useState([]);
  const [languages, setLanguages] = useState("");
  const [openLanguages, setOpenLanguages] = useState(false);
  const [singleedu, setSingleedu] = useState({});
  const [exp, setExp] = useState([]);
  const [openHobbies, setOpenHobbies] = useState(false);
  const [pnum, setPnum] = useState("Phone");
  const [email, setEmail] = useState("Your email id");
  const [linkedin, setLinkedin] = useState("linkedin");
  const [objective, setObjective] = useState(
    "Proficient sales professional with 1 year of experience in customer acquisition, relationship management, sales fulfilment and business development."
  );
  const [skills, setSkills] = useState([]);
  const [hobbies, setHobbies] = useState("Singing, dancing, playing");
  const [scname, setScname] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [fos, setFos] = useState("");
  const [scity, setScity] = useState("");
  const [sstate, setSstate] = useState("");
  const [gpa, setGpa] = useState("");
  const [activities, setActivities] = useState("");
  const [url, setUrl] = useState("");
  const [tab, setTab] = useState(0);
  const [show, setShow] = useState(false);
  const [dragActive, setDragActive] = React.useState(false);
  const [checked1, setChecked1] = useState(true);
  const [checked2, setChecked2] = useState(true);
  const [checked3, setChecked3] = useState(true);
  const [checked4, setChecked4] = useState(true);
  const [checked5, setChecked5] = useState(false);
  const [checked6, setChecked6] = useState(false);
  const [checked7, setChecked7] = useState(false);
  const [checked8, setChecked8] = useState(false);
  const [degree, setDegree] = useState("");
  const [checked9, setChecked9] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [role, setRole] = useState("");
  const [sdate, setSdate] = useState("");
  const [edate, setEdate] = useState("");
  const [check, setCheck] = useState(false);
  const [desc, setDesc] = useState("");
  const [singleexp, setSingleexp] = useState({});
  const [pjname, setPjname] = useState("");
  const [projdesc, setProjDesc] = useState("");
  const [singleproj, setSingleproj] = useState({});
  const [certificateName, setCertificateName] = useState("");
  const [certificateDesc, setCertificateDesc] = useState("");
  const [singleCert, setSingleCert] = useState({});
  const [awardName, setAwardName] = useState("");
  const [awardDesc, setAwardDesc] = useState("");
  const [singleAward, setSingleAward] = useState({});
  const [formattedstartdate, setFormattedstartdate] = useState("");
  const [formattedenddate, setFormattedenddate] = useState("");
  const [native, setNative] = useState("");
  const [fluent, setFluent] = useState("");
  const [conversational, setConversational] = useState("");
  const [visible, setVisible] = useState(false);
  const [extra, setExtra] = useState("");

  useEffect(()=>{
    ReactGA.event({
        category:"User views temp 6",
        action:"Template 6 editor opened"
    })
  },[])

  const gs = [
    "Communications",
    "Growth Mindset",
    "Critical Thinking",
    "Project Management",
    "Design Thinking",
  ];
  const os = [
    "Customer Service",
    "Customer Experience",
    "Operations Management",
    "Six Sigma",
    "Lean Six Sigma",
  ];
  const sales = [
    "Sales",
    "Negotiation",
    "Customer Retention",
    "Objection Handling",
    "Customer Relationship Management",
  ];
  const hr = [
    "Talent Acquisition",
    "Employee Relations",
    "Performance Management",
    "HRMS",
    "HR Operations",
  ];
  const leadership = [
    "Leadership",
    "Leadership Development",
    "Teamwork",
    "Team Building",
    "Strategic Planning",
  ];

  const finance = [
    "Accounting",
    "Financial Planning",
    "Financial Modelling",
    "Payment Processing",
    "Taxation",
  ];
  const tech = [
    "Frontend Developement",
    "Backend Development",
    "Mobile Technologies",
    "Data Engineering",
    "Information Technology",
  ];

  // ref
  const inputRef = React.useRef(null);
  const ref = React.useRef(null);

  const handleChange1 = (val) => {
    setChecked1(val);
  };

  const handleChange2 = (val) => {
    setChecked2(val);
  };

  const handleChange3 = (val) => {
    setChecked3(val);
  };

  const handleChange4 = (val) => {
    setChecked4(val);
  };

  const handleChange5 = (val) => {
    setChecked5(val);
  };

  const handleChange6 = (val) => {
    setChecked6(val);
  };

  const handleChange7 = (val) => {
    setChecked7(val);
  };

  const handleChange8 = (val) => {
    setChecked8(val);
  };

  const handleChange9 = (val) => {
    setChecked9(val);
  };

  const handleGeneratePdf = () => {
    const doc = new jsPDF({
      unit: "px",
      width: "100%",
      hotfixes: ["px_scaling"],
      // format: 'a4',
    });

    // doc.options.hotfixes = ["px_scaling"]
    doc.html(ref.current, {
      // margin:[0,10,20,0],
      async callback(doc) {
        await doc.save("document");
      },
    });
  };

  const objectExamples = [
    "Seeking a challenging position in [industry/field] where I can utilize my [skills/qualifications] to contribute to the success of the organization.",
    "To obtain a position as a [job title] in a dynamic and growth-oriented company where I can leverage my [skills/qualifications] to achieve professional and personal growth.",
    "Looking for a role in [industry/field] that allows me to apply my [skills/qualifications] to solve complex problems and drive business results.",
    "To secure a position in [industry/field] where I can use my [skills/qualifications] to make a meaningful impact and grow professionally.",
    "Seeking an opportunity in [industry/field] to utilize my [skills/qualifications] and contribute to a team-oriented work environment.",
    "To obtain a challenging position in [industry/field] that allows me to apply my [skills/qualifications] and develop new skills while making a positive contribution to the organization.",
    "Seeking a role as a [job title] in a company that values teamwork, creativity, and innovation, and provides opportunities for professional growth and advancement.",
  ];

  useEffect(() => {
    console.log(sdate.split("-")[0]);
    if (sdate.split("-")[1] === "01")
      setFormattedstartdate(`Jan ${sdate.split("-")[0]}`);
    else if (sdate.split("-")[1] === "02")
      setFormattedstartdate(`Feb ${sdate.split("-")[0]}`);
    else if (sdate.split("-")[1] === "03")
      setFormattedstartdate(`Mar ${sdate.split("-")[0]}`);
    else if (sdate.split("-")[1] === "04")
      setFormattedstartdate(`Apr ${sdate.split("-")[0]}`);
    else if (sdate.split("-")[1] === "05")
      setFormattedstartdate(`May ${sdate.split("-")[0]}`);
    else if (sdate.split("-")[1] === "06")
      setFormattedstartdate(`Jun ${sdate.split("-")[0]}`);
    else if (sdate.split("-")[1] === "07")
      setFormattedstartdate(`July ${sdate.split("-")[0]}`);
    else if (sdate.split("-")[1] === "08")
      setFormattedstartdate(`Aug ${sdate.split("-")[0]}`);
    else if (sdate.split("-")[1] === "09")
      setFormattedstartdate(`Sep ${sdate.split("-")[0]}`);
    else if (sdate.split("-")[1] === "10")
      setFormattedstartdate(`Oct ${sdate.split("-")[0]}`);
    else if (sdate.split("-")[1] === "11")
      setFormattedstartdate(`Nov ${sdate.split("-")[0]}`);
    else if (sdate.split("-")[1] === "12")
      setFormattedstartdate(`Dec ${sdate.split("-")[0]}`);
  }, [sdate]);

  useEffect(() => {
    if (edate !== "Present") {
      if (edate.split("-")[1] === "01")
        setFormattedenddate(`Jan ${edate.split("-")[0]}`);
      else if (edate.split("-")[1] === "02")
        setFormattedenddate(`Feb ${edate.split("-")[0]}`);
      else if (edate.split("-")[1] === "03")
        setFormattedenddate(`Mar ${edate.split("-")[0]}`);
      else if (edate.split("-")[1] === "04")
        setFormattedenddate(`Apr ${edate.split("-")[0]}`);
      else if (edate.split("-")[1] === "05")
        setFormattedenddate(`May ${edate.split("-")[0]}`);
      else if (edate.split("-")[1] === "06")
        setFormattedenddate(`Jun ${edate.split("-")[0]}`);
      else if (edate.split("-")[1] === "07")
        setFormattedenddate(`July ${edate.split("-")[0]}`);
      else if (edate.split("-")[1] === "08")
        setFormattedenddate(`Aug ${edate.split("-")[0]}`);
      else if (edate.split("-")[1] === "09")
        setFormattedenddate(`Sep ${edate.split("-")[0]}`);
      else if (edate.split("-")[1] === "10")
        setFormattedenddate(`Oct ${edate.split("-")[0]}`);
      else if (edate.split("-")[1] === "11")
        setFormattedenddate(`Nov ${edate.split("-")[0]}`);
      else if (edate.split("-")[1] === "12")
        setFormattedenddate(`Dec ${edate.split("-")[0]}`);
    } else {
      setFormattedenddate(edate);
    }
  }, [edate]);

  const edusave = () => {
    console.log(education);
    singleedu["scname"] = scname;
    singleedu["fos"] = fos;
    singleedu["degree"] = degree;
    singleedu["gpa"] = gpa;
    singleedu["activities"] = activities;
    console.log(singleedu);
    setEducation([...education, singleedu]);
    console.log(education);
    setSingleedu({});
    setScname("");
    setStartDate("");
    setEndDate("");
    setFos("");
    setDegree("");
    setActivities("");
    setSstate("");
    setGpa("");
  };

  const expsave = () => {
    singleexp["companyName"] = companyName;
    singleexp["role"] = role;
    singleexp["sdate"] = formattedstartdate;
    singleexp["edate"] = formattedenddate;
    singleexp["desc"] = desc;
    console.log(singleexp);
    setExp([...exp, singleexp]);
    setSingleexp({});
    setCompanyName("");
    setRole("");
    setSdate("");
    setEdate("");
    setDesc("");
    setCheck(false);
  };

  const projsave = () => {
    singleproj["pjname"] = pjname;
    singleproj["projdesc"] = projdesc;
    setProjects([...projects, singleproj]);
    setSingleproj({});
    setPjname("");
    setProjDesc("");
  };

  const certsave = () => {
    singleCert["certificateName"] = certificateName;
    singleCert["certificateDesc"] = certificateDesc;
    setCertificates([...certificates, singleCert]);
    setSingleCert({});
    setCertificateName("");
    setCertificateDesc("");
  };

  const awardsave = () => {
    singleAward["awardName"] = awardName;
    singleAward["awardDesc"] = awardDesc;
    setAwards([...awards, singleAward]);
    setSingleAward({});
    setAwardName("");
    setAwardDesc("");
  };

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      // handleFiles(e.dataTransfer.files);
    }
  };

  // triggers when file is selected with click
  const handleChange = function (e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      console.log(e.target.files);
      setUrl(URL.createObjectURL(e.target.files[0]));
      setShow(true);
    }
  };

  // triggers the input when the button is clicked
  const onButtonClick = () => {
    inputRef.current.click();
  };

  return (
    <div>
      <div className="fixed z-10 top-3 lg:hidden block right-0">
        <button
          className="bg-red-300 text-2xl p-3  rounded-xl"
          onClick={() => {
            setVisible(true);
          }}
        >
          <RxHamburgerMenu></RxHamburgerMenu>
        </button>
      </div>
      <div
        className={
          visible
            ? "w-5/6 bg-white h-full p-4 fixed shadow-2xl right-0 z-20"
            : "hidden"
        }
      >
        <p className="font-poppins mb-6">Control Panel</p>
        <div className="flex flex-col space-y-4 px-3">
          <div className="flex justify-between">
            <p>Summary</p>
            <ReactSwitch checked={checked1} onChange={handleChange1} />
          </div>
          <div className="flex  justify-between">
            <p>Skills</p>
            <ReactSwitch
              checked={checked2}
              onChange={handleChange2}
            ></ReactSwitch>
          </div>
          <div className="flex justify-between">
            <p>Experience</p>
            <ReactSwitch
              checked={checked3}
              onChange={handleChange3}
            ></ReactSwitch>
          </div>
          <div className="flex justify-between">
            <p>Education</p>
            <ReactSwitch
              checked={checked4}
              onChange={handleChange4}
            ></ReactSwitch>
          </div>
          <div className="flex justify-between">
            <p>Projects</p>
            <ReactSwitch
              checked={checked5}
              onChange={handleChange5}
            ></ReactSwitch>
          </div>
          <div className="flex justify-between">
            <p>Certificates</p>
            <ReactSwitch
              checked={checked6}
              onChange={handleChange6}
            ></ReactSwitch>
          </div>
          <div className="flex justify-between">
            <p>Awards</p>
            <ReactSwitch
              checked={checked7}
              onChange={handleChange7}
            ></ReactSwitch>
          </div>
          <div className="flex justify-between">
            <p>Languages</p>
            <ReactSwitch
              checked={checked8}
              onChange={handleChange8}
            ></ReactSwitch>
          </div>
          <div className="flex justify-between">
            <p>Hobbies</p>
            <ReactSwitch
              checked={checked9}
              onChange={handleChange9}
            ></ReactSwitch>
          </div>
          <div className="flex w-full justify-center">
            <button
              onClick={() => {
                setVisible(false);
              }}
            >
              <GrClose></GrClose>
            </button>
          </div>
        </div>
      </div>
      <div
        className={
          isContactInfo ||
          openObj ||
          openSkills ||
          openEdu ||
          openexp ||
          openHobbies ||
          openLanguages ||
          openProj ||
          openCerts ||
          openAwards
            ? " bg-gray-100 relative min-h-screen blur-sm"
            : "bg-gray-100 min-h-screen relative "
        }
      >
        <div className="flex py-2 items-center justify-between w-full">
          <Link to="/resume">
            <button className="bg-green-500 ml-3 lg:ml-20 px-3 py-2 lg:px-5 lg:py-4 rounded-xl text-white ">
              Go Back
            </button>
          </Link>
          <h1 className="text-center font-poppins  p-4 text-4xl pb-4">
            My Resume
          </h1>
          <h1 className="invisible mr-4 lg:mr-20">x</h1>
        </div>
        <div className="flex items-start justify-start space-x-8 lg:ml-20">
          <div
            ref={ref}
            className="bg-white  p-3 lg:mx-0 min-h-[69rem] w-[49.6rem] shadow-xl "
          >
           <div className="flex justify-start items-center space-x-4 bg-emerald-300 px-3">
           {!show ? (
                    <div className="my-4">
                      <form
                        id="form-file-upload-4"
                        className="rounded-full"
                        onDragEnter={handleDrag}
                        onSubmit={(e) => e.preventDefault()}
                      >
                        <input
                          ref={inputRef}
                          type="file"
                          id="input-file-upload"
                          multiple={true}
                          onChange={handleChange}
                        />
                        <label
                          id="label-file-upload-4"
                          htmlFor="input-file-upload"
                          className={dragActive ? "drag-active" : ""}
                        >
                          <div>
                            <p className="text-sm lg:text-lg">
                              Drag and drop <br></br> your file here or
                            </p>
                            <button
                              className="upload-button text-sm lg:text-lg"
                              onClick={onButtonClick}
                            >
                              Upload a file
                            </button>
                          </div>
                        </label>
                        {dragActive && (
                          <div
                            id="drag-file-element"
                            onDragEnter={handleDrag}
                            onDragLeave={handleDrag}
                            onDragOver={handleDrag}
                            onDrop={handleDrop}
                          ></div>
                        )}
                      </form>
                    </div>
                  ) : (
                    <img
                      src={url}
                      className="h-[10rem] m-3  w-[10rem]"
                    ></img>
                  )}
                  <div className=" px-3 py-2">
                  <button
                    onClick={() => {
                      setContactInfo(true);
                    }}
                    className="w-full text-left hover:bg-red-100"
                  >
                    <h1 className="lg:text-2xl text-lg font-bold">{name}</h1>
                    <p className=" w-full justify-start space-x-2 lg:text-lg text-sm">
                      <span>{pnum}</span>
                      <span>|</span>
                      <span>{email}</span>
                    </p>
                    <div className="flex w-full justify-start space-x-2 lg:text-lg text-sm">
                      <h1>
                        {city}, {state}
                      </h1>
                    </div>
                  </button>
                </div>
           </div>
                <div className="flex ">
                    <div className="w-2/5 bg-gray-200">
                    <div className="px-3 py-2">
                  {checked1 && (
                    <div>
                      {/* <div className="h-[0.1rem] w-full bg-[#0804fc] my-4"></div> */}
                      <button
                        className="w-full text-left mt-5 hover:bg-red-100"
                        onClick={() => {
                          setOpenObj(true);
                        }}
                      >
                        <div>
                          <h1 className="font-bold text-xl">Summary</h1>
                          <pre className="my-3 font-sans ">{objective}</pre>
                        </div>
                      </button>
                    </div>
                  )}
                  {checked2 && (
                    <div>
                      {/* <div className="h-[0.1rem] w-full bg-[#0804fc] my-4"></div> */}
                      <button
                        className="text-left w-full mt-5 hover:bg-red-100"
                        onClick={() => {
                          setOpenSkills(true);
                        }}
                      >
                        <div>
                          <h1 className="font-bold text-xl">Skills</h1>
                          <pre className="my-3 font-sans">
                            {skills.toString().replaceAll(",", ", ")}
                          </pre>
                        </div>
                      </button>
                    </div>
                  )}
                  {checked6 && (
                    <div>
                      {/* <div className="h-[0.1rem] w-full bg-[#0804fc] my-4"></div> */}
                      <button
                        className="text-left w-full hover:bg-red-100 mt-5"
                        onClick={() => {
                          setOpenCerts(true);
                        }}
                      >
                        <h1 className="font-bold text-xl">Certificates</h1>
                        {/* <p className="my-2 font-bold">Volunteering</p> */}
                        {certificates.map((c) => {
                          return (
                            <div className="my-2 ">
                              <p className="font-bold">{c.certificateName}</p>
                              <pre className="font-sans">
                                {c.certificateDesc}
                              </pre>
                            </div>
                          );
                        })}
                      </button>
                    </div>
                  )}

                  {checked7 && (
                    <div>
                      {/* <div className="h-[0.1rem] w-full bg-[#0804fc] my-4"></div> */}
                      <button
                        className="text-left w-full hover:bg-red-100 mt-5"
                        onClick={() => {
                          setOpenAwards(true);
                        }}
                      >
                        <h1 className="font-bold text-xl">Awards</h1>
                        {/* <p className="my-2 font-bold">Volunteering</p> */}
                        {awards.map((c) => {
                          return (
                            <div className="my-2 ">
                              <p className="font-bold">{c.awardName}</p>
                              <pre className="font-sans">{c.awardDesc}</pre>
                            </div>
                          );
                        })}
                      </button>
                    </div>
                  )}

                  {checked8 && (
                    <div>
                      {/* <div className="h-[0.1rem] w-full bg-[#0804fc] my-4"></div> */}
                      <button
                        className="text-left w-full hover:bg-red-100 mt-5"
                        onClick={() => {
                          setOpenLanguages(true);
                        }}
                      >
                        <h1 className="font-bold text-xl">Languages</h1>
                        {/* <p className="my-2 font-bold">Volunteering</p> */}
                        <p className="my-1">
                          <span className="font-bold">Native: </span>
                          {native}
                        </p>
                        <p>
                          <span className="font-bold">Fluent: </span>
                          {fluent}
                        </p>
                        <p>
                          <span className="font-bold">Conversational: </span>
                          {conversational}
                        </p>
                      </button>
                    </div>
                  )}
                </div>
                    </div>
                    <div className="w-3/5 px-3">
                {checked3 && (
                  <div>
                    {/* <div className="h-[0.1rem] w-full bg-[#0804fc] my-4"></div> */}
                    <button
                      className="text-left w-full mt-5 hover:bg-red-100"
                      onClick={() => {
                        setOpenExp(true);
                      }}
                    >
                      <h1 className="font-bold text-xl">Experience</h1>
                      {/* <p className="my-2 font-bold">Volunteering</p> */}
                      {exp.map((e) => {
                        return (
                          <div>
                            <p className="my-2 font-bold">{e.companyName}</p>
                            <p>{e.role}</p>
                            <p className="mb-4">
                              {e.sdate} to {e.edate}
                            </p>
                            <div className="my-2">
                              <pre className="font-sans">{e.desc}</pre>
                            </div>
                          </div>
                        );
                      })}
                    </button>
                  </div>
                )}
                {checked4 && (
                  <div>
                    {/* <div className="h-[0.1rem] w-full bg-[#0804fc] my-4"></div> */}
                    <div>
                      <button
                        className="w-full text-left mt-5 hover:bg-red-100"
                        onClick={() => {
                          setOpenEdu(true);
                        }}
                      >
                        <h1 className="font-bold text-xl">Education</h1>
                        {education.map((edu) => {
                          return (
                            <div>
                              <p className="my-2 font-bold">
                                {edu.fos}, {edu.degree}
                              </p>
                              <p>{edu.scname}</p>
                              <p>GPA / Percentage : {edu.gpa}</p>
                              <div className=" my-2">
                                <p className="font-bold">Activities</p>
                                <p className="font-sans">{edu.activities}</p>
                              </div>
                            </div>
                          );
                        })}
                      </button>
                    </div>
                  </div>
                )}
                {checked5 && (
                  <div>
                    {/* <div className="h-[0.1rem] w-full bg-[#0804fc] my-4"></div> */}
                    <button
                      className="text-left w-full hover:bg-red-100 mt-5"
                      onClick={() => {
                        setOpenProj(true);
                      }}
                    >
                      <h1 className="font-bold text-xl">Projects</h1>
                      {/* <p className="my-2 font-bold">Volunteering</p> */}
                      {projects.map((p) => {
                        return (
                          <div className="my-2">
                            <p className="font-bold">{p.pjname}</p>
                            <pre className="font-sans">{p.projdesc}</pre>
                          </div>
                        );
                      })}
                    </button>
                  </div>
                )}
                {checked9 && (
                  <div>
                    {/* <div className="h-[0.1rem] w-full bg-[#0804fc] my-4"></div> */}
                    <button
                      onClick={() => {
                        setOpenHobbies(true);
                      }}
                      className="w-full mt-5 text-left hover:bg-red-100"
                    >
                      <h1 className="font-bold text-xl">Hobbies</h1>
                      <p>{hobbies}</p>
                    </button>
                  </div>
                )}
              </div>
                </div>
          </div>
          <div className="bg-white hidden lg:block p-4 rounded-xl shadow-2xl w-1/4">
            <p className="font-poppins mb-6">Control Panel</p>
            <div className="flex flex-col space-y-4 px-3">
              <div className="flex justify-between">
                <p>Summary</p>
                <ReactSwitch checked={checked1} onChange={handleChange1} />
              </div>
              <div className="flex  justify-between">
                <p>Skills</p>
                <ReactSwitch
                  checked={checked2}
                  onChange={handleChange2}
                ></ReactSwitch>
              </div>
              <div className="flex justify-between">
                <p>Experience</p>
                <ReactSwitch
                  checked={checked3}
                  onChange={handleChange3}
                ></ReactSwitch>
              </div>
              <div className="flex justify-between">
                <p>Education</p>
                <ReactSwitch
                  checked={checked4}
                  onChange={handleChange4}
                ></ReactSwitch>
              </div>
              <div className="flex justify-between">
                <p>Projects</p>
                <ReactSwitch
                  checked={checked5}
                  onChange={handleChange5}
                ></ReactSwitch>
              </div>
              <div className="flex justify-between">
                <p>Certificates</p>
                <ReactSwitch
                  checked={checked6}
                  onChange={handleChange6}
                ></ReactSwitch>
              </div>
              <div className="flex justify-between">
                <p>Awards</p>
                <ReactSwitch
                  checked={checked7}
                  onChange={handleChange7}
                ></ReactSwitch>
              </div>
              <div className="flex justify-between">
                <p>Languages</p>
                <ReactSwitch
                  checked={checked8}
                  onChange={handleChange8}
                ></ReactSwitch>
              </div>
              <div className="flex justify-between">
                <p>Hobbies</p>
                <ReactSwitch
                  checked={checked9}
                  onChange={handleChange9}
                ></ReactSwitch>
              </div>
            </div>
          </div>
        </div>
        <div className="text-center">
          <button
            className="bg-[#E94B60] px-5 py-3 rounded-xl my-5 text-white"
            onClick={handleGeneratePdf}
          >
            Download Resume
          </button>
        </div>
      </div>

      {isContactInfo && (
        <div className="fixed top-1/2 left-1/2 -translate-x-1/2 w-full lg:w-[50rem]  -translate-y-1/2 bg-white rounded-xl z-30 backdrop-blur-xl p-4">
          <div className="flex justify-between items-center lg:w-full  ">
            <p className="invisible">a</p>
            <p className="font-poppins">Contact Info</p>
            <button
              onClick={() => {
                setContactInfo(false);
              }}
              class
            >
              x
            </button>
          </div>
          <div className="h-[0.1rem] w-full bg-[#0804fc] my-4"></div>
          <div className="lg:flex space-y-3 lg:space-y-0 lg:space-x-3">
            <div className="w-full">
              <div className="flex flex-col space-y-3">
                <div class="relative  mb-4 ">
                  <input
                    type="text"
                    required
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    id="floating_outlined"
                    className=" px-2.5 border-2 pb-2.5  pt-4 w-full  text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                  />
                  <label
                    for="floating_outlined"
                    className="absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 font-poppins"
                  >
                    Enter your name
                  </label>
                </div>
                <div class="relative  mb-4 lg:mb-0 ">
                  <input
                    type="text"
                    required
                    value={pnum}
                    onChange={(e) => {
                      setPnum(e.target.value);
                    }}
                    id="floating_outlined"
                    className=" px-2.5 border-2 pb-2.5  pt-4 w-full  text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                  />
                  <label
                    for="floating_outlined"
                    className="absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 font-poppins"
                  >
                    Enter your phone number
                  </label>
                </div>
                <div class="relative  mb-4 lg:mb-0 ">
                  <input
                    type="email"
                    required
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    id="floating_outlined"
                    className=" px-2.5 border-2 pb-2.5  pt-4 w-full  text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                  />
                  <label
                    for="floating_outlined"
                    className="absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 font-poppins"
                  >
                    Enter your email id
                  </label>
                </div>
                <div className="lg:flex w-full lg:space-x-3">
                  <div class="relative mb-4 lg:mb-0 lg:w-1/2">
                    <input
                      value={city}
                      onChange={(e) => {
                        setCity(e.target.value);
                      }}
                      type="text"
                      id="floating_outlined"
                      className=" px-2.5 border-2 pb-2.5 pt-4 w-full  text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=" "
                    />
                    <label
                      for="floating_outlined"
                      className="absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 font-poppins"
                    >
                      City
                    </label>
                  </div>
                  <div class="relative lg:w-1/2">
                    <input
                      value={state}
                      onChange={(e) => {
                        setState(e.target.value);
                      }}
                      type="text"
                      id="floating_outlined"
                      className=" px-2.5 border-2 pb-2.5 pt-4 w-full  text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=" "
                    />
                    <label
                      for="floating_outlined"
                      className="absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 font-poppins"
                    >
                      State
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="h-[0.1rem] w-full bg-[#0804fc] my-4"></div>
          <div className="flex justify-between ">
            <button
              className="rounded-xl px-4 py-3 bg-[#E94B60] text-white"
              onClick={() => {
                setContactInfo(false);
              }}
            >
              Save
            </button>
          </div>
        </div>
      )}
      {openObj && (
        <div className="fixed w-full lg:w-[50rem] top-1/2 left-1/2 -translate-x-1/2  -translate-y-1/2 bg-white rounded-xl z-30 backdrop-blur-xl p-4">
          <div className="flex justify-between items-center w-full ">
            <p className="invisible">a</p>
            <p className="font-poppins ">Summary</p>
            <button
              onClick={() => {
                setOpenObj(false);
              }}
            >
              x
            </button>
          </div>
          <div className="h-[0.1rem] w-full bg-[#0804fc] my-4"></div>
          <div className="lg:flex  space-x-4">
            <div className="lg:w-1/2">
              <div class="relative  mb-4 lg:mb-0 ">
                <textarea
                  type="text"
                  rows={10}
                  required
                  value={objective}
                  onChange={(e) => {
                    setObjective(e.target.value);
                  }}
                  id="floating_outlined"
                  className=" px-2.5 border-2 pb-2.5  pt-4 w-full  text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                />
                <label
                  for="floating_outlined"
                  className="absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 font-poppins"
                >
                  Whats your objective?
                </label>
              </div>
            </div>
            <div className="lg:w-1/2 mb-4 lg:mb-0">
              <div className="flex space-x-5 mb-3">
                <button
                  onClick={() => {
                    setTab(0);
                  }}
                >
                  <p className="font-bold">Tips</p>
                </button>
                <button
                  onClick={() => {
                    setTab(1);
                  }}
                >
                  <p className="">Examples</p>
                </button>
              </div>
              {
                            tab == 0 &&
                            <div className="overflow-y-scroll h-[13rem] scrollbar-hide p-3 bg-gray-100 rounded-xl text-sm">
                                <p className='mb-4'>A summary is your first opportunity to showcase yourself. A strong resume summary comprises your strengths, skills, and achievements concisely. Here are important points to help you create an impactful summary:</p>
                                <p className='mb-4'>1. Focus on relevant skills and qualifications. Emphasize the skills and qualifications that are directly applicable to the job you're applying for. Highlighting areas of expertise and / or certifications relevant to the job will be even better. E.g. Results-oriented customer service professional with a strong background in customer experience, handling customer inquiries.</p>
                                <p className='mb-4'>2. Showcase soft skills and personal attributes such as communication, problem-solving, teamwork, and growth mindset that are relevant to the posted job ad. E.g. Eye for detail and highly organized individual with excellent communication and interpersonal skills.</p>
                                <p className='mb-4'>3. When you limited or no prior experience, emphasize your academic achievements, relevant coursework, and any internships or projects you have completed. E.g. Completed a research project on sentiment analysis using natural language processing.</p>
                                <p className='mb-4'>4. Showcase achievement through numbers or metrics where possible. E.g. Managed a social media campaign that generated over 10,000 new followers and increased engagement by 25%.</p>
                                <p>Most importantly, tailor the summary to each job application. This shows that you've taken the time to understand the job and are genuinely interested in the opportunity. Remember to keep your summary concise, typically between three to five sentences. It should serve as an introduction to your resume and entice the employer to read further.</p>
                            </div>
                        }
                        {
                            tab == 1 &&
                            <div className='flex flex-col mt-2 space-y-4 overflow-y-scroll h-[13rem] scrollbar-hide p-3 rounded-xl'>
                                
                             <button className="text-left border shadow-2xl rounded-xl p-4" onClick={()=>{setObjective('Sales professional with up to 2 years of experience in growing businesses. I have a strong record of making more money and building good relationships with customers. I know how to find new opportunities and communicate well with people. I am focused on helping clients and meeting goals.')}}>
                             Sales professional with up to 2 years of experience in growing businesses. I have a strong record of making more money and building good relationships with customers. I know how to find new opportunities and communicate well with people. I am focused on helping clients and meeting goals.
                             </button>
                             <button className="text-left border shadow-2xl rounded-xl p-4" onClick={()=>{setObjective( 'I have 1 year of experience in Customer Operations. I am skilled at helping customers and making sure they are happy. I am good at solving problems and communicating clearly. I am dedicated to providing excellent service and achieving customer satisfaction.')}}>
                             I have 1 year of experience in Customer Operations. I am skilled at helping customers and making sure they are happy. I am good at solving problems and communicating clearly. I am dedicated to providing excellent service and achieving customer satisfaction.
                             </button>
                             <button className="text-left border shadow-2xl rounded-xl p-4" onClick={()=>{setObjective( 'As a fresher seeking a role as a Retail Bank Customer Service Executive, I bring a strong desire to help customers and ensure their needs are met. I am a good communicator and have a friendly approach. I am eager to learn and committed to delivering exceptional service to customer.')}}>
                             As a fresher seeking a role as a Retail Bank Customer Service Executive, I bring a strong desire to help customers and ensure their needs are met. I am a good communicator and have a friendly approach. I am eager to learn and committed to delivering exceptional service to customers.
                             </button> 
                             <button className="text-left border shadow-2xl rounded-xl p-4" onClick={()=>{setObjective( 'I have completed Graduation. As a fresher, I am interested in working as a Delivery personnel, I am enthusiastic and dependable. I have a strong work ethic and can handle physical tasks. I am committed to delivering packages efficiently and ensuring customer satisfaction. I am eager to contribute to the success of the team and grow in this role.')}}>
                             I have completed Graduation. As a fresher, I am interested in working as a Delivery personnel, I am enthusiastic and dependable. I have a strong work ethic and can handle physical tasks. I am committed to delivering packages efficiently and ensuring customer satisfaction. I am eager to contribute to the success of the team and grow in this role.
                             </button>                                         
                            </div>
                        }
            </div>
          </div>
          <div className="flex justify-between ">
            {/* <button className="border border-[#E94B60] rounded-xl px-4 py-3" onClick={()=>{setOpenObj(false)}}>Cancel</button> */}
            <button
              className="rounded-xl px-4 py-3 bg-[#E94B60] text-white"
              onClick={() => {
                setOpenObj(false);
              }}
            >
              Save
            </button>
          </div>
        </div>
      )}
      {openSkills && (
        <div>
          <div className="fixed w-full lg:w-[50rem] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white rounded-xl z-30 backdrop-blur-xl p-4">
            <div className="flex justify-between items-center w-full ">
              <p className="invisible">a</p>
              <p className="font-poppins ">Skills</p>
              <button
                onClick={() => {
                  setOpenSkills(false);
                }}
              >
                x
              </button>
            </div>
            <div className="h-[0.1rem] w-full bg-[#0804fc] my-4"></div>
            <div className="lg:flex space-x-4 ">
              <div className="lg:w-1/2">
                <div class="relative overflow-y-scroll h-[13rem] scrollbar-hide  mb-4 lg:mb-0 ">
                  {skills.length === 0 ? (
                    <div>
                      Choose at max 10 skills from examples tab or add
                      additional skills below
                    </div>
                  ) : (
                    skills.map((s) => {
                      return <p>{s},</p>;
                    })
                  )}
                  <div class="relative mt-5 mb-4 lg:mb-0 ">
                    <textarea
                      type="text"
                      rows={3}
                      required
                      value={extra}
                      onChange={(e) => {
                        setExtra(e.target.value);
                      }}
                      id="floating_outlined"
                      className=" px-2.5 border-2 pb-2.5  pt-4 w-full  text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=" "
                    />
                    <label
                      for="floating_outlined"
                      className="absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 font-poppins"
                    >
                      Write additional skills here
                    </label>
                  </div>
                </div>
              </div>
              <div className="lg:w-1/2 lg:mb-0 mb-4">
                <div className="flex space-x-5 mb-3">
                  <button
                    onClick={() => {
                      setTab(0);
                    }}
                  >
                    <p className={tab == 0 ? "font-bold text-red-500" : ""}>
                      Tips
                    </p>
                  </button>
                  <button
                    onClick={() => {
                      setTab(1);
                    }}
                  >
                    <p className={tab == 1 ? "font-bold text-red-500" : ""}>
                      Examples
                    </p>
                  </button>
                </div>
                {tab == 0 && (
                  <div className="overflow-y-scroll h-[13rem] scrollbar-hide p-3 bg-gray-100 rounded-xl text-sm">
                    {/* <p className='mb-4'>Writing an objective statement in a resume is all about communicating your career goals and what you hope to achieve in your job. Here are some simple steps to follow when writing an objective statement:</p> */}
                    <p className="mb-4">
                      1. Identify your skills: Make a list of all the skills you
                      possess that are relevant to the job you're applying for.
                      This can include technical skills, such as proficiency in
                      a certain software program or language, as well as soft
                      skills, such as teamwork, communication, or
                      problem-solving.
                    </p>
                    <p className="mb-4">
                      2. Prioritize your skills: Once you've identified all of
                      your skills, prioritize them based on the job requirements
                      and your level of proficiency. Put the skills most
                      relevant to the job and those that you're strongest in at
                      the top of the list.
                    </p>
                    <p className="mb-4">
                      3. Use bullet points: Use bullet points to organize your
                      skills in a clear and easy-to-read format. Each bullet
                      point should start with an action verb and be concise and
                      specific.
                    </p>
                    <p className="mb-4">
                      4. Be honest: Only include skills that you actually
                      possess and can demonstrate in the workplace. Don't
                      exaggerate your abilities or list skills that you don't
                      actually have.
                    </p>
                    <p>
                      Be honest: Only include skills that you actually possess
                      and can demonstrate in the workplace. Don't exaggerate
                      your abilities or list skills that you don't actually
                      have.
                    </p>
                    <p>
                      By following these simple steps, you can create a skills
                      section that effectively showcases your abilities and
                      makes you stand out as a strong candidate for the job.
                    </p>
                  </div>
                )}
                {tab == 1 && (
                  <div className="flex flex-col mt-2 space-y-4 overflow-y-scroll h-[13rem] scrollbar-hide p-3 rounded-xl">
                    <div className="p-4 shadow-xl rounded-xl">
                      <p className="mb-4">Generic Skills</p>
                      <div className="">
                        {gs.map((s) => {
                          return (
                            <button
                              className={
                                skills.includes(s)
                                  ? "px-2 py-1 rounded-xl m-1 text-white bg-green-500 shadow-xl"
                                  : "px-2 py-1 rounded-xl text-white m-1 bg-red-500 shadow-xl"
                              }
                              onClick={() => {
                                if (skills.includes(s)) {
                                  skills.filter((e, i) => {
                                    if (e === s) {
                                      skills.splice(i, 1);
                                      return true;
                                    }
                                  });
                                  setSkills([...skills]);
                                } else {
                                  setSkills([...skills, s]);
                                }
                              }}
                            >
                              {s}
                            </button>
                          );
                        })}
                      </div>
                    </div>
                    <div className="p-4 shadow-xl rounded-xl">
                      <p className="mb-4">Operational Skills</p>
                      <div className="">
                        {os.map((s) => {
                          return (
                            <button
                              className={
                                skills.includes(s)
                                  ? "px-2 py-1 rounded-xl m-1 text-white bg-green-500 shadow-xl"
                                  : "px-2 py-1 rounded-xl text-white m-1 bg-red-500 shadow-xl"
                              }
                              onClick={() => {
                                if (skills.includes(s)) {
                                  skills.filter((e, i) => {
                                    if (e === s) {
                                      skills.splice(i, 1);
                                      return true;
                                    }
                                  });
                                  setSkills([...skills]);
                                } else if (skills.length < 10) {
                                  setSkills([...skills, s]);
                                }
                              }}
                            >
                              {s}
                            </button>
                          );
                        })}
                      </div>
                    </div>
                    <div className="p-4 shadow-xl rounded-xl">
                      <p className="mb-4">Sales Skills</p>
                      <div className="">
                        {sales.map((s) => {
                          return (
                            <button
                              className={
                                skills.includes(s)
                                  ? "px-2 py-1 rounded-xl m-1 text-white bg-green-500 shadow-xl"
                                  : "px-2 py-1 rounded-xl text-white m-1 bg-red-500 shadow-xl"
                              }
                              onClick={() => {
                                if (skills.includes(s)) {
                                  skills.filter((e, i) => {
                                    if (e === s) {
                                      skills.splice(i, 1);
                                      return true;
                                    }
                                  });
                                  setSkills([...skills]);
                                } else if (skills.length < 10) {
                                  setSkills([...skills, s]);
                                }
                              }}
                            >
                              {s}
                            </button>
                          );
                        })}
                      </div>
                    </div>
                    <div className="p-4 shadow-xl rounded-xl">
                      <p className="mb-4">Human Resource Skills</p>
                      <div className="">
                        {hr.map((s) => {
                          return (
                            <button
                              className={
                                skills.includes(s)
                                  ? "px-2 py-1 rounded-xl m-1 text-white bg-green-500 shadow-xl"
                                  : "px-2 py-1 rounded-xl text-white m-1 bg-red-500 shadow-xl"
                              }
                              onClick={() => {
                                if (skills.includes(s)) {
                                  skills.filter((e, i) => {
                                    if (e === s) {
                                      skills.splice(i, 1);
                                      return true;
                                    }
                                  });
                                  setSkills([...skills]);
                                } else if (skills.length < 10) {
                                  setSkills([...skills, s]);
                                }
                              }}
                            >
                              {s}
                            </button>
                          );
                        })}
                      </div>
                    </div>
                    <div className="p-4 shadow-xl rounded-xl">
                      <p className="mb-4">Leadership Skills</p>
                      <div className="">
                        {leadership.map((s) => {
                          return (
                            <button
                              className={
                                skills.includes(s)
                                  ? "px-2 py-1 rounded-xl m-1 text-white bg-green-500 shadow-xl"
                                  : "px-2 py-1 rounded-xl text-white m-1 bg-red-500 shadow-xl"
                              }
                              onClick={() => {
                                if (skills.includes(s)) {
                                  skills.filter((e, i) => {
                                    if (e === s) {
                                      skills.splice(i, 1);
                                      return true;
                                    }
                                  });
                                  setSkills([...skills]);
                                } else if (skills.length < 10) {
                                  setSkills([...skills, s]);
                                }
                              }}
                            >
                              {s}
                            </button>
                          );
                        })}
                      </div>
                    </div>
                    <div className="p-4 shadow-xl rounded-xl">
                      <p className="mb-4">Finance Skills</p>
                      <div className="">
                        {finance.map((s) => {
                          return (
                            <button
                              className={
                                skills.includes(s)
                                  ? "px-2 py-1 rounded-xl m-1 text-white bg-green-500 shadow-xl"
                                  : "px-2 py-1 rounded-xl text-white m-1 bg-red-500 shadow-xl"
                              }
                              onClick={() => {
                                if (skills.includes(s)) {
                                  skills.filter((e, i) => {
                                    if (e === s) {
                                      skills.splice(i, 1);
                                      return true;
                                    }
                                  });
                                  setSkills([...skills]);
                                } else if (skills.length < 10) {
                                  setSkills([...skills, s]);
                                }
                              }}
                            >
                              {s}
                            </button>
                          );
                        })}
                      </div>
                    </div>
                    <div className="p-4 shadow-xl rounded-xl">
                      <p className="mb-4">Technological Skills</p>
                      <div className="">
                        {tech.map((s) => {
                          return (
                            <button
                              className={
                                skills.includes(s)
                                  ? "px-2 py-1 rounded-xl m-1 text-white bg-green-500 shadow-xl"
                                  : "px-2 py-1 rounded-xl text-white m-1 bg-red-500 shadow-xl"
                              }
                              onClick={() => {
                                if (skills.includes(s)) {
                                  skills.filter((e, i) => {
                                    if (e === s) {
                                      skills.splice(i, 1);
                                      return true;
                                    }
                                  });
                                  setSkills([...skills]);
                                } else if (skills.length < 10) {
                                  setSkills([...skills, s]);
                                }
                              }}
                            >
                              {s}
                            </button>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="flex justify-between ">
              {/* <button className="border border-[#E94B60] rounded-xl px-4 py-3" onClick={()=>{setOpenSkills(false)}}>Save</button> */}
              <button
                className="rounded-xl px-4 py-3 bg-[#E94B60] text-white"
                onClick={() => {
                  if (!skills.includes(extra)) {
                    skills.push(extra);
                  }
                  setOpenSkills(false);
                }}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}
      {openEdu && (
        <div className="fixed h-screen lg:h-auto w-full lg:w-[50rem] top-1/2 left-1/2  -translate-x-1/2 -translate-y-1/2 bg-white rounded-xl z-30 backdrop-blur-xl p-4">
          <div className="flex justify-between items-center w-full ">
            <p className="invisible">a</p>
            <p className="font-poppins ">Education</p>
            <button
              onClick={() => {
                setOpenEdu(false);
              }}
            >
              x
            </button>
          </div>
          <div className="h-[0.1rem] w-full bg-[#0804fc] my-4"></div>
          <div className="lg:flex space-x-3 lg:w-[48rem]">
            <div className="flex flex-col space-y-3">
              <div className="lg:flex w-full lg:space-x-3">
                <div class="relative mb-4 lg:mb-0 lg:w-1/2">
                  <input
                    value={scname}
                    onChange={(e) => setScname(e.target.value)}
                    type="text"
                    id="floating_outlined"
                    className=" px-2.5 border-2 pb-2.5 pt-4 w-full  text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                  />
                  <label
                    for="floating_outlined"
                    className="absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 font-poppins"
                  >
                    School Name
                  </label>
                </div>
                <div class="relative lg:w-1/2">
                  <input
                    value={fos}
                    onChange={(e) => setFos(e.target.value)}
                    type="text"
                    id="floating_outlined"
                    className=" px-2.5 border-2 pb-2.5 pt-4 w-full  text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                  />
                  <label
                    for="floating_outlined"
                    className="absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 font-poppins"
                  >
                    Specialization
                  </label>
                </div>
              </div>

              <div class="relative  mb-4 lg:mb-0 lg:w-[30rem]">
                <input
                  value={degree}
                  onChange={(e) => setDegree(e.target.value)}
                  type="text"
                  id="floating_outlined"
                  className=" px-2.5 border-2 pb-2.5 pt-4 w-full  text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                />
                <label
                  for="floating_outlined"
                  className="absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 font-poppins"
                >
                  Degree
                </label>
              </div>

              <div class="relative  mb-4 lg:mb-0 lg:w-[30rem]">
                <input
                  value={gpa}
                  onChange={(e) => {
                    setGpa(e.target.value);
                  }}
                  type="text"
                  required
                  id="floating_outlined"
                  className=" px-2.5 border-2 pb-2.5  pt-4 w-full  text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                />
                <label
                  for="floating_outlined"
                  className="absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 font-poppins"
                >
                  GPA / Percentage
                </label>
              </div>

              <div class="relative  mb-4 lg:mb-0 lg:w-[30rem]">
                <textarea
                  value={activities}
                  onChange={(e) => {
                    setActivities(e.target.value);
                  }}
                  type="text"
                  rows={5}
                  required
                  id="floating_outlined"
                  className=" px-2.5 border-2 pb-2.5  pt-4 w-full  text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                />
                <label
                  for="floating_outlined"
                  className="absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 font-poppins"
                >
                  Acheivements
                </label>
              </div>
            </div>
            <div>
              <div className="flex space-x-3 mb-3">
                <p className="font-bold">Tips</p>
              </div>
              <div className="overflow-y-scroll h-[13rem] scrollbar-hide p-3 bg-gray-100 rounded-xl text-sm">
                <p>
                  Enter your educational qualifications in a reverse order here.
                  Start with the latest Degree/Diploma or qualification and go
                  backwards covering each of your course detail.
                </p>
              </div>
            </div>
          </div>
          <div className="h-[0.1rem] w-full bg-[#0804fc] my-4"></div>

          <div className="flex justify-between ">
            <button
              className="border border-[#E94B60] rounded-xl px-4 py-3"
              onClick={edusave}
            >
              Add more
            </button>
            <button
              className="rounded-xl px-4 py-3 bg-[#E94B60] text-white"
              onClick={() => {
                edusave();
                setOpenEdu(false);
              }}
            >
              Save
            </button>
          </div>
        </div>
      )}
      {openProj && (
        <div>
          <div className="fixed h-screen lg:h-auto w-full lg:w-[50rem] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white rounded-xl z-30 backdrop-blur-xl p-4">
            <div className="flex justify-between items-center w-full ">
              <p className="invisible">a</p>
              <p className="font-poppins ">Projects</p>
              <button
                onClick={() => {
                  setOpenProj(false);
                }}
              >
                x
              </button>
            </div>

            <div className="h-[0.1rem] w-full bg-[#0804fc] my-4"></div>
            <div className="lg:flex lg:space-x-4 ">
              <div className="flex flex-col  space-y-3">
                <div className="relative  mb-10 lg:mb-0 lg:w-[30rem]">
                  <input
                    type="text"
                    value={pjname}
                    onChange={(e) => {
                      setPjname(e.target.value);
                    }}
                    required
                    id="floating_outlined"
                    className=" px-2.5 border-2 pb-2.5  pt-4 w-full  text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                  ></input>
                  <label
                    for="floating_outlined"
                    className="absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75  top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 font-poppins"
                  >
                    Project Name
                  </label>
                </div>
                <div className="relative  mb-4 lg:mb-0 lg:w-[30rem]">
                  <textarea
                    type="text"
                    value={projdesc}
                    onChange={(e) => {
                      setProjDesc(e.target.value);
                    }}
                    rows={10}
                    required
                    id="floating_outlined"
                    className=" px-2.5 border-2 pb-2.5  pt-4 w-full  text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                  />
                  <label
                    for="floating_outlined"
                    className="absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 font-poppins"
                  >
                    Project Description
                  </label>
                </div>
              </div>
              <div>
                <div className="flex space-x-3">
                  <p className="font-bold">Tips</p>
                  {/* <p className='invisible'>Examples</p> */}
                </div>
                <div className="overflow-scroll h-[13rem] scrollbar-hide p-3 bg-gray-100 rounded-xl text-sm">
                  <p className="mb-4">
                    1. Choose relevant projects: Only include projects that are
                    relevant to the job you are applying for. Choose projects
                    that showcase your skills and experience in a particular
                    field.
                  </p>
                  <p className="mb-4">
                    2. Provide a brief summary: Provide a brief summary of each
                    project that you include in your resume. This should include
                    the project’s purpose, your role, and the tools and
                    technologies used.
                  </p>
                  <p className="mb-4">
                    3. Use bullet points: Use bullet points to highlight the key
                    aspects of each project. This will make it easier for hiring
                    managers to quickly scan your resume and understand your
                    experience.
                  </p>
                  <p className="mb-4">
                    4. Quantify your results: If possible, quantify the results
                    of each project. For example, if you were part of a team
                    that increased sales by 20%, be sure to mention this in your
                    resume.
                  </p>
                  <p className="mb-4">
                    5. Show your problem-solving skills: Be sure to highlight
                    how you solved problems and overcame challenges during each
                    project. This will demonstrate your problem-solving skills
                    to potential employers.
                  </p>
                  <p className="mb-4">
                    6. Use action words: Use action words to describe your
                    involvement in each project. For example, use words like
                    “designed,” “developed,” “implemented,” and “managed.”
                  </p>
                  <p className="mb-4">
                    7. Include a link to your portfolio: If you have an online
                    portfolio that showcases your projects in more detail, be
                    sure to include a link in your resume. This will give hiring
                    managers a more comprehensive understanding of your work.
                  </p>
                  <p className="">
                    Remember, the goal of your projects section is to
                    demonstrate your skills and experience to potential
                    employers. By following these tips, you can create a strong
                    projects section that highlights your expertise and sets you
                    apart from other candidates.
                  </p>
                </div>
              </div>
            </div>
            <div className="flex justify-between ">
              <button
                className="border border-[#E94B60] rounded-xl px-4 py-3"
                onClick={projsave}
              >
                Add Another
              </button>
              <button
                className="rounded-xl px-4 py-3 bg-[#E94B60] text-white"
                onClick={() => {
                  projsave();
                  setOpenProj(false);
                }}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}
      {openCerts && (
        <div>
          <div className="fixed w-full lg:w-[50rem] h-screen lg:h-auto top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white rounded-xl z-30 backdrop-blur-xl p-4">
            <div className="flex justify-between items-center w-full ">
              <p className="invisible">a</p>
              <p className="font-poppins ">Certificates</p>
              <button
                onClick={() => {
                  setOpenCerts(false);
                }}
              >
                x
              </button>
            </div>

            <div className="h-[0.1rem] w-full bg-[#0804fc] my-4"></div>
            <div className="lg:flex space-x-4 ">
              <div className="flex flex-col space-y-3">
                <div className="relative  mb-3 lg:mb-0 lg:w-[30rem]">
                  <input
                    type="text"
                    value={certificateName}
                    onChange={(e) => {
                      setCertificateName(e.target.value);
                    }}
                    required
                    id="floating_outlined"
                    className=" px-2.5 border-2 pb-2.5  pt-4 w-full  text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                  ></input>
                  <label
                    for="floating_outlined"
                    className="absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75  top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 font-poppins"
                  >
                    Certificate Name
                  </label>
                </div>
                <div class="relative  mb-4 lg:mb-0 lg:w-[30rem]">
                  <textarea
                    type="text"
                    value={certificateDesc}
                    onChange={(e) => {
                      setCertificateDesc(e.target.value);
                    }}
                    rows={10}
                    required
                    id="floating_outlined"
                    className=" px-2.5 border-2 pb-2.5  pt-4 w-full  text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                  />
                  <label
                    for="floating_outlined"
                    className="absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 font-poppins"
                  >
                    Certificate Description
                  </label>
                </div>
              </div>
              <div>
                <div className="flex space-x-3">
                  <p className="font-bold">Tips</p>
                  {/* <p className='invisible'>Examples</p> */}
                </div>
                <div className="overflow-y-scroll h-[13rem] scrollbar-hide p-3 bg-gray-100 rounded-xl text-sm">
                  <p className="mb-4">
                    1. Choose relevant certifications: Only include
                    certifications that are relevant to the job you are applying
                    for. Choose certifications that showcase your skills and
                    experience in a particular field.
                  </p>
                  <p className="mb-4">
                    2. Provide a brief summary: Provide a brief summary of each
                    certification that you include in your resume. This should
                    include the certification name, the date you obtained it,
                    and the issuing organization.
                  </p>
                  <p className="mb-4">
                    3. Use bullet points: Use bullet points to highlight the key
                    aspects of each certification. This will make it easier for
                    hiring managers to quickly scan your resume and understand
                    your qualifications.
                  </p>
                  \
                  <p className="mb-4">
                    4. Mention the skills you gained: Be sure to mention the
                    skills you gained through each certification. For example,
                    if you obtained a certification in project management, be
                    sure to mention that you have skills in planning,
                    organizing, and executing projects.
                  </p>
                  <p className="mb-4">
                    5. Show your commitment to learning: Including
                    certifications in your resume shows that you are committed
                    to continuous learning and professional development. Be sure
                    to highlight this in your resume
                  </p>
                  <p className="mb-4">
                    6. List in reverse chronological order: List your
                    certifications in reverse chronological order, starting with
                    the most recent one first. This will ensure that hiring
                    managers see your most recent qualifications first.
                  </p>
                  <p className="mb-4">
                    7. Include the certification number: If applicable, include
                    the certification number in your resume. This can help
                    hiring managers verify your qualifications.
                  </p>
                  <p>
                    Remember, the goal of your certifications section is to
                    demonstrate your skills and qualifications to potential
                    employers. By following these tips, you can create a strong
                    certifications section that highlights your expertise and
                    sets you apart from other candidates.
                  </p>
                </div>
                <div></div>
              </div>
            </div>
            <div className="flex justify-between ">
              <button
                className="border border-[#E94B60] rounded-xl px-4 py-3"
                onClick={certsave}
              >
                Add Another
              </button>
              <button
                className="rounded-xl px-4 py-3 bg-[#E94B60] text-white"
                onClick={() => {
                  certsave();
                  setOpenCerts(false);
                }}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}
      {openLanguages && (
        <div>
          <div className="fixed w-full lg:w-[50rem] h-screen lg:h-auto top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white rounded-xl z-30 backdrop-blur-xl p-4">
            <div className="flex justify-between items-center w-full ">
              <p className="invisible">a</p>
              <p className="font-poppins ">Languages</p>
              <button
                onClick={() => {
                  setOpenLanguages(false);
                }}
              >
                x
              </button>
            </div>

            <div className="h-[0.1rem] w-full bg-[#0804fc] my-4"></div>
            <div className=" ">
              <div className="lg:grid space-y-3 lg:space-y-0 lg:grid-cols-3 gap-4">
                <div class="relative  mb-4 lg:mb-0 w-full">
                  <textarea
                    type="text"
                    value={native}
                    onChange={(e) => {
                      setNative(e.target.value);
                    }}
                    rows={10}
                    required
                    id="floating_outlined"
                    className=" px-2.5 border-2 pb-2.5  pt-4 w-full  text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                  />
                  <label
                    for="floating_outlined"
                    className="absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 font-poppins"
                  >
                    Native Languages
                  </label>
                </div>
                <div class="relative  mb-4 lg:mb-0 w-full">
                  <textarea
                    type="text"
                    value={fluent}
                    onChange={(e) => {
                      setFluent(e.target.value);
                    }}
                    rows={10}
                    required
                    id="floating_outlined"
                    className=" px-2.5 border-2 pb-2.5  pt-4 w-full  text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                  />
                  <label
                    for="floating_outlined"
                    className="absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 font-poppins"
                  >
                    Fluent Languages
                  </label>
                </div>
                <div class="relative  mb-4 lg:mb-0 w-full">
                  <textarea
                    type="text"
                    value={conversational}
                    onChange={(e) => {
                      setConversational(e.target.value);
                    }}
                    rows={10}
                    required
                    id="floating_outlined"
                    className=" px-2.5 border-2 pb-2.5  pt-4 w-full  text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                  />
                  <label
                    for="floating_outlined"
                    className="absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 font-poppins"
                  >
                    Conversational Languages
                  </label>
                </div>
              </div>
            </div>

            <div className="h-[0.1rem] w-full bg-[#0804fc] my-4"></div>
            <div className="flex justify-end ">
              {/* <button className="border border-[#E94B60] rounded-xl px-4 py-3" onClick={()=>{setOpenExp(false)}}>Cancel</button> */}
              <button
                className="rounded-xl px-4 py-3 bg-[#E94B60] text-white"
                onClick={() => {
                  setOpenLanguages(false);
                }}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}
      {openAwards && (
        <div>
          <div className="fixed lg:w-[50rem] w-full h-screen lg:h-auto top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white rounded-xl z-30 backdrop-blur-xl p-4">
            <div className="flex justify-between items-center w-full ">
              <p className="invisible">a</p>
              <p className="font-poppins ">Awards</p>
              <button
                onClick={() => {
                  setOpenAwards(false);
                }}
              >
                x
              </button>
            </div>

            <div className="h-[0.1rem] w-full bg-[#0804fc] my-4"></div>
            <div className="lg:flex space-x-4 ">
              <div className="flex flex-col space-y-3">
                <div className="relative  mb-3 lg:mb-0 lg:w-[30rem]">
                  <input
                    type="text"
                    value={awardName}
                    onChange={(e) => {
                      setAwardName(e.target.value);
                    }}
                    required
                    id="floating_outlined"
                    className=" px-2.5 border-2 pb-2.5  pt-4 w-full  text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                  ></input>
                  <label
                    for="floating_outlined"
                    className="absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75  top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 font-poppins"
                  >
                    Award Name
                  </label>
                </div>
                <div class="relative  mb-4 lg:mb-0 lg:w-[30rem]">
                  <textarea
                    type="text"
                    value={awardDesc}
                    onChange={(e) => {
                      setAwardDesc(e.target.value);
                    }}
                    rows={10}
                    required
                    id="floating_outlined"
                    className=" px-2.5 border-2 pb-2.5  pt-4 w-full  text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                  />
                  <label
                    for="floating_outlined"
                    className="absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 font-poppins"
                  >
                    Award Description
                  </label>
                </div>
              </div>
              <div>
                <div className="flex space-x-3">
                  <p className="font-bold">Tips</p>
                  {/* <p className='invisible'>Examples</p> */}
                </div>
                <div className="overflow-y-scroll h-[13rem] scrollbar-hide p-3 bg-gray-100 rounded-xl text-sm">
                  <p className="mb-4">
                    1. Choose relevant awards: Only include awards that are
                    relevant to the job you are applying for. Choose awards that
                    showcase your skills and experience in a particular field.
                  </p>
                  <p className="mb-4">
                    2. Provide a brief summary: Provide a brief summary of each
                    award that you include in your resume. This should include
                    the name of the award, the date it was awarded, and the
                    organization that awarded it.
                  </p>
                  <p className="mb-4">
                    3. Use bullet points: Use bullet points to highlight the key
                    aspects of each award. This will make it easier for hiring
                    managers to quickly scan your resume and understand your
                    accomplishments.
                  </p>
                  <p className="mb-4">
                    4. Quantify your results: If possible, quantify the results
                    of the award. For example, if you won an award for
                    increasing sales by 20%, be sure to mention this in your
                    resume.
                  </p>
                  <p className="mb-4">
                    5. Show your unique qualities: Awards often reflect unique
                    qualities or characteristics of the recipient, such as
                    leadership, teamwork, creativity, or problem-solving skills.
                    Be sure to highlight how the award reflects your unique
                    qualities.
                  </p>
                  <p className="mb-4">
                    6. List in reverse chronological order: List your awards in
                    reverse chronological order, starting with the most recent
                    one first. This will ensure that hiring managers see your
                    most recent accomplishments first.
                  </p>
                  <p className="mb-4">
                    7. Don't go overboard: While it's great to have received
                    recognition for your accomplishments, be careful not to go
                    overboard with the awards section. Only include awards that
                    are relevant and showcase your skills and experience.
                  </p>
                  <p>
                    Remember, the goal of your awards section is to demonstrate
                    your accomplishments and unique qualities to potential
                    employers. By following these tips, you can create a strong
                    awards section that highlights your expertise and sets you
                    apart from other candidates.
                  </p>
                </div>
                <div></div>
              </div>
            </div>
            <div className="flex justify-between ">
              <button
                className="border border-[#E94B60] rounded-xl px-4 py-3"
                onClick={awardsave}
              >
                Cancel
              </button>
              <button
                className="rounded-xl px-4 py-3 bg-[#E94B60] text-white"
                onClick={() => {
                  awardsave();
                  setOpenAwards(false);
                }}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}
      {openexp && (
        <div className="fixed top-1/2 left-1/2 h-screen lg:h-auto w-full lg:w-[50rem]  -translate-x-1/2 -translate-y-1/2 bg-white rounded-xl z-30 backdrop-blur-xl p-4">
          <div className="flex justify-between items-center w-full ">
            <p className="invisible">a</p>
            <p className="font-poppins ">Experience</p>
            <button
              onClick={() => {
                setOpenExp(false);
              }}
            >
              x
            </button>
          </div>
          <div className="h-[0.1rem] w-full bg-[#0804fc] my-4"></div>
          <div className="lg:flex space-x-3 ">
            <div className="flex flex-col space-y-3">
              <div className="lg:flex w-full lg:space-x-3">
                <div class="relative mb-4 lg:mb-0 lg:w-1/2">
                  <input
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                    type="text"
                    id="floating_outlined"
                    className=" px-2.5 border-2 pb-2.5 pt-4 w-full  text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                  />
                  <label
                    for="floating_outlined"
                    className="absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 font-poppins"
                  >
                    Company Name
                  </label>
                </div>
                <div class="relative lg:w-1/2">
                  <input
                    value={role}
                    onChange={(e) => setRole(e.target.value)}
                    type="text"
                    id="floating_outlined"
                    className=" px-2.5 border-2 pb-2.5 pt-4 w-full  text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                  />
                  <label
                    for="floating_outlined"
                    className="absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 font-poppins"
                  >
                    Designation/role
                  </label>
                </div>
              </div>
              <div className="lg:flex w-full lg:space-x-3">
                <div class="relative lg:w-1/2">
                  <input
                    type="month"
                    value={sdate}
                    onChange={(e) => setSdate(e.target.value)}
                    id="floating_outlined"
                    className=" px-2.5 border-2 pb-2.5 pt-4 w-full  text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                  />
                  <label
                    for="floating_outlined"
                    className="absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 font-poppins"
                  >
                    Start Date
                  </label>
                </div>

                {!check && (
                  <div class="relative lg:w-1/2">
                    <input
                      type="month"
                      value={edate}
                      onChange={(e) => setEdate(e.target.value)}
                      id="floating_outlined"
                      className=" px-2.5 border-2 pb-2.5 pt-4 w-full  text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=" "
                    />
                    <label
                      for="floating_outlined"
                      className="absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 font-poppins"
                    >
                      End Date
                    </label>
                  </div>
                )}
              </div>

              <div className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  value={check}
                  onChange={(e) => {
                    setCheck(e.target.checked);
                    setEdate("Present");
                  }}
                ></input>
                <p>I currently work here</p>
              </div>

              <div class="relative  mb-4 lg:mb-0 lg:w-[30rem]">
                <textarea
                  value={desc}
                  onChange={(e) => {
                    setDesc(e.target.value);
                  }}
                  type="text"
                  rows={5}
                  required
                  id="floating_outlined"
                  className=" px-2.5 border-2 pb-2.5  pt-4 w-full  text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                />
                <label
                  for="floating_outlined"
                  className="absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 font-poppins"
                >
                  Work Description
                </label>
              </div>
            </div>
            <div>
              <div className="flex space-x-3 mb-3">
                <p className="font-bold">Tips</p>
              </div>
              <div className="overflow-y-scroll h-[13rem] scrollbar-hide p-3 bg-gray-100 rounded-xl text-sm">
                <p>
                  Enter your experience in a reverse order here. Start with the
                  latest place that you have worked at and then go
                  chronologically backwards towards your first job. Be sure to
                  add any exemplary performances, achievement, market knowledge,
                  technical knowledge etc. that will showcase the value your
                  candidature to the interviewers organisation
                </p>
              </div>
            </div>
          </div>
          <div className="h-[0.1rem] w-full bg-[#0804fc] my-4"></div>

          <div className="flex justify-between ">
            <button
              className="border border-[#E94B60] rounded-xl px-4 py-3"
              onClick={expsave}
            >
              Add more
            </button>
            <button
              className="rounded-xl px-4 py-3 bg-[#E94B60] text-white"
              onClick={() => {
                expsave();
                setOpenExp(false);
              }}
            >
              Save
            </button>
          </div>
        </div>
      )}
      {openHobbies && (
        <div>
          <div className="fixed w-full lg:w-[50rem] h-screen lg:h-auto top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white rounded-xl z-30 backdrop-blur-xl p-4">
            <div className="flex justify-between items-center w-full ">
              <p className="invisible">a</p>
              <p className="font-poppins ">Hobbies</p>
              <button
                onClick={() => {
                  setOpenHobbies(false);
                }}
              >
                x
              </button>
            </div>

            <div className="h-[0.1rem] w-full bg-[#0804fc] my-4"></div>
            <div className="lg:flex space-x-4 lg:w-[50rem]">
              <div className="">
                <div class="relative  mb-4 lg:mb-0 lg:w-[25rem]">
                  <textarea
                    type="text"
                    rows={10}
                    required
                    value={hobbies}
                    onChange={(e) => {
                      setHobbies(e.target.value);
                    }}
                    id="floating_outlined"
                    className=" px-2.5 border-2 pb-2.5  pt-4 w-full  text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                  />
                  <label
                    for="floating_outlined"
                    className="absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 font-poppins"
                  >
                    What are the skills you would like to include?
                  </label>
                </div>
              </div>
              <div className="lg:w-1/2">
                <div className="flex space-x-3 ">
                  <p className="font-bold">Tips</p>
                </div>
                <div className="overflow-y-scroll h-[13rem] scrollbar-hide p-3 bg-gray-100 rounded-xl text-sm">
                  <p className="mb-4">
                    Including your areas of interest or hobbies in your resume
                    can help to showcase your personality, passions, and unique
                    qualities to potential employers. Here are some simple steps
                    to follow when writing about your interests or hobbies in
                    your resume:
                  </p>
                  <p className="mb-4">
                    1. Choose relevant interests: Select interests or hobbies
                    that are relevant to the job or industry you're applying
                    for. For example, if you're applying for a job in marketing,
                    you could include interests such as writing or social media.
                  </p>
                  <p className="mb-4">
                    2. Use bullet points: Organize your interests or hobbies in
                    a clear and easy-to-read format, using bullet points. Each
                    bullet point should describe a specific hobby or interest
                    and be concise and specific
                  </p>
                  <p className="mb-4">
                    3. Highlight transferable skills: Consider how your hobbies
                    or interests have helped you develop skills that are
                    transferable to the workplace. For example, if you enjoy
                    playing team sports, you could highlight your teamwork and
                    communication skills.
                  </p>
                  <p className="mb-4">
                    4. Avoid controversial topics: Avoid including any hobbies
                    or interests that may be controversial or offensive, such as
                    political or religious affiliations.
                  </p>
                  <p className="mb-4">
                    5. Be truthful: Only include hobbies or interests that you
                    actually enjoy and can talk about genuinely in an interview.
                    Don't list hobbies or interests just because you think they
                    will impress an employer.
                  </p>
                  <p className="mb-4">
                    6. By following these simple steps, you can effectively
                    highlight your areas of interest or hobbies in your resume,
                    demonstrating your personality and unique qualities to
                    potential employers.
                  </p>
                </div>
              </div>
            </div>
            <div className="flex justify-between ">
              <button
                className="border border-[#E94B60] rounded-xl px-4 py-3"
                onClick={() => {
                  setOpenHobbies(false);
                }}
              >
                Cancel
              </button>
              <button
                className="rounded-xl px-4 py-3 bg-[#E94B60] text-white"
                onClick={() => {
                  setOpenHobbies(false);
                }}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Template6;

import React from 'react'
import Rajiv from '../images/Rajiv.png'
import {FaLinkedinIn} from 'react-icons/fa'
import Avinash from '../images/Avinash.png'
import Sonal from '../images/Sonal.jpeg'
import Saaransh from '../images/Saaransh.jpeg'
import Rup from '../images/Rup.jpeg'
import Shalini from '../images/Shalini.png'
import Chintan from '../images/Chintan.png'
import Mansi from '../images/Mansi.jpeg'
import ReactGA from 'react-ga'
import Maya from '../images/Maya.JPG'
import Swaraj from '../images/swaraj.jpg'

const About = () => {
    React.useEffect(()=>{
        ReactGA.pageview(window.location.pathname + window.location.search)
      },[])
      
  return (
    <div>
        <p className="font-serif text-3xl lg:text-5xl text-center mt-16 mb-4">About Us</p>
        <p className="text-center text-xl">Finding inspiration on every turn</p>
        <div className="lg:w-2/4 mx-4 lg:mx-auto text-center  lg:text-xl space-y-6">
            {/* <p className="mt-5">Interview Vision provides candidates key insights on top soft skills evaluated by recruiters from leading organizations when hiring for new roles. We use machine Learning and AI to evaluate and assess our users on key soft skills.</p> */}
            <p className='mt-5'>Our purpose is to help millions of candidates succeed in their early career years. We help candidates improve themselves on key skills which help them establish themselves as contributors and achievers. Interview Vision offers employees and jobseekers proprietary video learning material and personalised coaching to help them improve essential skills which are valued highly by organisations. Additionally, our AI-based evaluation platform allows candidates to understand their strengths and weaknesses in these key skills and focus their learning on the areas which need more assistance. All this ensures that employees achieve success in interviews and  shine in their early career days!</p>
        </div>
        <p className="font-serif text-3xl lg:text-5xl text-center mt-16 mb-4">Founding Team</p>
        <div className="lg:grid lg:grid-cols-3 my-10 mx-4 flex flex-col lg:mx-20 gap-8 text-[#34445c] text-xl font-serif">
        <div className="mx-auto">
                <img src={Avinash} className="h-[17rem]"></img>
                <div className="flex items-center  justify-between">
                    <p className="my-3 ">Avinash V Singh</p>
                    <a href="https://www.linkedin.com/in/avinash-v-singh-678a6a9" target="_blank">
                    <FaLinkedinIn></FaLinkedinIn>
                    </a>
                </div>
                <p>Business Operations & Marketing</p>
            </div>
            <div className="mx-auto">
                <img src={Rajiv} className="h-[17rem]"></img>
                <div className="flex items-center  justify-between">
                    <p className="my-3 ">Rajiv Ranjan</p>
                    <a href="https://www.linkedin.com/in/13rajivranjan" target="_blank">
                    <FaLinkedinIn></FaLinkedinIn>
                    </a>
                </div>
                <p>Business Operations & <br></br> Subject Matter Expertise</p>
            </div>

            <div className="mx-auto">
                <img src={Maya} className="h-[17rem]"></img>
                <div className="flex items-center  justify-between">
                    <p className="my-3 ">Maya Mookherjee</p>
                    <a href="https://www.linkedin.com/in/maya-mookherjee-9b680811/" target="_blank">
                    <FaLinkedinIn></FaLinkedinIn>
                    </a>
                </div>
                <p>Learning Management</p>
            </div>

            
            <div className="mx-auto ">
                <img src={Shalini} className="h-[17rem] "></img>
                <div className="flex items-center mx-auto justify-between">
                    <p className="my-3 ">Shalini Singh</p>
                    <a href="https://www.linkedin.com/in/shalini-singh-637350159" target="_blank">
                    <FaLinkedinIn></FaLinkedinIn>
                    </a>
                </div>
                <p>ML & Data Science Engineer</p>
            </div>
            <div className="mx-auto ">
                <img src={Rup} className="h-[17rem] "></img>
                <div className="flex items-center mx-auto justify-between">
                    <p className="my-3 ">Rupasree Mullick</p>
                    <a href="https://www.linkedin.com/in/rupasree-mullick-2656a0152/" target="_blank">
                        <FaLinkedinIn></FaLinkedinIn>
                    </a>
                </div>
                <p>Social Media Specialist</p>
            </div>
            <div className="mx-auto ">
                <img src={Mansi} className="h-[17rem] "></img>
                <div className="flex items-center mx-auto justify-between">
                    <p className="my-3 ">Mansi Korotania</p>
                    <a href="https://www.linkedin.com/in/mansi-korotania-95a122217/" target="_blank">
                        <FaLinkedinIn></FaLinkedinIn>
                    </a>
                </div>
                <p>Social Media Specialist</p>
            </div>
            <div className="mx-auto ">
                <img src={Swaraj} className="h-[17rem] "></img>
                <div className="flex items-center mx-auto justify-between">
                    <p className="my-3 ">Swaraj CS</p>
                    <a href="https://www.linkedin.com/in/swaraj-c-s-3ab54653" target="_blank">
                    <FaLinkedinIn></FaLinkedinIn>
                    </a>
                </div>
                <p>Sr. Engineer - Gamified Learning</p>
            </div>

            <div className="mx-auto ">
                <img src={Saaransh} className="h-[17rem] "></img>
                <div className="flex items-center mx-auto justify-between">
                    <p className="my-3 ">Saaransh Shandilya</p>
                    <a href="https://www.linkedin.com/in/saaransh-shandilya-799b441b5" target="_blank">
                    <FaLinkedinIn></FaLinkedinIn>
                    </a>
                </div>
                <p>Software Engineer</p>
            </div>
            <div className="mx-auto ">
                <img src={Chintan} className="h-[17rem] "></img>
                <div className="flex items-center mx-auto justify-between">
                    <p className="my-3 ">Chintan Tutlani</p>
                    <a href="https://www.linkedin.com/in/chintan-tutlani-484b221a5" target="_blank">
                    <FaLinkedinIn></FaLinkedinIn>
                    </a>
                </div>
                <p>Software Engineer</p>
            </div>
        </div>
    </div>
  )
}

export default About

import React, {useState, useEffect} from 'react'
import onboarding_guy from '../images/onboarding_guy.png'
import interviewguru_logo from '../images/interview_guru_logo.png'
import { useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import axios from 'axios'
import ReactGA from 'react-ga'
import { Helmet } from 'react-helmet'

const Onboarding = () => {
    const [pin, setPin] = useState('')
    const [city, setCity] = useState('')
    const [state, setState] = useState('')
    const [fname, setFname] = useState('')
    const [counter, setCounter] = useState(30)
    const date = new Date()
    const [datetime, setDatetime] = useState(date)
    const [otpsent, setOTPSent] = useState(false)
    const [phoneNumber, setPhoneNumber] = useState('')
    const [lname, setLname] = useState('')
    const [verify, setVerify] = useState(false)
    const [choice, setChoice] = useState(null)
    const [test, setText]=useState('')
    const [otp1,setOtp1] = useState('')
    const [otp2,setOtp2] = useState('')
    const [otp3,setOtp3] = useState('')
    const [otp4,setOtp4] = useState('')
    const location  = useLocation()
    if(pin.length == 6){
        axios.get(`https://api.postalpincode.in/pincode/${pin}`)
        .then((response) =>{
            // console.log(response)
            setCity(response.data[0].PostOffice[0].Division)
            setState(response.data[0].PostOffice[0].Circle)
        })
        .catch((error) =>{
            alert('Something went wrong! Please try again')
            console.log(error)
        })
    }

    const [page, setPage] = useState(0)
    const [width,setWidth] = useState(1)
    const [disabled, setDisabled] = useState(true)

    React.useEffect(() => {
        if(otpsent == true){
            const timer =
              counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
            return () => clearInterval(timer);
        }
    }, [counter, otpsent]);

    ReactGA.initialize('UA-209731463-1');

    useEffect(()=>{
        if(city && state && pin && verify){
            setDisabled(false)
        }
    },[city, state, pin, verify])


    const navigate = useNavigate()

    const email = localStorage.getItem('email')

    const sendOTP = () =>{
        axios.get(`https://www.interviewvision.com/users/genphoneotp/${phoneNumber}`)
        .then((response) =>{
            setOTPSent(true)
            console.log(response)
        })
        .catch((err) =>{
            setOTPSent(false)
            alert('This mobile number is already registered with us.')
            console.log(err)

        })
    }

    useEffect(()=>{
        if(page==1){
            axios.put(`https://interviewvision.com/users/u/${email}`,{
                fname:fname,
                lname:lname,
                city:city,
                state:state,
                phone:phoneNumber,
    
            }).then((response) =>{
                console.log(response)
            })
            .catch((error) =>{
                alert('Something went wrong! Please try again')
                console.log(error)
            })
        }
        if(page==2){
            if(choice == 2){
                setText('Pursuing Graduation')
            }
            else if(choice == 3){
                setText('Pursuing Post Graduation')
            }
            else if(choice == 1){
                setText('Fresher looking for a job')
            }
            else if(choice == 4){
                setText('Working Professional with less than 2 Years Experience')
            }
            else if(choice == 5){
                setText('Working Professional with more than 2 Years Experience')
            }
            axios.put(`https://interviewvision.com/users/u/${email}`,{
                experience:test
            })
            .then((response) => {
                
                console.log(response)
                navigate('/registration_success',)
            })
            .catch((error) => {
                alert('Something went wrong! Please try again')
                console.log(error)
            })
        }
    },[page])

    console.log(fname, lname, pin, verify, )

    const verifyOTP = () =>{
        axios.get(`https://www.interviewvision.com/users/verifyphoneotp/${phoneNumber}/${otp1+otp2+otp3+otp4}`)
        .then((response) =>{
            console.log(response)
            setVerify(true)
        })
        .catch((error) =>{
            setVerify(false)
            console.log('Wrong OTP entered, please try again')
        })
    }

  return (
    <div>
        {/* <Helmet>
        <script type="text/javascript">
            {`window.gtag('event', 'conversion', {'send_to': 'AW-11249740142/BbbCCIrB180YEO7SpfQp'})`}
        </script>
        </Helmet> */}
        <div className="flex w-full ">
            <img src={onboarding_guy} className="h-screen shadow-2xl lg:block hidden"></img>
            <div className="lg:flex-col hidden lg:flex   h-screen lg:justify-center  lg:space-y-16 -ml-7 ">
                <button  onClick={()=>{
                    setPage(0)
                    setWidth(1)
                }}>
                <div className={"rounded-full border-8 border-[#E94B60] bg-white px-4 py-2"}>
                    <h1 className={"text-xl font-bold text-[#E94B60]"}>1</h1>
                </div>
                </button>
                <button disabled={disabled} onClick={()=>{
                    setPage(1)
                    setWidth(2)
                }}>
                <div className={page == 1 ? "rounded-full border-8 border-[#F0939F] bg-white px-4 py-2":"rounded-full border-8 border-[#E9E9E9] bg-white px-4 py-2"}>
                    <h1 className={page == 1 ?"text-[#F0939F]  text-xl font-bold":"text-xl font-bold text-[#E9E9E9]"}>2</h1>
                </div>
                </button>
                
            </div>
            <div className="w-4/6 ">
                <img src={interviewguru_logo} className="h-20 mt-3"></img>
                <div className="mx-10 mt-8 w-full">
                    <h1 className="text-3xl font-extrabold font-poppins">Please tell us a little about yourself</h1>
                    <div className="bg-[#E9E9E9] h-3 w-full mt-3 rounded-lg ">
                        <div className={`bg-gradient-to-r from-[#EA5266] to-[#EA4C6166] w-${width}/2 h-3 rounded-xl `}></div>
                    </div>
                    {
                    page == 0 &&   
                    <div className="w-full">
        <div className="mr-4 mt-12">  
                <h1 className="my-3 font-extrabold font-poppins text-2xl">Introduction</h1>
                <div className="space-y-8">
                <div className="lg:flex  w-full  lg:space-x-3">
                <div class="relative  mb-4 lg:mb-0 lg:w-1/2">
                    <input type="text" required value={fname} onChange={(e)=>{setFname(e.target.value)}} id="floating_outlined" className=" px-2.5 border-2 pb-2.5  pt-4 w-full  text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
                    <label for="floating_outlined" className="absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 font-poppins">First Name</label>
                </div>
                <div class="relative lg:w-1/2">
                    <input type="text" required value={lname} onChange={(e)=>{setLname(e.target.value)}} id="floating_outlined" className=" px-2.5 border-2 pb-2.5 pt-4 w-full  text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
                    <label for="floating_outlined" className="absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 font-poppins">Last Name</label>
                </div>
                </div>
                <div className="lg:flex w-full lg:space-x-3">
                <div className="relative mb-4 lg:mb-0  lg:w-1/2">
                    <input type="text" required value={pin} onChange={(e)=>{setPin(e.target.value)}} id="floating_outlined" className=" px-2.5 border-2 pb-2.5 pt-4 w-full  text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
                    <label for="floating_outlined" className="absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 font-poppins">PIN Code</label>
                </div>
                <div className="relative mb-4 lg:mb-0  lg:w-1/2">
                    <input value={city} readOnly type="text" id="floating_outlined" className=" px-2.5 border-2 pb-2.5 pt-4 w-full  text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
                    <label for="floating_outlined" className="absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 font-poppins">City</label>
                </div>
                </div>

                <div className="lg:flex w-full lg:space-x-3">
                {
                    verify?
                    <div className="lg:w-1/2 items-center mb-3 ">
                        {phoneNumber} has been successfully <span className="text-green-500 font-bold ">verified!</span>
                    </div>
                    :
                    <div className="w-full">
                        {
                        !otpsent?    
                        <div className="flex w-full lg:mb-0 mb-4 space-x-3 items-center ">
                        <div class="relative   lg:mb-0 w-full">
                            <input type="text" required value={phoneNumber} onChange={(e)=>{setPhoneNumber(e.target.value)}} id="floating_outlined" className=" px-2.5 border-2 pb-2.5  pt-4 w-full  text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
                            <label for="floating_outlined" className="absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 font-poppins">Phone number</label>
                        </div>
                        <button className="px-4 py-2 bg-blue-500 text-white rounded-xl h-full " onClick={sendOTP}>
                            Verify
                        </button>
                        </div>   
                        :
                        <div >
                            <div className="lg:flex   lg:space-x-3 space-y-3 lg:space-y-0 lg:mb-0 mb-4 ">
                                <div className="flex items-start mx-auto justify-center w-full lg:justify-end space-x-4 ">
                                    <input name="field-1" className="border-2 outline-none w-[2rem] px-2 py-2 rounded-lg text-center" maxlength="1" value={otp1} onChange={(e)=>{
                                        const { maxLength, value, name } = e.target
                                        const [fieldName, fieldIndex] = name.split("-");
                                        let fieldIntIndex = parseInt(fieldIndex, 10);
                                        if (value.length >= maxLength) {
 
                                            // It should not be last input field
                                            if (fieldIntIndex < 4) {
                                 
                                                // Get the next input field using it's name
                                                const nextfield = document.querySelector(
                                                    `input[name=field-${fieldIntIndex + 1}]`
                                                );
                                 
                                                // If found, focus the next field
                                                if (nextfield !== null) {
                                                    nextfield.focus();
                                                }
                                            }
                                        }
                                        setOtp1(e.target.value)}}></input>
                                    <input name="field-2" className="border-2 outline-none w-[2rem] px-2 py-2 rounded-lg text-center" maxlength="1" value={otp2} onChange={(e)=>{
                                        const { maxLength, value, name } = e.target
                                        const [fieldName, fieldIndex] = name.split("-");
                                        let fieldIntIndex = parseInt(fieldIndex, 10);
                                        if (value.length >= maxLength) {
 
                                            // It should not be last input field
                                            if (fieldIntIndex < 4) {
                                 
                                                // Get the next input field using it's name
                                                const nextfield = document.querySelector(
                                                    `input[name=field-${fieldIntIndex + 1}]`
                                                );
                                 
                                                // If found, focus the next field
                                                if (nextfield !== null) {
                                                    nextfield.focus();
                                                }
                                            }
                                        }
                                        setOtp2(e.target.value)}}></input>
                                    <input name="field-3" className="border-2 outline-none w-[2rem] px-2 py-2 rounded-lg text-center" maxlength="1" value={otp3} onChange={(e)=>{
                                        const { maxLength, value, name } = e.target
                                        const [fieldName, fieldIndex] = name.split("-");
                                        let fieldIntIndex = parseInt(fieldIndex, 10);
                                        if (value.length >= maxLength) {
 
                                            // It should not be last input field
                                            if (fieldIntIndex < 4) {
                                 
                                                // Get the next input field using it's name
                                                const nextfield = document.querySelector(
                                                    `input[name=field-${fieldIntIndex + 1}]`
                                                );
                                 
                                                // If found, focus the next field
                                                if (nextfield !== null) {
                                                    nextfield.focus();
                                                }
                                            }
                                        }
                                        setOtp3(e.target.value)}}></input>
                                    <input name="field-4" className="border-2 outline-none w-[2rem] px-2 py-2 rounded-lg text-center" maxlength="1" value={otp4} onChange={(e)=>{
                                        const { maxLength, value, name } = e.target
                                        const [fieldName, fieldIndex] = name.split("-");
                                        let fieldIntIndex = parseInt(fieldIndex, 10);
                                        if (value.length >= maxLength) {
 
                                            // It should not be last input field
                                            if (fieldIntIndex < 4) {
                                 
                                                // Get the next input field using it's name
                                                const nextfield = document.querySelector(
                                                    `input[name=field-${fieldIntIndex + 1}]`
                                                );
                                 
                                                // If found, focus the next field
                                                if (nextfield !== null) {
                                                    nextfield.focus();
                                                }
                                            }
                                        }
                                        setOtp4(e.target.value)}}></input>
                                </div>
                                <div className=" text-center ">
                        <button className="px-10   bg-blue-500 text-white text-lg  rounded-xl py-2  " onClick={()=>{
                            verifyOTP()}}>Verify</button>
                        </div>
                        <div className="text-center">
                        <button disabled={counter>0} className="px-10   bg-blue-500 text-white text-lg  rounded-xl py-2  " onClick={()=>{
                            setCounter(30)
                            sendOTP()}}>
                            {
                                counter>0?
                                counter
                                :
                                "Resend"
                            }
                        </button>
                        </div>
                            </div>
                            <p className='lg:text-right text-center text-sm font-poppins my-3'>A four digit OTP has been sent to your WhatsApp account linked with this phone number.</p>
                        </div>
                        }    
                    </div>
                }    
                

                </div>
               
                
                
                </div>
                </div>
    </div>
                    }
                    {
                        page == 1 &&
                        <div>
        <h1 className="text-2xl font-extrabold font-poppins mt-6 mb-3">I am currently a...</h1>
        <div className="flex-col flex font-poppins lg:w-2/3 space-y-3">
            <button onClick={()=>{setChoice(2)}} className={choice==2?"bg-blue-500 py-4 rounded-2xl px-5 flex text-lg space-x-3 items-center text-white":"bg-[#F3F3F3]  py-4 rounded-2xl px-5 flex text-lg space-x-3 items-center" }>
            <div className="border-4 p-3 border-white rounded-full "></div>
                <h1>Pursuing graduation</h1>
            </button>
            <button onClick={()=>{setChoice(3)}} className={choice==3?"bg-blue-500 py-4 rounded-2xl px-5 flex text-lg space-x-3 items-center text-white":"bg-[#F3F3F3]  py-4 rounded-2xl px-5 flex text-lg space-x-3 items-center" }>
            <div className="border-4 p-3 border-white rounded-full "></div>
                <h1>Pursuing post graduation</h1>
            </button>
            <button onClick={()=>{setChoice(1)}} className={choice==1?"bg-blue-500 py-4 rounded-2xl px-5 flex text-lg space-x-3 items-center text-white":"bg-[#F3F3F3]  py-4 rounded-2xl px-5 flex text-lg space-x-3 items-center" }>
                <div className="border-4 p-3 border-white rounded-full "></div>
                <h1>Fresher looking for a job</h1>
            </button>
            <button onClick={()=>{setChoice(4)}} className={choice==4?"bg-blue-500 py-4 rounded-2xl px-5 flex text-lg space-x-3 items-center text-white":"bg-[#F3F3F3]  py-4 rounded-2xl px-5 flex text-lg space-x-3 items-center" }>
            <div className="border-4 p-3 border-white rounded-full "></div>
                <h1 className="text-left">Working Professional with less than 2 Years Experience</h1>
            </button>
            <button onClick={()=>{setChoice(5)}} className={choice==5?"bg-blue-500 py-4 rounded-2xl px-5 flex text-lg space-x-3 items-center text-white":"bg-[#F3F3F3]  py-4 rounded-2xl px-5 flex text-lg space-x-3 items-center" }>
            <div className="border-4 p-3 border-white rounded-full "></div>
                <h1 className='text-left'>Working Professional with more than 2 Years Experience</h1>
            </button>
        </div>
    </div>
                    }
                    {/* {
                        page == 2 &&
                        <Education/>  
                    } */}
                    <div className="lg:mr-10 float-right my-5">
                    <button disabled={disabled} className={!disabled?"bg-gradient-to-r text-white py-4 px-10 text-left from-[#E94B60] to-[#E94B6091] rounded-xl":"bg-gray-300 text-white py-4 px-10 text-left rounded-xl"} onClick={()=>{
                        ReactGA.event({
                            category:"User finally reaches home page after registration",
                            action:"User reaches internal home page after registration"
                        })
                        setPage(page+1)
                        setWidth(width+1)
                    }}>Next</button>
                    </div>
                </div>  
            </div>
        </div>
    </div>
  )
}

export default Onboarding
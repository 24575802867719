import React, { useState, useEffect} from 'react'
import signupgirl from '../images/signup_girl.png'
import interviewguru_logo from '../images/interview_guru_logo.png'
import google_logo from '../images/google_logo.png'
import {Link, useNavigate} from 'react-router-dom'
import axios from 'axios'
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { getAuth } from "firebase/auth";
import app from '../firebase'
import Helmet from 'react-helmet'
import {InfinitySpin} from 'react-loader-spinner'
import ReactGA from 'react-ga'
import uuid from 'react-uuid'
import ErrorModal from './ErrorModal'
import {ColorRing} from 'react-loader-spinner'
import data from '../config'

const Signup = () => {
    
    const [email, setEmail]  = useState('')
    const date = new Date()
    const [fname, setFname] = useState('')
    const [phone, setPhone] = useState('')
    const [disable, setDisable] = useState(true)
    const [phonestatus,setPhonestatus] = useState(true)
    const [checkbox, setCheckbox] = useState(false)
    const [checkbox2, setCheckbox2] = useState(false)
    const [pnum, setPnum] = useState('')
    const [error, setError] = useState(false)
    const [errorText, setErrorText] = useState('')
    const [datetime, setDatetime] = useState(date)
    const [confpass, setConfPass] = useState('')
    const [loading, setLoading] = useState(false)
    const [verify, setVerify] = useState(false)
    const [invalid, setInvalid] = useState(false)
    const [otp1,setOtp1] = useState('')
    const [loader, setLoader] = useState(false)
    const [otpsent, setOTPSent] = useState(false)
    const [otp2,setOtp2] = useState('')
    const [otp3,setOtp3] = useState('')
    const [otp4,setOtp4] = useState('')
    const [counter, setCounter] = useState(30)
    const navigate = useNavigate()
    const provider = new GoogleAuthProvider()
    const auth = getAuth(app);
    const [uid, setUid] = useState('')
    auth.languageCode = 'it';

    console.log(checkbox)
   

    React.useEffect(() => {
        if(otpsent == true){
            const timer =
              counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
            return () => clearInterval(timer);
        }
    }, [counter, otpsent]);


    const sendOTP = () =>{
        setLoader(true)
        axios.get(`https://${data.API}/users/gensmsotp/${pnum}`)
        .then((response) =>{
            setOTPSent(true)
            setOtp1('')
            setOtp2('')
            setOtp3('')
            setOtp4('')
            setLoader(false)
            setCounter(30)
            console.log(response)
        })
        .catch((err) =>{
            setLoader(false)
            setOTPSent(false)
            setError(true)
            setErrorText('This mobile number is already registered with us.')
            console.log(err)

        })
    }

    const verifyOTP = () =>{
        axios.get(`https://${data.API}/users/verifyphoneotp/${pnum}/${otp1+otp2+otp3+otp4}`)
        .then((response) =>{
            console.log(response)
            setVerify(true)
        })
        .catch((error) =>{
            setVerify(false)
            setInvalid(true)
            console.log('Wrong OTP entered, please try again')
        })
    }

    useEffect(()=>{
        if(fname && verify && email.includes('@')&&checkbox && checkbox2){
            setDisable(false)
        }
        else{
            setDisable(true)
        }
    },[pnum , fname, verify, email, checkbox, checkbox2])

   

    useEffect(()=>{
        setDatetime(date)
    },[])
    
    
    useEffect(()=>{
        ReactGA.pageview(window.location.pathname + window.location.search)
    },[])
      
    const handleSubmit = (e)=>{
        setLoading(true)
        e.preventDefault()
        axios.post(`https://${data.API}/users/c`,{
            username:pnum,
            password:'',
            email:email,
            portal_name:"Interview Vision Main"
        })
        .then((response)=>{
            console.log(response)
            localStorage.setItem('email',pnum)
            axios.put(`https://${data.API}/users/u/${pnum}`,{
                fname:fname,
                phone:pnum
            })
            .then((response)=>{
              navigate('/registration_success',{state:{email:email}})
              setLoading(false)
            })
        })
        .catch((error)=>{
            console.log(error)
            if(error.response.status === 409){
                alert('User already exists')
                navigate('/login')
            }
            setLoading(false)
        })
        setEmail('')
        setPhone('')
    }

    function handleState() {
        setError(false)
        navigate('/login')
    }
    
    if(loading){
        return(
         <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
             <InfinitySpin
             width='200'
             color='#4899B7'
             ></InfinitySpin>
         </div>   
        )
    }

    else{
  return (
    <>
    <Helmet>
    <script type="text/javascript">
  {`gtag('event', 'conversion', {'send_to': 'AW-11249740142/BbbCCIrB180YEO7SpfQp'});`}
</script>
    </Helmet>
    <div className="">
        {
        error?  
        <div className="">
            <ErrorModal text={errorText} change={handleState} buttonText={'Signin Now'}/>
        </div>
        :    
        <div className="flex">
            <img src={signupgirl} className="h-screen lg:block hidden"></img>
            <div className="w-full">
            <div className="flex justify-between items-center mt-3 mx-4">
                <Link to="/">
                <img src={interviewguru_logo} className="h-20 "></img>
                </Link>
            </div>
            <div className="mt-12 lg:w-1/2  mx-auto">
                <h1 className="text-2xl lg:text-left text-center font-poppins font-semibold">Enter your phone number</h1>
               <div className="flex space-x-3 items-center my-4 lg:justify-start justify-center">

                </div> 
                
                <div className="lg:flex w-full lg:px-0 px-4 lg:space-x-3 my-4">
                {
                    verify?
                    <div className="lg:w-1/2 items-center mb-3 ">
                        {pnum} has been successfully <span className="text-green-500 font-bold ">verified!</span>
                    </div>
                    :
                    <div className="w-full">
                        <div>
                        <div className="flex w-full lg:mb-0 mb-4 space-x-3 items-center ">
                        <div class="relative   lg:mb-0 w-full">
                            <input type="text" required value={pnum} onChange={(e)=>{setPnum(e.target.value)}} id="floating_outlined" className=" px-2.5 border-2 pb-2.5  pt-4 w-full  text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
                            <label for="floating_outlined" className="absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 font-poppins">Phone number</label>
                        </div>
                        <button disabled={pnum.length != 10} className={pnum.length != 10?"px-4 py-2 w-[15rem]  text-center bg-gray-300 text-white rounded-xl h-full ":"text-center px-4 py-2 w-[15rem] bg-blue-500 text-white rounded-xl h-full"} onClick={sendOTP}>
                        {
                            loader?
                            <div className="flex w-full justify-center">
                                <ColorRing
                                    visible={true}
                                    height="30"
                                    width="30"
                                    className="mx-auto"
                                    ariaLabel="blocks-loading"
                                    wrapperStyle={{}}
                                    wrapperClass="blocks-wrapper"
                                    colors={['#FFFFFF','#FFFFFF','#FFFFFF','#FFFFFF','#FFFFFF']}
                                />
                            </div>
                            :    
                            'Generate OTP'
                        }
                        </button>
                        </div>
                        {
                            pnum.length!=10
                            &&
                            <p className="font-poppins text-sm">Enter a valid 10 digit phone number</p>
                        }
                        </div>   
                        {
                            otpsent &&
                        <div>
                            <div className="lg:flex text-left my-6 lg:space-x-3 space-y-3 lg:space-y-0 lg:mb-0 mb-4 ">
                                <div className="flex items-start mx-auto justify-center w-full lg:justify-start space-x-4 ">
                                    <input type="number" name="field-1" className={invalid?"border-2 text-red-500 border-red-500 outline-none w-[2rem] px-2 py-2 rounded-lg text-center":"border-2 outline-none w-[2rem] px-2 py-2 rounded-lg text-center"} maxlength="1" value={otp1} onChange={(e)=>{
                                        const { maxLength, value, name } = e.target
                                        const [fieldName, fieldIndex] = name.split("-");
                                        let fieldIntIndex = parseInt(fieldIndex, 10);
                                        if (value.length >= maxLength) {
 
                                            // It should not be last input field
                                            if (fieldIntIndex < 4) {
                                 
                                                // Get the next input field using it's name
                                                const nextfield = document.querySelector(
                                                    `input[name=field-${fieldIntIndex + 1}]`
                                                );
                                 
                                                // If found, focus the next field
                                                if (nextfield !== null) {
                                                    nextfield.focus();
                                                }
                                            }
                                        }
                                        setOtp1(e.target.value)}}></input>
                                    <input type="number" name="field-2" className={invalid?"border-2 text-red-500 border-red-500 outline-none w-[2rem] px-2 py-2 rounded-lg text-center":"border-2 outline-none w-[2rem] px-2 py-2 rounded-lg text-center"} maxlength="1" value={otp2} onChange={(e)=>{
                                        const { maxLength, value, name } = e.target
                                        const [fieldName, fieldIndex] = name.split("-");
                                        let fieldIntIndex = parseInt(fieldIndex, 10);
                                        if (value.length >= maxLength) {
 
                                            // It should not be last input field
                                            if (fieldIntIndex < 4) {
                                 
                                                // Get the next input field using it's name
                                                const nextfield = document.querySelector(
                                                    `input[name=field-${fieldIntIndex + 1}]`
                                                );
                                 
                                                // If found, focus the next field
                                                if (nextfield !== null) {
                                                    nextfield.focus();
                                                }
                                            }
                                        }
                                        setOtp2(e.target.value)}}></input>
                                    <input type="number" name="field-3" className={invalid?"border-2 text-red-500 border-red-500 outline-none w-[2rem] px-2 py-2 rounded-lg text-center":"border-2 outline-none w-[2rem] px-2 py-2 rounded-lg text-center"} maxlength="1" value={otp3} onChange={(e)=>{
                                        const { maxLength, value, name } = e.target
                                        const [fieldName, fieldIndex] = name.split("-");
                                        let fieldIntIndex = parseInt(fieldIndex, 10);
                                        if (value.length >= maxLength) {
 
                                            // It should not be last input field
                                            if (fieldIntIndex < 4) {
                                 
                                                // Get the next input field using it's name
                                                const nextfield = document.querySelector(
                                                    `input[name=field-${fieldIntIndex + 1}]`
                                                );
                                 
                                                // If found, focus the next field
                                                if (nextfield !== null) {
                                                    nextfield.focus();
                                                }
                                            }
                                        }
                                        setOtp3(e.target.value)}}></input>
                                    <input type="number" name="field-4" className={invalid?"border-2 text-red-500 border-red-500 outline-none w-[2rem] px-2 py-2 rounded-lg text-center":"border-2 outline-none w-[2rem] px-2 py-2 rounded-lg text-center"} maxlength="1" value={otp4} onChange={(e)=>{
                                        const { maxLength, value, name } = e.target
                                        const [fieldName, fieldIndex] = name.split("-");
                                        let fieldIntIndex = parseInt(fieldIndex, 10);
                                        if (value.length >= maxLength) {
 
                                            // It should not be last input field
                                            if (fieldIntIndex < 4) {
                                 
                                                // Get the next input field using it's name
                                                const nextfield = document.querySelector(
                                                    `input[name=field-${fieldIntIndex + 1}]`
                                                );
                                 
                                                // If found, focus the next field
                                                if (nextfield !== null) {
                                                    nextfield.focus();
                                                }
                                            }
                                        }
                                        setOtp4(e.target.value)}}></input>
                                </div>
                                <div className=" text-center ">
                                {
                                invalid &&
                                <p className="font-poppins text-sm my-3 lg:hidden block text-red-500">Invalid OTP</p>
                            }
                        <button className="px-10   bg-blue-500 text-white text-lg  rounded-xl py-2  " onClick={()=>{
                            verifyOTP()}}>Verify</button>
                        </div>
                        <div className="text-center">
                        <button disabled={counter>0} className="px-10   bg-blue-500 text-white text-lg  rounded-xl py-2  " onClick={()=>{
                            setCounter(30)
                            sendOTP()}}>
                            {
                                counter>0?
                                counter
                                :
                                "Resend"
                            }
                        </button>
                        </div>
                            </div>
                            {
                                invalid &&
                                <p className="font-poppins text-sm my-3 hidden lg:block text-red-500">Invalid OTP</p>
                            }
                            <p className='text-center text-sm font-poppins my-3'>A four digit OTP has been sent to your phone number.</p>
                        </div>
                        }
                        
                        
                    </div>
                }    
                </div>
                
                <div class="relative lg:mx-0 mx-5">
                    <input type="text" value={fname} autoComplete="off" autoSave="off" onChange={(e)=>{setFname(e.target.value)}} id="floating_outlined" class="block px-2.5 border-2 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
                    <label for="floating_outlined" class="absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">Enter your full name</label>
                </div>

                <div class="relative lg:mx-0 mx-5 mt-4">
                    <input type="text" value={email} autoComplete="off" autoSave="off" onChange={(e)=>{setEmail(e.target.value)}} id="floating_outlined" class="block px-2.5 border-2 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
                    <label for="floating_outlined" class="absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">Enter your email id</label>
                </div>
                
                <div className="flex items-center mt-3 space-x-4 mx-5 lg:mx-0">
                <input required type="checkbox" checked={checkbox} onChange={(e)=>{setCheckbox(e.target.checked)}} className="accent-[#E94B60] h-7 w-7  lg:h-5 lg:w-5" ></input>
                <p className="text-sm">By clicking sign up, I agree that I have read and accepted the Terms of Use and Privacy Policy.</p>
                </div>
                <div className="flex items-center mt-3 space-x-4 mx-5 lg:mx-0">
                <input required type="checkbox" checked={checkbox2} onChange={(e)=>{setCheckbox2(e.target.checked)}} className="accent-[#E94B60] h-7 w-7  lg:h-5 lg:w-5" ></input>
                <p className="text-sm">I agree to be contacted by WhatsApp to receive marketing and other related information.</p>
                </div>
                <div className="mx-5 lg:mx-0">
                <button className={disable?`px-10 w-full bg-slate-400 text-slate-200 text-lg font-poppins rounded-xl py-3 my-4 lg:mx-0 `:`px-10 w-full bg-[#4899B7] text-white text-lg font-poppins rounded-xl py-3 my-4 lg:mx-0 ` } disabled={disable} onClick={handleSubmit} >Sign up</button>
                </div>
                <Link to="/login">
                <h1 className="font-poppins  my-3 text- text-center"> Already have an account? <span className="text-red-500 font-bold">Sign in</span></h1>
                </Link>
            </div>

            </div>
        </div>
        }
    </div>
    </>
  )
    }
}

//write headings in numeric interviews

export default Signup
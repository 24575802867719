import React,{useState, useEffect} from 'react'
import { Link , useLocation} from 'react-router-dom'
import desk from '../images/desk.png'
import heart from '../images/heart.png'
import play from '../images/play.png'
import YouTube from 'react-youtube';
import thumbnail13 from '../images/youtube (1).jpeg'
import thumbnail15 from '../images/youtube (2).jpeg'
import taketest from '../images/taketest.mp4';
import ReactGA from 'react-ga';
import {RxCross1} from 'react-icons/rx'
import data from '../config'
import axios from 'axios'
const Taketest = () => {
    const [choice, setChoice] = useState(0)
    const [link,setLink] = useState('')
    const [open,setOpen] = useState(false)
    const [width,setWidth] = useState(0)
    const [height,setHeight] = useState(0)
    const [visibleVideo,setVisibleVideo] = useState(true)
    const [firstinterview, setFirstInterview] = useState(null)
    
    const thumbnail1 = 'https://img.youtube.com/vi/dItUGF8GdTw/0.jpg'
    const thumbnail2 = "https://img.youtube.com/vi/x0vUsxhMczI/0.jpg"
    const thumbnail14 = "https://img.youtube.com/vi/iCtoCeFTMME/0.jpg"
    const thumbnail10 = "https://img.youtube.com/vi/cFdCzN7RYbw/0.jpg"
    const thumbnail11 = "https://img.youtube.com/vi/1sSing6ntVA/0.jpg"
    const thumbnail12 = "https://img.youtube.com/vi/UwsMogSQmYI/0.jpg"
    const thumbnail5 = "https://img.youtube.com/vi/rUJkbWNnNy4/0.jpg"
    const thumbnail6 = "https://img.youtube.com/vi/hiiEeMN7vbQ/0.jpg"
    const thumbnail8 = "https://img.youtube.com/vi/7wUCyjiyXdg/0.jpg"
    const thumbnail9 = "https://img.youtube.com/vi/NqgIVZJv-do/0.jpg"
    const thumbnail16 = "https://img.youtube.com/vi/pJ7RgUCEd5M/0.jpg"

    //demo
    //no mcqs
    //3 sample questions
    //instructions for practice interview
    //Instructions for practise interview:
//     Please use a phone or computer with a camera and a microphone
// Ensure to give the necessary permissions to allow recording if prompted
// Please make sure there is no background noise
// Try to sit in a place with sufficient light
// It is better to use a headphone for clear voice

    const [noi, setNoi] = useState(null)

    useEffect(()=>{
        if(window.innerWidth<500){
            setWidth(300)
            setHeight(150)
        }
        else{
            setWidth(400)
            setHeight(250)
        }
    },[])

    useEffect(()=>{
        ReactGA.pageview('/home')
        ReactGA.event({
            category:"User lands on landing page",
            label:"User signs up or signs in on landing page",
        })
        window.fbq('track', 'CompleteRegistration');
        window.gtag('config', 'AW-11249740142');
      },[])
      

    useEffect(()=>{
        const email = localStorage.getItem('email')
        axios.get(`https://${data.API}/users/r/${email}`)
        .then((response)=>{
            setNoi(response.data.remaininginterviews)
            setFirstInterview(response.data.firsti_nterview)
        })
        .catch((error)=>{
            alert('Something went wrong! Please try again')
            console.log(error)
        })
    },[])

    console.log(firstinterview)

   

  if(noi!=null && firstinterview!=null)return (
    <div className="h-full pb-10 ">

        {
            visibleVideo &&
          <div className="fixed bottom-16 md:bottom-4 right-6 ">
            <video src={taketest} className="md:rounded-xl  h-[10rem] rounded-xl md:h-[16rem] transition-all hover:h-[30rem]" controls loop playsInline autoPlay muted></video>
            <button onClick={()=>{setVisibleVideo(false)}}>
            <div className="absolute top-0 right-0 bg-black text-white text-lg rounded-full p-2 -mr-4 -mt-4">
              <RxCross1></RxCross1>
            </div>
            </button>
          </div>
          }
        <div className="bg-white flex px-10 py-7  mr-6 rounded-xl justify-between font-poppins my-4 items-center">
            <div>
                <h1 className=" text-lg lg:text-2xl font-bold ">Kickstart your journey to secure your dream job</h1>
                <div className="items-center flex">
                <div>
                <h1 className="font-light text-sm lg:text-lg my-4">Take a video interview. Get your interview skills evaluated by our AI engine</h1>
                {
                    !firstinterview?
                    <Link  to='/data' >
                <button className="bg-[#E94B60] px-5 lg:px-10 py-3 lg:py-3 text-white rounded-xl">Try Free</button>
                </Link>
                    :
                    <div>
                    {
                    noi>0&&    
                    <Link  to='/data' >
                    <button className="bg-[#E94B60] px-5 lg:px-10 py-3 lg:py-3 text-white rounded-xl">Let's Go!</button>
                    </Link>
                    }
                    {
                        noi==0 &&
                        <Link  to='/pricing'>
                        <button className="bg-[#E94B60] px-5 lg:px-10 py-3 lg:py-3 text-white rounded-xl">Let's Go!</button>
                        </Link>
                    }
                    </div>

                }
                {
                    noi>0 &&
                    <p className="my-4 font-poppins">Number of interviews left : {noi}</p>
                }
                <div >
                {/* <Link to="/demo" state={{name:name, pass:pass}}>
                <button onClick={()=>{ReactGA.event({
      category:"Takes test interview",
      action:"User takes test interview"
    })}} className="underline text-lg ml-1 my-4 ">Try free</button>
                </Link> */}
                </div>
                </div>
                <img src={desk} className='lg:h-60 h-16 -mr-8 lg:mr-0 float-right lg:hidden'></img>   
                </div>
            </div>
            <img src={desk} className='lg:h-60 h-24 lg:block hidden'></img>
        </div>
        <div className="my-5">
            <h1 className="font-poppins font-extrabold text-xl ml-4 lg:ml-0">Take Inspiration</h1>
            <div className="mr-5 ">
            <div className="flex  text-sm gap-8 items-center overflow-x-auto w-full font-poppins my-2  ">
                <button onClick={()=>{setChoice(0)}} className="text-center ">
                    <h1 className={choice == 0 ?"text-[#E94B60] ":"text-[#7272728F]"}>Critical Thinking</h1>
                    <div className={choice == 0 ?"p-1 w-[0.1rem] mx-auto rounded-full bg-[#E94B60] ":"p-1 w-[0.1rem] mx-auto rounded-full bg-[#4899B7] invisible"}></div>
                </button>
                <button onClick={()=>{setChoice(1)}} className="text-center ">
                    <h1 className={choice == 1 ?"text-[#E94B60] ":"text-[#7272728F]"}>Communication</h1>
                    <div className={choice == 1 ?"p-1 w-[0.1rem] mx-auto rounded-full bg-[#E94B60] ":"p-1 w-[0.1rem] mx-auto rounded-full bg-[#4899B7] invisible"}></div>
                </button>
                <button  onClick={()=>{setChoice(2)}} className="text-center ">
                    <h1 className={choice == 2 ?"text-[#E94B60] ":"text-[#7272728F]"}>Teamwork</h1>
                    <div className={choice == 2 ?"p-1 w-[0.1rem] mx-auto rounded-full bg-[#E94B60] ":"p-1 w-[0.1rem] mx-auto rounded-full bg-[#4899B7] invisible"}></div>
                </button>
                <button onClick={()=>{setChoice(3)}} className="text-center ">
                    <h1 className={choice == 3 ?"text-[#E94B60] ":"text-[#7272728F]"}>Growth Mindset</h1>
                    <div className={choice == 3 ?"p-1 w-[0.1rem] mx-auto rounded-full bg-[#E94B60] ":"p-1 w-[0.1rem] mx-auto rounded-full bg-[#4899B7] invisible"}></div>
                </button>
                <button onClick={()=>{setChoice(4)}} className="text-center ">
                    <h1 className={choice == 4 ?"text-[#E94B60] ":"text-[#7272728F] mr-auto"}>Active Listening</h1>
                    <div className={choice == 4 ?"p-1 w-[0.1rem] mx-auto rounded-full bg-[#E94B60] ":"p-1 w-[0.1rem] mx-auto rounded-full bg-[#4899B7] invisible"}></div>
                </button>
                
            </div>
            </div>
            <div className="w-full">

            
            {
                choice == 0 
                &&
                <div className="lg:grid lg:grid-cols-3  lg:space-y-0 space-y-4 gap-8">
                    <button  onClick={()=>{
          setLink('dItUGF8GdTw')
          setOpen(true)
          }} className=" text-left">
        <div className="bg-white px-4 py-5 flex  lg:mr-0 mr-4   space-x-4 rounded-xl">
            <img src={thumbnail1} className="w-1/3 h-full rounded-xl"></img>
                    <div className="space-y-2">
                    <h1 className="lg:text-xl text-lg font-bold">5 tips to improve your critical thinking </h1>
                    <h1 className="lg:text-lg text-lg">by Samantha Agoos</h1>
                    <div className="h-[0.1rem] bg-[#D9D9D9]"></div>
                    <h1 className='text-sm font-extralight'>4 minutes 29 seconds</h1>
            </div>
        </div>
        </button>
        <button onClick={()=>{setLink('x0vUsxhMczI')
      setOpen(true)
      }} className="text-left">
        <div className="bg-white px-4 py-5 flex  lg:mr-0 mr-4   space-x-4 rounded-xl">
            <img src={thumbnail2} className="w-1/3 h-full rounded-xl"></img>
                    <div className="space-y-2">
                    <h1 className="lg:text-xl text-lg font-bold">The Best Way To Learn Critical Thinking</h1>
                    <h1 className="lg:text-lg text-lg">by Jordan Peterson</h1>
                    <div className="h-[0.1rem] bg-[#D9D9D9]"></div>
                    <h1 className='text-sm font-extralight'>4 minutes 1 seconds</h1>
            </div>
        </div>
        </button>
        <div className="self-end">
            <Link to="/courses">
            <p className="font-bold">more..</p>
            </Link>
        </div>
                </div>
            }
            {
                choice == 1
                &&
                <div className="lg:grid lg:grid-cols-3 lg:space-y-0 space-y-4 gap-8">
                    <div className="h-full">
          <button onClick={()=>{setLink('pJ7RgUCEd5M')
      setOpen(true)
      }} className=" text-left h-full w-full">
        <div className="bg-white px-4 py-5 flex h-full   lg:mr-0 mr-4   space-x-4 rounded-xl">
            <img src={thumbnail16} className="w-1/3 h-1/2 rounded-xl"></img>
                    <div className="space-y-2">
                    <h1 className="lg:text-xl text-lg font-bold">Top 5 Tips to Improve Communication Skills</h1>
                    <h1 className="lg:text-lg text-lg">by Simplilearn</h1>
                    <div className="h-[0.1rem] bg-[#D9D9D9]"></div>
                    <h1 className='text-sm font-extralight'>5 minutes 55 seconds</h1>
            </div>
        </div>
        </button>
            </div>

            <div className="h-full">
          <button onClick={()=>{setLink('cFdCzN7RYbw')
      setOpen(true)
      }} className=" text-left h-full w-full">
        <div className="bg-white px-4 py-5 flex h-full  lg:mr-0 mr-4   space-x-4 rounded-xl">
            <img src={thumbnail10} className="w-1/3 h-1/2 rounded-xl"></img>
                    <div className="space-y-2">
                    <h1 className="lg:text-xl text-lg font-bold">Science Of Persuasion</h1>
                    <h1 className="lg:text-lg text-lg">by influenceatwork</h1>
                    <div className="h-[0.1rem] bg-[#D9D9D9]"></div>
                    <h1 className='text-sm font-extralight'>11 minutes 50 seconds</h1>
            </div>
        </div>
        </button>
            </div>
            <div className="self-end">
            <Link to="/courses" >
            <p className="font-bold">more..</p>
            </Link>
        </div>
                </div>
            }
            {
                choice ==2
                &&
                <div className="lg:grid lg:grid-cols-3 lg:space-y-0  space-y-4 gap-8">
                    <div className="h-full">
          <button onClick={()=>{setLink('1sSing6ntVA')
      setOpen(true)
      }} className=" text-left h-full">
        <div className="bg-white px-4 py-5 flex  lg:mr-0 mr-4   space-x-4 rounded-xl">
            <img src={thumbnail11} className="w-1/3 h-full rounded-xl"></img>
                    <div className="space-y-2">
                    <h1 className="lg:text-xl text-lg font-bold">How to Improve your Teamwork Skills instead of Stressing Out by Peer Connect</h1>
                    <h1 className="lg:text-lg text-lg">by I Time Travel Well</h1>
                    <div className="h-[0.1rem] bg-[#D9D9D9]"></div>
                    <h1 className='text-sm font-extralight'>3 minutes 2 seconds</h1>
            </div>
        </div>
        </button>
            </div>

            <div className="h-full">
          <button onClick={()=>{setLink('UwsMogSQmYI')
      setOpen(true)
      }} className=" text-left h-full w-full">
        <div className="bg-white px-4 py-5 flex h-full   lg:mr-0 mr-4   space-x-4 rounded-xl">
            <img src={thumbnail12} className="w-1/3 h-1/2 rounded-xl"></img>
                    <div className="space-y-2">
                    <h1 className="lg:text-xl text-lg font-bold">Teamwork Reimagined</h1>
                    <h1 className="lg:text-lg text-lg">by Kevin Cahill</h1>
                    <div className="h-[0.1rem] bg-[#D9D9D9]"></div>
                    <h1 className='text-sm font-extralight'>13 minutes 53 seconds</h1>
            </div>
        </div>
        </button>
            </div>
            <div className="self-end">
            <Link to="/courses">
            <p className="font-bold">more..</p>
            </Link>
        </div>
                </div>
            }
            {
                choice == 3
                &&
                <div className="lg:grid lg:space-y-0 space-y-4 lg:grid-cols-3 gap-8">
                    <button onClick={()=>{setLink('rUJkbWNnNy4')
      setOpen(true)
      }} className=" text-left">
        <div className="bg-white px-4 py-5 flex  lg:mr-0 mr-4   space-x-4 rounded-xl">
            <img src={thumbnail5} className="w-1/3 h-full rounded-xl"></img>
                    <div className="space-y-2">
                    <h1 className="lg:text-xl text-lg font-bold">Developing A Growth Mindset</h1>
                    <h1 className="lg:text-lg text-lg">by ClickView</h1>
                    <div className="h-[0.1rem] bg-[#D9D9D9]"></div>
                    <h1 className='text-sm font-extralight'>4 minutes 51 seconds</h1>
            </div>
        </div>
        </button>
        <button onClick={()=>{setLink('hiiEeMN7vbQ')
      setOpen(true)
      }} className=" text-left">
        <div className="bg-white px-4 py-5 flex  lg:mr-0 mr-4   space-x-4 rounded-xl">
            <img src={thumbnail6} className="w-1/3 h-full rounded-xl"></img>
                    <div className="space-y-2">
                    <h1 className="lg:text-xl text-lg font-bold">Developing a Growth Mindset</h1>
                    <h1 className="lg:text-lg text-lg">by Carol Dweck</h1>
                    <div className="h-[0.1rem] bg-[#D9D9D9]"></div>
                    <h1 className='text-sm font-extralight'>4 minutes 51 seconds</h1>
            </div>
        </div>
        </button>
        <div className="self-end">
            <Link to="/courses">
            <p className="font-bold">more..</p>
            </Link>
        </div>
                </div>
            }
            {
                choice  == 4
                &&
                <div>
                    <div className="lg:grid lg:space-y-0 space-y-4 lg:grid-cols-3 gap-8 items-stretch ">
          <div className="h-full">
        <button onClick={()=>{setLink('7wUCyjiyXdg')
      setOpen(true)
      }} className="h-full text-left w-full">
        <div className="bg-white px-4 h-full py-5 flex  w-full lg:mr-0 mr-4   space-x-4 rounded-xl">
            <img src={thumbnail8} className="w-1/3 h-1/2 rounded-xl"></img>
                    <div className="space-y-2">
                    <h1 className="lg:text-xl text-lg font-bold">Active Listening Skills</h1>
                    <h1 className="lg:text-lg text-lg">by Alexander Lyon</h1>
                    <div className="h-[0.1rem] bg-[#D9D9D9]"></div>
                    <h1 className='text-sm font-extralight'>6 minutes 13 seconds</h1>
            </div>
        </div>
        </button>
          </div>
          <div className="h-full">
        <button onClick={()=>{setLink('NqgIVZJv-do')
      setOpen(true)
      }} className=" text-left h-full">
        <div className="bg-white px-4 py-5 flex  lg:mr-0 mr-4   space-x-4 rounded-xl">
            <img src={thumbnail9} className="w-1/3 h-full rounded-xl"></img>
                    <div className="space-y-2">
                    <h1 className="lg:text-xl text-lg font-bold">5 Techniques to Improve Active Listening Skills</h1>
                    <h1 className="lg:text-lg text-lg">by English Learning Hub</h1>
                    <div className="h-[0.1rem] bg-[#D9D9D9]"></div>
                    <h1 className='text-sm font-extralight'>6 minutes 13 seconds</h1>
            </div>
        </div>
        </button>
          </div>
          <div className="self-end">
            <Link to="/courses">
            <p className="font-bold">more..</p>
            </Link>
        </div>
          
        </div>
                </div>

            }
            </div>
            
        </div>
        <div>
            <p className='font-poppins font-extrabold text-xl mb-4 ml-4 lg:ml-0'>Our Webinars</p>
            <div className="lg:grid space-y-5 lg:space-y-0 lg:grid-cols-3  gap-8">
            <div className="h-full">
        <button onClick={()=>{setLink('x4Q5zXaUySA')
      setOpen(true)
      }} className=" text-left h-full">
        <div className="bg-white px-4 py-5 flex  lg:mr-0 mr-4   space-x-4 rounded-xl">
            <img src={thumbnail13} className="w-1/3 h-full rounded-xl"></img>
                    <div className="space-y-2">
                    <h1 className="lg:text-xl text-lg font-bold">How To Get Your Resume Shortlisted</h1>
                    <h1 className="lg:text-lg text-lg">by Interview Vision</h1>
                    <div className="h-[0.1rem] bg-[#D9D9D9]"></div>
                    <h1 className='text-sm font-extralight'>33 minutes 16 seconds</h1>
            </div>
        </div>
        </button>
          </div>
          <div className="h-full">
        <button onClick={()=>{setLink('YPiTM_PJvtU')
      setOpen(true)
      }} className=" text-left h-full">
        <div className="bg-white px-4 py-5 flex  lg:mr-0 mr-4   space-x-4 rounded-xl">
            <img src={thumbnail15} className="w-1/3 h-full rounded-xl"></img>
                    <div className="space-y-2">
                    <h1 className="lg:text-xl text-lg font-bold">How To Respond To Key Questions</h1>
                    <h1 className="lg:text-lg text-lg">by Interview Vision</h1>
                    <div className="h-[0.1rem] bg-[#D9D9D9]"></div>
                    <h1 className='text-sm font-extralight'>51 minutes 49 seconds</h1>
            </div>
        </div>
        </button>
          </div>
          <div className="self-end">
            <Link to="/courses">
            <p className="font-bold">more..</p>
            </Link>
        </div>
            </div>
        </div>
        {/* onclick send to payment page user razorpay */}
        {/* <div>
            Choose a pack that suits you
            <div className="grid grid-cols-3">
                <div>
                    One interview Pack
                    INR 125
                </div>
                <div>
                    Three interview pack
                    INR 300
                </div>
                <div>
                    Five Interview Pack
                    INR 375
                </div>
            </div>
        </div> */}
        
        {
          open &&
        <div className={width===300?"fixed top-0 left-0 lg:w-1/3 px-4 lg:h-1/2 bg-[#2d9dba] rounded-xl shadow-2xl":"fixed  right-0 bottom-0 lg:w-1/3 lg:h-1/2 bg-[#2d9dba] rounded-xl shadow-2xl"}>
          <div className="flex justify-between items-center">
          <p className="text-white ml-4 text-xl mt-4 font-poppins">Now Playing</p>
          <button className="text-white text-xl font-poppins mr-4" onClick={()=>{setOpen(false)}}>x</button>
          </div>
          <div className="mx-auto text-center">
          <YouTube videoId={link} iframeClassName='mx-auto my-4 ' className="" opts={{width:width, height:height}} />
          </div>
        </div>
        }
        
    </div>
  )
  else return(
    <div>
        Loading..
    </div>
  )
}

export default Taketest

import qoutes from '../components/Quotes'
import idea from '../images/idea.png'
import { useRecordWebcam } from 'react-record-webcam'
import {ThreeDots} from 'react-loader-spinner'
import axios from 'axios'
import React, {useState, useEffect} from 'react'
import logo from '../images/logo_white.png'
import testpic1 from '../images/test_pic1.png'
import testpic2 from '../images/test_pic2.png'
import clip from '../images/Clip.png'
import { Link , useLocation} from 'react-router-dom'
import {InfinitySpin} from 'react-loader-spinner'
import {useNavigate} from 'react-router-dom'
import uuid from 'react-uuid'
import 'video.js/dist/video-js.min.css';
import {
  MediaPermissionsError,
  MediaPermissionsErrorType,
  requestAudioPermissions,
  requestVideoPermissions,
  requestMediaPermissions
} from 'mic-check';
import 'videojs-record/dist/css/videojs.record.css';
import WaveSurfer from 'wavesurfer.js';
import MicrophonePlugin from 'wavesurfer.js/dist/plugin/wavesurfer.microphone.js';
// register videojs-wavesurfer plugin
import 'videojs-wavesurfer/dist/css/videojs.wavesurfer.css';
import ReactGA from 'react-ga';
import instructions from '../images/instructions.mp4'
import {RxCross1} from 'react-icons/rx'
import ReactStars from 'react-stars'
import data1 from '../config'


function Data() {
  WaveSurfer.microphone = MicrophonePlugin;
  const recordWebcam = useRecordWebcam({ frameRate: 60 , height:500, width:480});
  const [choice1, setChoice1] = useState(0)
  const [choice2, setChoice2] = useState(0)
  const [choice3, setChoice3] = useState(0)
  const [choice4, setChoice4] = useState(0)
  const [firstinterview, setFirstInterview] = useState(null)
  const [emailSubmitted, setEmailSubmitted] = useState(false)
  const [choice5, setChoice5] = useState(0)
  const [choice6, setChoice6] = useState(0)
  const [index, setIndex] = useState(0)
  const [comm, setComm] = useState(0)
  const [crit, setCrit] = useState(0)
  const [userEmail, setUserEmail] = useState('')
  const [leader, setLeader] = useState(0)
  const [team, setTeam] = useState(0)
  const [star, setStar] = useState(0)
  const [intro, setIntro] = useState(0)
  const [grow, setGrow] = useState(0)
  const [status, setStatus] = useState(true)
  const [toi, setToi] = useState('')
  const [ques, setQues] = useState(0)
  const [mcqs, setMcqs] = useState([])
  const email = localStorage.getItem('email')
  const [loader, setLoader] = useState(true)
  const [qoutesindex, setQoutesIndex] = useState([])
  const [i, setI] = useState(0)
  const [j, setJ] = useState(0)
  const [uid, setUid] = useState('')
  const [loader1, setLoader1] = useState(false)
  const [loader2, setLoader2] = useState(false)
  const [loader3, setLoader3] = useState(false)
  const [loader4, setLoader4] = useState(false)
  const [loader5, setLoader5] = useState(false)
  const [loader6, setLoader6] = useState(false)
  const [loader7, setLoader7] = useState(false)
  const [loader8, setLoader8] = useState(false)
  const [feed, setFeed] = useState([])
  const [visibleVideo, setVisibleVideo] = useState(true)
  const [visibleVideo2, setVisibleVideo2] = useState(true)
  const navigate = useNavigate()
  const [questions, setQuestions] = useState([])
  const [visible, setvisible] = useState(false)
  const [, updateState] = React.useState();
  const [isDone, setIsDone] = useState(false)
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const user  = localStorage.getItem('email')
  const feedback_save = ()=>{
    if(choice1 == 0 || choice2 == 0 || choice3 == 0 || choice4 == 0 || choice5 == 0 || choice6 == 0){
      console.log(choice1,choice2,choice3,choice4,choice5,choice6)
      alert('Enter a valid choice')
    }
    else{
      setFeed([choice1, choice2, choice3, choice4, choice5, choice6])
      axios.post(`https://${data1.API}/interview/feedback`,{
        username: user,
        feedback:feed
      })
      .then((response) =>{
        console.log(response)
      })
      .catch((error) =>{
        console.log(error)
      })
      recordWebcam.close()
      navigate('/home')
    }
  }
  const feedback=[
    {
      statement:"How would you rate the user interface of the platform?",
      options:[
        "Excellent",
        "Good",
        "Average",
        "Poor"
      ]
    },
    {
      statement:"How effective was the platform in simulating a real interview experience?",
      options:[
        "Very effective, it felt like a real interview",
        "Moderately effective, but it could be improved",
        "Not very effective, it didn’t resemble a real interview",
        "I did not find the simulation useful"
      ]
    },
    {
      statement:"Were the available interview questions relevant and challenging?",
      options:[
        "Yes, the questions were highly relevant and challenging",
        "Yes, but some questions could be improved",
        "No, the questions were not relevant or challenging enough",
        "I did not find the available questions useful"
      ]
    },
    {
      statement:"Would you recommend this platform to others who want to improve their interview skills?",
      options:[
        "1",
        "2",
        "3",
        "4",
        "5"
      ]
    },
    {
      statement:"How satisfied were you with the overall functionality and features of the platform?",
      options:[
        "Very satisfied",
        "Satisfied",
        "Neutral",
        "Dissatisfied",
        "Very dissatisfied"
      ]
    },
    {
      statement:"Would you consider using this platform again in the future?",
      options:[
        "Definitely",
        "Likely",
        "Undecided",
        "Unlikely",
        "Definitely not"
      ]
    }
  ]

  useEffect(()=>{
    const email = localStorage.getItem('email')
   
    setUid(uuid())
    axios.get(`https://${data1.API}/users/r/${email}`)
    .then((response)=>{
      console.log(response)
      setFirstInterview(response.data.firsti_nterview)
      axios.get(`https://${data1.API}/interview/questions`)
      .then((response)=>{
          console.log(response);
          setQuestions(response.data.result)
          console.log(questions)
          setLoader(false)
      })
      .catch((err)=>{
          console.log(err);
      })
    })
    .catch((error)=>{
        alert('Something went wrong! Please try again')
        console.log(error)
    })
},[])



useEffect(()=>{
  if(firstinterview != null){
    if(firstinterview === false){
      setToi('free')
    }
    else if(firstinterview === true){
      setToi('paid')
    }
  }
},[firstinterview])

  useEffect(()=>{
    requestMediaPermissions()
    .then(()=>{
      recordWebcam.open()
    })
    .catch((error) =>{
      const { type, name, message } = error;
      if(type === MediaPermissionsErrorType.SystemPermissionDenied){
        alert('Please allow permission from system')
      }
      else if(type === MediaPermissionsErrorType.UserPermissionDenied){
        alert('You denied the permission in browser, please allow permission from system')
      }
      else if(type === MediaPermissionsErrorType.CouldNotStartVideoSource){
        alert('Your video was busy in other places, please close all tabs to enable camera')
      }
      else{
        alert('Something went wrong, please try again')
      }
    })
     
      // setI(localStorage.getItem('i'))
      // setQues(localStorage.getItem('ques'))
    },[])
    
    useEffect(()=>{
      if(loader8 == true) {
        setInterval(()=>{
          var ind = (Math.floor(Math.random()*(124)))
          setIndex(ind)
        },5000)
      }
    },[loader8])


  useEffect(()=>{
    recordWebcam.open();
    if(recordWebcam.status == "ERROR")
    {
      alert('Please try again later')
      navigate('/')
    }
    
    if(ques == 1){
      ReactGA.event({
        category:"Answering_MCQ",
        action:"User started givong MCQ interview"
      })
    }
    else if(ques>=5){
      ReactGA.event({
        category:"ANSWERING_VIDEO",
        action:"User started givong VIDEO interview"
      })
    }
  },[ques])

  console.log(toi)
  console.log(qoutesindex)

  const shuffle  = () =>{
    return (Math.floor(Math.random()*(124)))
  }




  useEffect(()=>{
    // localStorage.setItem('i',i)
    if(i==10){
      setQues(4)
    }
  },[i])

  useEffect(() => {
    const handleTabClose = event => {
      event.preventDefault();
  
      console.log('beforeunload event triggered');
  
      return (event.returnValue =
        'Are you sure you want to exit?');
    };
  
    window.addEventListener('beforeunload', handleTabClose);
  
    return () => {
      window.removeEventListener('beforeunload', handleTabClose);
    };
  }, []);

  const convert = (ans)=>{
    if(ans == 'Bad'){
      return 1;
    }
    else if(ans == "Below Average"){
      return 2;
    }
    else if(ans == "Average"){
      return 3;
    }
    else if(ans == "Above Average"){
      return 4;
    }
    else if(ans == "Good"){
      return 5;
    }
  }


  const save = (cat, ans)=>{
    if(cat == "ct"){
      setCrit(crit+convert(ans));
    }
    if(cat == "l"){
      setLeader(leader+convert(ans));
    }
    if(cat=="t"){
      setTeam(team+convert(ans));
    }
    if(cat=="i"){
      setIntro(intro+convert(ans));
    }
    if(cat == "g"){
      setGrow(grow+convert(ans));
    }
    if(cat == "c"){
      setComm(comm+(convert(ans)))
    }
  }

  const stop = ()=>{
    recordWebcam.stop()
    setStatus(false)
  }

  const retake = ()=>{
    recordWebcam.retake()
    setStatus(true)
  }

  // useEffect(()=>{
  //   forceUpdate()
  // },[crit, team,leader,grow, comm, intro])

  console.log(crit, team,leader,grow, comm, intro)

  const upload =  (data, cat, q)=>{
    // console.log('res sent')
     
  }
  
  const saveFile1 = async () => {
    const blob = await recordWebcam.getRecording();
    const data = new FormData()
    data.append('file',blob)
    data.append('questionText',questions[0].statement)
    data.append('questionId',questions[0].index)
    data.append('username',email)
    data.append('interviewid', uid)
    data.append('category',questions[0].category)
    data.append('interviewtype', toi)
    axios.post(`https://${data1.API}/interview/video?flag=false`,data,
     {
      headers:{
        "Content-Type":"multipart/form-data"
      }
    })
    .then((response) =>{
      console.log(response);
      console.log(response.data);
      // // setRank(response.data.result.rank)
      // save(cat,response.data.result.rank)
      setLoader1(false)
      
    })
    .catch((error) =>{
      console.log(error)
    })
    // upload(data, 'i',5)
    setQues(5)
    console.log(intro)
    recordWebcam.open()
    ReactGA.event({
      category:"Answered_q1",
      action:"User answers q1"
    })
    // loader = false
  };

  const saveFile2 = async () => {
    // loader = true
    const blob = await recordWebcam.getRecording();
    const data = new FormData()
    // forceUpdate()
    data.append('file',blob)
    data.append('questionText',questions[1].statement)
    data.append('questionId',questions[1].index)
    data.append('username',email)
    data.append('interviewid', uid)
    data.append('category',questions[1].category)
    data.append('interviewtype', toi)
    axios.post(`https://${data1.API}/interview/video?flag=false`,data,
     {
      headers:{
        "Content-Type":"multipart/form-data"
      }
    })
    .then((response) =>{
      console.log(response);
      console.log(response.data);
      // // setRank(response.data.result.rank)
      // save(cat,response.data.result.rank)
      setLoader2(false)
      
    })
    .catch((error) =>{
      console.log(error)
    })
    // upload(data, 'i',6)
    setQues(6)
    ReactGA.event({
      category:"Answered_q2",
      action:"User answers q2"
    })
    recordWebcam.open()
    console.log(blob)
    // loader = false
  };

  const saveFile3 = async () => {
    // loader = true
    const blob = await recordWebcam.getRecording();
    const data = new FormData()
    // forceUpdate()
    data.append('file',blob)
    data.append('questionId',questions[2].index)
    data.append('questionText',questions[2].statement)
    data.append('username',email)
    data.append('interviewid', uid)
    data.append('interviewtype', toi)
    data.append('category',questions[2].category)
    axios.post(`https://${data1.API}/interview/video?flag=false`,data,
     {
      headers:{
        "Content-Type":"multipart/form-data"
      }
    })
    .then((response) =>{
      console.log(response);
      console.log(response.data);
      // // setRank(response.data.result.rank)
      // save(cat,response.data.result.rank)
      setLoader3(false)
      
    })
    .catch((error) =>{
      console.log(error)
    })
    // upload(data,'i',7)
    setQues(7)
    ReactGA.event({
      category:"Answered_q3",
      action:"User answers q3"
    })
    recordWebcam.open()
    console.log(blob)
    // loader = false
  };

  const saveFile4 = async () => {
    // loader = true
    const blob = await recordWebcam.getRecording();
    const data = new FormData()
    // forceUpdate()
    data.append('file',blob)
    data.append('questionText',questions[3].statement)
    data.append('questionId',questions[3].index)
    data.append('username',email)
    data.append('interviewid', uid)
    data.append('category',questions[3].category)
    data.append('interviewtype', toi)
    axios.post(`https://${data1.API}/interview/video?flag=false`,data,
     {
      headers:{
        "Content-Type":"multipart/form-data"
      }
    })
    .then((response) =>{
      console.log(response);
      console.log(response.data);
      // // setRank(response.data.result.rank)
      // save(cat,response.data.result.rank)
      setLoader4(false)
      
    })
    .catch((error) =>{
      console.log(error)
    })
    // upload(data, 'c',8)
    setQues(8)
    ReactGA.event({
      category:"Answered_q4",
      action:"User answers q4"
    })
    recordWebcam.open()
    console.log(blob)
    // loader = false
  };
  const saveFile5 = async () => {
    // loader = true
    console.log('start')
    const blob = await recordWebcam.getRecording();
    const data = new FormData()
    // forceUpdate()
    data.append('file',blob)
    data.append('questionText',questions[4].statement)
    data.append('questionId',questions[4].index)
    data.append('username',email)
    data.append('interviewid', uid)
    data.append('interviewtype', toi)
    data.append('category',questions[4].category)
    axios.post(`https://${data1.API}/interview/video?flag=false`,data,
     {
      headers:{
        "Content-Type":"multipart/form-data"
      }
    })
    .then((response) =>{
      console.log(response);
      console.log(response.data);
      // // setRank(response.data.result.rank)
      // save(cat,response.data.result.rank)
      setLoader5(false)
      
    })
    .catch((error) =>{
      console.log(error)
    })
    // upload(data, 'l',9)
    setQues(9)
    recordWebcam.open()
    console.log(blob)
    ReactGA.event({
      category:"Answered_q5",
      action:"User answers q5"
    })
    console.log('stop')
    // loader = false
  };
  const saveFile6 = async () => {
    // loader = true
    const blob = await recordWebcam.getRecording();
    const data = new FormData()
    // forceUpdate()
    data.append('file',blob)
    data.append('questionText',questions[5].statement)
    data.append('questionId',questions[5].index)
    data.append('username',email)
    data.append('interviewid', uid)
    data.append('category',questions[5].category)
    data.append('interviewtype', toi)
    axios.post(`https://${data1.API}/interview/video?flag=false`,data,
     {
      headers:{
        "Content-Type":"multipart/form-data"
      }
    })
    .then((response) =>{
      console.log(response);
      console.log(response.data);
      // // setRank(response.data.result.rank)
      // save(cat,response.data.result.rank)
      setLoader6(false)
      
    })
    .catch((error) =>{
      console.log(error)

    })
    // upload(data,'t',10)
    setQues(10)
    ReactGA.event({
      category:"Answered_q6",
      action:"User answers q6"
    })
    recordWebcam.open()
    console.log(blob)
    // loader = false
  };
  
  const saveFile8 = async () => {
    // loader = true
    const blob = await recordWebcam.getRecording();
    const data = new FormData()
    console.log(data)
    // forceUpdate()
    data.append('file',blob)
    data.append('questionText',questions[6].statement)
    data.append('questionId',questions[6].index)
    data.append('username',email)
    data.append('interviewid', uid)
    data.append('category',questions[6].category)
    data.append('interviewtype', toi)
    axios.post(`https://${data1.API}/interview/video?flag=false`,data,
     {
      headers:{
        "Content-Type":"multipart/form-data"
      }
    })
    .then((response) =>{
      console.log(response);
      console.log(response.data);
      // // setRank(response.data.result.rank)
      // save(cat,response.data.result.rank)
      setLoader7(false)
      
    })
    .catch((error) =>{
      console.log(error)
    })
    // upload(data,'g',11)
    setQues(11)
    ReactGA.event({
      category:"Answered_q7",
      action:"User answers q7"
    })
    recordWebcam.open()
    console.log(blob)
    // loader = false
  };
  
  const saveFile9 = async () => {
    // loader = true
    const blob = await recordWebcam.getRecording();
    const data = new FormData()
    // forceUpdate()
    data.append('file',blob)
    data.append('questionText',questions[7].statement)
    data.append('questionId',questions[7].index)
    data.append('username',email)
    data.append('interviewid', uid)
    data.append('interviewtype', toi)
    data.append('category',questions[7].category)
    axios.post(`https://${data1.API}/interview/video?flag=false`,data,
     {
      headers:{
        "Content-Type":"multipart/form-data"
      }
    })
    .then((response) =>{
      console.log(response);
      ReactGA.event({
        category:"Answered_q8",
        action:"User answers q8"
      })
      if(toi === "free"){
        axios.put(`https://interviewvision.com/users/u/freeinterview/${email}`)
        .then((response)=>{
          console.log(response)
          setLoader8(false)
          setQues(12)
        })
        .catch((error)=>{
          console.log(error);
        })
      }
      else{
        axios.put(`https://${data1.API}/users/u/interview/`,{
          username:email
        })
        .then((response)=>{
          console.log(response)
          setLoader8(false)
          setQues(12)
        })
        .catch((error)=>{
          console.log(error);
        })
      }
      
    })
    .catch((error) =>{console.log(error)})
    recordWebcam.close()
  };

  const nav = ()=>{
    
    recordWebcam.close()
    if(star!=0){
      axios.post(`https://${data1.API}/interview/feedback`,{
        username:user,
        feedback:[star],
      })
      .then((response) =>{
        console.log(response)
        navigate('/dashboard')
      })
      .catch((error) =>{
        console.log(error)
      })
    }
    else{
      navigate('/dashboard')
    }
  }

  const back = ()=>{

    navigate('/home')

  }
  console.log(questions)
  console.log(questions.length)

  if(loader){
    return(
      <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
             <InfinitySpin
             width='200'
             color='#4899B7'
             ></InfinitySpin>
      </div>  
    )
  }
  else return (
    <div>
      {
        visible &&
        <div className="absolute left-1/2 top-1/2 px-4 py-3  rounded-xl space-y-3 bg-white z-60 -translate-x-1/2 backdrop-blur-sm -translate-y-1/2 " >
          <p className="text-xl">Are you sure you want quit now?</p>
          <p>If you quit now, all your answers submitted till now will be lost and you will have to start from the beginning again</p>
        </div>
      }
    
    {
      ((isDone) && (loader7 || loader8))?
      <div className="flex w-full h-full justify-center items-center min-h-screen ">
        <div className="flex flex-col p-6 items-center font-poppins space-y-5">
          <img src={idea} className="h-10 text-center animate-bounce "></img>
          <p className="text-xl font-extrabold">Super! You have completed your answers. Give us a few moments to upload your answers to our knowledge cloud.</p>
          <p className="font-extrabold text-xl">Pro Tip</p>
        <p className="text-lg font-extrabold "><span className="font-mono hidden lg:block text-6xl">"</span>{qoutes[index]}<span className="font-mono hidden text-6xl">"</span></p>
             <ThreeDots
             width='50'
             color='#4899B7'
             className="mx-auto"
             ></ThreeDots>
             <p>interviewvision.com</p>
        </div>
      </div> 
      :
    <div className="bg-cover min-h-screen" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/Bg.png'})`}}>
      <img src={logo} className="lg:h-28 h-16 p-4 "></img>
      {
        ques == 0 &&
        <div className="relative">
          {
            visibleVideo &&
          <div className="fixed bottom-16 md:bottom-4 z-40 right-6 ">
            <video src={instructions} className="rounded-xl h-[10rem] md:h-[16rem] transition-all hover:h-[30rem]" controls playsInline autoPlay muted></video>
            <button onClick={()=>{setVisibleVideo(false)}}>
            <div className="absolute top-0 right-0 bg-black text-white text-lg rounded-full p-2 -mr-4 -mt-4">
              <RxCross1></RxCross1>
            </div>
            </button>
          </div>
          }
          <div className="lg:w-3/6 py-20 relative mx-auto font-poppins">
            <p className=" mx-3">Please read the following instructions carefully before starting with your test interview:</p>
            <div className="lg:grid lg:grid-cols-2  mx-4 space-y-4 lg:space-y-0 py-4 gap-x-6">
              <div className="bg-white rounded-xl py-3 px-3">
                <img src={testpic1} className="h-48 mx-auto my-4"></img>
                <p className="font-bold my-4">Instructions for Recording:</p>
                <ul className="list-disc ml-6">
                  <li>Use a phone/ computer with camera</li>
                  <li>Allow permissions to record when prompted</li>
                  <li>Sit in a quiet place</li>
                  <li>Ensure sufficient light on your face</li>
                  <li>Speak clearly and slowly</li>
                </ul>
              </div>
              <div className="bg-white rounded-xl py-3 px-3">
                <img src={testpic2} className="h-48 mx-auto my-4 "></img>
                <p className="font-bold my-4">How you will be tested?</p>
                <ul className="list-disc ml-6">
                  <li>This is an automatic video interview</li>
                  <li>Click on START recording after reading the question</li>
                  <li>After answering click on STOP and then SUBMIT</li>
                  <li>Complete all answers to get your report</li>
                </ul>
              </div>
            </div>
            <div className="fixed bottom-4 w-full lg:w-1/2 px-3">
              <div className="flex justify-between">
                <button className="bg-gray-200 w-full py-3 rounded-l-xl" onClick={back}>Back</button>
                <button className="bg-[#E94B60] text-white w-full py-3 rounded-r-xl" onClick={()=>{setQues(4)}}>Next</button>
              </div>

            </div>
            {/* <div className="flex justify-between fixed w-3/6 bottom-0 items-center pb-5 mx-3">
              <Link to='/home' >
              <button className="lg:py-4 py-2 px-6 lg:px-12 w-1/2 text-slate-600 bg-[#FFFFFF] rounded-lg ">Back</button>
              </Link>
              <button className="lg:py-4 py-2 px-6 lg:px-12 w-1/2 text-slate-200 bg-gradient-to-r from-[#E94B60] to-[#F0939F] rounded-lg " onClick={()=>{setQues(4)}}>Lets Go</button>
            </div> */}
          </div>
        </div>
      }
     
      
      
      
      {
        (ques == 4) 
        &&
        <div className="lg:w-2/3 mx-3 lg:mx-auto bg-cover rounded-xl py-4 px-3" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/Rectangle5.png'})`}}>
        <div className="flex bg-[#39435A] items-center space-x-3 px-3 py-3 rounded-xl">
              <p className="bg-white rounded-full p-2 px-4 font-bold font-poppins">1</p>
              <p className="text-white font-poppins">{questions[0].statement}</p>
            </div>
      <div className="mx-auto text-center py-3 ">
        <div className="relative lg:w-1/2  mx-auto">
        <video playsInline ref={recordWebcam.previewRef} className={recordWebcam.status !="PREVIEW" ? "mx-auto my-4 rounded-xl hidden":"mx-auto my-4 rounded-xl"} autoPlay muted={status}  loop />
          {
            recordWebcam.status !=="PREVIEW" &&
            <video playsInline ref={recordWebcam.webcamRef} className="mx-auto my-4 rounded-xl " autoPlay muted />
          }
          {
            recordWebcam.status == "RECORDING" &&
            <p className="absolute top-0 text-white w-full mx-autp left-0 right-0">Recording</p>
          }
        </div>
      <div className="space-x-3">
        {
          recordWebcam.status =="OPEN" &&
          <button onClick={recordWebcam.start} className="bg-green-500 px-6 py-3 rounded-xl text-white">Start recording</button>
        }
      {
        recordWebcam.status =="RECORDING" &&
        <button onClick={stop} className="bg-red-600 px-6 py-3 rounded-xl text-white">Stop recording</button>
      }
      {
        recordWebcam.status == "PREVIEW" &&
        <div className="lg:space-x-3 flex flex-col space-y-3 lg:block lg:space-y-0">
          <button onClick={retake} className="bg-blue-600 px-6 py-3 rounded-xl text-white">Retake recording</button>
          <button onClick={recordWebcam.download} className="bg-blue-600 px-6 py-3 rounded-xl text-white">Download recording</button>
          <button onClick={()=>{
            setLoader1(true);
            saveFile1();
          }} className="bg-blue-600 px-6 py-3 rounded-xl text-white">Submit</button>
        </div>
      }
      <button className="bg-transparent px-6 py-3 rounded-xl text-white" onClick={recordWebcam.open}>If you are unable to see your video, click here</button>
      </div>
      </div>
      {
            // recordWebcam.status == "PREVIEW" &&
            
      }
      </div>
      
      }
      {
        ques == 5 &&
        <div className="lg:w-2/3 mx-3 lg:mx-auto bg-cover  rounded-xl py-4 px-3" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/Rectangle5.png'})`}}> 
        <div className="flex bg-[#39435A] items-center space-x-3 px-3 py-3 rounded-xl">
              <p className="bg-white rounded-full p-2 px-4 font-bold font-poppins">2</p>
              <p className="text-white font-poppins">{questions[1].statement}</p>
            </div>
      <div className="mx-auto text-center py-3 ">
        <div className="relative lg:w-1/2 mx-auto">
        <video playsInline ref={recordWebcam.previewRef} className={recordWebcam.status !="PREVIEW" ? "mx-auto my-4 rounded-xl hidden":"mx-auto my-4 rounded-xl"} autoPlay muted={status}  loop />
          {
            recordWebcam.status !=="PREVIEW" &&
            <video playsInline ref={recordWebcam.webcamRef} className="mx-auto my-4 rounded-xl " autoPlay muted />
          }
          {
            recordWebcam.status == "RECORDING" &&
            <p className="absolute top-0 text-white w-full mx-autp left-0 right-0">Recording</p>
          }
        </div>
      <div className="space-x-3">
        {
          recordWebcam.status =="OPEN" &&
        <button onClick={recordWebcam.start} className="bg-green-500 px-6 py-3 rounded-xl text-white">Start recording</button>
        }
      {
        recordWebcam.status =="RECORDING" &&
        <button onClick={stop} className="bg-red-600 px-6 py-3 rounded-xl text-white">Stop recording</button>
      }
      {
        recordWebcam.status == "PREVIEW" &&
        <div className="lg:space-x-3 flex flex-col space-y-3 lg:block lg:space-y-0">
          <button onClick={retake} className="bg-blue-600 px-6 py-3 rounded-xl text-white">Retake recording</button>
          <button onClick={recordWebcam.download} className="bg-blue-600 px-6 py-3 rounded-xl text-white">Download recording</button>
          <button onClick={()=>{
            setLoader2(true)
            saveFile2()
          }} className="bg-blue-600 px-6 py-3 rounded-xl text-white">Submit</button>
        </div>
      }
      <button className="bg-transparent px-6 py-3 rounded-xl text-white" onClick={recordWebcam.open}>If you are unable to see your video, click here</button>
      </div>
      </div>
      {
            // recordWebcam.status == "PREVIEW" &&
            
      }
      </div>
      }
      {
        ques == 6 &&
        <div className="lg:w-2/3 mx-3 lg:mx-auto bg-cover rounded-xl py-4 px-3" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/Rectangle5.png'})`}}>
        <div className="flex bg-[#39435A] items-center space-x-3 px-3 py-3 rounded-xl">
              <p className="bg-white rounded-full p-2 px-4 font-bold font-poppins">3</p>
              <p className="text-white font-poppins">{questions[2].statement}</p>
            </div>
      <div className="mx-auto text-center py-3 ">
        <div className="relative lg:w-1/2 mx-auto">
        <video playsInline ref={recordWebcam.previewRef} className={recordWebcam.status !="PREVIEW" ? "mx-auto my-4 rounded-xl hidden":"mx-auto my-4 rounded-xl"} autoPlay muted={status}  loop />
          {
            recordWebcam.status !=="PREVIEW" &&
            <video playsInline ref={recordWebcam.webcamRef} className="mx-auto my-4 rounded-xl " autoPlay muted />
          }
          {
            recordWebcam.status == "RECORDING" &&
            <p className="absolute top-0 text-white w-full mx-autp left-0 right-0">Recording</p>
          }
        </div>
      <div className="space-x-3">
        {
          recordWebcam.status =="OPEN" &&
        <button onClick={recordWebcam.start} className="bg-green-500 px-6 py-3 rounded-xl text-white">Start recording</button>
        }
      {
        recordWebcam.status =="RECORDING" &&
        <button onClick={stop} className="bg-red-600 px-6 py-3 rounded-xl text-white">Stop recording</button>
      }
      {
        recordWebcam.status == "PREVIEW" &&
        <div className="lg:space-x-3 flex flex-col space-y-3 lg:block lg:space-y-0">
          <button onClick={retake} className="bg-blue-600 px-6 py-3 rounded-xl text-white">Retake recording</button>
          <button onClick={recordWebcam.download} className="bg-blue-600 px-6 py-3 rounded-xl text-white">Download recording</button>
          <button onClick={()=>{
            setLoader3(true)
            saveFile3()
          }} className="bg-blue-600 px-6 py-3 rounded-xl text-white">Submit</button>
        </div>
      }
      <button className="bg-transparent px-6 py-3 rounded-xl text-white" onClick={recordWebcam.open}>If you are unable to see your video, click here</button>
      </div>
      </div>
      {
            // recordWebcam.status == "PREVIEW" &&
            
      }
      </div>
      }
      {
        ques == 7 &&
        <div className="lg:w-2/3 mx-3 lg:mx-auto bg-cover rounded-xl py-4 px-3" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/Rectangle5.png'})`}}>
        <div className="flex bg-[#39435A] items-center space-x-3 px-3 py-3 rounded-xl">
              <p className="bg-white rounded-full p-2 px-4 font-bold font-poppins">4</p>
              <p className="text-white font-poppins">{questions[3].statement}</p>
            </div>
      <div className="mx-auto text-center py-3 ">
        <div className="relative lg:w-1/2 mx-auto">
        <video playsInline ref={recordWebcam.previewRef} className={recordWebcam.status !="PREVIEW" ? "mx-auto my-4 rounded-xl hidden":"mx-auto my-4 rounded-xl"} autoPlay muted={status}  loop />
          {
            recordWebcam.status !=="PREVIEW" &&
            <video playsInline ref={recordWebcam.webcamRef} className="mx-auto my-4 rounded-xl " autoPlay muted />
          }
          {
            recordWebcam.status == "RECORDING" &&
            <p className="absolute top-0 text-white w-full mx-autp left-0 right-0">Recording</p>
          }
        </div>
      <div className="space-x-3">
        {
          recordWebcam.status =="OPEN" &&
        <button onClick={recordWebcam.start} className="bg-green-500 px-6 py-3 rounded-xl text-white">Start recording</button>
        }
      {
        recordWebcam.status =="RECORDING" &&
        <button onClick={stop} className="bg-red-600 px-6 py-3 rounded-xl text-white">Stop recording</button>
      }
      {
        recordWebcam.status == "PREVIEW" &&
        <div className="lg:space-x-3 flex flex-col space-y-3 lg:block lg:space-y-0">
          <button onClick={retake} className="bg-blue-600 px-6 py-3 rounded-xl text-white">Retake recording</button>
          <button onClick={recordWebcam.download} className="bg-blue-600 px-6 py-3 rounded-xl text-white">Download recording</button>
          <button onClick={()=>{
            setLoader4(true)
            saveFile4()
          }} className="bg-blue-600 px-6 py-3 rounded-xl text-white">Submit</button>
        </div>
      }
      <button className="bg-transparent px-6 py-3 rounded-xl text-white" onClick={recordWebcam.open}>If you are unable to see your video, click here</button>
      </div>
      </div>
      </div>
      }
      {
        ques == 8 &&
        <div className="lg:w-2/3 mx-3 lg:mx-auto bg-cover rounded-xl py-4 px-3" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/Rectangle5.png'})`}}>
        <div className="flex bg-[#39435A] items-center space-x-3 px-3 py-3 rounded-xl">
              <p className="bg-white rounded-full p-2 px-4 font-bold font-poppins">5</p>
              <p className="text-white font-poppins">{questions[4].statement}</p>
            </div>
      <div className="mx-auto text-center py-3 ">
        <div className="relative lg:w-1/2 mx-auto">
        <video playsInline ref={recordWebcam.previewRef} className={recordWebcam.status !="PREVIEW" ? "mx-auto my-4 rounded-xl hidden":"mx-auto my-4 rounded-xl"} autoPlay muted={status}  loop />
          {
            recordWebcam.status !=="PREVIEW" &&
            <video playsInline ref={recordWebcam.webcamRef} className="mx-auto my-4 rounded-xl " autoPlay muted />
          }
          {
            recordWebcam.status == "RECORDING" &&
            <p className="absolute top-0 text-white w-full mx-autp left-0 right-0">Recording</p>
          }
        </div>
      <div className="space-x-3">
        {
          recordWebcam.status =="OPEN" &&
        <button onClick={recordWebcam.start} className="bg-green-500 px-6 py-3 rounded-xl text-white">Start recording</button>
        }
      {
        recordWebcam.status =="RECORDING" &&
        <button onClick={stop} className="bg-red-600 px-6 py-3 rounded-xl text-white">Stop recording</button>
      }
      {
        recordWebcam.status == "PREVIEW" &&
        <div className="lg:space-x-3 flex flex-col space-y-3 lg:block lg:space-y-0">
          <button onClick={retake} className="bg-blue-600 px-6 py-3 rounded-xl text-white">Retake recording</button>
          <button onClick={recordWebcam.download} className="bg-blue-600 px-6 py-3 rounded-xl text-white">Download recording</button>
          <button onClick={()=>{
            setLoader5(true)
            saveFile5()
          }} className="bg-blue-600 px-6 py-3 rounded-xl text-white">Submit</button>
        </div>
      }
      <button className="bg-transparent px-6 py-3 rounded-xl text-white" onClick={recordWebcam.open}>If you are unable to see your video, click here</button>
      </div>
      </div>
      </div>
      }
       
      {
        ques == 9 &&
        <div className="lg:w-2/3 mx-3 lg:mx-auto bg-cover rounded-xl py-4 px-3" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/Rectangle5.png'})`}}>
        <div className="flex bg-[#39435A] items-center space-x-3 px-3 py-3 rounded-xl">
              <p className="bg-white rounded-full p-2 px-4 font-bold font-poppins">6</p>
              <p className="text-white font-poppins">{questions[5].statement}</p>
            </div>
      <div className="mx-auto text-center py-3 ">
        <div className="relative lg:w-1/2 mx-auto">
        <video playsInline ref={recordWebcam.previewRef} className={recordWebcam.status !="PREVIEW" ? "mx-auto my-4 rounded-xl hidden":"mx-auto my-4 rounded-xl"} autoPlay muted={status}  loop />
          {
            recordWebcam.status !=="PREVIEW" &&
            <video playsInline ref={recordWebcam.webcamRef} className="mx-auto my-4 rounded-xl " autoPlay muted />
          }
          {
            recordWebcam.status == "RECORDING" &&
            <p className="absolute top-0 text-white w-full mx-autp left-0 right-0">Recording</p>
          }
        </div>
      <div className="space-x-3">
        {
          recordWebcam.status =="OPEN" &&
        <button onClick={recordWebcam.start} className="bg-green-500 px-6 py-3 rounded-xl text-white">Start recording</button>
        }
      {
        recordWebcam.status =="RECORDING" &&
        <button onClick={stop} className="bg-red-600 px-6 py-3 rounded-xl text-white">Stop recording</button>
      }
      {
        recordWebcam.status == "PREVIEW" &&
        <div className="lg:space-x-3 flex flex-col space-y-3 lg:block lg:space-y-0">
          <button onClick={retake} className="bg-blue-600 px-6 py-3 rounded-xl text-white">Retake recording</button>
          <button onClick={recordWebcam.download} className="bg-blue-600 px-6 py-3 rounded-xl text-white">Download recording</button>
          <button onClick={()=>{
            setLoader6(true)
            saveFile6()
            }} className="bg-blue-600 px-6 py-3 rounded-xl text-white">Submit</button>
        </div>
      }
      <button className="bg-transparent px-6 py-3 rounded-xl text-white" onClick={recordWebcam.open}>If you are unable to see your video, click here</button>
      </div>
      </div>
      </div>
      }
      
      {
        ques == 10 &&
        <div className="lg:w-2/3 mx-3 lg:mx-auto rounded-xl bg-cover py-4 px-3" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/Rectangle5.png'})`}}>
        <div className="flex bg-[#39435A] items-center space-x-3 px-3 py-3 rounded-xl">
              <p className="bg-white rounded-full p-2 px-4 font-bold font-poppins">7</p>
              <p className="text-white font-poppins">{questions[6].statement}</p>
            </div>
      <div className="mx-auto text-center py-3 ">
        <div className="relative lg:w-1/2 mx-auto">
        <video playsInline ref={recordWebcam.previewRef} className={recordWebcam.status !="PREVIEW" ? "mx-auto my-4 rounded-xl hidden":"mx-auto my-4 rounded-xl"} autoPlay muted={status}  loop />
          {
            recordWebcam.status !=="PREVIEW" &&
            <video playsInline ref={recordWebcam.webcamRef} className="mx-auto my-4 rounded-xl " autoPlay muted />
          }
          {
            recordWebcam.status == "RECORDING" &&
            <p className="absolute top-0 text-white w-full mx-autp left-0 right-0">Recording</p>
          }
        </div>
      <div className="space-x-3">
        {
          recordWebcam.status =="OPEN" &&
        <button onClick={recordWebcam.start} className="bg-green-600 px-6 py-3 rounded-xl text-white">Start recording</button>
        }
      {
        recordWebcam.status =="RECORDING" &&
        <button onClick={stop} className="bg-red-600 px-6 py-3 rounded-xl text-white">Stop recording</button>
      }
      {
        recordWebcam.status == "PREVIEW" &&
        <div className="lg:space-x-3 lg:block lg:space-y-0 flex flex-col space-y-3">
          <button onClick={retake} className="bg-blue-600 px-6 py-3 rounded-xl text-white">Retake recording</button>
          <button onClick={recordWebcam.download} className="bg-blue-600 px-6 py-3 rounded-xl text-white">Download recording</button>
          <button onClick={()=>{
            setLoader7(true)
            saveFile8()
          }} className="bg-blue-600 px-6 py-3 rounded-xl text-white">Submit</button>
        </div>
      }
      <button className="bg-transparent px-6 py-3 rounded-xl text-white" onClick={recordWebcam.open}>If you are unable to see your video, click here</button>
      </div>
      </div>
      </div>
      }
      {
        ques == 11 &&
        <div className="lg:w-2/3 mx-3 lg:mx-auto rounded-xl bg-cover py-4 px-3" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/Rectangle5.png'})`}}>
        <div className="flex bg-[#39435A] items-center space-x-3 px-3 py-3 rounded-xl">
              <p className="bg-white rounded-full p-2 px-4 font-bold font-poppins">8</p>
              <p className="text-white font-poppins">{questions[7].statement}</p>
            </div>
      <div className="mx-auto text-center py-3 ">
        <div className="relative lg:w-1/2 mx-auto">
        <video playsInline ref={recordWebcam.previewRef} className={recordWebcam.status !="PREVIEW" ? "mx-auto my-4 rounded-xl hidden":"mx-auto my-4 rounded-xl"} autoPlay muted={status}  loop />
          {
            recordWebcam.status !=="PREVIEW" &&
            <video playsInline ref={recordWebcam.webcamRef} className="mx-auto my-4 rounded-xl " autoPlay muted />
          }
          {
            recordWebcam.status == "RECORDING" &&
            <p className="absolute top-0 text-white w-full mx-autp left-0 right-0">Recording</p>
          }
        </div>
      <div className="space-x-3">
        {
          recordWebcam.status =="OPEN" &&
        <button onClick={recordWebcam.start} className="bg-green-500 px-6 py-3 rounded-xl text-white">Start recording</button>
        }
      {
        recordWebcam.status =="RECORDING" &&
        <button onClick={stop} className="bg-red-600 px-6 py-3 rounded-xl text-white">Stop recording</button>
      }
      {
        recordWebcam.status == "PREVIEW" &&
        <div className="lg:space-x-3 lg:block lg:space-y-0 flex flex-col space-y-3">
          <button onClick={retake} className="bg-blue-600 px-6 py-3 rounded-xl text-white">Retake recording</button>
          <button onClick={recordWebcam.download} className="bg-blue-600 px-6 py-3 rounded-xl text-white">Download recording</button>
          <button onClick={()=>{
            setIsDone(true)
            setLoader8(true)
            saveFile9()
          }} className="bg-blue-600 px-6 py-3 rounded-xl text-white">Submit</button>
        </div>
      }
      <button className="bg-transparent px-6 py-3 rounded-xl text-white" onClick={recordWebcam.open}>If you are unable to see your video, click here</button>
      </div>
      </div>
      </div>
      }
      
      {
        ques == 14 && 
        <div className="my-20">
        <div className="lg:w-2/3 mx-3 lg:mx-auto bg-cover  rounded-xl py-4 px-4 lg:px-20" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/Rectangle5.png'})`}}>
        <p className="text-center font- font-poppins my-5 lg:text-4xl lg:leading-10 text-white">Congratulations!<br></br>You have completed the first section. <br></br>Click next to begin your video interview section</p>
        </div>
        <div className="flex justify-between lg:w-2/3 mr-3  lg:mx-auto items-center py-3 pb-5">
          <button className="lg:py-4 py-2 px-6 lg:px-12 text-slate-600 bg-[#FFFFFF] rounded-lg " onClick={()=>{setQues(3)}}>Back</button>
        <button className="lg:py-4 py-2 px-6 lg:px-12 text-slate-200 bg-gradient-to-r from-[#E94B60] to-[#F0939F] rounded-lg " onClick={()=>{setQues(4)}}>Next</button>
        </div>
        </div>
        
      }
      {
        ques!=13 && ques!==12 && ques!==13 && ques!==0 && ques!=14 && ques!=15
        &&
      <input className="mt-4 bottom-0 w-full h-16 outline-none px-2 py-2" placeholder="You can write your notes here"></input>
      }
      {
        ques == 12 &&
        <div className="relative space-y-10 pb-10" >
          {/* {
            visibleVideo2 &&
          <div className="fixed bottom-16 z-20 md:bottom-4 right-6 ">
            <video src={end} className="rounded-xl h-[10rem] md:h-[16rem] transition-all hover:h-[30rem]" controls playsInline autoPlay muted></video>
            <button onClick={()=>{setVisibleVideo2(false)}}>
            <div className="absolute top-0 right-0 bg-black text-white text-lg rounded-full p-2 -mr-4 -mt-4">
              <RxCross1></RxCross1>
            </div>
            </button>
          </div>
          } */}
          <div className="my-20">
        <div className="lg:w-2/3 mx-3 lg:mx-auto bg-cover  rounded-xl py-4 px-4 lg:px-12" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/Rectangle5.png'})`}}>
        <div className="text-center font- font-poppins my-5 lg:text-2xl lg:leading-10"><span className="text-3xl">Congratulations! </span><br></br>Interview Submitted for analysis<br></br>

        </div>
        <p className="text-center">Interview ID : {uid}</p>

        <div className="">
                {/* <p className="bg-white rounded-full p-2 px-4 font-bold font-poppins">1</p> */}
                <p className="mt-10 font-poppins text-center py-2">Rate your experience</p>
              </div>
              <div className="flex w-full justify-center">
              <ReactStars
                count={5}
                className="text-center"
                value={star}
                onChange={(e)=>{setStar(e)}}
                isHalf={false}
                half={false}
                edit={true}
                size={60}
                color1={'#FFFFFF'}
              ></ReactStars>
              </div>

        </div>
        
      </div>
              <div className="w-full flex justify-center text-white">
                <button onClick={nav}  className={"fixed md:relative bottom-5 rounded-xl  py-3 bg-[#E94B60] md:w-1/3 w-1/2 "}>Go to Dashboard</button>
              </div>
          <div>

          </div>
        </div>

      }
    </div>
}
</div>
  );
}

export default Data;

import React, {useState, useEffect} from 'react'
import interviewguru_logo from '../images/interview_guru_logo.png'
import success from '../images/success (2).png'
import tt1 from '../images/tt (1).jpeg'
import tt2 from '../images/tt (2).jpeg'
import tt3 from '../images/tt3.jpeg'
import tt4 from '../images/tt4.jpeg'
import tt5 from '../images/tt5.jpeg'
import tt6 from '../images/tt6.jpeg'
import vid1 from '../images/testimonial_videos/1.MP4'
import vid2 from '../images/testimonial_videos/2.MP4'
import vid3 from '../images/testimonial_videos/3.MP4'
import vid4 from '../images/testimonial_videos/4.MP4'
import vid5 from '../images/testimonial_videos/5.MP4'
import {AiOutlineArrowRight} from 'react-icons/ai'
import {AiOutlineArrowLeft} from 'react-icons/ai'
import axios from 'axios'
import data from '../config'
import uuid from 'react-uuid'
import ErrorModal from '../components/ErrorModal'
import {InfinitySpin} from 'react-loader-spinner'
import { Link } from 'react-router-dom'

const WhatsappFlow = () => {

  const [name, setName] = useState('')
  const [sent, setSent] = useState(false)
  const [loading, setLoading] = useState(true)
  const [phone, setPhone] = useState('')
  const [index, setIndex] = useState(0)
  const [videos, setVideos] = useState([])
  const [checkbox1, setCheckbox1] = useState(false)
  const [checkbox2, setCheckbox2] = useState(false)
  useEffect(()=>{
    setVideos([vid1,vid2,vid3,vid4,vid5])
    setLoading(false)
  },[])

  useEffect(()=>{
    window.fbq('track', 'CompleteRegistration');
  },[])


  const save = () =>{
    axios.post(`https://${data.API}/users/c`,{
            username:phone,
            password:'',
            email:uuid(),
            portal_name:"WhatsApp Flow"
      })
      .then((response)=>{
        axios.put(`https://${data.API}/users/u/${phone}`,{
                fname:name,
                phone:phone
            })
            .then((response)=>{
              axios.post('https://api.interakt.ai/v1/public/track/users/',
        { 
          fullPhoneNumber: `91`+phone,
          "countryCode": "+91",
          "traits": {
              "name": name
          }
      },
      {
        headers:{
          'Content-Type': 'application/json',
          'Authorization':"Basic ZG9EUk5JSVRNWW54VHJJSnhEc0RpR2xMdjBraHRSRWJfV085V2xvTTM4Yzo="
        }
      }
        )
        .then((response)=>{
          console.log(response)
          axios.post('https://api.interakt.ai/v1/public/track/events/',
        { 
          fullPhoneNumber: `91`+phone,
          "countryCode": "+91",
          "event": "user Registered",
      },
      {
        headers:{
          'Content-Type': 'application/json',
          'Authorization':"Basic ZG9EUk5JSVRNWW54VHJJSnhEc0RpR2xMdjBraHRSRWJfV085V2xvTTM4Yzo="
        }
      }
        )
        .then((response)=>{
          console.log(response)
          window.fbq('track', 'CompleteRegistration');
          setLoading(false)
          setSent(true)
        })
        .catch((error)=>{
          console.log(error);
          setLoading(false)
          setSent(true)
        })
        })
        .catch((error)=>{
          console.log(error);
          
        })
            })
            .catch((err)=>{
              console.log(err)
            })
      })
      .catch((err)=>{
        axios.post('https://api.interakt.ai/v1/public/track/users/',
        { 
          fullPhoneNumber: `91`+phone,
          "countryCode": "+91",
          "traits": {
              "name": name
          }
      },
      {
        headers:{
          'Content-Type': 'application/json',
          'Authorization':"Basic ZG9EUk5JSVRNWW54VHJJSnhEc0RpR2xMdjBraHRSRWJfV085V2xvTTM4Yzo="
        }
      }
        )
        .then((response)=>{
          console.log(response)
          axios.post('https://api.interakt.ai/v1/public/track/events/',
        { 
          fullPhoneNumber: `91`+phone,
          "countryCode": "+91",
          "event": "user Registered",
      },
      {
        headers:{
          'Content-Type': 'application/json',
          'Authorization':"Basic ZG9EUk5JSVRNWW54VHJJSnhEc0RpR2xMdjBraHRSRWJfV085V2xvTTM4Yzo="
        }
      }
        )
        .then((response)=>{
          console.log(response)
          window.fbq('track', 'CompleteRegistration');
          setLoading(false)
          setSent(true)
        })
        .catch((error)=>{
          console.log(error);
          setLoading(false)
          setSent(true)
        })
        })
        .catch((error)=>{
          console.log(error);
          
        })
      })
      setPhone('')
      setName('')
}




    const ReadMore = ({ children }) => {
        const text = children;
        const [isReadMore, setIsReadMore] = useState(true);
        const toggleReadMore = () => {
          setIsReadMore(!isReadMore);
        };
        return (
          <p className="text">
            {isReadMore ? text.slice(0, 80) : text}
            <span onClick={toggleReadMore} className="read-or-hide cursor-pointer text-red-500">
              {isReadMore ? " ... read more" : " show less"}
            </span>
          </p>
        );
    };

    const left = () =>{
      if(index==0){
        setIndex(4)
      }
      else{
        setIndex(index-1)
      }
    }

    const right = () =>{
      setIndex((index+1)%5)
    }

    const Content = (props) => {
        return (
          <div className="container">
            <h2>
              <ReadMore>
                {props.content}
              </ReadMore>
            </h2>
          </div>
        );
      };

      function handleState() {
        setSent(false)
    }



  return (
    <div>
     {
      !loading?
    <div className="p-4 font-poppins">
      {
        sent && 
        <ErrorModal text={'An invite link has been sent to your whatsapp account.'} change={handleState} buttonText={"Okay"}></ErrorModal>
      }
        <img src={interviewguru_logo} className="h-12"></img>
        <p className="font-poppins text-3xl mt-10 font-extrabold text-center">This is your ticket to</p>
        <div className="flex space-x-3 w-full justify-center items-center">
            <p className="text-3xl font-poppins font-extrabold text-center mt-3 text-[#E94B60] ">Success</p>
            <img src={success} className="h-10"></img>
        </div>
        <p className="text-center mx-5 my-10 font-bold">Improve your chances by <span className="text-[#E94B60] text-2xl">10X</span> to secure your dream job</p>
        <div className="rounded-xl p-4 bg-[#D1D9ED73] bg-opacity-45 space-y-3">
        <input value={name} onChange={(e)=>{setName(e.target.value)}} className="w-full py-2 outline-none px-2 rounded-xl" placeholder='Enter Name'></input>
          <input value={phone} onChange={(e)=>{setPhone(e.target.value)}} className="w-full py-2 outline-none px-2 rounded-xl" placeholder='Enter number'></input>
          <div className="flex items-center mt-3 space-x-4 mx-5 lg:mx-0">
                <input required type="checkbox" checked={checkbox1} onChange={(e)=>{setCheckbox1(e.target.checked)}} className="accent-[#E94B60] h-4 w-4  lg:h-5 lg:w-5" ></input>
                <p className="text-sm">I agree to the <Link to="/terms" className="text-[#E94B60] font-bold">Terms & Conditions</Link> related to this program </p>
                </div>
                <div className="flex items-center mt-3 space-x-4 mx-5 lg:mx-0">
                <input required type="checkbox" checked={checkbox2} onChange={(e)=>{setCheckbox2(e.target.checked)}} className="accent-[#E94B60] h-7 w-7  lg:h-5 lg:w-5" ></input>
                <p className="text-sm">I agree to be contacted via WhatsAPP for this webinar and other marketing related information</p>
                </div>
        <div className="text-center">
        <button disabled={!(name&&phone && checkbox1 && checkbox2)}  className={((name&&phone && checkbox1 && checkbox2))?"bg-[#E94B60] px-3 py-3 text-white rounded-lg":"bg-gray-400 px-3 py-3 text-white rounded-lg"} onClick={()=>{
          setLoading(true)
          save()}}>Attend Webinar</button>
        <p className="text-sm my-4">Note: You will receive a Whatsapp link with details</p>
        </div>
        </div>
        <p className="text-center mx-4 my-4 text-sm">Learn how to clear top interview in our webinar</p>
        <p className="text-center text-2xl font-bold mt-10">Results!!</p>
        <p className="text-center mx-4 my-4 text-sm">Helping candidates at every step</p>
        <div className="grid grid-cols-2 text-xs gap-4 ">
            <div className="shadow-xl rounded-xl border-2 p-4">
                <p><span className="text-[#E94B60] font-bold">89%</span> Success ratio of Interview Vision candidates</p>
            </div>
            <div className="shadow-xl rounded-xl border-2 p-4">
                <p><span className="text-[#E94B60] font-bold">93%</span> Candidates are able to answer every question asked</p>
            </div>
            <div className="shadow-xl rounded-xl border-2 p-4">
                <p><span className="text-[#E94B60] font-bold">96%</span> Candidates report high confidence in interviews</p>
            </div>
            <div className="shadow-xl rounded-xl border-2 p-4">
                <p><span className="text-[#E94B60] font-bold">91%</span> Candidates have negotiated a better salary</p>
            </div>
        </div>
        <div className="text-center my-10">
        <button className="bg-[#E94B60] px-3 py-3 text-white rounded-lg">Attend Webinar</button>
        </div>

        
        <p className="text-center font-bold text-2xl">User Testimonials</p>
        <div className="w-full flex justify-between items-center">
        <button onClick={left} className=" opacity-80  bg-black text-white h-full p-4  rounded-xl  z-40">
          <AiOutlineArrowLeft className="text-xl h-full"></AiOutlineArrowLeft>
      </button> 
        <video src={videos[index]} 
          autoPlay
          muted
          playsInline
          controls
          loop
          className="h-96 my-7 mx-auto block rounded-xl">
        </video>
        <button onClick={right} className=" opacity-80  bg-black text-white  h-full p-4 rounded-xl  z-40">
          <AiOutlineArrowRight className="text-xl h-full"></AiOutlineArrowRight>
      </button> 
        </div>


        <p className="text-center font-bold text-2xl">Candidates Review</p>

        <div className="lg:grid grid-cols-3 justify-evenly mt-6 gap-4 gap-y-10 items-start  lg:space-y-0 space-y-10">
            <div className="text-left p-4 pt-7 relative transition-all  shadow-2xl rounded-xl">
                <p className="text-red-500 absolute font-serif right-6 -top-9 text-9xl">"</p>
                <Content className="mb-3" content={"As someone transitioning into a new field, Interview Vision gave me the confidence to excel in interviews. The platform's focus on soft skills evaluation, such as critical thinking and problem-solving abilities, helped me highlight my transferable skills. The learning material provided was invaluable in conquering the challenges and securing a job in my desired industry. "}></Content>
               <div className="bg-slate-400 mt-4  text-white -mx-4  -ml-4 p-3 flex items-center  space-x-4">
                <img src={tt1} className="h-[8rem] rounded-xl"></img>
                <div>
                <p className="text-xl font-extrabold">Pallavi Singh</p>
                <p>Manager - Media Business Development</p>
                </div>
                </div> 
            </div>
            <div className="text-left p-4 relative pt-7   shadow-2xl rounded-xl">
                <p className="text-red-500 absolute font-serif right-6 -top-9 text-9xl">"</p>
                <Content className="mb-3" content={"Interview Vision is a game-changer for job seekers! The AI-powered virtual interviews accurately evaluated my critical thinking, communication skills, and problem-solving abilities. The detailed feedback helped me fine-tune my approach and excel in real interviews. Thanks to Interview Vision, I secured multiple job offers and had the confidence to choose the perfect fit for me!"}></Content>
               <div className="bg-slate-400 mt-4  text-white -mx-4   p-3 flex items-center  space-x-4">
                <img src={tt2} className="h-[8rem] rounded-xl"></img>
                <div>
                <p className="text-xl font-extrabold">Derrick James</p>
                <p>Regional HR Manager</p>
                </div>
                </div> 
            </div>
            <div className="text-left p-4 relative pt-7 shadow-2xl rounded-xl">
                <p className="text-red-500 absolute font-serif right-6 -top-9 text-9xl">"</p>
                <Content content={"Interview Vision made a significant impact on my transition from student to professional. The virtual interviews simulated real-world scenarios, allowing me to showcase my creative problem-solving abilities. The personalised feedback and performance scores helped me understand my potential and focus areas. With the guidance and support from Interview Vision, I secured my first job as a digital marketing expert!"} className="mb-3 "></Content>
               <div className="bg-slate-400 mt-4 bottom-3 text-white  -mx-4 lg:-ml-4 p-3 flex items-center  space-x-4">
                <img src={tt3} className="h-[8rem] rounded-xl"></img>
                <div>
                <p className="text-xl font-extrabold">Akash Wadhwa</p>
                <p>Manager - Digital Marketing</p>
                </div>
                </div> 
            </div>
            <div className="text-left p-4 relative pt-7 shadow-2xl rounded-xl">
                <p className="text-red-500 absolute font-serif right-6 -top-9 text-9xl">"</p>
                <Content content={"Interview Vision empowered me to ace my job interviews with confidence! The platform's evaluation of my communication skills and active listening abilities gave me a competitive advantage. The comprehensive analysis of my performance allowed me to tailor my approach and excel in interviews. Thanks to Interview Vision, I secured a job where I can utilize my strengths and grow professionally!"} className="mb-3 "></Content>
               <div className="bg-slate-400 mt-4 bottom-3 text-white  -mx-4 lg:-ml-4 p-3 flex items-center  space-x-4">
                <img src={tt4} className="h-[8rem] rounded-xl"></img>
                <div>
                <p className="text-xl font-extrabold">Akansha Singh</p>
                <p>Customer Service Operations Lead</p>
                </div>
                </div> 
            </div>
            <div className="text-left p-4 relative pt-7 shadow-2xl rounded-xl">
                <p className="text-red-500 absolute font-serif right-6 -top-9 text-9xl">"</p>
                <Content content={"Thanks to Interview Vision, I was able to showcase my creative problem-solving abilities to potential employers. The platform's AI-driven evaluations not only recognized my strengths but also highlighted areas where I could further enhance my skills. The comprehensive learning material provided by Interview Vision equipped me with innovative approaches, and it played a pivotal role in securing a job that allows me to put my problem-solving skills to the test."} className="mb-3 "></Content>
               <div className="bg-slate-400 mt-4 bottom-3 text-white  -mx-4 lg:-ml-4 p-3 flex items-center  space-x-4">
                <img src={tt5} className="h-[8rem] rounded-xl"></img>
                <div>
                <p className="text-xl font-extrabold">Akash Yadav</p>
                <p>Online Learning Faculty</p>
                </div>
                </div> 
            </div>
            <div className="text-left p-4 relative pt-7 shadow-2xl rounded-xl">
                <p className="text-red-500 absolute font-serif right-6 -top-9 text-9xl">"</p>
                <Content content={"I am thrilled to share my experience with Interview Vision, as it played a crucial role in my recent success in landing a job as an associate engineer at a renowned IT company. With numerous candidates vying for the same position, Interview Vision’s unbiased scoring system and evaluation of my communication skills and critical thinking proved to be the deciding factor."} className="mb-3 "></Content>
               <div className="bg-slate-400 mt-4 bottom-3 text-white  -mx-4 lg:-ml-4 p-3 flex items-center  space-x-4">
                <img src={tt6} className="h-[8rem] rounded-xl"></img>
                <div>
                <p className="text-xl font-extrabold">Ishan Agrawal</p>
                <p>4th Year Student, Computer Science Engineering</p>
                </div>
                </div> 
            </div>
        </div>

        <div className="rounded-xl p-4 mt-5 bg-[#D1D9ED73] bg-opacity-45 space-y-3">
          <input value={name} onChange={(e)=>{setName(e.target.value)}} className="w-full py-2 outline-none px-2 rounded-xl" placeholder='Enter Name'></input>
          <input value={phone} onChange={(e)=>{setPhone(e.target.value)}} className="w-full py-2 outline-none px-2 rounded-xl" placeholder='Enter number'></input>
          <div className="flex items-center mt-3 space-x-4 mx-5 lg:mx-0">
                <input required type="checkbox" value={checkbox1} onChange={(e)=>{setCheckbox1(e.target.value)}} className="accent-[#E94B60] h-4 w-4  lg:h-5 lg:w-5" ></input>
                <p className="text-sm">I agree to the <Link to="/terms" className="text-[#E94B60] font-bold">Terms & Conditions</Link> related to this program </p>
                </div>
                <div className="flex items-center mt-3 space-x-4 mx-5 lg:mx-0">
                <input required type="checkbox" value={checkbox2} onChange={(e)=>{setCheckbox2(e.target.value)}} className="accent-[#E94B60] h-7 w-7  lg:h-5 lg:w-5" ></input>
                <p className="text-sm">I agree to be contacted via WhatsAPP for this webinar and other marketing related information</p>
                </div>
        <div className="text-center">
        <button disabled={!(name&&phone && checkbox1 && checkbox2)}  className={((name&&phone && checkbox1 && checkbox2))?"bg-[#E94B60] px-3 py-3 text-white rounded-lg":"bg-gray-400 px-3 py-3 text-white rounded-lg"} onClick={()=>{
          setLoading(true)
          save()}}>Attend Webinar</button>
        <p className="text-sm my-4">Note: You will receive a Whatsapp link with details</p>
        </div>
        </div>



    </div>
    :
    <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
             <InfinitySpin
             width='200'
             color='#4899B7'
             ></InfinitySpin>
         </div>   
     } 
    </div>
  )
}

export default WhatsappFlow
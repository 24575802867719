import React, { useState, useEffect, useRef } from "react";
import laptop_girl from "../images/laptop_girl.png";
import graph from "../images/graph.png";
import group from "../images/group.png";
import headphone from "../images/headphone.png";
import idea from "../images/idea.png";
import speech from "../images/speech-bubble.png";
import intro from "../images/introduction.png";
import meter1 from "../images/meter.jpeg";
import meter2 from "../images/meter_2.jpeg";
import meter3 from "../images/meter_5.png";
import meter4 from "../images/meter_4.png";
import { Link } from "react-router-dom";
import { AiOutlineRobot } from "react-icons/ai";
import { TbFreeRights } from "react-icons/tb";
import { HiOutlineNewspaper } from "react-icons/hi";
import { RiParentLine } from "react-icons/ri";
import { AiOutlineInstagram } from "react-icons/ai";
import { BsLinkedin } from "react-icons/bs";
import { AiFillFacebook } from "react-icons/ai";
import kutumbh from "../images/kutumbh.png";
import epic from "../images/epic-logo.svg";
import biojob from "../images/biojobs.png";
import talreso from "../images/talreso.png";
import pinnacle from "../images/pinnacle.png";
import consultbae from "../images/consultbae.svg";
import { AnimatePresence, motion } from "framer-motion";
import Doranda_college_1 from "../images/Doranda_college(1).jpeg";
import Doranda_college_2 from "../images/Doranda_college(2).jpeg";
import Doranda_college_3 from "../images/Doranda_college(3).jpeg";
import Doranda_college_4 from "../images/Doranda_college(4).jpeg";
import JN_college_1 from "../images/JN_college (1).jpeg";
import JN_college_2 from "../images/JN_college (2).jpeg";
import JN_college_3 from "../images/JN_college (3).jpeg";
import JN_college_4 from "../images/JN_college (4).jpeg";
import JN_college_5 from "../images/JN_college (5).jpeg";
import Nirmala_college_1 from "../images/Nirmala_college (1).jpeg";
import Nirmala_college_2 from "../images/Nirmala_college (2).jpeg";
import Nirmala_college_3 from "../images/Nirmala_college (3).jpeg";
import Nirmala_college_4 from "../images/Nirmala_college (4).jpeg";
import Nirmala_college_5 from "../images/Nirmala_college (5).jpeg";
import Nirmala_college_6 from "../images/Nirmala_college (6).jpeg";
import imac from "../images/imac_screen.png";
import tt1 from "../images/tt (1).jpeg";
import tt2 from "../images/tt (2).jpeg";
import tt3 from "../images/tt3.jpeg";
import tt4 from "../images/tt4.jpeg";
import tt5 from "../images/tt5.jpeg";
import tt6 from "../images/tt6.jpeg";
import ReactGA from "react-ga";
import CountUp from "react-countup";
import videoplay from "../images/interviewchanges.mp4";
import { AiOutlineArrowRight } from "react-icons/ai";
import { AiOutlineArrowLeft } from "react-icons/ai";
import starter from "../images/Master course intro.mp4";
const Entry = () => {
  const scroller = (scrollOffset) => {
    ref.current.scrollLeft += scrollOffset;

    console.log("Scroll active");
  };

  const scroller2 = (scrollOffset) => {
    ref1.current.scrollLeft += scrollOffset;
  };

  const ref = useRef(null);
  const ref1 = useRef(null);
  const [photos, setPhotos] = useState([
    Doranda_college_1,
    Doranda_college_2,
    Doranda_college_3,
    Doranda_college_4,
    JN_college_2,
    JN_college_3,
    Nirmala_college_1,
    Nirmala_college_2,
    Nirmala_college_3,
    Nirmala_college_4,
    Nirmala_college_5,
    Nirmala_college_6,
  ]);
  const [photos1, setPhotos1] = useState([]);
  const [photos2, setPhotos2] = useState([]);
  const [photos3, setPhotos3] = useState([]);
  const [count1, setCount1] = useState(0);
  const [count2, setCount2] = useState(0);
  const [count3, setCount3] = useState(0);
  const [anim, setAnim] = useState(false);
  const [anim2, setAnim2] = useState(false);
  const [anim3, setAnim3] = useState(false);
  const [anim4, setAnim4] = useState(false);
  const [anim5, setAnim5] = useState(false);
  const [anim6, setAnim6] = useState(false);
  const [scroll, setScroll] = useState(0);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  useEffect(() => {
    setPhotos1([
      Doranda_college_1,
      Doranda_college_2,
      Doranda_college_3,
      Doranda_college_4,
    ]);
    setPhotos2([JN_college_2, JN_college_3]);
    setPhotos3([
      Nirmala_college_1,
      Nirmala_college_2,
      Nirmala_college_3,
      Nirmala_college_4,
      Nirmala_college_5,
      Nirmala_college_6,
    ]);
    setPhotos([
      Doranda_college_1,
      Doranda_college_2,
      Doranda_college_3,
      Doranda_college_4,
      JN_college_2,
      JN_college_3,
      Nirmala_college_1,
      Nirmala_college_2,
      Nirmala_college_3,
      Nirmala_college_4,
      Nirmala_college_5,
      Nirmala_college_6,
    ]);
    console.log(photos);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setScroll(scroll + 1);
      // console.log(scroll)
    }, 1000);

    return () => clearInterval(interval);
  }, [scroll]);

  useEffect(() => {
    //Implementing the setInterval method
    const interval = setInterval(() => {
      if (count1 < 3) {
        setCount1(count1 + 1);
      } else {
        setCount1(0);
      }

      if (count2 < photos2.length - 1) {
        setCount2(count2 + 1);
      } else {
        setCount2(0);
      }
      if (count3 < photos3.length - 1) {
        setCount3(count3 + 1);
      } else {
        setCount3(0);
      }
    }, 3000);

    //Clearing the interval
    return () => clearInterval(interval);
  }, [count1, count2, count3]);
  if (photos.length > 0)
    return (
      <div className="">
        <div className="lg:grid grid-cols-2 items-center mt-5 relative overflow-clip">
          <div className=" lg:self-center mx-4  text-center flex-auto lg:mx-24 ">
            <b className="lg:text-6xl text-3xl  font-extrabold text-[#E94B60]">
              Achieve career <br></br>
              <span className="text-black text-left">success</span>
            </b>
            <p className="lg:text-2xl text-lg font-bold my-8">
              Increase the chances of success in your job by{" "}
              <CountUp end={85} duration={2}></CountUp>%
            </p>
            <div className="grid grid-cols-2 gap-4  ">
              <div className="p-4 bg-invisible  shadow-xl rounded-xl  text-center">
                <AiOutlineRobot className="text-2xl text-center flex justify-center w-full"></AiOutlineRobot>
                <p>Assess your employability score with AI </p>
              </div>
              <div className="p-4 bg-invisible shadow-xl rounded-xl  text-center">
                <TbFreeRights className="text-2xl text-center flex justify-center w-full"></TbFreeRights>
                <p>Improve your skills with our Video resources</p>
              </div>
              <div className="p-4 bg-invisible shadow-xl rounded-xl  text-center">
                <HiOutlineNewspaper className="text-2xl text-center flex justify-center w-full"></HiOutlineNewspaper>
                <p>Build great resumes</p>
              </div>
              <div className="p-4 bg-invisible shadow-xl rounded-xl  text-center">
                <RiParentLine className="text-2xl text-center flex justify-center w-full"></RiParentLine>
                <p>Get personal guidance</p>
              </div>
            </div>
            {/* <p className="lg:text-3xl text-xl font-extrabold ">Get your success score now</p> */}
            <Link to="/signup">
              <button className="bg-red-500 py-4 px-7 mt-7 rounded-xl mb-5 w-1/2 text-white">
                Try free
              </button>
            </Link>
          </div>
          <div className=" mx-4 mt-5 lg:mt-0">
            <div className="relative mb-4 lg:mr-10">
              <video
                src={videoplay}
                className=" block rounded-xl "
                autoPlay
                muted
                playsInline
                controls
                loop
                width={2000}
                height={2000}
              ></video>
            </div>
          </div>
        </div>
        <div className="bg-[#e94b60] text-white ">
          <div className="text-center font-extrabold text-2xl lg:text-2xl mx-4 lg:mx-auto py-20  lg:w-2/3">
            <p className="text-3xl hidden lg:block">
              Build skills that companies want
            </p>
            <p className="mt-5 ">
              Soft skills are 6X more important than hard skills for job success{" "}
              <br></br>{" "}
              <span className="text-sm">Research by Harvard University</span>
            </p>
          </div>
        </div>
        <div className="lg:grid lg:grid-cols-2 items-center">
          <div className="bg-[#383434] ">
            <div className="lg:mx-24 mx-4">
              <p className="font-serif text-white pt-10 lg:mt-20 mb-10 lg:pt-0 text-5xl leading-28">
                Take a free demo
              </p>
              <Link to="/signup">
                <button className="bg-red-500 py-4 px-7 mb-20 rounded-xl text-white">
                  Start Now
                </button>
              </Link>
            </div>
          </div>
          <div>
            <p className="lg:mx-10 mx-4 text-center my-5 lg:my-0 text-2xl lg:text-4xl font-extralight">
              Lack of soft skills is the reason of failure for
              <span className="text-3xl lg:text-5xl">89%</span> of new hires
              <br></br>
              <br></br>
              <span className="text-xl lg:text-2xl">- Leadership IQ </span>
            </p>
          </div>
        </div>
        <p className="text-center my-12 font-serif text-3xl lg:text-5xl font-medium text-red-500">
          Learn what employers want
        </p>
        <div className="lg:grid lg:grid-cols-2 my-3 gap-5 items-center mx-3 text-lg md:mx-20 mt-6">
          <div className="order-last">
            <video
              src={starter}
              className=" block rounded-xl  "
              autoPlay
              muted
              playsInline
              controls
              loop
              width={2000}
              height={2000}
            ></video>
          </div>
          <div className="px-2 text-justify ">
            <p className=" font-poppins text-base lg:text-lg my-2">
              Are you looking for a new job? Are you trying to find success in
              your early career as an employee? As you gear up for your work
              life, you will realise that you were never educated about the
              skills which will help you achieve success in finding a new job.
              Or, achieving success in your early work years. The reason?
              Colleges often miss out on training candidates on the most
              critical success factors that fuel success in corporate life. Our
              specialised courses fill the gap between academic knowledge and
              real-world skills and help you train on these critical Success
              Factors
            </p>

            {/* <p>...teaches what employers need </p> */}
            <p className="my-5 text-base"></p>
            {/* <div className="list-disc text-base invisible">
            <li>Specialized topics often overlooked by traditional education covering the following modules: </li>
            <div className="list-disc ml-3">
              <li>Communication & Articulation</li>
              <li>Teamwork</li>
              <li>Active listening</li>
              <li>Critical thinking & innovative problem-solving</li>
              <li>Growth Mindset and Adaptability</li>
            </div>
            <li>All modules in short video formats</li>
            <li>Bridging the college-to-job life gap</li>
          </div> */}
          </div>
        </div>

        <p className="text-center my-12 font-serif text-3xl lg:text-5xl font-medium text-[#34445c]">
          Course Modules
        </p>
        <div className="lg:grid lg:grid-cols-3  space-y-6 lg:space-y-0 lg:gap-8 lg:w-2/3 mx-4 lg:mx-auto">
          <div className="bg-[#f3f6ff] px-8 py-7">
            <div className="flex items-center space-x-4">
              <img src={intro} className="h-10"></img>
              <p className="text-2xl font-medium">Introduction</p>
            </div>
            <p className="my-4">
              A good self-introduction helps you build confidence to set a
              positive first impression which is crucial to highlight relevant
              skills and qualifications, impacting the interview outcome.{" "}
            </p>
          </div>
          <div className="bg-[#f3f6ff] px-8 py-7">
            <div className="flex items-center space-x-4">
              <img src={speech} className="h-10"></img>
              <p className="text-2xl font-medium truncate">Communication</p>
            </div>
            <p className="my-4">
              Communication skills help you articulate your thoughts better and
              convey your ideas to your peers giving you a winning edge.
            </p>
          </div>
          <div className="bg-[#f3f6ff] px-8 py-7">
            <div className="flex items-center space-x-4">
              <img src={headphone} className="h-10"></img>
              <p className="text-2xl font-medium">Active Listening</p>
            </div>
            <p className="my-4">
              Active listening helps you in conscious listening, understanding
              the context and respond appropriately, demonstrating your
              communication and problem-solving skills.
            </p>
          </div>
          <div className="bg-[#f3f6ff] px-8 py-7">
            <div className="flex items-center space-x-4">
              <img src={group} className="h-10"></img>
              <p className="text-2xl font-medium">Teamwork</p>
            </div>
            <p className="my-4">
              Teamwork is a highly valued skill in the workplace and can be a
              deciding factor in an interview. Demonstrating your ability to
              work effectively with others showcases your adaptability and
              ability to contribute to a positive work environment.
            </p>
          </div>
          <div className="bg-[#f3f6ff] px-8 py-7">
            <div className="flex items-center space-x-4">
              <img src={graph} className="h-10"></img>
              <p className="text-2xl font-medium">Growth Mindset</p>
            </div>
            <p className="my-4">
              Having a growth mindset shows that you are open to learning and
              improvement. It also indicates your ability to adapt to changes
              and to upskill, both of which are important in today's fast-paced
              work environment.
            </p>
          </div>
          <div className="bg-[#f3f6ff] px-8 py-7">
            <div className="flex items-center space-x-4">
              <img src={idea} className="h-10"></img>
              <p className="text-2xl font-medium">Critical Thinking</p>
            </div>
            <p className="my-4">
              Critical thinking helps you display your ability to analyse
              information, solve problems and make informed decisions. It also
              helps you display creativity and potential for future growth, all
              of which are greatly valued by employers.
            </p>
          </div>
        </div>

        <div className="bg-[#f3f6ff] py-8 mt-8">
          <p className="text-center font-serif text-3xl lg:text-5xl font-medium text-[#34445c]">
            Assessment Dashboard
          </p>
          <div className=" mx-8">
            <p className="text-center my-5 text-2xl">
              Assess, Learn & Grow 10X faster
            </p>
            {/* <p className="text-center mt-10 text-xl">Scale Description</p>
                <img src={meter4} className="mx-auto h-12 lg:h-20 mt-5"></img> */}
            <div className="lg:grid lg:grid-cols-2 items-center gap-8">
              <div>
                <div className="lg:grid lg:grid-cols-2 lg:space-y-0 space-y-2  my-4">
                  <img src={meter1} className="h-80  mx-auto rounded-xl"></img>
                  <img src={meter2} className="h-80  mx-auto rounded-xl"></img>
                </div>
                <img src={meter3} className="mx-auto lg:h-80 rounded-xl"></img>
              </div>
              <div className="flex flex-col mt-4 space-y-8">
                <div className="bg-white p-4 rounded-xl flex space-x-4 shadow-xl items-center">
                  <b className="text-4xl text-red-500 ">01</b>
                  <div className="space-y-3">
                    <b className="mb-7 text-2xl">
                      Discover the skills for career success
                    </b>
                    <p className="hidden lg:block">
                      We help you understand and improve the critical skills
                      which help you succeed in your interviews and in your
                      early career days using our AI-backed system. Find out
                      your inner strengths that can help you fly high in your
                      career{" "}
                    </p>
                  </div>
                </div>
                <div className="bg-white p-4 rounded-xl flex space-x-4 shadow-xl items-center">
                  <b className="text-4xl text-red-500 ">02</b>
                  <div className="space-y-3">
                    <b className=" text-2xl">
                      An unbiased and accurate employability report
                    </b>
                    <p className="hidden lg:block">
                      Discover where you stand today on our employability skills
                      test. Our ML algorithms trained on hours of real human
                      data help us score your skills with high accuracy, giving
                      you pinpointed results through advanced dashboards
                    </p>
                  </div>
                </div>
                <div className="bg-white p-4 rounded-xl flex space-x-4 shadow-xl items-center">
                  <b className="text-4xl text-red-500 ">03</b>
                  <div className="space-y-3">
                    <b className=" text-2xl">Expert-built learning resources</b>
                    <p className="hidden lg:block">
                      Our specially created learning material built under the
                      guidance of psychologists and Industry experts ensures you
                      grow to hit your success targets. These focus specifically
                      on learning built to enhance your corporate life
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p className="text-center my-12 font-serif text-3xl lg:text-5xl font-medium text-red-500">
          Placement Partners
        </p>

        <div className="lg:flex flex-col lg:flex-row  items-center justify-center w-full ">
          <div className="flex items-center space-x-2 justify-center">
            <a href="https://talreso.com/" target="_blank">
              <img
                src={talreso}
                className="bg-blue-500 h-12 md:h-20 p-2 rounded-xl"
              ></img>
            </a>
            <a href="https://www.biojobz.com/" target="_blank">
              <img src={biojob} className="h-12 md:h-20"></img>
            </a>
          </div>
          <div className="flex items-center space-x-3 mt-10 justify-center ">
            {/* <a href="https://www.epicip.com/" target="_blank">
        <img src={epic} className="lg:h-20 h-12"></img>
          </a>  */}
            <a href="https://www.kutumbhhrcare.com/" target="_blank">
              <img src={kutumbh} className="lg:h-20 h-12"></img>
            </a>
          </div>
        </div>
        <div className="flex mt-10 items-center justify-center w-full space-x-2 md:space-x-6">
          <a href="https://pinnacleu.in/" target="_blank">
            <img src={pinnacle} className=" md:h-20 h-14 p-2 rounded-xl"></img>
          </a>
          <a href="https://www.consultbae.com/" target="_blank">
            <img src={consultbae} className="h-14"></img>
          </a>
        </div>

        <p className="text-center font-serif text-3xl my-10 lg:text-5xl font-medium text-red-500">
          Seminars
        </p>
        <div className="relative">
          <button
            onClick={() => {
              scroller2(-100);
            }}
            className="absolute justify-center opacity-0 hover:opacity-80 flex flex-col transition-all mr-0 bg-black text-white left-0 h-full p-4 rounded-xl  translate-y-1/2 bottom-1/2  z-40"
          >
            <AiOutlineArrowLeft className="text-4xl h-full"></AiOutlineArrowLeft>
          </button>
          <button
            onClick={() => {
              scroller2(100);
            }}
            className="absolute justify-center opacity-0 hover:opacity-80 flex flex-col transition-all  mr-0 bg-black text-white right-0 h-full p-4 rounded-xl  translate-y-1/2 bottom-1/2  z-40"
          >
            <AiOutlineArrowRight className="text-4xl  h-full"></AiOutlineArrowRight>
          </button>
          <div
            ref={ref1}
            className="items-center overflow-auto  scrollbar-hide space-x-4  mx-4 whitespace-nowrap"
          >
            {photos.map((p) => {
              return (
                <div className="inline-block">
                  <img src={p} className="rounded-xl h-[15rem] "></img>
                </div>
              );
            })}
          </div>
        </div>

        {/* <div className="my-3">

        

        <div className="lg:grid relative lg:grid-cols-2 my-10">
          <div className="mx-auto ">
            <motion.img
              animate={{ z: [0, 100] }}
              transition={{ duration: 3, repeat: Infinity }}
              src={photos1[count1]}
              className="rounded-xl h-[15rem] mx-auto lg:h-[27rem] shadow-xl "
            ></motion.img>
            <div className="w-full flex justify-center">
              <motion.img
                animate={{ z: [-10, 100, 0] }}
                transition={{ duration: 3, repeat: Infinity }}
                src={photos1[(count1 + 1) % 4]}
                className="rounded-xl h-[15rem] lg:h-[27rem] -z-10 rotate-12  top-0 absolute  "
              ></motion.img>
            </div>
          </div>
          <div className="text-center lg:my-auto text-xl my-20 lg:text-4xl font-poppins">
            <p>Doranda College</p>
          </div>
        </div>
        <div className="lg:grid relative lg:grid-cols-2 my-5">
          <div className="mx-auto ">
            <motion.img
              animate={{ z: [0, 100] }}
              transition={{ duration: 3, repeat: Infinity }}
              src={photos2[count2]}
              className="rounded-xl h-[15rem] mx-auto lg:h-[27rem] shadow-xl "
            ></motion.img>
            <div className="w-full flex justify-center">
              <motion.img
                animate={{ z: [-10, 100, 0] }}
                transition={{ duration: 3, repeat: Infinity }}
                src={photos2[(count2 + 1) % photos2.length]}
                className="rounded-xl h-[15rem] lg:h-[27rem] -z-10 rotate-12  top-0 absolute  "
              ></motion.img>
            </div>
          </div>
          <div className="text-center lg:my-auto order-first text-xl my-20 lg:text-4xl font-poppins">
            <p>JN College</p>
          </div>
        </div>
        <div className="lg:grid relative lg:grid-cols-2 my-5">
          <div className="mx-auto ">
            <motion.img
              animate={{ z: [0, 100] }}
              transition={{ duration: 3, repeat: Infinity }}
              src={photos3[count3]}
              className="rounded-xl h-[15rem] mx-auto lg:h-[27rem] shadow-xl "
            ></motion.img>
            <div className="w-full flex justify-center">
              <motion.img
                animate={{ z: [-10, 100, 0] }}
                transition={{ duration: 3, repeat: Infinity }}
                src={photos3[(count3 + 1) % photos3.length]}
                className="rounded-xl h-[15rem] lg:h-[27rem] -z-10 rotate-12  top-0 absolute  "
              ></motion.img>
            </div>
          </div>
          <div className="text-center lg:my-auto text-xl my-20 lg:text-4xl font-poppins">
            <p>Nirmala College for girls</p>
          </div>
        </div>
      </div> */}

        <p className="text-center font-serif  text-3xl lg:text-5xl text-[#34445c] mt-20 my-12">
          Testimonials
        </p>
        <div className="relative">
          <button
            onClick={() => {
              scroller(-100);
            }}
            className="absolute justify-center opacity-0 hover:opacity-80 flex flex-col transition-all mr-0 bg-black text-white left-0 h-full p-4 rounded-xl  translate-y-1/2 bottom-1/2  z-40"
          >
            <AiOutlineArrowLeft className="text-4xl h-full"></AiOutlineArrowLeft>
          </button>
          <button
            onClick={() => {
              scroller(100);
            }}
            className="absolute justify-center opacity-0 hover:opacity-80 flex flex-col transition-all  mr-0 bg-black text-white right-0 h-full p-4 rounded-xl  translate-y-1/2 bottom-1/2  z-40"
          >
            <AiOutlineArrowRight className="text-4xl  h-full"></AiOutlineArrowRight>
          </button>
          <div
            ref={ref}
            className=" items-center overflow-auto  scrollbar-hide space-x-4  mx-4 whitespace-nowrap"
          >
            <motion.div className="inline-block">
              <div className="flex items-center relative">
                <button
                  onMouseEnter={() => {
                    setAnim(true);
                  }}
                  onMouseLeave={() => {
                    setAnim(false);
                  }}
                  className="inline-block relative "
                >
                  <img
                    src={tt1}
                    className="rounded-xl h-[20rem] aspect-square"
                  ></img>
                  <p className="text-xl font-extrabold text-white absolute bottom-3 flex w-full justify-center z-20">
                    Pallavi Singh
                  </p>
                </button>

                <motion.div
                  animate={{ x: anim ? 340 : 0, opacity: anim ? 1 : 0 }}
                  className="absolute -z-10 w-[27rem] "
                >
                  <div className="">
                    <p className="font-poppins text-3xl">Pallavi Singh</p>
                    <div className="bg-slate-300 h-[0.1rem] mt-3"></div>
                    <p className="mt-3">Manager - Media Business Development</p>
                    <p className="mt-4 whitespace-normal">
                      As someone transitioning into a new field, Interview
                      Vision gave me the confidence to excel in interviews. The
                      platform's focus on soft skills evaluation, such as
                      critical thinking and problem-solving abilities, helped me
                      highlight my transferable skills. The learning material
                      provided was invaluable in conquering the challenges and
                      securing a job in my desired industry.
                    </p>
                  </div>
                </motion.div>
              </div>
            </motion.div>

            <motion.div
              animate={{ x: anim ? 480 : 0 }}
              transition={{ duration: 0.2 }}
              className="inline-block"
            >
              <div className="flex items-center relative">
                <button
                  onMouseEnter={() => {
                    setAnim2(true);
                  }}
                  onMouseLeave={() => {
                    setAnim2(false);
                  }}
                  className="inline-block relative "
                >
                  <img
                    src={tt2}
                    className="rounded-xl h-[20rem] aspect-square"
                  ></img>
                  <p className="text-xl font-extrabold text-white absolute bottom-3 flex w-full justify-center z-20">
                    Derrick James
                  </p>
                </button>

                <motion.div
                  animate={{ x: anim2 ? 340 : 0, opacity: anim2 ? 1 : 0 }}
                  className="absolute -z-10 w-[27rem] "
                >
                  <div className="">
                    <p className="font-poppins text-3xl">Derrick James</p>
                    <div className="bg-slate-300 h-[0.1rem] mt-3"></div>
                    <p className="mt-3">Regional HR Manager</p>
                    <p className="mt-4 whitespace-normal">
                      Interview Vision is a game-changer for job seekers! The
                      AI-powered virtual interviews accurately evaluated my
                      critical thinking, communication skills, and
                      problem-solving abilities. The detailed feedback helped me
                      fine-tune my approach and excel in real interviews. Thanks
                      to Interview Vision, I secured multiple job offers and had
                      the confidence to choose the perfect fit for me!
                    </p>
                  </div>
                </motion.div>
              </div>
            </motion.div>

            <motion.div
              animate={{ x: anim || anim2 ? 480 : 0 }}
              transition={{ duration: 0.2 }}
              className="inline-block"
            >
              <div className="flex relative items-center">
                <button
                  onMouseEnter={() => {
                    setAnim3(true);
                  }}
                  onMouseLeave={() => {
                    setAnim3(false);
                  }}
                  className="inline-block relative "
                >
                  <img
                    src={tt3}
                    className="rounded-xl h-[20rem] aspect-square"
                  ></img>
                  <p className="text-xl font-extrabold text-white absolute bottom-3 flex w-full justify-center z-20">
                    Akash Wadhwa
                  </p>
                </button>

                <motion.div
                  animate={{ x: anim3 ? 340 : 0, opacity: anim3 ? 1 : 0 }}
                  className="absolute -z-10 w-[27rem] "
                >
                  <div className="">
                    <p className="font-poppins text-3xl">Akash Wadhwa</p>
                    <div className="bg-slate-300 h-[0.1rem] mt-3"></div>
                    <p className="mt-3">Digital Marketing</p>
                    <p className="mt-4 whitespace-normal">
                      Interview Vision made a significant impact on my
                      transition from student to professional. The virtual
                      interviews simulated real-world scenarios, allowing me to
                      showcase my creative problem-solving abilities. The
                      personalised feedback and performance scores helped me
                      understand my potential and focus areas. With the guidance
                      and support from Interview Vision, I secured my first job
                      as a digital marketing expert!
                    </p>
                  </div>
                </motion.div>
              </div>
            </motion.div>
            <motion.div
              animate={{ x: anim || anim2 || anim3 ? 480 : 0 }}
              transition={{ duration: 0.2 }}
              className="inline-block"
            >
              <div className="flex relative items-center">
                <button
                  onMouseEnter={() => {
                    setAnim4(true);
                  }}
                  onMouseLeave={() => {
                    setAnim4(false);
                  }}
                  className="inline-block relative "
                >
                  <img
                    src={tt4}
                    className="rounded-xl h-[20rem] aspect-square"
                  ></img>
                  <p className="text-xl font-extrabold text-white absolute bottom-3 flex w-full justify-center z-20">
                    Akansha Singh
                  </p>
                </button>
                <motion.div
                  animate={{ x: anim4 ? 340 : 0, opacity: anim4 ? 1 : 0 }}
                  className="absolute -z-10 w-[27rem] "
                >
                  <div className="">
                    <p className="font-poppins text-3xl">Akansha Singh</p>
                    <div className="bg-slate-300 h-[0.1rem] mt-3"></div>
                    <p className="mt-3">Customer Service Operations Lead</p>
                    <p className="mt-4 whitespace-normal">
                      Interview Vision empowered me to ace my job interviews
                      with confidence! The platform's evaluation of my
                      communication skills and active listening abilities gave
                      me a competitive advantage. The comprehensive analysis of
                      my performance allowed me to tailor my approach and excel
                      in interviews. Thanks to Interview Vision, I secured a job
                      where I can utilize my strengths and grow professionally!
                    </p>
                  </div>
                </motion.div>
              </div>
            </motion.div>
            <motion.div
              animate={{ x: anim || anim2 || anim3 || anim4 ? 480 : 0 }}
              transition={{ duration: 0.2 }}
              className="inline-block"
            >
              <div className="flex relative items-center">
                <button
                  onMouseEnter={() => {
                    setAnim5(true);
                  }}
                  onMouseLeave={() => {
                    setAnim5(false);
                  }}
                  className="inline-block relative "
                >
                  <img
                    src={tt5}
                    className="rounded-xl h-[20rem] aspect-square"
                  ></img>
                  <p className="text-xl font-extrabold text-white absolute bottom-3 flex w-full justify-center z-20">
                    Akash Yadav
                  </p>
                </button>
                <motion.div
                  animate={{ x: anim5 ? 340 : 0, opacity: anim5 ? 1 : 0 }}
                  className="absolute -z-10 w-[27rem] "
                >
                  <div className="">
                    <p className="font-poppins text-3xl">Akash Yadav</p>
                    <div className="bg-slate-300 h-[0.1rem] mt-3"></div>
                    <p className="mt-3">Online Learning Faculty</p>
                    <p className="mt-4 whitespace-normal">
                      Thanks to Interview Vision, I was able to showcase my
                      creative problem-solving abilities to potential employers.
                      The comprehensive learning material provided by Interview
                      Vision equipped me with innovative approaches, and it
                      played a pivotal role in securing a job that allows me to
                      put my problem-solving skills to the test.
                    </p>
                  </div>
                </motion.div>
              </div>
            </motion.div>
            <motion.div
              animate={{
                x: anim || anim2 || anim3 || anim4 || anim5 ? 480 : 0,
              }}
              transition={{ duration: 0.2 }}
              className="inline-block"
            >
              <div className="flex relative items-center">
                <button
                  onMouseEnter={() => {
                    scroller(1000);
                    setAnim6(true);
                  }}
                  onMouseLeave={() => {
                    setAnim6(false);
                  }}
                  className="inline-block relative "
                >
                  <img
                    src={tt6}
                    className="rounded-xl h-[20rem] aspect-square"
                  ></img>
                  <p className="text-xl font-extrabold text-white absolute bottom-3 flex w-full justify-center z-20">
                    Ishan Agrawal
                  </p>
                </button>
                <motion.div
                  animate={{ x: anim6 ? 340 : 0, opacity: anim6 ? 1 : 0 }}
                  className={"absolute -z-10 w-[27rem] "}
                >
                  <div className="">
                    <p className="font-poppins text-3xl">Ishan Agrawal</p>
                    <div className="bg-slate-300 h-[0.1rem] mt-3"></div>
                    <p className="mt-3">
                      4th year student, Computer Science Engineering
                    </p>
                    <p className="mt-4 whitespace-normal">
                      I am thrilled to share my experience with Interview
                      Vision, as it played a crucial role in my recent success
                      in landing a job as an associate engineer at a renowned IT
                      company. With numerous candidates vying for the same
                      position, Interview Vision’s unbiased scoring system and
                      evaluation of my communication skills and critical
                      thinking proved to be the deciding factor.
                    </p>
                  </div>
                </motion.div>
              </div>
            </motion.div>

            <motion.div
              animate={{
                x: anim || anim2 || anim3 || anim4 || anim5 ? 480 : 0,
              }}
              transition={{ duration: 0.2 }}
              className="inline-block invisible"
            >
              <div className="flex relative items-center">
                <button
                  onMouseEnter={() => {
                    scroller(300);
                    setAnim6(true);
                  }}
                  onMouseLeave={() => {
                    setAnim6(false);
                  }}
                  className="inline-block relative "
                >
                  <img
                    src={tt6}
                    className="rounded-xl h-[20rem] aspect-square"
                  ></img>
                  <p className="text-xl font-extrabold text-white absolute bottom-3 flex w-full justify-center z-20">
                    Ishan Agrawal
                  </p>
                </button>
                <motion.div
                  animate={{ x: anim6 ? 340 : 0, opacity: anim6 ? 1 : 0 }}
                  className={"absolute -z-10 w-[27rem] "}
                >
                  <div className="">
                    <p className="font-poppins text-3xl">Ishan Agrawal</p>
                    <div className="bg-slate-300 h-[0.1rem] mt-3"></div>
                    <p className="mt-3">
                      4th year student, Computer Science Engineering
                    </p>
                    <p className="mt-4 whitespace-normal">
                      I am thrilled to share my experience with Interview
                      Vision, as it played a crucial role in my recent success
                      in landing a job as an associate engineer at a renowned IT
                      company. With numerous candidates vying for the same
                      position, Interview Vision’s unbiased scoring system and
                      evaluation of my communication skills and critical
                      thinking proved to be the deciding factor.
                    </p>
                  </div>
                </motion.div>
              </div>
            </motion.div>
          </div>
        </div>

        {/* <div className="lg:grid lg:grid-cols-3 border border-black">
            <div className="border border-black text-center pt-8 pb-6 px-6">
                <p className="font-mono text-[#e94b60] text-9xl">"</p>
                <p className="font-serif text-3xl font-medium">Sanjiv Mishra,</p>
                <p className="font-serif text-3xl">Accounts Executive</p>
                <p className="mx-16 my-5 text-lg">"Interview Vision has helped me improne my critical thinking through evaluation and their study material. I am currently working happily as an Accounts Executive with India's leading fintech wallet."</p>
            </div>
            <div className="border border-black text-center pt-8 pb-6 px-6">
                <p className="font-mono text-[#e94b60] text-9xl">"</p>
                <p className="font-serif text-3xl font-medium">Saurabh Singh,</p>
                <p className="font-serif text-3xl">Team Lead,</p>
                <p className="font-serif text-3xl">Logistic Services</p>
                <p className="mx-16 my-5 text-lg">"The way I got to prepare and improve has not just helped me improve my communication skills but also secure a good job and its very affordable."</p>
            </div>
            <div className="border border-black text-center pt-8 pb-6 px-6">
                <p className="font-mono text-[#e94b60] text-9xl">"</p>
                <p className="font-serif text-3xl font-medium">Garvit Srivastava,</p>
                <p className="font-serif text-3xl">Customer Support <br></br> Executive</p>
                <p className="mx-16 my-5 text-lg">"We were never told to work on soft skills in college. But after many failed interviews, when I came across Interview Vision, I realized how important these skills are to clear the first round of an interview with a recruiter."</p>
            </div>
        </div> */}
        <div className="bg-[#e94b60] text-white ">
          <div className="text-center font-extrabold text-xl lg:text-3xl mx-4 lg:mx-auto py-20  lg:w-1/2">
            <p>Ready to start on your journey to secure a job?</p>
            <Link to="/signup">
              <button className="mt-5 px-4 py-3 bg-white text-[#e94b60] rounded-xl text-xl lg:text-3xl">
                Try free
              </button>
            </Link>
          </div>
        </div>
        <div className="bg-slate-800 text-white py-6">
          <div className="flex justify-center items-center h-full w-full">
            <p>
              © 2023 Novelio Technologies Private Limited | All rights reserved
            </p>
          </div>
          <div className="flex  justify-center items-center mt-5 space-x-4 text-sm mx-4 lg:mx-20">
            <Link to="/privacy">
              <p>Privacy Policy</p>
            </Link>
            <Link to="/terms">
              <p>Terms and Conditions</p>
            </Link>
            <Link to="/disclaimer">
              <p>Disclaimer</p>
            </Link>
            <Link to="/refund">
              <p>Refund Policy</p>
            </Link>
          </div>
          <div className="flex  justify-center items-center mt-5 space-x-4 text-sm mx-4 lg:mx-20">
            <div className="flex space-x-1 items-center">
              <AiOutlineInstagram className="text-2xl"></AiOutlineInstagram>
              <a
                href="https://www.instagram.com/_interviewvision/"
                target="_blank"
              >
                Instagram
              </a>
            </div>
            <div className="flex space-x-1 items-center">
              <BsLinkedin className="text-xl"></BsLinkedin>
              <a
                href="https://www.linkedin.com/company/interviewvision/"
                target="_blank"
              >
                LinkedIn
              </a>
            </div>
            <div className="flex space-x-1 items-center">
              <AiFillFacebook className="text-xl"></AiFillFacebook>
              <a
                href="https://www.facebook.com/profile.php?id=100090117109866&mibextid=LQQJ4d"
                target="_blank"
              >
                Facebook
              </a>
            </div>
          </div>
        </div>
      </div>
    );
};

export default Entry;

import React from 'react'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'

const Error = () => {
    const location = useLocation()
  return (
      <div>
        <Link to="/home" >
        404, not found, please click here to return to HOME page
        </Link>
    </div>
  )
}

export default Error
import React, {useState, useEffect} from 'react'
import { Helmet } from 'react-helmet'
import { useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom'

const ThankYou = () => {

    const navigate = useNavigate()
    const location  = useLocation()
    const details = localStorage.getItem('email')
    const {state} = useLocation();
    const {email} = state;
    const [counter, setCounter] = useState(10)

    React.useEffect(() => {
            const timer =
              counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
            return () => clearInterval(timer);
    }, [counter]);

    useEffect(()=>{
        if(counter === 1) {
            navigate('/data')
        }
    },[counter])
  return (
    <div className="min-h-screen relative h-screen w-screen  ">
      <Helmet>
      <script type="text/javascript">
        {`gtag('event', 'conversion', {'send_to': 'AW-11249740142/oplSCJvT1M0YEO7SpfQp'});`}
      </script>
      </Helmet>
        <div className="p-4 py-6 rounded-xl absolute text-center bg-[#E94B60] text-white shadow-2xl hover:scale-110 transition-all font-poppins -translate-y-1/2 left-1/2 top-1/2 -translate-x-1/2  ">
            <p className="my-4 text-3xl font-bold">Thank you!</p>
            <p className="text-xl my-3">You have been successfully registered with the email {email} on our portal.</p>
            <p>You will be redirected to home page in {counter} seconds.</p>
        </div>
    </div>
  )
}

export default ThankYou
import axios from 'axios'
import React, {useState, useEffect} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import interview_guru_logo from '../images/Interview vision logo.png'
import success from '../images/success.png'
import failed from '../images/failed.png'
import {InfinitySpin} from 'react-loader-spinner'
import { Helmet } from 'react-helmet'
import { useCalendlyEventListener,InlineWidget } from "react-calendly";
import { Link } from 'react-router-dom'
import data from '../config'

const CoachPaymentSuccess = () => {

  const [date, setDate] = useState('')
  const [booked, setBooked] = useState(false)
  const  [url, setUrl] = useState('')
  const navigate = useNavigate()
  const [tid, setTid] = useState('')
  const [successStatus, setSuccessStatus] = useState(null)

  function useQuery() {
      const { search } = useLocation();
      return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery()

  const [loader, setLoader] = useState(true)
  const [username, setUsername] = useState('')

  useCalendlyEventListener({
    onEventScheduled: (e) => {
      axios.get(e.data.payload.event.uri,{
        headers:{
          Authorization: 'Bearer eyJraWQiOiIxY2UxZTEzNjE3ZGNmNzY2YjNjZWJjY2Y4ZGM1YmFmYThhNjVlNjg0MDIzZjdjMzJiZTgzNDliMjM4MDEzNWI0IiwidHlwIjoiUEFUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiJodHRwczovL2F1dGguY2FsZW5kbHkuY29tIiwiaWF0IjoxNjkzMzI3MDcxLCJqdGkiOiJhMmMzOTg4MS02OGJhLTQyZDktOTJhYS01NGIwNzA5YzQ1ZjIiLCJ1c2VyX3V1aWQiOiI1OTUzYWEzZC0yOWFhLTQwNWQtYTNmMy1jNzU1NDZjZWMwMWEifQ.Ny3_1aJ5-Zu8g3pm-xzDN5qsSpJzmth-xIJ4lZTF__w7iqCUuObYu1rC4IUWLl-du-z5DncHuo1T6MW6tDolYQ'
        }
      })
      .then((response) => {
        console.log(response)
        setDate(response.data.resource.end_time)
      })
      .catch((error) => {
        console.log(error)
      })

    },
  });


  useEffect(()=>{
    if(date.length>0){
      axios.post(`https://${data.API}/subscription/coach`,{
        transid:tid,
        date:date
      })
      .then((response)=>{
        console.log(response)
        localStorage.setItem('email',response.data.username)
        setBooked(true)
      })
      .catch((error) => {
        console.log(error)
      })
    }
  },[date])



    useEffect(()=>{
        setTid(query.get('tranId'))
    },[])
    useEffect(()=>{
        if(tid.length>0){
            axios.get(`https://www.interviewvision.com/payment/pe/coachstatus/${tid}`)
            .then((response)=>{
                console.log(response)
                if(response.data.status === "PAYMENT_SUCCESS"){
                  setSuccessStatus(true)
                  setUrl(response.data.calendlyurl)
                  setLoader(false)
                }
                else{
                    setSuccessStatus(false)
                    setLoader(false)
                }
            })

        }
    },[tid])

    useEffect(()=>{
        if(username){
            localStorage.setItem('email',username)
        }
    },[username])

    console.log(loader)

  return (
    <div>
        <Helmet>
        <script> 
            {`gtag('event', 'conversion', { 'send_to': 'AW-11249740142/6vD_CJLJi84YEO7SpfQp', 'transaction_id': '' });`} 
        </script>
        </Helmet>
        {
            loader?
            <div className="absolute left-1/2 top-1/2 -translate-x-1/2  text-center -translate-y-1/2">
                    <InfinitySpin
                    width='200'
                    color='#4899B7'
                    ></InfinitySpin>
                </div>
            :
            <div>
                {
                    successStatus?
                    <div>
                        {
                            
                            <div>
                <img src={interview_guru_logo} className="h-32"></img>
                              <img src={success} className="mx-auto text-center h-36"></img>
                              <p className="text-center mt-8 font-poppins text-2xl">Payment Success</p>
                                <InlineWidget url={url}></InlineWidget>
                              
                      
                      {
                        booked &&

                    <div className="text-center">
                      <Link to="/home">
                    <button className="bg-red-500 text-white px-5 py-2 my-5 rounded-xl" >Back to home</button>
                      </Link>
                    </div>
                      }
                    
                    </div>
                        }
                    </div>
                    :
                    <div>
                        <img src={interview_guru_logo} className="h-32"></img>
                        <img src={failed} className="mx-auto text-center h-36"></img>
                        
                    <p className="text-center mt-8 font-poppins text-2xl">Payment Failed</p>
                    <p className="text-center font-poppins mt-4">Don't worry, it happens with the best of us</p>
                    <div className="text-center">
                    <button onClick={()=>{navigate('/home')}} className="w-[20rem] mx-auto bg-red-500 mt-7 text-white rounded-xl py-5  ">
                        Back to Payments Page
                    </button>
                    </div>
                    </div>

                }
            </div>
        }
    </div>
  )
}

export default CoachPaymentSuccess 
import React, {useEffect} from 'react'
import { Link } from 'react-router-dom'
import {BiArrowBack} from 'react-icons/bi'
const Refund = () => {
  useEffect(()=>{
    window.scroll(0,0)
  },[])
  return (
    <div className="mt-3 mx-3"><Link to="/">
    <button className="bg-green-500 text-white rounded-xl p-4">
      <BiArrowBack></BiArrowBack>
    </button>
    </Link>
      <h2 className="text-center bg-red-500 text-white  py-8 rounded-xl my-4"><b>Refund Policy</b></h2>
        <p>Updated on 1st April 2023
            <br></br>
All sales are final and no refund will be issued.</p>
<p className="text-xl">Questions:</p>
<p>If you have any questions concerning our refund & cancellation policy, please reach out to us on <a href="mailto:contact@interviewvision.com">contact@interviewvision.com</a></p>
    </div>
  )
}

export default Refund
import React , {useEffect, useState} from 'react'
import loginguy from '../images/login_guy.png'
import interviewguru_logo from '../images/interview_guru_logo.png'
import google_logo from '../images/google_logo.png'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { GoogleAuthProvider, } from "firebase/auth";
import { getAuth } from "firebase/auth";
import app from '../firebase'
import { useNavigate } from 'react-router-dom'
import { FacebookAuthProvider } from "firebase/auth";
import {InfinitySpin} from 'react-loader-spinner'
import {ColorRing} from 'react-loader-spinner'
import ReactGA from 'react-ga'
import ErrorModal from './ErrorModal'
import data from '../config'

const PhoneNumberLogin = () => {
    const [email,setEmail] = useState('')
    const [pnum, setPnum] = useState('')
    const [pass, setPass] = useState('')
    const [disable, setDisable] = useState(true)
    const [loading, setLoading] = useState(false)
    const [verify, setVerify] = useState(false)
    const [nav, setNav] = useState(false)
    const [loader, setLoader] = useState(false)
    const [invalid, setInvalid] = useState(false)
    const [otp1,setOtp1] = useState('')
    const [otpsent, setOTPSent] = useState(false)
    const [otp2,setOtp2] = useState('')
    const [otp3,setOtp3] = useState('')
    const [error, setError] = useState(false)
    const [otp4,setOtp4] = useState('')
    const [errorText, setErrorText] = useState(false)
    const [counter, setCounter] = useState(30)
    const navigate = useNavigate()
    const provider = new GoogleAuthProvider()
    const fbprovider = new FacebookAuthProvider()
    const auth = getAuth(app);
    auth.languageCode = 'it';

    fbprovider.setCustomParameters({
        'display': 'popup'
    });

    useEffect(()=>{
        ReactGA.pageview(window.location.pathname + window.location.search)
    },[])
    
    
    React.useEffect(() => {
        if(otpsent == true){
            const timer =
              counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
            return () => clearInterval(timer);
        }
    }, [counter, otpsent]);

    useEffect(()=>{
        if(verify){
            setDisable(false)
        }
        else{
            setDisable(true)
        }
    },[verify])


    const sendOTP = () =>{
        setLoader(true)
        axios.get(`https://${data.API}/users/genloginsmsotp/${pnum}`)
        .then((response) =>{
            setOTPSent(true)
            setOtp1('')
            setOtp2('')
            setOtp3('')
            setOtp4('')
            setCounter(30)
            setInvalid(false)
            console.log(response)
            setLoader(false)
        })
        .catch((err) =>{
            setOTPSent(false)
            setError(true)
            setErrorText('You are not a registered user.')
            console.log(err)
            setLoader(false)
        })
    }

    function handleState() {
        setError(false)
        navigate('/signup')
    }

    const verifyOTP = () =>{
        axios.get(`https://${data.API}/users/verifyphoneotp/${pnum}/${otp1+otp2+otp3+otp4}`)
        .then((response) =>{
            setLoader(true)
            console.log(response)
            setVerify(true)
            handleSubmit()
        })
        .catch((error) =>{
            console.log(error)
            setVerify(false)
            setInvalid(true)
            setLoader(false)
            console.log('Wrong OTP entered, please try again')
        })
    }
  

    useEffect(()=>{
        if(email.includes('@') && pass){
            setDisable(false);
        }
        else{
            setDisable(true)
        }
    },[email, pass])

    

    const handleSubmit = () => {
        setLoading(true)
        localStorage.setItem('email',pnum);
        navigate('/home')
        setLoading(false)
    }
    
        if(loading){
            return(
                <div className="absolute left-1/2 top-1/2 -translate-x-1/2  text-center -translate-y-1/2">
                    <InfinitySpin
                    width='200'
                    color='#4899B7'
                    ></InfinitySpin>
                </div>
            )
        }
        else{
            return (
              <div className="relative min-h-screen ">
                {
                  error?  
                  <div className="">
                      <ErrorModal text={errorText} change={handleState} buttonText={'Signup now'}/>
                  </div>
                :
                  <div className={error?"flex blur-2xl":"flex "}>
                      <img src={loginguy} className="h-screen hidden lg:block"></img>
                      <div className="w-full">
                        <Link to="/">
                      <div className="flex justify-between items-center mt-3 mx-4">
                          <img src={interviewguru_logo} className="h-20 "></img>
                      </div>
                        </Link>
                        <div className="mt-12 lg:w-1/2  mx-auto">
                <h1 className="text-2xl lg:text-left text-center font-poppins font-semibold ">Enter your phone number</h1>
               <div className="flex space-x-3 items-center my-4 lg:justify-start justify-center">

                </div> 
                
                <div className="lg:flex w-full lg:px-0 px-4 lg:space-x-3 my-4">
                {
                    verify?
                    <div className="lg:w-1/2 items-center mb-3 ">
                        {pnum} has been successfully <span className="text-green-500 font-bold ">verified!</span>
                    </div>
                    :
                    <div className="w-full">
                        <div>
                        <div className="flex w-full lg:mb-0 mb-4 space-x-3 items-center ">
                        <div class="relative   lg:mb-0 w-full">
                            <input type="text" required value={pnum} onChange={(e)=>{setPnum(e.target.value)}} id="floating_outlined" className=" px-2.5 border-2 pb-2.5  pt-4 w-full  text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
                            <label for="floating_outlined" className="absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 font-poppins">Phone number</label>
                        </div>
                        <button disabled={pnum.length != 10} className={pnum.length!=10?"px-4 py-2 w-[15rem] bg-gray-300 text-white rounded-xl h-full ":"px-4 py-2 w-[15rem] bg-blue-500 text-white rounded-xl h-full"} onClick={sendOTP}>
                            {
                            loader?
                            <div className="flex w-full justify-center">
                                <ColorRing
                                    visible={true}
                                    height="30"
                                    width="30"
                                    className="mx-auto"
                                    ariaLabel="blocks-loading"
                                    wrapperStyle={{}}
                                    wrapperClass="blocks-wrapper"
                                    colors={['#FFFFFF','#FFFFFF','#FFFFFF','#FFFFFF','#FFFFFF']}
                                />
                            </div>
                            :    
                            'Generate OTP'
                            }
                        </button>
                        </div>
                        {
                            pnum.length!=10
                            &&
                            <p className="font-poppins text-sm">Enter a valid 10 digit phone number</p>
                        }
                        </div>   
                        {
                            otpsent &&
                        <div>
                            <div className="lg:flex text-left my-6 lg:space-x-3 space-y-3 lg:space-y-0 lg:mb-0 mb-4 ">
                                <div className="flex items-start mx-auto justify-center w-full lg:justify-start space-x-4 ">
                                    <input type="number" name="field-1" className={invalid?"border-2 text-red-500 border-red-500 outline-none w-[2rem] px-2 py-2 rounded-lg text-center":"border-2 outline-none w-[2rem] px-2 py-2 rounded-lg text-center"} maxlength="1" value={otp1} onChange={(e)=>{
                                        const { maxLength, value, name } = e.target
                                        const [fieldName, fieldIndex] = name.split("-");
                                        let fieldIntIndex = parseInt(fieldIndex, 10);
                                        if (value.length >= maxLength) {
 
                                            // It should not be last input field
                                            if (fieldIntIndex < 4) {
                                 
                                                // Get the next input field using it's name
                                                const nextfield = document.querySelector(
                                                    `input[name=field-${fieldIntIndex + 1}]`
                                                );
                                 
                                                // If found, focus the next field
                                                if (nextfield !== null) {
                                                    nextfield.focus();
                                                }
                                            }
                                        }
                                        setOtp1(e.target.value)}}></input>
                                    <input type="number" name="field-2" className={invalid?"border-2 text-red-500 border-red-500 outline-none w-[2rem] px-2 py-2 rounded-lg text-center":"border-2 outline-none w-[2rem] px-2 py-2 rounded-lg text-center"} maxlength="1" value={otp2} onChange={(e)=>{
                                        const { maxLength, value, name } = e.target
                                        const [fieldName, fieldIndex] = name.split("-");
                                        let fieldIntIndex = parseInt(fieldIndex, 10);
                                        if (value.length >= maxLength) {
 
                                            // It should not be last input field
                                            if (fieldIntIndex < 4) {
                                 
                                                // Get the next input field using it's name
                                                const nextfield = document.querySelector(
                                                    `input[name=field-${fieldIntIndex + 1}]`
                                                );
                                 
                                                // If found, focus the next field
                                                if (nextfield !== null) {
                                                    nextfield.focus();
                                                }
                                            }
                                        }
                                        setOtp2(e.target.value)}}></input>
                                    <input type="number" name="field-3" className={invalid?"border-2 text-red-500 border-red-500 outline-none w-[2rem] px-2 py-2 rounded-lg text-center":"border-2 outline-none w-[2rem] px-2 py-2 rounded-lg text-center"} maxlength="1" value={otp3} onChange={(e)=>{
                                        const { maxLength, value, name } = e.target
                                        const [fieldName, fieldIndex] = name.split("-");
                                        let fieldIntIndex = parseInt(fieldIndex, 10);
                                        if (value.length >= maxLength) {
 
                                            // It should not be last input field
                                            if (fieldIntIndex < 4) {
                                 
                                                // Get the next input field using it's name
                                                const nextfield = document.querySelector(
                                                    `input[name=field-${fieldIntIndex + 1}]`
                                                );
                                 
                                                // If found, focus the next field
                                                if (nextfield !== null) {
                                                    nextfield.focus();
                                                }
                                            }
                                        }
                                        setOtp3(e.target.value)}}></input>
                                    <input type="number" name="field-4" className={invalid?"border-2 text-red-500 border-red-500 outline-none w-[2rem] px-2 py-2 rounded-lg text-center":"border-2 outline-none w-[2rem] px-2 py-2 rounded-lg text-center"} maxlength="1" value={otp4} onChange={(e)=>{
                                        const { maxLength, value, name } = e.target
                                        const [fieldName, fieldIndex] = name.split("-");
                                        let fieldIntIndex = parseInt(fieldIndex, 10);
                                        if (value.length >= maxLength) {
 
                                            // It should not be last input field
                                            if (fieldIntIndex < 4) {
                                 
                                                // Get the next input field using it's name
                                                const nextfield = document.querySelector(
                                                    `input[name=field-${fieldIntIndex + 1}]`
                                                );
                                 
                                                // If found, focus the next field
                                                if (nextfield !== null) {
                                                    nextfield.focus();
                                                }
                                            }
                                        }
                                        setOtp4(e.target.value)}}></input>
                                </div>
                                <div className=" text-center ">
                                {
                                invalid &&
                                <p className="font-poppins text-sm my-3 lg:hidden block text-red-500">Invalid OTP</p>
                            }
                        <button className="px-10   bg-blue-500 text-white text-lg  rounded-xl py-2  " onClick={()=>{
                            verifyOTP()}}>Verify</button>
                        </div>
                        <div className="text-center">
                        <button disabled={counter>0} className="px-10   bg-blue-500 text-white text-lg  rounded-xl py-2  " onClick={()=>{
                            setCounter(30)
                            sendOTP()}}>
                            {
                                counter>0?
                                counter
                                :
                                "Resend"
                            }
                        </button>
                        </div>
                            </div>
                            {
                                invalid &&
                                <p className="font-poppins text-sm my-3 hidden lg:block text-red-500">Invalid OTP</p>
                            }
                            <p className='text-center text-sm font-poppins my-3'>A four digit OTP has been sent to your phone number.</p>
                        </div>
                        }
                        
                        
                    </div>
                }    
                </div>
                
                
                {/* <div className="mx-5 lg:mx-0">
                <button className={disable?`px-10 w-full bg-slate-400 text-slate-200 text-lg font-poppins rounded-xl py-3 my-4 lg:mx-0 `:`px-10 w-full bg-[#4899B7] text-white text-lg font-poppins rounded-xl py-3 my-4 lg:mx-0 ` } disabled={disable} onClick={handleSubmit} >Sign in</button>
                </div> */}
                <Link to="/signup">
                <h1 className="font-poppins  my-3 text- text-center">  New User? <span className="text-red-500 font-bold">Create an account</span></h1>
                </Link>
            </div>
          
                      </div>
                  </div>
        }
              </div>
            )
     }
}

export default PhoneNumberLogin
import React,{useState, useEffect} from 'react'
import YouTube from 'react-youtube'
import axios from 'axios'
import {BiArrowBack} from 'react-icons/bi'
import { Link } from 'react-router-dom'
import data from '../config'
const Webinars = () => {


  const [link,setLink] = useState('')
  const [open,setOpen] = useState(false)
  const [width,setWidth] = useState(0)
  const [height,setHeight] = useState(0)

  useEffect(()=>{
    if(window.innerWidth<500){
        setWidth(300)
        setHeight(150)
    }
    else{
        setWidth(400)
        setHeight(250)
    }
})
  const thumbnail17 = "https://img.youtube.com/vi/x4Q5zXaUySA/0.jpg"
  const thumbnail18 = "https://img.youtube.com/vi/YPiTM_PJvtU/0.jpg"
  const user = localStorage.getItem('email')
  const record = (username, cat, code, title)=>{
    axios.post(`https://${data.API}/interview/video/watched`,{
      username:username,
      category:cat,
      videocode:code,
      title:title
    })
    .then((response)=>{
      console.log(response)
    })
    .catch((error)=>{
      console.log(error)
    })
  }


  return (
    <div className="mt-16">

<button className="p-4 bg-green-500 rounded-xl text-white ">
      <Link to="/courses">
      <BiArrowBack></BiArrowBack>
      </Link>
    </button> 
        <div>
            <p className='font-poppins font-extrabold text-2xl my-4 ml-4 lg:ml-0'>Our Webinars</p>
            <div className="lg:grid lg:grid-cols-3 space-y-5 lg:space-y-0 gap-8">
            <div className="h-full">
        <button onClick={()=>{setLink('x4Q5zXaUySA')
      setOpen(true)
      record(user,'web','x4Q5zXaUySA','How To Get Your Resume Shortlisted')
      }} className=" text-left h-full">
        <div className="bg-white px-4 py-5 flex  lg:mr-0 mr-4   space-x-4 rounded-xl">
            <img src={thumbnail17} className="w-1/3 h-full rounded-xl"></img>
                    <div className="space-y-2">
                    <h1 className="lg:text-xl text-lg font-bold">How To Get Your Resume Shortlisted</h1>
                    <h1 className="lg:text-lg text-lg">by Interview Vision</h1>
                    <div className="h-[0.1rem] bg-[#D9D9D9]"></div>
                    <h1 className='text-sm font-extralight'>33 minutes 16 seconds</h1>
            </div>
        </div>
        </button>
          </div>
          <div className="h-full">
        <button onClick={()=>{setLink('YPiTM_PJvtU')
      setOpen(true)
      record(user,'web','YPiTM_PJvtU','How To Respond To Key Questions')
      }} className=" text-left h-full">
        <div className="bg-white px-4 py-5 flex  lg:mr-0 mr-4   space-x-4 rounded-xl">
            <img src={thumbnail18} className="w-1/3 h-full rounded-xl"></img>
                    <div className="space-y-2">
                    <h1 className="lg:text-xl text-lg font-bold">How To Respond To Key Questions</h1>
                    <h1 className="lg:text-lg text-lg">by Interview Vision</h1>
                    <div className="h-[0.1rem] bg-[#D9D9D9]"></div>
                    <h1 className='text-sm font-extralight'>51 minutes 49 seconds</h1>
            </div>
        </div>
        </button>
          </div>
            </div>
        </div>

        {
          open &&
        <div className={width===300?"fixed top-0 left-0 lg:w-1/3 px-4 lg:h-1/2 bg-[#2d9dba] rounded-xl shadow-2xl":"fixed  right-0 bottom-0 lg:w-1/3 lg:h-1/2 bg-[#2d9dba] rounded-xl shadow-2xl"}>
          <div className="flex justify-between items-center">
          <p className="text-white ml-4 text-xl mt-4 font-poppins">Now Playing</p>
          <button className="text-white text-xl font-poppins mr-4" onClick={()=>{setOpen(false)}}>x</button>
          </div>
          <div className="mx-auto text-center">
          <YouTube videoId={link} iframeClassName='mx-auto my-4 ' className="" opts={{width:width, height:height}} />
          </div>
        </div>
        }
    </div>
  )
}

export default Webinars
import React, {useState, useEffect} from 'react'
import temp1 from '../images/temp1.png'
import temp2 from '../images/temp2.png'
import temp3 from '../images/temp3.png'
import temp4 from '../images/temp4.png'
import temp5 from '../images/temp5.png'
import temp6 from '../images/temp6.png'
import temp7 from '../images/temp7.png'
import temp8 from '../images/temp8.png'
import axios from 'axios'
import data from '../config'

import crown from '../images/crown.png' 
import ReactGA from 'react-ga'
import { Link ,  useNavigate} from 'react-router-dom'
import { InfinitySpin } from 'react-loader-spinner'
const Resume = () => {
  const navigate = useNavigate()
  const [status, setStatus] = useState(null)
  const [loader, setLoader] = useState(true)
  const email = localStorage.getItem('email')

  useEffect(()=>{
    axios.get(`https://${data.API}/users/r/${email}`)
    .then((response)=>{
        setStatus(response.data.subscribedpack)
    })
    .catch((error)=>{
        console.log(error)
    })
  },[])
  useEffect(()=>{
    
    if(status!=null){
        setLoader(false)
    }
  },[status])

  const basicCheck = (id)=>{
    if(status ==""){
        axios.post(`https://${data.API}/payment/pe/create`,{
          username:email,
          amount:49900,
          pack:"basic"
        })
        .then((response)=>{
        console.log(response)
        localStorage.setItem('merchantTransactionId',response.data.merchantTransactionId)
        window.location.href = response.data.url
      })
      .catch((error)=>{
        console.log(error)
      })
    }
    else{
        navigate('/editor',{state:{index:id}})
    }
  }

  const premCheck = (id)=>{
    if(status ==""  ){
        axios.post('https://interviewvision.com/payment/pe/create',{
          username:email,
          amount:69900,
          pack:"standard"
        })
        .then((response)=>{
        console.log(response)
        localStorage.setItem('merchantTransactionId',response.data.merchantTransactionId)
        window.location.href = response.data.url
      })
      .catch((error)=>{
        console.log(error)
      })
    }
    else{
        navigate('/editor',{state:{index:id}})
    }
  }
function ConditionalLink({ children, condition, ...props }) {
    return !!condition && props.to ?<>{children}</>   : <Link {...props}>{children}</Link>
  }

  if (loader==false) return (
    <div className="min-h-screen bg-gray-100 font-poppins">
        <h1 className="text-3xl  ml-4 mt-8">Templates</h1>
        <div className="lg:grid lg:grid-cols-3 mx-3 -ml-1 mt-10 ">
            <button onClick={()=>{
                basicCheck(1)
                ReactGA.event({
      category:"Uses Resume 1",
      action:"User chooses resume 1"
    })}}
    className="p-3 hover:border-2 hover:opacity-60 w-full hover:rounded-xl hover:border-[#E94B60]">
                <div className="relative">
                <img src={temp2} className="h-[25rem] aspect-[12/16] mx-auto"></img>
                {
                    status==""
                    &&
                    <div>
                        <p className="top-1/2 left-1/2 bg-[#3f475f] text-white -translate-x-1/2 -translate-y-1/2 absolute text-center backdrop-blur p-3 rounded-xl">Subscribe to basic pack to access</p>
                        <img src={crown} className="absolute bottom-3 right-10 rounded-full h-10"></img>
                    </div>
                }
                </div>
                <p className="text-center mt-3">Vision</p>
            </button>
            <button onClick={()=>{
                basicCheck(2)
                ReactGA.event({
      category:"Uses Resume 2",
      action:"User chooses resume 2"
    })}} className="p-3 w-full hover:border-2 hover:opacity-60  hover:rounded-xl hover:border-[#E94B60]">
            <div className="relative">
                <img src={temp1} className="h-[25rem] aspect-[12/16] mx-auto"></img>
                {
                    status==""
                    &&
                    <div>
                        <p className="top-1/2 left-1/2 bg-[#3f475f] text-white -translate-x-1/2 -translate-y-1/2 absolute text-center backdrop-blur p-3 rounded-xl">Subscribe to basic pack to access</p>
                        <img src={crown} className="absolute bottom-3 right-10 rounded-full h-10"></img>
                    </div>
                }
                </div>
                <p className='text-center mt-3'>Nebula</p>
            </button>
            <button onClick={()=>{
                basicCheck(3)
                ReactGA.event({
      category:"Uses Resume 3",
      action:"User chooses resume 3"
    })}} className="p-3 hover:border-2 w-full hover:opacity-60  hover:rounded-xl hover:border-[#E94B60]">
            <div className="relative">
                <img src={temp3} className="h-[25rem] aspect-[12/16] mx-auto"></img>
                {
                    status==""
                    &&
                    <div>
                        <p className="top-1/2 left-1/2 bg-[#3f475f] text-white -translate-x-1/2 -translate-y-1/2 absolute text-center backdrop-blur p-3 rounded-xl">Subscribe to basic pack to access</p>
                        <img src={crown} className="absolute bottom-3 right-10 rounded-full h-10"></img>
                    </div>
                }
                </div>
                <p className='text-center mt-3'>Loki</p>
            </button>
            <button onClick={()=>{
                premCheck(4)
                ReactGA.event({
      category:"Uses Resume 4",
      action:"User chooses resume 4"
    })}} className="p-3 hover:border-2 w-full hover:opacity-60  hover:rounded-xl hover:border-[#E94B60]">
            <div className="relative">
                <img src={temp4} className="h-[25rem] aspect-[12/16] mx-auto"></img>
                {
                    (status==""  )
                    &&
                    <div>
                        <p className="top-1/2 left-1/2 bg-[#3f475f] text-white -translate-x-1/2 -translate-y-1/2 absolute text-center backdrop-blur p-3 rounded-xl">Subscribe to standard or premium pack to access</p>
                        <img src={crown} className="absolute bottom-3 right-10 rounded-full h-10"></img>
                    </div>
                }
                </div>
                <p className='text-center mt-3'>Gamora</p>
            </button>
            <button onClick={()=>{
                premCheck(5)
                ReactGA.event({
      category:"Uses Resume 5",
      action:"User chooses resume 5"
    })}} className="p-3 hover:border-2 w-full hover:opacity-60  hover:rounded-xl hover:border-[#E94B60]">
            <div className="relative">
                <img src={temp5} className="h-[25rem] aspect-[12/16] mx-auto"></img>
                {
                    (status==""  )
                    &&
                    <div>
                        <p className="top-1/2 left-1/2 bg-[#3f475f] text-white -translate-x-1/2 -translate-y-1/2 absolute text-center backdrop-blur p-3 rounded-xl">Subscribe to standard or premium pack to access</p>
                        <img src={crown} className="absolute bottom-3 right-10 rounded-full h-10"></img>
                    </div>
                }
                </div>
                <p className='text-center mt-3'>Falcon</p>
            </button>
            <button onClick={()=>{
                premCheck(6)
                ReactGA.event({
      category:"Uses Resume 6",
      action:"User chooses resume 6"
    })}} className="p-3 hover:border-2 w-full hover:opacity-60  hover:rounded-xl hover:border-[#E94B60]">
            <div className="relative">
                <img src={temp6} className="h-[25rem] aspect-[12/16] mx-auto"></img>
                {
                    (status==""  ) 
                    &&
                    <div>
                        <p className="top-1/2 left-1/2 bg-[#3f475f] text-white -translate-x-1/2 -translate-y-1/2 absolute text-center backdrop-blur p-3 rounded-xl">Subscribe to standard or premium pack to access</p>
                        <img src={crown} className="absolute bottom-3 right-10 rounded-full h-10"></img>
                    </div>
                }
                </div>
                <p className='text-center mt-3'>Groot</p>
            </button>
            <button onClick={()=>{
                premCheck(7)
                ReactGA.event({
      category:"Uses Resume 7",
      action:"User chooses resume 7"
    })}} className="p-3 hover:border-2 w-full hover:opacity-60  hover:rounded-xl hover:border-[#E94B60]">
            <div className="relative">
                <img src={temp7} className="h-[25rem] aspect-[12/16] mx-auto"></img>
                {
                    (status==""  )
                    &&
                    <div>
                        <p className="top-1/2 left-1/2 bg-[#3f475f] text-white -translate-x-1/2 -translate-y-1/2 absolute text-center backdrop-blur p-3 rounded-xl">Subscribe to standard or premium pack to access</p>
                        <img src={crown} className="absolute bottom-3 right-10 rounded-full h-10"></img>
                    </div>
                }
                </div>
                <p className='text-center mt-3'>Drax</p>
            </button>
            <button onClick={()=>{
                premCheck(8)
                ReactGA.event({
      category:"Uses Resume 8",
      action:"User chooses resume 8"
    })}} className="p-3 hover:border-2 w-full hover:opacity-60  hover:rounded-xl hover:border-[#E94B60]">
            <div className="relative">
                <img src={temp8} className="h-[25rem] aspect-[12/16] mx-auto"></img>
                {
                    (status==""  )
                    &&
                    <div>
                        <p className="top-1/2 left-1/2 bg-[#3f475f] text-white -translate-x-1/2 -translate-y-1/2 absolute text-center backdrop-blur p-3 rounded-xl">Subscribe to standard or premium pack to access</p>
                        <img src={crown} className="absolute bottom-3 right-10 rounded-full h-10"></img>
                    </div>
                }
                </div>
                <p className='text-center mt-3'>Mantis</p>
            </button>
        </div>
    </div>
  )
  else return(
    <div className="absolute left-1/2 top-1/2 -translate-x-1/2  text-center -translate-y-1/2">
                    <InfinitySpin
                    width='200'
                    color='#4899B7'
                    ></InfinitySpin>
                </div>
  )
}

export default Resume
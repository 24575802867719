import axios from 'axios'
import React, {useEffect, useState} from 'react'
import { Link } from 'react-router-dom'
import ReactStars from 'react-stars'
import linkedin from '../images/linkedin.png'
import maya from '../images/Maya.JPG'
import rajiv from '../images/rajiv_coach.jpg'
import { InfinitySpin } from 'react-loader-spinner'
import Vijaya from '../images/Vijaya.jpeg'
import coach_image from '../images/coaches.svg'
import data from '../config'

const Coaches = () => {
    const [coaches, setCoaches] = useState([])
    const [photos, setPhotos] = useState([maya, rajiv, Vijaya])

    useEffect(()=>{
        axios.get(`https://${data.API}/coach`)
        .then((response)=>{
            console.log(response)
            setCoaches(response.data)

        })
        .catch((error)=>{
            console.log(error)
        })
    },[])

    const ReadMore = ({ children }) => {
        const text = children;
        const [isReadMore, setIsReadMore] = useState(true);
        const toggleReadMore = () => {
          setIsReadMore(!isReadMore);
        };
        return (
          <pre className="text font-poppins">
            {isReadMore ? text.slice(0, 90) : text}
            <span onClick={toggleReadMore} className="read-or-hide cursor-pointer text-red-500">
              {isReadMore ? " ... read more" : " show less"}
            </span>
          </pre>
        );
      };

      const Content = (props) => {
        return (
          <div className="container">
            <h2>
              <ReadMore>
                {props.content}
              </ReadMore>
            </h2>
          </div>
        );
      };

  return (
    <div>
    {
        coaches.length>0 ?
    <div className="px-5 mt-16 font-poppins">
        <div className="bg-white rounded-xl p-4 ">
            <div className="rounded-xl p-6 bg-[#EDEDED] gap-4 md:flex items-center justify-between">
                <img src={coach_image} className="h-60 order-last"></img>
                <div className="md:w-2/3 w-full ">
                <p className="font-poppins text-2xl font-bold">Coaches</p>
                <p className="text-md my-3 ">Coaching is a personalised and collaborative approach aimed at facilitating personal and professional growth. For job seekers in their early career years, coaching can be immensely beneficial. It provides tailored guidance to navigate the complexities of the job market and professional development. Discover yourself on a guided  professional journey with our super star coaches and mentors and find out the best parts about yourself that your never thought existed.</p>
                <Link to="/courses/coaches/bookings">
                <button className="text-white bg-[#E94B60] px-5 py-3 rounded-xl">My bookings</button>
                </Link>
                </div>
            </div>
            

        </div>
        <p className="text-[#464646] text-2xl font-extrabold my-10">Our Coaches</p>

        <div className="md:grid md:grid-cols-3 auto-rows-max  items-stretch space-y-4 md:space-y-0 gap-4">

        {
            coaches.map((c, i)=>{
                return(
                  <div className="">
                    
                    <div className="rounded-xl ">
                <img src={photos[i]} className=" rounded-t-xl h-[20rem] w-full"></img>
                <div className="bg-white flex flex-col  justify-between h-[14rem] p-4 rounded-b-xl">
                    <div className="flex justify-between items-center ">
                    <p className="">{c.Name}</p>
                    <ReactStars
                        className="pointer-events-none"
                        count={5}
                        value={5}
                        isHalf={true}
                        edit={false}
                        size={22}
                    ></ReactStars>
                    </div>
                    <Content content={c.Profile} className=" my-4"></Content>
                    <div className="flex justify-between items-center">
                        <a href={c.Linkedin} target="_blank" >
                        <img src={linkedin} className="h-9"></img>
                        </a>
                        <Link to="/courses/coaches/info" state={{name:c.Name}}>
                        <button className="bg-[#E94B60] text-white py-2 px-5 rounded-xl">Explore Now</button>
                        </Link>
                    </div>
                </div>
            </div>
            </div>
                )
            })
        }
        </div>

        <div>
        
        </div>
    </div>
    :
    <div>
        <div className="absolute left-1/2 top-1/2 -translate-x-1/2  text-center -translate-y-1/2">
                    <InfinitySpin
                    width='200'
                    color='#4899B7'
                    ></InfinitySpin>
                </div>
    </div>
        }
    </div>
  )
}



export default Coaches
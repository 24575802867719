import React, {useState, useEffect} from 'react'
import YouTube from 'react-youtube';
import ReactGA from 'react-ga'
import grow_thumbnail from '../images/growth_thumbnail.png'
import comm_thumbnail from '../images/comm_thumbnail.png'
import growth_video from '../images/growth.mp4'
import introduction from '../images/Introduction.mp4'
import comm_video from '../images/comm.mp4'
import axios from 'axios';
import {FaLock} from 'react-icons/fa'
import {InfinitySpin} from 'react-loader-spinner'
import { Link } from 'react-router-dom';
import {IoIosClose} from 'react-icons/io'
import {BiArrowBack} from 'react-icons/bi'
import introduction_thumbnail from '../images/Introduction_thumbnail.png'
import ct from '../images/Critical Thinking.mp4'
import ct_thumbnail from '../images/ct_thumbnail.png'
import ls from '../images/Active Listening.mp4'
import ls_2 from '../images/ls_2.mp4'
import ls_3 from '../images/ls_3.mp4'
import ls_thumbnail from '../images/Active Listening.png'
import tw from '../images/Teamwork.mp4'
import tw_thumbnail from '../images/Teamwork.png'
import comm_2 from '../images/Comm_2.mp4'
import comm_3 from '../images/Comm_3.mp4'
import intro_2 from '../images/intro_2.mp4'
import starter from '../images/Master course intro.mp4'
import data from '../config'
import { AnimatePresence, motion } from 'framer-motion';
import {MdKeyboardArrowRight} from 'react-icons/md'
const Inhouse = () => {
  const [link,setLink] = useState('')
  const [open,setOpen] = useState(false)
  const [width,setWidth] = useState(0)
  const [height,setHeight] = useState(0)
  const user = localStorage.getItem('email')
  const [pack, setPack] = useState(null)
  const [introOpen, setIntroOpen] = useState(false)
  const [growthOpen, setGrowthOpen] = useState(false)
  const [lsOpen, setLsOpen] = useState(false)
  const [twOpen, setTwOpen] = useState(false)
  const [commOpen, setCommOpen] = useState(false)
  const [ctOpen, setCtOpen] = useState(false)
  const [name, setName] = useState('')
  const [loader, setLoader] = useState(true)
  const [team, setTeam] = useState([])
  const [comm, setComm] = useState([])
  const [critical, setCritical] = useState([])
  const [gm, setGm] = useState([])
  const [listening, setListening] = useState([])
  const [intro, setIntro] = useState([])
  const [videos, setVideos] = useState([])
  const [mainText, setMainText] = useState('')
  const [subText, setSubText] = useState('')

  const gmText = 'In our Growth Mindset modules you will learn ways of thinking about self and your abilities that help you grow and improve. Having a positive attitude that helps you see challenges and mistakes as opportunities to learn and improve, instead of getting discouraged or giving up.'
  const ctText = "The Critical Thinking modules support us in thinking about problems and making decisions in a smart way by being curious, by gathering information, by asking questions and using reasoning to figure things out and find the best solution"
  const introText = "Learn how your introduction sets the tone for the rest of the conversation and helps establish a positive first impression. These modules prepare you to understand how the interviewer forms a preliminary opinion of you based on your introduction."
  const commText = "You will learn the ability to share information and ideas that makes sure the message is understood clearly by the person receiving it."
  const lsText = "These modules develop your skill in paying close attention to someone when they're speaking to you and understanding and trying to learn from what they're telling you."
  const twText = "Learn the art of working together with others to achieve a common goal. Teamwork is when we listen to each other's ideas, help each other when needed, and make decisions together to achieve success."

  const gm1 = `What you will learn
  • Introduction to mindset
  • Your current mindset
  • Self reflection
  • Fixed mindset
  `
  const gm2 = `What you will learn
  • Introduction growth mindset
  • Can mindset be changed
  • Neuroplasticity
  • Tips to increase your brain’s efficiency
  `

  const i1 = `
  What you will learn 
  • How does it matter?
  • What is a self-Introduction?
  • Purpose of self-introduction
  `

  const i2 = `
  What you will learn
  • What to cover in our self-introduction
  • How do we introduce ourselves? 
  • Ways to introduce ourselves as a fresher.
  `
  
  const comm1 = `
  What you will Learn 
  • Communication and its importance
  • Elements of communication
  • Interactive ways of communication
  • What isn't communication?
  `

  const comm2 = `
  What you will learn
  • Various channels of communication 
  • Purpose of communication
  • Audience & communication
  `

  const comm3 = `
  What you will learn
  • Enhance verbal communication to have relevant impact on audience
  • Appropriate use of body language, gestures and posture
  • Appropriate channel for effective communication
  • Appropriate use of oral and written modes of communication with tips
  `

  const ct1 = `
  What you will learn 
  • Introduction to critical thinking
  • Confirmation bias
  • What is thinking?
  `

  const ls1 = `
  What you will learn
  • Understanding selective listening
  • Role of situation and context while listening
  • Differentiating between hearing and listening
  `
  const ls2 = `
  What you will learn
  • What is the intent?
  • Why is intention more important than action?
  • Application of intent during an interview
  `

  const ls3 = `
  What you will learn
  • How to develop active listening skills
  • Understanding non-verbal cues
  • Staying in the same mental space as an interviewer
  `

  const tw1 = `
  What you will learn
  • Understanding teamwork 
  • Difference between group and individual contributor.
  • Formal group v/s an informal group
  `

  const textUpdate = (cat) =>{
    if(cat == "gm"){
      setMainText(gmText)
    }
    else if(cat == "comm"){
      setMainText(commText)
    }
    else if(cat == "ct"){
      setMainText(ctText)
    }
    else if(cat == "ls"){
      setMainText(lsText)
    }
    else if(cat == "tw"){
      setMainText(twText)
    }
    else if(cat == "web"){
      setMainText(introText)
    }
  }

  const subTextUpdate = (name)=>{
    if(name == "Introducing Yourself"){
      setSubText(i1)
    }
    else if(name == "Preparing your Self-introduction"){
      setSubText(i2)
    }
    else if(name == "Introduction to Growth Mindset"){
      setSubText(gm1)
    }
    else if(name == "Introduction to Communication"){
      setSubText(comm1)
    }
    else if(name == "Channel & Pupose of communication"){
      setSubText(comm2)
    }
    else if(name == "Application of communication"){
      setSubText(comm3)
    }
    else if(name == "Introduction to Critical Thinking"){
      setSubText(ct1)
    }
    else if(name == "Introduction to Active Listening"){
      setSubText(ls1)
    }
    else if(name == "The role of Intent"){
      setSubText(ls2)
    }
    else if(name == "Facets of Active Listening"){
      setSubText(ls3)
    }
    else if(name == "Introduction to Teamwork"){
      setSubText(tw1)
    }
  }


  const checkStatus = (link, name, cat)=>{
    if((pack=="" || pack=="basic")){
      premCheck()
    }
    else{
      subTextUpdate(name)
      setLink(link)
      setOpen(true)
      setName(name)
      textUpdate(cat)
      record(user,cat,link,name)
      
    }
  }

  const checkBucketStatus = (uuid, displayName, text, cat)=>{
    if((pack=="" || pack=="basic")){
      premCheck()
    }
    else{
      textUpdate(cat)
      subTextUpdate(text)
      axios.get(`https://${data.API}/video/geturl/${uuid}`)
      .then((response)=>{
        setLink(response.data.url)
        setName(displayName)
        record(user,cat,link,displayName)
      })
      .catch((error)=>{
        console.log(error)
      })

    }
  }
  useEffect(()=>{
    setLink(starter)
    setOpen(true)
    setName('Master course video')
    record(user,'gm',starter,'Master course video')
      const email = localStorage.getItem('email')
      axios.get(`https://${data.API}/video/details`)
      .then((response)=>{
        setVideos(response.data)
        axios.get(`https://${data.API}/users/r/${email}`)
        .then((response)=>{
            setPack(response.data.subscribedpack)
        })
        .catch((error)=>{
            alert('Something went wrong! Please try again')
            console.log(error)
        })
        console.log(response)
      })
      .catch((err)=>{
        console.log(err)
      })
  },[])

  useEffect(()=>{
    if(videos.length>0){
      setListening(videos.filter((v)=>{
        if(v.category=="ls"){
          return v
        }
      }))
      setCritical(videos.filter((v)=>{
        if(v.category=="ct"){
          return v
        }
      }))
      setIntro(videos.filter((v)=>{
        if(v.category=="i"){
          return v
        }
      }))
      setComm(videos.filter((v)=>{
        if(v.category=="comm"){
          return v
        }
      }))
      setTeam(videos.filter((v)=>{
        if(v.category=="tw"){
          return v
        }
      }))
      setGm(videos.filter((v)=>{
        if(v.category=="gm"){
          return v
        }
      }))
    }
    setLoader(false)
  },[videos])
    
    useEffect(()=>{
      if(window.innerWidth<500){
          setWidth(300)
          setHeight(150)
      }
      else{
          setWidth(700)
          setHeight(250)
      }
  })
  useEffect(()=>{
    ReactGA.pageview(window.location.pathname + window.location.search)
  },[])

  const premCheck = ()=>{
    
        axios.post(`https://${data.API}/payment/pe/create`,{
          username:user,
          amount:69900,
          pack:"standard"
        })
        .then((response)=>{
        console.log(response)
        localStorage.setItem('merchantTransactionId',response.data.merchantTransactionId)
        window.location.href = response.data.url
      })
      .catch((error)=>{
        console.log(error)
      })
    
  }
  

  const record = (username, cat, code, title)=>{
    axios.post(`https://${data.API}/interview/video/watched`,{
      username:username,
      category:cat,
      videocode:code,
      title:title
    })
    .then((response)=>{
      console.log(response)
    })
    .catch((error)=>{
      console.log(error)
    })
  }

  console.log(intro, listening, comm, gm, team,critical )
  return (
    <div className="mt-16 px-5">
     <button className="p-4 bg-green-500 rounded-xl text-white ">
      <Link to="/courses">
      <BiArrowBack></BiArrowBack>
      </Link>
    </button> 
     {
      (pack!=null && loader==false)?
     <div className="relative ">
      <div className="md:flex md:space-x-5 space-y-4 md:space-y-0 items-start justify-start w-full">
      <div className={"w-full md:w-2/3"}>
          <div className="mx-auto text-center">
            <video
            src={link}
            // height={height}
            // width={width}
            autoPlay
            controls
            className="mx-auto my-8 rounded-xl"
            playsInline
            >
            </video>
          </div>
          <p className="text-2xl font-poppins">{name}</p>
          <p className="font-poppins">by Interview Vision</p>
          <p className="font-poppins my-3">{mainText}</p>
          <pre className="font-poppins my-3">{subText}</pre>
        </div>
        <div className="md:w-1/3 w-full">
          <p className="font-poppins text-2xl ">Course Content</p>
          <div className="flex flex-col px-4 py-4 bg-white justify-start rounded-xl my-4">
            <button onClick={()=>{
               setLink(starter)
               setOpen(true)
               record(user,'gm',starter,'Master course video')
            }}
            className="text-left"
            >
            <div className="  rounded-t-xl w-full">
              <p>Welcome</p>
            </div>
            </button>
            <div className="py-[0.05rem] bg-gray-200 my-4 w-full"></div>
            <div>
            <button onClick={()=>{setIntroOpen(!introOpen)}} className="flex justify-between items-center  w-full">
              <p>Introduction</p>
              {
                (pack == '' || pack == 'basic') ?
                <div className="">
                  <FaLock className="text-sm opacity-40 z-20"></FaLock>
                </div>
                :
                <div>
                  <MdKeyboardArrowRight className={introOpen?"rotate-90 transition-all":"rotate-0 transition-all"}></MdKeyboardArrowRight>
                </div>
              }
            </button>

            <AnimatePresence>
            {
              introOpen &&
            <motion.div
            initial={{ opacity: 0, y:-100 }}
        animate={{ opacity: 1, y:0 }}
        exit={{ opacity: 0, y:-50}}
            className="bg-gray-100 rounded-xl mb-2 py-3 px-4 mt-4">
              <button onClick={()=>{checkStatus(introduction, 'Introducing Yourself', 'web')}} className="w-full  text-left">
              <p>Introducing Yourself</p>
              </button>
              <div className="py-[0.05rem] bg-gray-200 my-4 w-full"></div>
              <button onClick={()=>{checkStatus(intro_2, 'Preparing your Self-introduction', 'web')}} >
              <p>Preparing your Self-Introduction</p>
              </button>
              {
                intro.map((c)=>{
                  return(
                    <div>
                      <div className="py-[0.05rem] bg-gray-200 my-4 w-full"></div>
              <button onClick={()=>{
             checkBucketStatus(c.uuid, c.name,c.content, 'web')}} >
              <p>{c.name}</p>
              </button>
                    </div>

                  )
                })
              }
            </motion.div>
            }
            </AnimatePresence>
            </div>
            <div className="py-[0.05rem] bg-gray-200 my-4 w-full"></div>
            <div>
            <button onClick={()=>{setGrowthOpen(!growthOpen)}} className="flex justify-between items-center w-full">
              <p>Growth Mindset</p>
              {
                (pack == '' || pack == 'basic') ?
                <div className="">
                  <FaLock className="text-sm opacity-40 z-20"></FaLock>
                </div>
                :
                <div>
                  <MdKeyboardArrowRight className={growthOpen?"rotate-90 transition-all":"rotate-0 transition-all"}></MdKeyboardArrowRight>
                </div>
              }
            </button>

            <AnimatePresence>
            {
              growthOpen &&
            <motion.div
            initial={{ opacity: 0, y:-100 }}
        animate={{ opacity: 1, y:0 }}
        exit={{ opacity: 0, y:-50}}
            className="bg-gray-100 rounded-xl mb-2 py-3 px-4 mt-4">
              <button onClick={()=>{checkStatus(growth_video, 'Introduction to Growth Mindset', 'gm')}} className="w-full text-left">
              <p>Introduction to Growth Mindset</p>
              </button>

              {
                gm.map((c)=>{
                  return(
                    <div>
                      <div className="py-[0.05rem] bg-gray-200 my-4 w-full"></div>
              <button
              className="text-left"
              onClick={()=>{
             checkBucketStatus(c.uuid, c.name,c.content, 'gm')}} >
              <p>{c.name}</p>
              </button>
                    </div>

                  )
                })
              }
              
            </motion.div>
            }
            </AnimatePresence>
            </div>

            
            <div className="py-[0.05rem] bg-gray-200 my-4 w-full"></div>

            <div>
            <button onClick={()=>{setCommOpen(!commOpen)}} className="flex justify-between items-center  w-full">
              <p>Communication</p>
              {
                (pack == '' || pack == 'basic') ?
                <div className="">
                  <FaLock className="text-sm opacity-40 z-20"></FaLock>
                </div>
                 :
                 <div>
                   <MdKeyboardArrowRight className={commOpen?"rotate-90 transition-all":"rotate-0 transition-all"}></MdKeyboardArrowRight>
                 </div>
              }
            </button>

            <AnimatePresence>
            {
              commOpen &&
            <motion.div
            initial={{ opacity: 0, y:-100 }}
        animate={{ opacity: 1, y:0 }}
        exit={{ opacity: 0, y:-50}}
            className="bg-gray-100 rounded-xl mb-2 py-3 px-4 mt-4">
              <button onClick={()=>{checkStatus(comm_video, 'Introduction to Communication', 'comm')}} className="w-full text-left">
              <p>Introduction to Communication</p>
              </button>
              <div className="py-[0.05rem] bg-gray-200 my-4 w-full"></div>
              <button onClick={()=>{
              checkStatus(comm_2, 'Channel & Purpose of communication', 'comm')}} >
              <p>Channel & Pupose of communication</p>
              </button>
              <div className="py-[0.05rem] bg-gray-200 my-4 w-full"></div>
              <button onClick={()=>{
             checkStatus(comm_3, 'Application of communication', 'comm')}} >
              <p>Application of communication</p>
              </button>
              {
                comm.map((c)=>{
                  return(
                    <div>
                      <div className="py-[0.05rem] bg-gray-200 my-4 w-full"></div>
              <button onClick={()=>{
             checkBucketStatus(c.uuid, c.name,c.content, 'comm')}} >
              <p>{c.name}</p>
              </button>
                    </div>

                  )
                })
              }
            </motion.div>
            }
            </AnimatePresence>

            
            <div className="py-[0.05rem] bg-gray-200 my-4 w-full"></div>
            </div>
            <div>
            <button onClick={()=>{setCtOpen(!ctOpen)}} className="flex justify-between items-center  w-full">
              <p>Critical Thinking</p>
              {
                (pack == '' || pack == 'basic') ?
                <div className="">
                  <FaLock className="text-sm opacity-40 z-20"></FaLock>
                </div>
                 :
                 <div>
                   <MdKeyboardArrowRight className={ctOpen?"rotate-90 transition-all":"rotate-0 transition-all"}></MdKeyboardArrowRight>
                 </div>
              }
            </button>

            <AnimatePresence>
            {
              ctOpen &&
            <motion.div
            initial={{ opacity: 0, y:-100 }}
        animate={{ opacity: 1, y:0 }}
        exit={{ opacity: 0, y:-50}}
            className="bg-gray-100 rounded-xl mb-2 py-3 px-4 mt-4">
              <button onClick={()=>{
          checkStatus(ct, 'Introduction to Critical Thinking', 'ct')}} className="w-full text-left">
              <p>Introduction to Critical Thinking</p>
              </button>

              {
                critical.map((c)=>{
                  return(
                    <div>
                      <div className="py-[0.05rem] bg-gray-200 my-4 w-full"></div>
              <button onClick={()=>{
             checkBucketStatus(c.uuid, c.name,c.content, 'ct')}} >
              <p>{c.name}</p>
              </button>
                    </div>

                  )
                })
              }
              
            </motion.div>
            }
            </AnimatePresence>
            
            <div className="py-[0.05rem] bg-gray-200 my-4 w-full"></div>
            </div>

            <div>
            <button onClick={()=>{setLsOpen(!lsOpen)}} className="flex justify-between items-center  w-full">
              <p>Active Listening</p>
              {
                (pack == '' || pack == 'basic') ?
                <div className="">
                  <FaLock className="text-sm opacity-40 z-20"></FaLock>
                </div>
                 :
                 <div>
                   <MdKeyboardArrowRight className={lsOpen?"rotate-90 transition-all":"rotate-0 transition-all"}></MdKeyboardArrowRight>
                 </div>
              }
            </button>

            <AnimatePresence>
            {
              lsOpen &&
            <motion.div
            initial={{ opacity: 0, y:-100 }}
        animate={{ opacity: 1, y:0 }}
        exit={{ opacity: 0, y:-50}}
            className="bg-gray-100 rounded-xl mb-2 py-3 px-4 mt-4">
              <button onClick={()=>{
              checkStatus(ls, 'Introduction to Active Listening', 'ls')}} className="w-full text-left">
              <p>Introduction to Active Listening</p>
              </button>

              <div className="py-[0.05rem] bg-gray-200 my-4 w-full"></div>
              <button onClick={()=>{
              checkStatus(ls_2, 'The role of Intent', 'ls')}} >
              <p>The role of Intent</p>
              </button>
              
              <div className="py-[0.05rem] bg-gray-200 my-4 w-full"></div>
              <button onClick={()=>{
              checkStatus(ls_3, 'Facets of Active Listening', 'ls')}} >
              <p>Facets of Active Listening</p>
              </button>

              {
                listening.map((c)=>{
                  return(
                    <div>
                      <div className="py-[0.05rem] bg-gray-200 my-4 w-full"></div>
              <button onClick={()=>{
             checkBucketStatus(c.uuid, c.name,c.content, 'ls')}} >
              <p>{c.name}</p>
              </button>
                    </div>

                  )
                })
              }
            </motion.div>
            }
            
            <div className="py-[0.05rem] bg-gray-200 my-4 w-full"></div>
            </AnimatePresence>
            </div>

            <div>
            <button onClick={()=>{setTwOpen(!twOpen)}} className="flex justify-between items-center w-full">
              <p>Teamwork</p>
              {
                (pack == '' || pack == 'basic') ?
                <div className="">
                  <FaLock className="text-sm opacity-40 z-20"></FaLock>
                </div>
                 :
                 <div>
                   <MdKeyboardArrowRight className={twOpen?"rotate-90 transition-all":"rotate-0 transition-all"}></MdKeyboardArrowRight>
                 </div>
              }
            </button>

            <AnimatePresence>
            {
              twOpen &&
            <motion.div
            initial={{ opacity: 0, y:-100 }}
        animate={{ opacity: 1, y:0 }}
        exit={{ opacity: 0, y:-50}}
            className="bg-gray-100 rounded-xl mb-2 py-3 px-4 mt-4">
              <button onClick={()=>{
          checkStatus(tw, 'Introduction to Teamwork', 'tw')}} className="w-full text-left">
              <p>Introduction to Teamwork</p>
              </button>
              {
                team.map((c)=>{
                  return(
                    <div>
                      <div className="py-[0.05rem] bg-gray-200 my-4 w-full"></div>
              <button onClick={()=>{
             checkBucketStatus(c.uuid, c.name,c.content, 'tw')}} >
              <p>{c.name}</p>
              </button>
                    </div>

                  )
                })
              }
              
            </motion.div>
            }
            </AnimatePresence>
            
            <div className="py-[0.05rem] bg-gray-200 my-4 w-full"></div>
            </div>
          </div>
        </div>
      </div>
     </div>
    :
    <div>
      <div className="absolute left-1/2 top-1/2 -translate-x-1/2  text-center -translate-y-1/2">
                    <InfinitySpin
                    width='200'
                    color='#4899B7'
                    ></InfinitySpin>
        </div>
    </div>
}
    </div>
  )
}



export default Inhouse

   
    {/* <div className={openPopUp?"block  relative blur":"block  relative "}>

    <p className="font-poppins my-6 font-extrabold text-2xl">Introduction</p>

    <div className="lg:grid lg:grid-cols-3 lg:space-y-0 space-y-4 gap-8">
      <div className="h-full">
        <button onClick={()=>{
          checkStatus(introduction, 'Introducing Yourself')
      }} className=" text-left relative">
        {
          (pack == '' || pack == 'basic') &&
        <div className="absolute left-1/2 top-1/2  -translate-x-1/2 -translate-y-1/2">
          <FaLock className="text-5xl opacity-40 z-20"></FaLock>
        </div>
        }
        <div className="bg-white px-4 py-5 flex  lg:mr-0 mr-4   space-x-4 rounded-xl">
            <img src={introduction_thumbnail} className="w-32 xl:w-24 sm:w-24 h-32 rounded-xl"></img>
                    <div className="space-y-2">
                    <h1 className="lg:text-xl text-lg font-bold">Introducing Yourself</h1>
                    <h1 className="lg:text-lg text-lg">by Interview Vision</h1>
                    <div className="h-[0.1rem] bg-[#D9D9D9]"></div>
                    <h1 className='text-sm font-extralight'>7 minutes 12 seconds</h1>
            </div>
        </div>
        </button>
      </div>
        <button onClick={()=>{
          checkStatus(intro_2, 'Preparing your Self-introduction')
      }} className=" text-left relative">
        {
          (pack == '' || pack == 'basic') &&
        <div className="absolute left-1/2 top-1/2  -translate-x-1/2 -translate-y-1/2">
          <FaLock className="text-5xl opacity-40 z-20"></FaLock>
        </div>
        }
        <div className="bg-white px-4 py-5 flex  lg:mr-0 mr-4   space-x-4 rounded-xl">
            <img src={introduction_thumbnail} className="w-32 xl:w-24 sm:w-24 h-32 rounded-xl"></img>
                    <div className="space-y-2">
                    <h1 className="lg:text-xl text-lg font-bold">Preparing your Self-introduction</h1>
                    <h1 className="lg:text-lg text-lg">by Interview Vision</h1>
                    <div className="h-[0.1rem] bg-[#D9D9D9]"></div>
                    <h1 className='text-sm font-extralight'>7 minutes 10 seconds</h1>
            </div>
        </div>
        </button>
        </div>
        


      
        <p className="font-poppins my-6 font-extrabold text-2xl">Growth Mindset</p>

        <div className="lg:grid lg:grid-cols-3 lg:space-y-0 space-y-4 gap-8">
        <button onClick={()=>{
          checkStatus(growth_video, 'Introduction to Growth Mindset')
      }} className=" text-left relative">
        {
          (pack == '' || pack == 'basic') &&
        <div className="absolute left-1/2 top-1/2  -translate-x-1/2 -translate-y-1/2">
          <FaLock className="text-5xl opacity-40 z-20"></FaLock>
        </div>
        }
        <div className="bg-white px-4 py-5 flex  lg:mr-0 mr-4   space-x-4 rounded-xl">
            <img src={grow_thumbnail} className="w-32 h-32 rounded-xl"></img>
                    <div className="space-y-2">
                    <h1 className="lg:text-xl text-lg font-bold">Introduction to Growth Mindset</h1>
                    <h1 className="lg:text-lg text-lg">by Interview Vision</h1>
                    <div className="h-[0.1rem] bg-[#D9D9D9]"></div>
                    <h1 className='text-sm font-extralight'>8 minutes 02 seconds</h1>
            </div>
        </div>
        </button>
        </div>


        
          <p className="font-poppins my-6 font-extrabold text-2xl">Communication</p>
          
          <div className="lg:grid space-y-4 lg:space-y-0  lg:grid-cols-3 gap-8 items-stretch ">

          <div className="h-full">
          <button onClick={()=>{
          checkStatus(comm_video, 'Introduction to Communication')
      }} className="relative text-left h-full w-full">
        {
          (pack == '' || pack == 'basic') &&
        <div className="absolute left-1/2 top-1/2  -translate-x-1/2 -translate-y-1/2">
          <FaLock className="text-5xl opacity-40 z-20"></FaLock>
        </div>
        }
        <div className="bg-white px-4 py-5 flex h-full   lg:mr-0 mr-4   space-x-4 rounded-xl">
            <img src={comm_thumbnail} className="w-32 h-32 rounded-xl"></img>
                    <div className="space-y-2">
                    <h1 className="lg:text-xl text-lg font-bold">Introduction to Communication</h1>
                    <h1 className="lg:text-lg text-lg">by Interview Vision</h1>
                    <div className="h-[0.1rem] bg-[#D9D9D9]"></div>
                    <h1 className='text-sm font-extralight'>6 minutes 56 seconds</h1>
            </div>
        </div>
        </button>
            </div>

            <div className="h-full">
          <button onClick={()=>{
          checkStatus(comm_2, 'Channel & Purpose of communication')
      }} className="relative text-left h-full w-full">
        {
          (pack == '' || pack == 'basic') &&
        <div className="absolute left-1/2 top-1/2  -translate-x-1/2 -translate-y-1/2">
          <FaLock className="text-5xl opacity-40 z-20"></FaLock>
        </div>
        }
        <div className="bg-white px-4 py-5 flex h-full   lg:mr-0 mr-4   space-x-4 rounded-xl">
            <img src={comm_thumbnail} className="w-32 h-32 rounded-xl"></img>
                    <div className="space-y-2">
                    <h1 className="lg:text-xl text-lg font-bold">Channel & Purpose of communication</h1>
                    <h1 className="lg:text-lg text-lg">by Interview Vision</h1>
                    <div className="h-[0.1rem] bg-[#D9D9D9]"></div>
                    <h1 className='text-sm font-extralight'>8 minutes 04 seconds</h1>
            </div>
        </div>
        </button>
            </div>

            <div className="h-full">
          <button onClick={()=>{
          checkStatus(comm_3, 'Application of communication')
      }} className="relative text-left h-full w-full">
        {
          (pack == '' || pack == 'basic') &&
        <div className="absolute left-1/2 top-1/2  -translate-x-1/2 -translate-y-1/2">
          <FaLock className="text-5xl opacity-40 z-20"></FaLock>
        </div>
        }
        <div className="bg-white px-4 py-5 flex h-full   lg:mr-0 mr-4   space-x-4 rounded-xl">
            <img src={comm_thumbnail} className="w-32 h-32 rounded-xl"></img>
                    <div className="space-y-2">
                    <h1 className="lg:text-xl text-lg font-bold">Application of communication</h1>
                    <h1 className="lg:text-lg text-lg">by Interview Vision</h1>
                    <div className="h-[0.1rem] bg-[#D9D9D9]"></div>
                    <h1 className='text-sm font-extralight'>11 minutes 52 seconds</h1>
            </div>
        </div>
        </button>
            </div>

            
            
          </div>

          <p className="font-poppins my-6 font-extrabold text-2xl">Critical Thinking</p>
          
          <div className="lg:grid space-y-4 lg:space-y-0  lg:grid-cols-3 gap-8 items-stretch ">

          <div className="h-full">
          <button onClick={()=>{
          checkStatus(ct, 'Introduction to Critical Thinking')
      }} className="relative text-left h-full w-full">
        {
          (pack == '' || pack == 'basic') &&
        <div className="absolute left-1/2 top-1/2  -translate-x-1/2 -translate-y-1/2">
          <FaLock className="text-5xl opacity-40 z-20"></FaLock>
        </div>
        }
        
        <div className="bg-white px-4 py-5 flex h-full   lg:mr-0 mr-4   space-x-4 rounded-xl">
            <img src={ct_thumbnail} className="w-32 h-32 rounded-xl"></img>
                    <div className="space-y-2">
                    <h1 className="lg:text-xl text-lg font-bold">Introduction to Critical Thinking</h1>
                    <h1 className="lg:text-lg text-lg">by Interview Vision</h1>
                    <div className="h-[0.1rem] bg-[#D9D9D9]"></div>
                    <h1 className='text-sm font-extralight'>6 minutes 02 seconds</h1>
            </div>
        </div>
        </button>
            </div>

            
            
          </div>

          <p className="font-poppins my-6 font-extrabold text-2xl">Active Listening</p>
          
          <div className="lg:grid space-y-4 lg:space-y-0  lg:grid-cols-3 gap-8 items-stretch ">

          <div className="h-full">
          <button onClick={()=>{
          checkStatus(ls, 'Introduction to Active Listening')
      }} className="relative text-left h-full w-full">
        {
          (pack == '' || pack == 'basic') &&
        <div className="absolute left-1/2 top-1/2  -translate-x-1/2 -translate-y-1/2">
          <FaLock className="text-5xl opacity-40 z-20"></FaLock>
        </div>
        }
        
        <div className="bg-white px-4 py-5 flex h-full   lg:mr-0 mr-4   space-x-4 rounded-xl">
            <img src={ls_thumbnail} className="w-32 h-32 rounded-xl"></img>
                    <div className="space-y-2">
                    <h1 className="lg:text-xl text-lg font-bold">Introduction to Active Listening</h1>
                    <h1 className="lg:text-lg text-lg">by Interview Vision</h1>
                    <div className="h-[0.1rem] bg-[#D9D9D9]"></div>
                    <h1 className='text-sm font-extralight'>4 minutes 27 seconds</h1>
            </div>
        </div>
        </button>
            </div>

            <div className="h-full">
          <button onClick={()=>{
          checkStatus(ls_2, 'The role of Intent')
      }} className="relative text-left h-full w-full">
        {
          (pack == '' || pack == 'basic') &&
        <div className="absolute left-1/2 top-1/2  -translate-x-1/2 -translate-y-1/2">
          <FaLock className="text-5xl opacity-40 z-20"></FaLock>
        </div>
        }
        
        <div className="bg-white px-4 py-5 flex h-full   lg:mr-0 mr-4   space-x-4 rounded-xl">
            <img src={ls_thumbnail} className="w-32 h-32 rounded-xl"></img>
                    <div className="space-y-2">
                    <h1 className="lg:text-xl text-lg font-bold">The role of Intent</h1>
                    <h1 className="lg:text-lg text-lg">by Interview Vision</h1>
                    <div className="h-[0.1rem] bg-[#D9D9D9]"></div>
                    <h1 className='text-sm font-extralight'>6 minutes 45 seconds</h1>
            </div>
        </div>
        </button>
            </div>

            <div className="h-full">
          <button onClick={()=>{
          checkStatus(ls_3, 'Facets of Active Listening')
      }} className="relative text-left h-full w-full">
        {
          (pack == '' || pack == 'basic') &&
        <div className="absolute left-1/2 top-1/2  -translate-x-1/2 -translate-y-1/2">
          <FaLock className="text-5xl opacity-40 z-20"></FaLock>
        </div>
        }
        
        <div className="bg-white px-4 py-5 flex h-full   lg:mr-0 mr-4   space-x-4 rounded-xl">
            <img src={ls_thumbnail} className="w-32 h-32 rounded-xl"></img>
                    <div className="space-y-2">
                    <h1 className="lg:text-xl text-lg font-bold">Facets of Active Listening</h1>
                    <h1 className="lg:text-lg text-lg">by Interview Vision</h1>
                    <div className="h-[0.1rem] bg-[#D9D9D9]"></div>
                    <h1 className='text-sm font-extralight'>8 minutes 11 seconds</h1>
            </div>
        </div>
        </button>
            </div>

            
            
            
          </div>
          

          <p className="font-poppins my-6 font-extrabold text-2xl">Teamwork</p>
          
          <div className="lg:grid space-y-4 lg:space-y-0  lg:grid-cols-3 gap-8 items-stretch ">

          <div className="h-full">
          <button onClick={()=>{
          checkStatus(tw, 'Introduction to Teamwork')
      }} className="relative text-left h-full w-full">
        {
          (pack == '' || pack == 'basic') &&
        <div className="absolute left-1/2 top-1/2  -translate-x-1/2 -translate-y-1/2">
          <FaLock className="text-5xl opacity-40 z-20"></FaLock>
        </div>
        }
        
        <div className="bg-white px-4 py-5 flex h-full   lg:mr-0 mr-4   space-x-4 rounded-xl">
            <img src={tw_thumbnail} className="w-32  h-32 rounded-xl"></img>
                    <div className="space-y-2">
                    <h1 className="lg:text-xl text-lg font-bold">Introduction to Teamwork</h1>
                    <h1 className="lg:text-lg text-lg">by Interview Vision</h1>
                    <div className="h-[0.1rem] bg-[#D9D9D9]"></div>
                    <h1 className='text-sm font-extralight'>7 minutes 52 seconds</h1>
            </div>
        </div>
        </button>
            </div>
            
          </div>
          
          


          
        {
          open &&
        <div className={width===300?"fixed top-0 left-0 lg:w-1/3 px-4 lg:h-1/2 bg-[#2d9dba] rounded-xl shadow-2xl":"fixed  right-0 bottom-0 lg:w-1/3 lg:h-1/2 bg-[#2d9dba] rounded-xl shadow-2xl"}>
          <div className="flex justify-between items-center">
          <p className="text-white ml-4 text-xl mt-4 font-poppins">Now Playing</p>
          <button className="text-white text-xl font-poppins mr-4" onClick={()=>{setOpen(false)}}>x</button>
          </div>
          <div className="mx-auto text-center">
            <video
            src={link}
            height={height}
            width={width}
            autoPlay
            controls
            className="mx-auto my-8"
            >
            </video>
          </div>
        </div>
        }

    </div> */}
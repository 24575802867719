import React, {useEffect, useState} from 'react'
import axios from 'axios'
import { useLocation } from 'react-router-dom'
import ReactGA from 'react-ga'
import data from '../config'

const Settings = () => {
    
    const user = localStorage.getItem('email')
    const [fname, setFname] = useState('')
    const [lname, setLname] = useState('')
    const [pnum, setPnum] = useState('')
    const [text,setText] = useState('')
    const [city, setCity] = useState('')
    const [state, setState] = useState('')
    const [choice, setChoice] = useState(null)
    const [loader,setLoader] = useState(false)
    
    useEffect(()=>{
        ReactGA.pageview(window.location.pathname + window.location.search)
      },[])
      
    useEffect(()=>{
        axios.get(`https://${data.API}/users/r/${user}`)
        .then((response)=>{
            console.log(response)
            setFname(response.data.fname)
            setLname(response.data.lname)
            setPnum(response.data.phone)
            setCity(response.data.city)
            setState(response.data.state)
            setText(response.data.text)
            console.log(text)
            console.log(fname)
            console.log(choice)
            
            setLoader(false)
            
        })
        .catch((error)=>{
            console.log(error)
            alert('Something went wrong! Please try again')
        })
        if(text == 'Pursuing Graduation'){
            setChoice(2)
        }
        else if(text =='Pursuing Post Graduation'){
            setChoice(3)
        }
        else if(text =='Fresher looking for a job'){
            setChoice(1)
        }
        else if(text == 'Working Professional with less than 2 Years Experience'){
            setChoice(4)
        }
        else if(text =='Working Professional with more than 2 Years Experience'){
            setChoice(5)
        }
        else if(text ==''){
            setChoice(0)
        }
    },[])

    // if(fname.length == 0){
    //     setFname('-')
    // }
    // if(lname.length == 0){
    //     setLname('-')
    // }
    // if(pnum.length == 0){
    //     setPnum('-')
    // }
    // if(city.length == 0){
    //     setCity('-')
    // }
    // if(state.length ==0){
    //     setState('-')
    // }
    const handleSumbit = (e)=>{
        e.preventDefault()
        if(choice == 2){
            setText('Pursuing Graduation')
        }
        else if(choice == 3){
            setText('Pursuing Post Graduation')
        }
        else if(choice == 1){
            setText('Fresher looking for a job')
        }
        else if(choice == 4){
            setText('Working Professional with less than 2 Years Experience')
        }
        else if(choice == 5){
            setText('Working Professional with more than 2 Years Experience')
        }

        axios.put(`https://${data.API}/users/u/${user}`,{
            fname:fname,
            lname:lname,
            city:city,
            state:state,
            email:user,
            phone:pnum,
            experience:text
        })
        .then((response) => {
            console.log(response)
            window.location.reload()
        })
        .catch((error) => {
            console.log(error)
            alert('Something went wrong! Please try again')
        })

    }


  return (
    <div className="font-poppins my-4 mr-3 w-">
        {
            !loader &&
        <div>
        <p className="text-2xl">Update Personal Info</p>
        <div className="lg:flex my-4  w-full  lg:space-x-3">
                <div class="relative  mb-4 lg:mb-0 lg:w-1/2">
                    <input type="text" value={fname} onChange={(e)=>{setFname(e.target.value)}} id="floating_outlined" className=" px-2.5 border-2 pb-2.5  pt-4 w-full  text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder='' />
                    <label for="floating_outlined" className="absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-gray-100  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 font-poppins">First Name</label>
                </div>
                <div class="relative lg:w-1/2">
                    <input type="text" value={lname} onChange={(e)=>{setLname(e.target.value)}} id="floating_outlined" className=" px-2.5 border-2 pb-2.5 pt-4 w-full  text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder={lname} />
                    <label for="floating_outlined" className="absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-gray-100  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 font-poppins">Last Name</label>
            </div>
            </div>

            <div class="relative lg:w-1/2">
                    <input type="text" value={pnum} onChange={(e)=>{setPnum(e.target.value)}} id="floating_outlined" className=" px-2.5 border-2 pb-2.5 pt-4 w-full  text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder={pnum} />
                    <label for="floating_outlined" className="absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-gray-100  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 font-poppins">Username</label>
            </div>

            <p className="text-2xl my-6">Update Demographics</p>
            <div className="lg:flex my-4  w-full  lg:space-x-3">
                <div class="relative  mb-4 lg:mb-0 lg:w-1/2">
                    <input type="text" value={city} onChange={(e)=>{setCity(e.target.value)}} id="floating_outlined" className=" px-2.5 border-2 pb-2.5  pt-4 w-full  text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder={city} />
                    <label for="floating_outlined" className="absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-gray-100  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 font-poppins">City</label>
                </div>
                <div class="relative lg:w-1/2">
                    <input type="text" value={state} onChange={(e)=>{setState(e.target.value)}} id="floating_outlined" className=" px-2.5 border-2 pb-2.5 pt-4 w-full  text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder={state} />
                    <label for="floating_outlined" className="absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-gray-100  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 font-poppins">State</label>
            </div>
            </div>
            <p className="text-2xl">Update Experience</p>
            <div className="bg-white px-3 py-5 rounded-xl my-3">
            <div className="flex-col   flex font-poppins lg:w-2/3 space-y-4">
            <button onClick={()=>{setChoice(2)}} className={choice==2?"bg-blue-500 py-4 rounded-2xl px-5 flex text-lg space-x-3 items-center text-white":"bg-[#F3F3F3]  py-4 rounded-2xl px-5 flex text-lg space-x-3 items-center" }>
            <div className="border-4 p-3 border-white rounded-full "></div>
                <h1>Pursuing graduation</h1>
            </button>
            <button onClick={()=>{setChoice(3)}} className={choice==3?"bg-blue-500 py-4 rounded-2xl px-5 flex text-lg space-x-3 items-center text-white":"bg-[#F3F3F3]  py-4 rounded-2xl px-5 flex text-lg space-x-3 items-center" }>
            <div className="border-4 p-3 border-white rounded-full "></div>
                <h1>Pursuing post graduation</h1>
            </button>
            <button onClick={()=>{setChoice(1)}} className={choice==1?"bg-blue-500 py-4 rounded-2xl px-5 flex text-lg space-x-3 items-center text-white":"bg-[#F3F3F3]  py-4 rounded-2xl px-5 flex text-lg space-x-3 items-center" }>
                <div className="border-4 p-3 border-white rounded-full "></div>
                <h1>Fresher looking for a job</h1>
            </button>
            <button onClick={()=>{setChoice(4)}} className={choice==4?"bg-blue-500 py-4 rounded-2xl px-5 flex text-lg space-x-3 items-center text-white":"bg-[#F3F3F3]  py-4 rounded-2xl px-5 flex text-lg space-x-3 items-center" }>
            <div className="border-4 p-3 border-white rounded-full "></div>
                <h1 className="text-left">Working Professional with less than 2 Years Experience</h1>
            </button>
            <button onClick={()=>{setChoice(5)}} className={choice==5?"bg-blue-500 py-4 rounded-2xl px-5 flex text-lg space-x-3 items-center text-white":"bg-[#F3F3F3]  py-4 rounded-2xl px-5 flex text-lg space-x-3 items-center" }>
            <div className="border-4 p-3 border-white rounded-full "></div>
                <h1 className='text-left'>Working Professional with more than 2 Years Experience</h1>
            </button>
        </div>
            </div>

            <div className="text-center">
                <button className="bg-blue-600 rounded-xl text-white px-10 py-4" onClick={handleSumbit}>Save</button>
            </div>
        </div>
        }
    </div>
  )
}

export default Settings
import React, {useState, useEffect} from 'react'
import YouTube from 'react-youtube';
import ReactGA from 'react-ga'
import axios from 'axios';
import {BiArrowBack} from 'react-icons/bi'
import {Link} from 'react-router-dom'
import data from '../config'

const Resources = () => {
  const [link,setLink] = useState('')
  const [open,setOpen] = useState(false)
  const [width,setWidth] = useState(0)
  const [height,setHeight] = useState(0)
  const user = localStorage.getItem('email')
    var thumbnail1 = 'https://img.youtube.com/vi/dItUGF8GdTw/0.jpg'
    const thumbnail2 = "https://img.youtube.com/vi/x0vUsxhMczI/0.jpg"
    const thumbnail3 = "https://img.youtube.com/vi/iKU6hhJM0-A/0.jpg"
    const thumbnail4 = 'https://img.youtube.com/vi/NHjgKe7JMNE/0.jpg'
    const thumbnail5 = "https://img.youtube.com/vi/rUJkbWNnNy4/0.jpg"
    const thumbnail6 = "https://img.youtube.com/vi/hiiEeMN7vbQ/0.jpg"
    const thumbnail7 = "https://img.youtube.com/vi/qjBdcyueom8/0.jpg"
    const thumbnail8 = "https://img.youtube.com/vi/7wUCyjiyXdg/0.jpg"
    const thumbnail9 = "https://img.youtube.com/vi/NqgIVZJv-do/0.jpg"
    const thumbnail10 = "https://img.youtube.com/vi/cFdCzN7RYbw/0.jpg"
    const thumbnail11 = "https://img.youtube.com/vi/1sSing6ntVA/0.jpg"
    const thumbnail12 = "https://img.youtube.com/vi/UwsMogSQmYI/0.jpg"
    const thumbnail13 = "https://img.youtube.com/vi/zP9jpxitfb4/0.jpg"
    const thumbnail14 = "https://img.youtube.com/vi/pJ7RgUCEd5M/0.jpg"
    const thumbnail15 = "https://img.youtube.com/vi/cSohjlYQI2A/0.jpg"
    const thumbnail16 = "https://img.youtube.com/vi/4Z1BIeje_ko/0.jpg"
    const thumbnail17 = "https://img.youtube.com/vi/x4Q5zXaUySA/0.jpg"
    const thumbnail18 = "https://img.youtube.com/vi/YPiTM_PJvtU/0.jpg"
    useEffect(()=>{
      if(window.innerWidth<500){
          setWidth(300)
          setHeight(150)
      }
      else{
          setWidth(400)
          setHeight(250)
      }
  })
  useEffect(()=>{
    ReactGA.pageview(window.location.pathname + window.location.search)
  },[])
  

  const record = (username, cat, code, title)=>{
    axios.post(`https://${data.API}/interview/video/watched`,{
      username:username,
      category:cat,
      videocode:code,
      title:title
    })
    .then((response)=>{
      console.log(response)
    })
    .catch((error)=>{
      console.log(error)
    })
  }
  return (
    <div className="block mt-16 px-5">

<button className="p-4 bg-green-500 rounded-xl text-white ">
      <Link to="/courses">
      <BiArrowBack></BiArrowBack>
      </Link>
    </button> 
      
      {/* <div>
            <p className='font-poppins font-extrabold text-2xl my-4 ml-4 lg:ml-0'>Our Webinars</p>
            <div className="lg:grid lg:grid-cols-3 space-y-5 lg:space-y-0 gap-8">
            <div className="h-full">
        <button onClick={()=>{setLink('x4Q5zXaUySA')
      setOpen(true)
      record(user,'web','x4Q5zXaUySA','How To Get Your Resume Shortlisted')
      }} className=" text-left h-full">
        <div className="bg-white px-4 py-5 flex  lg:mr-0 mr-4   space-x-4 rounded-xl">
            <img src={thumbnail17} className="w-1/3 h-full rounded-xl"></img>
                    <div className="space-y-2">
                    <h1 className="lg:text-xl text-lg font-bold">How To Get Your Resume Shortlisted</h1>
                    <h1 className="lg:text-lg text-lg">by Interview Vision</h1>
                    <div className="h-[0.1rem] bg-[#D9D9D9]"></div>
                    <h1 className='text-sm font-extralight'>33 minutes 16 seconds</h1>
            </div>
        </div>
        </button>
          </div>
          <div className="h-full">
        <button onClick={()=>{setLink('YPiTM_PJvtU')
      setOpen(true)
      record(user,'web','YPiTM_PJvtU','How To Respond To Key Questions')

      }} className=" text-left h-full"
        <div className="bg-white px-4 py-5 flex  lg:mr-0 mr-4   space-x-4 rounded-xl">
            <img src={thumbnail18} className="w-1/3 h-full rounded-xl"></img>
                    <div className="space-y-2">
                    <h1 className="lg:text-xl text-lg font-bold">How To Respond To Key Questions</h1>
                    <h1 className="lg:text-lg text-lg">by Interview Vision</h1>
                    <div className="h-[0.1rem] bg-[#D9D9D9]"></div>
                    <h1 className='text-sm font-extralight'>51 minutes 49 seconds</h1>
            </div>
        </div>
        </button>
          </div>
            </div>
        </div> */}
        <p className="font-poppins my-6 font-extrabold text-2xl">Critical Thinking</p>
        
        <div className="lg:grid lg:grid-cols-3 lg:space-y-0 space-y-4 gap-5 mr-4">
        
        <button onClick={()=>{
          setLink('dItUGF8GdTw')
          setOpen(true)
          record(user,'ct','dItUGF8GdTw','5 tips to improve your critical thinking ')
          }} className=" text-left">
        <div className="bg-white px-4 py-5 flex  lg:mr-0 mr-4   space-x-4 rounded-xl">
            <img src={thumbnail1} className="w-1/3 h-full rounded-xl"></img>
                    <div className="space-y-2">
                    <h1 className="lg:text-xl text-lg font-bold">5 tips to improve your critical thinking </h1>
                    <h1 className="lg:text-lg text-lg">by Samantha Agoos</h1>
                    <div className="h-[0.1rem] bg-[#D9D9D9]"></div>
                    <h1 className='text-sm font-extralight'>4 minutes 29 seconds</h1>
            </div>
        </div>
        </button>
        <button onClick={()=>{setLink('x0vUsxhMczI')
      setOpen(true)
      record(user,'ct','x0vUsxhMczI','The Best Way To Learn Critical Thinking')
      }} className=" text-left">
        <div className="bg-white px-4 py-5 flex  lg:mr-0 mr-4   space-x-4 rounded-xl">
            <img src={thumbnail2} className="w-1/3 h-full rounded-xl"></img>
                    <div className="space-y-2">
                    <h1 className="lg:text-xl text-lg font-bold">The Best Way To Learn Critical Thinking</h1>
                    <h1 className="lg:text-lg text-lg">by Jordan Peterson</h1>
                    <div className="h-[0.1rem] bg-[#D9D9D9]"></div>
                    <h1 className='text-sm font-extralight'>4 minutes 1 seconds</h1>
            </div>
        </div>
        </button>
        <button onClick={()=>{setLink('iKU6hhJM0-A')
      setOpen(true)
      record(user,'ct','iKU6hhJM0-A','How to develop CRITICAL THINKING')
      }} className=" text-left">
        <div className="bg-white px-4 py-5 flex  lg:mr-0 mr-4   space-x-4 rounded-xl">
            <img src={thumbnail3} className="w-1/3 h-full rounded-xl"></img>
                    <div className="space-y-2">
                    <h1 className="lg:text-xl text-lg font-bold">How to develop CRITICAL THINKING</h1>
                    <h1 className="lg:text-lg text-lg">by Sid Warrier</h1>
                    <div className="h-[0.1rem] bg-[#D9D9D9]"></div>
                    <h1 className='text-sm font-extralight'>4 minutes 29 seconds</h1>
            </div>
        </div>
        </button>
        <button onClick={()=>{setLink('NHjgKe7JMNE')
      setOpen(true)
      record(user,'ct','NHjgKe7JMNE','Five simple strategies to sharpen your critical thinking')
      }} className=" text-left">
        <div className="bg-white px-4 py-5 flex  lg:mr-0 mr-4   space-x-4 rounded-xl">
            <img src={thumbnail4} className="w-1/3 h-full rounded-xl"></img>
                    <div className="space-y-2">
                    <h1 className="lg:text-xl text-lg font-bold">Five simple strategies to sharpen your critical thinking</h1>
                    <h1 className="lg:text-lg text-lg">by BBC Ideas</h1>
                    <div className="h-[0.1rem] bg-[#D9D9D9]"></div>
                    <h1 className='text-sm font-extralight'>4 minutes 29 seconds</h1>
            </div>
        </div>
        </button>
        </div>

        <p className="font-poppins my-6 font-extrabold text-2xl">Growth Mindset</p>

        <div className="lg:grid lg:grid-cols-3 lg:space-y-0 space-y-4 gap-8">
        <button onClick={()=>{setLink('rUJkbWNnNy4')
      setOpen(true)
      record(user,'gm','rUJkbWNnNy4','Developing A Growth Mindset')
      }} className=" text-left">
        <div className="bg-white px-4 py-5 flex  lg:mr-0 mr-4   space-x-4 rounded-xl">
            <img src={thumbnail5} className="w-1/3 h-full rounded-xl"></img>
                    <div className="space-y-2">
                    <h1 className="lg:text-xl text-lg font-bold">Developing A Growth Mindset</h1>
                    <h1 className="lg:text-lg text-lg">by ClickView</h1>
                    <div className="h-[0.1rem] bg-[#D9D9D9]"></div>
                    <h1 className='text-sm font-extralight'>4 minutes 51 seconds</h1>
            </div>
        </div>
        </button>
        <button onClick={()=>{setLink('hiiEeMN7vbQ')
      setOpen(true)
      record(user,'gm',link,'Developing a Growth Mindset')
      }} className=" text-left">
        <div className="bg-white px-4 py-5 flex  lg:mr-0 mr-4   space-x-4 rounded-xl">
            <img src={thumbnail6} className="w-1/3 h-full rounded-xl"></img>
                    <div className="space-y-2">
                    <h1 className="lg:text-xl text-lg font-bold">Developing a Growth Mindset</h1>
                    <h1 className="lg:text-lg text-lg">by Carol Dweck</h1>
                    <div className="h-[0.1rem] bg-[#D9D9D9]"></div>
                    <h1 className='text-sm font-extralight'>4 minutes 51 seconds</h1>
            </div>
        </div>
        </button>

        <button onClick={()=>{setLink('qjBdcyueom8')
      setOpen(true)
      record(user,'gm',link,'The Most Powerful Mindset for Success')
      }} className=" text-left">
        <div className="bg-white px-4 py-5 flex  lg:mr-0 mr-4   space-x-4 rounded-xl">
            <img src={thumbnail7} className="w-1/3 h-full rounded-xl"></img>
                    <div className="space-y-2">
                    <h1 className="lg:text-xl text-lg font-bold">The Most Powerful Mindset for Success</h1>
                    <h1 className="lg:text-lg text-lg">by Freedom in Thought</h1>
                    <div className="h-[0.1rem] bg-[#D9D9D9]"></div>
                    <h1 className='text-sm font-extralight'>9 minutes 49 seconds</h1>
            </div>
        </div>
        </button>
        </div>

        <p className="font-poppins my-6 font-extrabold text-2xl">Active Listening</p>

        <div className="lg:grid lg:space-y-0 space-y-4 lg:grid-cols-3 gap-8 items-stretch ">
          <div className="h-full">
        <button onClick={()=>{setLink('7wUCyjiyXdg')
      setOpen(true)
      record(user,'ls',link,'Active Listening Skills')
      }} className="h-full text-left w-full">
        <div className="bg-white px-4 h-full py-5 flex  w-full lg:mr-0 mr-4   space-x-4 rounded-xl">
            <img src={thumbnail8} className="w-1/3 h-1/2 rounded-xl"></img>
                    <div className="space-y-2">
                    <h1 className="lg:text-xl text-lg font-bold">Active Listening Skills</h1>
                    <h1 className="lg:text-lg text-lg">by Alexander Lyon</h1>
                    <div className="h-[0.1rem] bg-[#D9D9D9]"></div>
                    <h1 className='text-sm font-extralight'>6 minutes 13 seconds</h1>
            </div>
        </div>
        </button>
          </div>

          <div className="h-full">
        <button onClick={()=>{setLink('cSohjlYQI2A')
      setOpen(true)
      record(user,'ls',link,'5 ways to listen better')
      }} className="h-full text-left w-full">
        <div className="bg-white px-4 h-full py-5 flex  w-full lg:mr-0 mr-4   space-x-4 rounded-xl">
            <img src={thumbnail15} className="w-1/3 h-1/2 rounded-xl"></img>
                    <div className="space-y-2">
                    <h1 className="lg:text-xl text-lg font-bold">5 ways to listen better</h1>
                    <h1 className="lg:text-lg text-lg">by Julian Treasure</h1>
                    <div className="h-[0.1rem] bg-[#D9D9D9]"></div>
                    <h1 className='text-sm font-extralight'>7 minutes 50 seconds</h1>
            </div>
        </div>
        </button>
          </div>


          <div className="h-full">
        <button onClick={()=>{setLink('NqgIVZJv-do')
      setOpen(true)
      record(user,'ls',link,'5 Techniques to Improve Active Listening Skills')
      }} className=" text-left h-full">
        <div className="bg-white px-4 py-5 flex  lg:mr-0 mr-4   space-x-4 rounded-xl">
            <img src={thumbnail9} className="w-1/3 h-full rounded-xl"></img>
                    <div className="space-y-2">
                    <h1 className="lg:text-xl text-lg font-bold">5 Techniques to Improve Active Listening Skills</h1>
                    <h1 className="lg:text-lg text-lg">by English Learning Hub</h1>
                    <div className="h-[0.1rem] bg-[#D9D9D9]"></div>
                    <h1 className='text-sm font-extralight'>6 minutes 13 seconds</h1>
            </div>
        </div>
        </button>
          </div>
          
        </div>
          <p className="font-poppins my-6 font-extrabold text-2xl">Communication</p>
          
          <div className="lg:grid space-y-4 lg:space-y-0  lg:grid-cols-3 gap-8 items-stretch ">

          <div className="h-full">
          <button onClick={()=>{setLink('pJ7RgUCEd5M')
      setOpen(true)
      record(user,'comm',link,'Top 5 Tips to Improve Communication Skills')
      }} className=" text-left h-full w-full">
        <div className="bg-white px-4 py-5 flex h-full   lg:mr-0 mr-4   space-x-4 rounded-xl">
            <img src={thumbnail14} className="w-1/3 h-1/2 rounded-xl"></img>
                    <div className="space-y-2">
                    <h1 className="lg:text-xl text-lg font-bold">Top 5 Tips to Improve Communication Skills</h1>
                    <h1 className="lg:text-lg text-lg">by Simplilearn</h1>
                    <div className="h-[0.1rem] bg-[#D9D9D9]"></div>
                    <h1 className='text-sm font-extralight'>5 minutes 55 seconds</h1>
            </div>
        </div>
        </button>
            </div>

            <div className="h-full">
          <button onClick={()=>{setLink('4Z1BIeje_ko')
      setOpen(true)
      record(user,'comm',link,'Communication Cycle')
      }} className=" text-left h-full w-full">
        <div className="bg-white px-4 py-5 flex h-full   lg:mr-0 mr-4   space-x-4 rounded-xl">
            <img src={thumbnail16} className="w-1/3 h-1/2 rounded-xl"></img>
                    <div className="space-y-2">
                    <h1 className="lg:text-xl text-lg font-bold">Communication Cycle</h1>
                    <h1 className="lg:text-lg text-lg">by David Harmon</h1>
                    <div className="h-[0.1rem] bg-[#D9D9D9]"></div>
                    <h1 className='text-sm font-extralight'>3 minutes 02 seconds</h1>
            </div>
        </div>
        </button>
            </div>


            <div className="h-full">
          <button onClick={()=>{setLink('cFdCzN7RYbw')
      setOpen(true)
      record(user,'comm',link,'Science Of Persuasion')
      }} className=" text-left h-full w-full">
        <div className="bg-white px-4 py-5 flex h-full  lg:mr-0 mr-4   space-x-4 rounded-xl">
            <img src={thumbnail10} className="w-1/3 h-1/2 rounded-xl"></img>
                    <div className="space-y-2">
                    <h1 className="lg:text-xl text-lg font-bold">Science Of Persuasion</h1>
                    <h1 className="lg:text-lg text-lg">by influenceatwork</h1>
                    <div className="h-[0.1rem] bg-[#D9D9D9]"></div>
                    <h1 className='text-sm font-extralight'>11 minutes 50 seconds</h1>
            </div>
        </div>
        </button>
            </div>
            
          </div>

          <p className="font-poppins my-6 font-extrabold text-2xl">Teamwork</p>

          <div className="lg:grid space-y-4 lg:space-y-0 lg:grid-cols-3 gap-8 items-stretch">
          <div className="h-full">
          <button onClick={()=>{setLink('1sSing6ntVA')
      setOpen(true)
      record(user,'tw',link,'How to Improve your Teamwork Skills instead of Stressing Out by Peer Connect')
      }} className=" text-left h-full">
        <div className="bg-white px-4 py-5 flex  lg:mr-0 mr-4   space-x-4 rounded-xl">
            <img src={thumbnail11} className="w-1/3 h-full rounded-xl"></img>
                    <div className="space-y-2">
                    <h1 className="lg:text-xl text-lg font-bold">How to Improve your Teamwork Skills instead of Stressing Out by Peer Connect</h1>
                    <h1 className="lg:text-lg text-lg">by I Time Travel Well</h1>
                    <div className="h-[0.1rem] bg-[#D9D9D9]"></div>
                    <h1 className='text-sm font-extralight'>3 minutes 2 seconds</h1>
            </div>
        </div>
        </button>
            </div>

            <div className="h-full">
          <button onClick={()=>{setLink('UwsMogSQmYI')
      setOpen(true)
      record(user,'tw',link,'Teamwork Reimagined')
      }} className=" text-left h-full w-full">
        <div className="bg-white px-4 py-5 flex h-full   lg:mr-0 mr-4   space-x-4 rounded-xl">
            <img src={thumbnail12} className="w-1/3 h-1/2 rounded-xl"></img>
                    <div className="space-y-2">
                    <h1 className="lg:text-xl text-lg font-bold">Teamwork Reimagined</h1>
                    <h1 className="lg:text-lg text-lg">by Kevin Cahill</h1>
                    <div className="h-[0.1rem] bg-[#D9D9D9]"></div>
                    <h1 className='text-sm font-extralight'>13 minutes 53 seconds</h1>
            </div>
        </div>
        </button>
            </div>

            <div className="h-full">
          <button onClick={()=>{setLink('zP9jpxitfb4')
      setOpen(true)
      record(user,'tw',link,'What Makes the Highest Performing Teams in the World')
      }} className=" text-left h-full w-full">
        <div className="bg-white px-4 py-5 flex h-full   lg:mr-0 mr-4   space-x-4 rounded-xl">
            <img src={thumbnail13} className="w-1/3 h-1/2 rounded-xl"></img>
                    <div className="space-y-2">
                    <h1 className="lg:text-xl text-lg font-bold">What Makes the Highest Performing Teams in the World</h1>
                    <h1 className="lg:text-lg text-lg">by Simon Sinek</h1>
                    <div className="h-[0.1rem] bg-[#D9D9D9]"></div>
                    <h1 className='text-sm font-extralight'>1 minutes 21 seconds</h1>
            </div>
        </div>
        </button>
            </div>
          </div>


          
        {
          open &&
        <div className={width===300?"fixed top-0 left-0 lg:w-1/3 px-4 lg:h-1/2 bg-[#2d9dba] rounded-xl shadow-2xl":"fixed  right-0 bottom-0 lg:w-1/3 lg:h-1/2 bg-[#2d9dba] rounded-xl shadow-2xl"}>
          <div className="flex justify-between items-center">
          <p className="text-white ml-4 text-xl mt-4 font-poppins">Now Playing</p>
          <button className="text-white text-xl font-poppins mr-4" onClick={()=>{setOpen(false)}}>x</button>
          </div>
          <div className="mx-auto text-center">
          <YouTube videoId={link} iframeClassName='mx-auto my-4 ' className="" opts={{width:width, height:height}} />
          </div>
        </div>
        }

    </div>
  )
}

export default Resources
import React, { useState, useEffect } from "react";
import ReactStars from "react-stars";
import linkedin from "../images/linkedin.png";
import maya from "../images/Maya.JPG";
import rajiv from "../images/rajiv_coach.heic";
import axios from "axios";
import { Link } from "react-router-dom";
import { InfinitySpin } from "react-loader-spinner";
import Vijaya from "../images/Vijaya.jpeg";
import data from '../config'

const Bookings = () => {

  const [coaches, setCoaches] = useState([]);
  const [loader, setLoader] = useState(true);
  const [photos, setPhotos] = useState([maya, rajiv, Vijaya]);
  const [bookings, setBookings] = useState([]);
  const [active, setActive] = useState("all");
  const user = localStorage.getItem("email");
  const today = new Date().getTime();
  var month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const change = (tab) => {
    setActive(tab);
  };

  useEffect(() => {
    axios
      .get(`https://${data.API}/coach`)
      .then((response) => {
        console.log(response);
        setCoaches(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get(`https://${data.API}/subscription/getbooking/${user}`)
      .then((response) => {
        console.log(response);
        setBookings(response.data);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const ReadMore = ({ children }) => {
    const text = children;
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
      setIsReadMore(!isReadMore);
    };
    return (
      <pre className="text font-poppins">
        {isReadMore ? text.slice(0, 90) : text}
        <span
          onClick={toggleReadMore}
          className="read-or-hide cursor-pointer text-red-500"
        >
          {isReadMore ? " ... read more" : " show less"}
        </span>
      </pre>
    );
  };

  const Content = (props) => {
    return (
      <div className="container">
        <h2>
          <ReadMore>{props.content}</ReadMore>
        </h2>
      </div>
    );
  };

  const dateBookings = bookings.map((obj) => {
    return { ...obj, Date: new Date(Date.parse(obj.Date)) };
  });

  var sortedDesc = dateBookings.sort(
    (objA, objB) => Number(objA.Date) - Number(objB.Date)
  );

  const upcoming = dateBookings.filter((d) => {
    var time = new Date(d.Date).getTime();
    // console.log(time)
    return time > today;
  });

  const exp = dateBookings.filter((d) => {
    var time = new Date(d.Date).getTime();
    // console.log(time)
    return time < today;
  });

  return (
    <div>
      {!loader ? (
        <div className="mt-16 font-poppins">
          <p className="text-[#464646] text-2xl px-5 font-extrabold my-10">
            My bookings
          </p>
          <div className="px-5">
            <div className="flex justify-start border-b-2 pb-4 border-red-400 rounded-xl px-3 items-center w-full space-x-6">
              <button
                className={active == "all" ? "text-red-400" : ""}
                onClick={() => {
                  change("all");
                }}
              >
                All Bookings
              </button>
              <button
                className={active == "up" ? "text-red-400" : ""}
                onClick={() => {
                  change("up");
                }}
              >
                Upcoming
              </button>
              <button
                className={active == "exp" ? "text-red-400" : ""}
                onClick={() => {
                  change("exp");
                }}
              >
                Expired
              </button>
            </div>
            <div>
              {active == "all" && (
                <div>
                  {sortedDesc.length > 0 ? (
                    <div className="flex flex-col gap-4 my-5">
                      {sortedDesc.map((b) => {
                        return (
                          <div className="bg-white rounded-xl px-4 py-5 flex items-center justify-between">
                            <div>
                              <p className="font-bold text-xl mb-2">
                                Coach Name
                              </p>
                              <p className="">{b.CoachName}</p>
                            </div>
                            <div className="text-right">
                              <p className="font-bold text-xl mb-2">Date</p>
                              <p className="">{`${b.Date.getDate()} ${
                                month[b.Date.getMonth()]
                              } ${b.Date.getFullYear()}`}</p>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <p className="text-center my-5">
                      No bookings as of yet now.
                    </p>
                  )}
                </div>
              )}
              {active == "up" && (
                <div>
                  {upcoming.length > 0 ? (
                    <div className="flex flex-col gap-4 my-5">
                      {upcoming.map((b) => {
                        return (
                          <div className="bg-white rounded-xl px-4 py-5 flex items-center justify-between">
                            <div>
                              <p className="font-bold text-xl mb-2">
                                Coach Name
                              </p>
                              <p className="">{b.CoachName}</p>
                            </div>
                            <div className="text-right">
                              <p className="font-bold text-xl mb-2">Date</p>
                              <p className="">{`${b.Date.getDate()} ${
                                month[b.Date.getMonth()]
                              } ${b.Date.getFullYear()}`}</p>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <p className="text-center my-5">No upcoming bookings.</p>
                  )}
                </div>
              )}
              {active == "exp" && (
                <div>
                  {exp.length > 0 ? (
                    <div className="flex flex-col gap-4 my-5">
                      {exp.map((b) => {
                        return (
                          <div className="bg-white rounded-xl px-4 py-5 flex items-center justify-between">
                            <div>
                              <p className="font-bold text-xl mb-2">
                                Coach Name
                              </p>
                              <p className="">{b.CoachName}</p>
                            </div>
                            <div className="text-right">
                              <p className="font-bold text-xl mb-2">Date</p>
                              <p className="">{`${b.Date.getDate()} ${
                                month[b.Date.getMonth()]
                              } ${b.Date.getFullYear()}`}</p>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <p className="text-center my-5">No expired bookings.</p>
                  )}
                </div>
              )}
            </div>
          </div>

          <div>
            <p className="text-[#464646] text-2xl px-5 font-extrabold my-10">
              Discover More Coaches
            </p>

            <div className="md:grid md:grid-cols-3 space-y-4 md:space-y-0 gap-4">
              {coaches.map((c, i) => {
                return (
                  <div className="rounded-xl ">
                    <img src={photos[i]} className=" rounded-t-xl  "></img>
                    <div className="bg-white p-4 rounded-b-xl">
                      <div className="flex justify-between items-center ">
                        <p className="">{c.Name}</p>
                        <ReactStars
                          className="pointer-events-none"
                          count={5}
                          value={5}
                          isHalf={true}
                          edit={false}
                          size={22}
                        ></ReactStars>
                      </div>
                      <Content content={c.Profile} className=" my-4"></Content>
                      <div className="flex justify-between items-center">
                        <a href={c.Linkedin} target="_blank">
                          <img src={linkedin} className="h-9"></img>
                        </a>
                        <Link
                          to="/courses/coaches/info"
                          state={{ name: c.Name }}
                        >
                          <button className="bg-[#E94B60] text-white py-2 px-5 rounded-xl">
                            Explore Now
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className="absolute left-1/2 top-1/2 -translate-x-1/2  text-center -translate-y-1/2">
            <InfinitySpin width="200" color="#4899B7"></InfinitySpin>
          </div>
        </div>
      )}
    </div>
  );
};

export default Bookings;

import {HashRouter as Router,Routes,Route,} from "react-router-dom";
import {useEffect} from 'react'
import Onboarding from "./pages/Onboarding";
import Signup from "./pages/Signup";
import Video from "./pages/Video";
import Login from "./pages/Login";
import Home from "./pages/Home";
import Demo from "./pages/Demo";
import Password from "./pages/Password";
import Data from "./pages/Data";
import Error from "./pages/Error";
import Redeem from "./pages/Redeem";
import Start from './pages/Start'
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";
import Disclaimer from "./pages/Disclaimer";
import ReactGA from 'react-ga';
import Editor from "./pages/Editor";
import Refund from "./pages/Refund";
import Success from "./pages/Success";
import TagManager from 'react-gtm-module';
import ThankYou from "./pages/ThankYou";
import PhoneNumberSignup from './components/PhoneNumberSignup'
import Webinars from "./components/Webinars";
import PhoneNumberLogin from "./components/PhoneNumberLogin";
import CoachPaymentSuccess from "./pages/CoachPaymentSuccess";
import WhatsappFlow from "./pages/WhatsappFlow";
import Bookings from "./pages/Bookings";
import Test from "./pages/Test"
import Contact from "./pages/Contact";
import Offer from "./pages/Offer";
// import Checkout from "./pages/Checkout";




function App() {
  useEffect(() => {
    TagManager.initialize({ gtmId: 'GTM-WPTLD4Q' });
    ReactGA.initialize('UA-209731463-1',{
      config:{
        "AW-11249740142": { "groups": "default" }
      }
    });
  }, []);
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Start></Start>}></Route>
        <Route path="/payment_success" element={<Success/>}></Route>
        <Route path="/offer" element={<Offer/>}></Route>
        <Route path="/signup" element={<PhoneNumberSignup></PhoneNumberSignup>}></Route>
        <Route path="/signup/onboarding" element={<Onboarding/>}></Route>
        <Route path="/registration_success" element={<ThankYou/>}></Route>
        <Route path="/login" element={<PhoneNumberLogin/>}></Route>
        <Route path="/home" element={<Home/>}></Route>
        <Route path="/courses" element={<Home></Home>}></Route>
        <Route path="/demo" element={<Demo></Demo>}></Route>
        <Route path="/dashboard" element={<Home/>}></Route>
        <Route path="/settings" element={<Home></Home>}></Route>
        <Route path="/forgotPassword" element={<Password/>}></Route>
        <Route path="/data" element={<Data/>}></Route>
        <Route path="/money" element={<Redeem></Redeem>}></Route>
        <Route path='/err' element={<Error/>}></Route>
        <Route path="/privacy" element={<Privacy/>}></Route>
        <Route path="/terms" element={<Terms/>}></Route>
        <Route path="/disclaimer" element={<Disclaimer/>}></Route>
        <Route path="/resume" element={<Home></Home>}></Route>
        <Route path="/editor" element={<Editor/>}></Route>
        <Route path ="/pricing" element={<Home></Home>}></Route>
        <Route path="/refund" element={<Refund/>}></Route>
        <Route path="/courses/resources" element={<Home/>}></Route>
        <Route path="/courses/webinars" element={<Home></Home>}></Route>
        <Route path="/courses/learn" element={<Home></Home>}></Route>
        <Route path="/courses/coaches" element={<Home></Home>}></Route>
        <Route path="/courses/coaches/info" element={<Home></Home>}></Route>
        <Route path="/courses/coaches/bookings" element={<Home></Home>}></Route>
        <Route path="/whatsapp" element={<WhatsappFlow/>}></Route>
        <Route path="/support" element={<Contact/>}></Route>
        <Route path="/t" element={<Test></Test>}></Route>
        <Route path="/coach_payment" element={<CoachPaymentSuccess/>}></Route>
        {/* <Route path="/checkout" element={<Checkout/>}></Route> */}
      </Routes>
    </Router>
  );
}

export default App;

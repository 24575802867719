import React, { useEffect, useState } from 'react'
import path from '../images/Path.png'
import badge from '../images/Shape.png'
import tick from '../images/tick.png'
import shield from '../images/badge.png'
import cup from '../images/cup.png'
import { Link , useLocation} from 'react-router-dom'
import axios, { Axios } from 'axios'
import tt1 from '../images/tt (1).jpeg'
import tt2 from '../images/tt (2).jpeg'
import tt3 from '../images/tt3.jpeg'
import tt4 from '../images/tt4.jpeg'
import data from '../config'
import tt5 from '../images/tt5.jpeg'
import tt6 from '../images/tt6.jpeg'
// import 'https://checkout-staging.pluralonline.com/v1/web-sdk-checkout.js'


const InternalPricing = () => {
    const user = localStorage.getItem('email')
    const [flag, setFlag] = useState(false)
    const [loader, setLoader] = useState(true)
    const[enc,setEnc] =useState(null)
    

    useEffect(()=>{
        if(enc!=null){
            setFlag(true)
        }
    },[enc])
    
    useEffect(()=>{
        window.scrollTo(0,0)
    },[])
    // "mobile_number": "9050157978",
    // "email_id": "balwant.singh@pinelabs.com"

    

    const buy=(amt, pack)=>{
        axios.post(`https://${data.API}/payment/pe/create`,{
            username:user,
            amount:amt,
            pack:pack
        })
        .then((response)=>{
            console.log(response)
            localStorage.setItem('merchantTransactionId',response.data.merchantTransactionId)
            window.location.href = response.data.url
        })
        .catch((error)=>{
            console.log(error)
        })
    }



    const ReadMore = ({ children }) => {
        const text = children;
        const [isReadMore, setIsReadMore] = useState(true);
        const toggleReadMore = () => {
          setIsReadMore(!isReadMore);
        };
        return (
          <p className="text">
            {isReadMore ? text.slice(0, 80) : text}
            <span onClick={toggleReadMore} className="read-or-hide cursor-pointer text-red-500">
              {isReadMore ? " ... read more" : " show less"}
            </span>
          </p>
        );
      };

      const Content = (props) => {
        return (
          <div className="container">
            <h2>
              <ReadMore>
                {props.content}
              </ReadMore>
            </h2>
          </div>
        );
      };

    // https://localhost:8999/payment/cca/create_order

  return (
    
    <div className="font-poppins">

    <p className="font-extrabold text-lg ml-4 lg:ml-0 mt-8">Choose the plan that's right as per your requirement</p>
    <div className="lg:grid lg:ml-5 lg:mr-8 mr-2 space-y-4 lg:space-y-0 lg:grid-cols-3 mt-4 gap-4">
    <div className="px-5 py-4 rounded-xl border-4 hover:scale-90 transition-all lg:scale-[0.8]">
    <div className="rounded-full  bg-[#E94B60] px-3 py-8 float-right ">
                <p className="text-white text-lg">33% OFF</p>
            </div>
            <img src={badge} className="h-12 mt-3"></img>
            <p className="my-6 font-bold">Basic</p>
            {/* <p className="my-3">All the basics for businesses that are just getting started.</p> */}
            <p className="text-lg text-center my-3 font-extrabold text-[#E94B60] mb-10 "><strike>₹ 1499</strike><span className="text-5xl">₹ 999</span></p>
            
            <button onClick={()=>{buy(99900,"basic")}}  className="bg-transparent hover:bg-[#E94B60] rounded-full text-[#E94B60] hover:text-white py-3 border w-full border-[#E94B60]">Buy Now</button>
            <div className="flex items-center space-x-2 mt-4">
                <img src={tick} className="h-3"></img>
                <p>2 AI Employability Assessment Tests</p>
            </div>
            <div className="flex items-center space-x-2 mt-4">
                <img src={tick} className="h-3"></img>
                <p>Full assessment report with strength and weakness analysis</p>
            </div>
            <div className="flex items-center space-x-2 mt-4">
                <img src={tick} className="h-3"></img>
                <p>Personalised dashboard for progression tracking</p>
            </div>
            <div className="flex items-center space-x-2 mt-4">
                <img src={tick} className="h-3"></img>
                <p>Access to our fully automated resume builder with free resume templates</p>
            </div>
            <div className="flex items-center space-x-2 mt-4">
                <img src={tick} className="h-3"></img>
                <p>Access to our free curated learning videos</p>
            </div>
        </div>
        <div className="px-5 py-4 rounded-xl border-4 text-white scale-100 lg:scale-90 relative lg:hover:scale-[0.93] transition-all bg-[#3f475f] ">
            <div className="rounded-full  bg-[#E94B60] px-3 py-8 float-right ">
                <p className="text-white text-lg">40% OFF</p>
            </div>
            <div className="flex w-2/3 items-center space-x-0">
            <img src={shield} className="h-12 mt-3"></img>
            <div className="bg-transparent text-white font-poppins mt-2 italic rounded-xl px-3 py-1">
                <p>Bestseller</p>
            </div>
            </div>

            <p className=" font-bold my-6">Standard</p>
            {/* <p className="my-3">All the basics for businesses that are just getting started.</p> */}
            
            <p className="text-lg text-center my-3 font-extrabold text-[#E94B60] mb-10 "><strike>₹ 2499 </strike><span className="text-5xl">₹ 1499</span></p>
            
            <button onClick={()=>{buy(149900,"standard")}} className="bg-white rounded-full text-[#E94B60]  py-3 border w-full border-[#E94B60]">Buy Now</button>
            <div className="flex items-center space-x-2 mt-4">
                <img src={tick} className="h-3"></img>
                <p>3 AI Employability Assessment Tests</p>
            </div>
            <div className="flex items-center space-x-2 mt-4">
                <img src={tick} className="h-3"></img>
                <p>Access to our complete Premium Learning Resources which help you enhance your key skills for career success</p>
            </div>
            <div className="flex items-center space-x-2 mt-4">
                <img src={tick} className="h-3"></img>
                <p>Full assessment report with strength and weakness analysis</p>
            </div>
            
            <div className="flex items-center space-x-2 mt-4">
                <img src={tick} className="h-3"></img>
                <p>Personalised dashboard for progression tracking</p>
            </div>
            <div className="flex items-center space-x-2 mt-4">
                <img src={tick} className="h-3"></img>
                <p>Access to our fully automated resume builder with free resume templates</p>
            </div>
            <div className="flex items-center space-x-2 mt-4">
                <img src={tick} className="h-3"></img>
                <p>15-minute interview preparation session with our expert coachs</p>
            </div>
            {/* <div className="w-full text-center absolute py-3 text-white mt-10 left-0  bg-[#E94B60] ">
                <p className="text-2xl font-poppins">Bestseller</p>
            </div> */}
        </div>
        <div className="px-5 py-4 rounded-xl bg-transparent transition-all border-4 hover:scale-90 lg:scale-[0.8]">
        <div className="rounded-full  bg-[#E94B60] px-3 py-8 float-right ">
                <p className="text-white text-lg">43% OFF</p>
            </div>
            <img src={cup} className="h-12 mt-3"></img>
            <p className="font-bold my-6">Premium</p>
            {/* <p className="my-3">All the basics for businesses that are just getting started.</p> */}
            <p className="text-lg font-extrabold text-[#E94B60] mb-10 text-center"><strike>₹ 3499 </strike><span className="text-5xl">₹ 1999</span></p>
            
            
        
            <button onClick={()=>{buy(199900, "premium")}} className=" bg-white rounded-full text-[#E94B60]  py-3 border w-full border-[#E94B60]">Buy Now</button>
            <div className="flex items-center space-x-2 mt-4">
                <img src={tick} className="h-3"></img>
                <p>5 Employability Assessment Tests</p>
            </div>
            <div className="flex items-center space-x-2 mt-4">
                <img src={tick} className="h-3"></img>
                <p>Access to our complete Premium Learning Resources which help you enhance your key skills for career success</p>
            </div>
            <div className="flex items-center space-x-2 mt-4">
                <img src={tick} className="h-3"></img>
                <p>Full assessment report with strength and weakness analysis</p>
            </div>
            <div className="flex items-center space-x-2 mt-4">
                <img src={tick} className="h-3"></img>
                <p>Personalised dashboard for progression tracking</p>
            </div>
            <div className="flex items-center space-x-2 mt-4">
                <img src={tick} className="h-3"></img>
                <p>Access to our fully automated resume builder with free resume templates</p>
            </div>
            <div className="flex items-center space-x-2 mt-4">
                <img src={tick} className="h-3"></img>
                <p>A 45-minute career coaching session with our Expert Coach</p>
            </div>
            <div className="flex items-center space-x-2 mt-4">
                <img src={tick} className="h-3"></img>
                <p>Invitation to 3 master classes conducted by industry experts</p>
            </div>
           
        </div>
    </div>

    <div className="mr-3 ">
        <p className="font-extrabold lg:text-2xl text-lg  ml-4 lg:ml-0 mt-8 ">Read what our users are saying</p>
        <div className="lg:grid grid-cols-3 justify-evenly mt-6 gap-4 gap-y-10 items-start  lg:space-y-0 space-y-10">
            <div className="text-left p-4 pt-7 relative transition-all  shadow-2xl rounded-xl">
                <p className="text-red-500 absolute font-serif right-6 -top-9 text-9xl">"</p>
                <Content className="mb-3" content={"As someone transitioning into a new field, Interview Vision gave me the confidence to excel in interviews. The platform's focus on soft skills evaluation, such as critical thinking and problem-solving abilities, helped me highlight my transferable skills. The learning material provided was invaluable in conquering the challenges and securing a job in my desired industry. "}></Content>
               <div className="bg-slate-400 mt-4  text-white -mx-4  -ml-4 p-3 flex items-center  space-x-4">
                <img src={tt1} className="h-[8rem] rounded-xl"></img>
                <div>
                <p className="text-xl font-extrabold">Pallavi Singh</p>
                <p>Manager - Media Business Development</p>
                </div>
                </div> 
            </div>
            <div className="text-left p-4 relative pt-7   shadow-2xl rounded-xl">
                <p className="text-red-500 absolute font-serif right-6 -top-9 text-9xl">"</p>
                <Content className="mb-3" content={"Interview Vision is a game-changer for job seekers! The AI-powered virtual interviews accurately evaluated my critical thinking, communication skills, and problem-solving abilities. The detailed feedback helped me fine-tune my approach and excel in real interviews. Thanks to Interview Vision, I secured multiple job offers and had the confidence to choose the perfect fit for me!"}></Content>
               <div className="bg-slate-400 mt-4  text-white -mx-4   p-3 flex items-center  space-x-4">
                <img src={tt2} className="h-[8rem] rounded-xl"></img>
                <div>
                <p className="text-xl font-extrabold">Derrick James</p>
                <p>Regional HR Manager</p>
                </div>
                </div> 
            </div>
            <div className="text-left p-4 relative pt-7 shadow-2xl rounded-xl">
                <p className="text-red-500 absolute font-serif right-6 -top-9 text-9xl">"</p>
                <Content content={"Interview Vision made a significant impact on my transition from student to professional. The virtual interviews simulated real-world scenarios, allowing me to showcase my creative problem-solving abilities. The personalised feedback and performance scores helped me understand my potential and focus areas. With the guidance and support from Interview Vision, I secured my first job as a digital marketing expert!"} className="mb-3 "></Content>
               <div className="bg-slate-400 mt-4 bottom-3 text-white  -mx-4 lg:-ml-4 p-3 flex items-center  space-x-4">
                <img src={tt3} className="h-[8rem] rounded-xl"></img>
                <div>
                <p className="text-xl font-extrabold">Akash Wadhwa</p>
                <p>Manager - Digital Marketing</p>
                </div>
                </div> 
            </div>
            <div className="text-left p-4 relative pt-7 shadow-2xl rounded-xl">
                <p className="text-red-500 absolute font-serif right-6 -top-9 text-9xl">"</p>
                <Content content={"Interview Vision empowered me to ace my job interviews with confidence! The platform's evaluation of my communication skills and active listening abilities gave me a competitive advantage. The comprehensive analysis of my performance allowed me to tailor my approach and excel in interviews. Thanks to Interview Vision, I secured a job where I can utilize my strengths and grow professionally!"} className="mb-3 "></Content>
               <div className="bg-slate-400 mt-4 bottom-3 text-white  -mx-4 lg:-ml-4 p-3 flex items-center  space-x-4">
                <img src={tt4} className="h-[8rem] rounded-xl"></img>
                <div>
                <p className="text-xl font-extrabold">Akansha Singh</p>
                <p>Customer Service Operations Lead</p>
                </div>
                </div> 
            </div>
            <div className="text-left p-4 relative pt-7 shadow-2xl rounded-xl">
                <p className="text-red-500 absolute font-serif right-6 -top-9 text-9xl">"</p>
                <Content content={"Thanks to Interview Vision, I was able to showcase my creative problem-solving abilities to potential employers. The platform's AI-driven evaluations not only recognized my strengths but also highlighted areas where I could further enhance my skills. The comprehensive learning material provided by Interview Vision equipped me with innovative approaches, and it played a pivotal role in securing a job that allows me to put my problem-solving skills to the test."} className="mb-3 "></Content>
               <div className="bg-slate-400 mt-4 bottom-3 text-white  -mx-4 lg:-ml-4 p-3 flex items-center  space-x-4">
                <img src={tt5} className="h-[8rem] rounded-xl"></img>
                <div>
                <p className="text-xl font-extrabold">Akash Yadav</p>
                <p>Online Learning Faculty</p>
                </div>
                </div> 
            </div>
            <div className="text-left p-4 relative pt-7 shadow-2xl rounded-xl">
                <p className="text-red-500 absolute font-serif right-6 -top-9 text-9xl">"</p>
                <Content content={"I am thrilled to share my experience with Interview Vision, as it played a crucial role in my recent success in landing a job as an associate engineer at a renowned IT company. With numerous candidates vying for the same position, Interview Vision’s unbiased scoring system and evaluation of my communication skills and critical thinking proved to be the deciding factor."} className="mb-3 "></Content>
               <div className="bg-slate-400 mt-4 bottom-3 text-white  -mx-4 lg:-ml-4 p-3 flex items-center  space-x-4">
                <img src={tt6} className="h-[8rem] rounded-xl"></img>
                <div>
                <p className="text-xl font-extrabold">Ishan Agrawal</p>
                <p>4th Year Student, Computer Science Engineering</p>
                </div>
                </div> 
            </div>
        </div>
    </div>

</div>
  )
}

export default InternalPricing
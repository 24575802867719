import React from 'react'
import path from '../images/Path.png'
import badge from '../images/Shape.png'
import tick from '../images/tick.png'
import shield from '../images/badge.png'
import cup from '../images/cup.png'
import {Link} from 'react-router-dom'
import { useLocation } from 'react-router-dom'
{/* <Link to='/err' state={{name:name, pass:pass}}></Link> */}
const Redeem = () => {
  const location = useLocation()
  return (
    <div>
        <div className="font-poppins lg:p-8 p-5 bg-gray-100 min-h-screen">
            <p className="font-extrabold text-xl ml-4 lg:ml-0">Choose the plan that's right as per your requirement</p>
            <div className="lg:grid  space-y-4 lg:w-3/4 lg:mx-auto  lg:space-y-0 lg:grid-cols-2 mt-10 gap-4">
            
                <div className="px-5 py-4 shadow-xl rounded-xl border-4 border-red-200 ">
                    <img src={path} className="h-24 float-right -mt-5 -mr-5"></img>
                    <img src={shield} className="h-12 mt-3"></img>
                    <p className="my-3 font-bold">Three Interviews Pack</p>
                    {/* <p className="my-3">All the basics for businesses that are just getting started.</p> */}
                    <p className="text-5xl font-extrabold text-[#E94B60] mb-10">₹ 1299 <span className="text-lg">One Time</span></p>
                    <Link to='/err' >
                    <button className="bg-transparent hover:bg-[#E94B60] rounded-full text-[#E94B60] hover:text-white py-3 border w-full border-[#E94B60]">Buy Now</button>
                    </Link>
                    <div className="flex items-center space-x-2 mt-4">
                        <img src={tick} className="h-3"></img>
                        <p>3 Interview Assessments</p>
                    </div>
                    <div className="flex items-center space-x-2 mt-4">
                        <img src={tick} className="h-3"></img>
                        <p>Detailed Report on 5 soft skill parameters</p>
                    </div>
                    <div className="flex items-center space-x-2 mt-4">
                        <img src={tick} className="h-3"></img>
                        <p>Progression tracking and Improvement report</p>
                    </div>
                    <div className="flex items-center space-x-2 mt-4">
                        <img src={tick} className="h-3"></img>
                        <p>Free curated self-paced learning courses</p>
                    </div>
                    <div className="flex items-center space-x-2 mt-4">
                        <img src={tick} className="h-3"></img>
                        <p>Access to Resume Builder tool</p>
                    </div>
                </div>
                <div className="px-5 py-4 shadow-xl rounded-xl border-4 border-red-200 ">
                    <img src={path} className="h-24 float-right -mt-5 -mr-5"></img>
                    <img src={cup} className="h-12 mt-3"></img>
                    <p className="my-3 font-bold">Five Interviews Pack</p>
                    {/* <p className="my-3">All the basics for businesses that are just getting started.</p> */}
                    <p className="text-5xl font-extrabold text-[#E94B60] mb-10">₹ 1499 <span className="text-lg">One Time</span></p>
                    <Link to='/err' >
                    <button className="bg-transparent hover:bg-[#E94B60] rounded-full text-[#E94B60] hover:text-white py-3 border w-full border-[#E94B60]">Buy Now</button>
                    </Link>
                    <div className="flex items-center space-x-2 mt-4">
                        <img src={tick} className="h-3"></img>
                        <p>5 Interview Assessments</p>
                    </div>
                    <div className="flex items-center space-x-2 mt-4">
                        <img src={tick} className="h-3"></img>
                        <p>Detailed Report on 5 soft skill parameters</p>
                    </div>
                    <div className="flex items-center space-x-2 mt-4">
                        <img src={tick} className="h-3"></img>
                        <p>Progression tracking and Improvement report</p>
                    </div>
                    <div className="flex items-center space-x-2 mt-4">
                        <img src={tick} className="h-3"></img>
                        <p>Free curated self-paced learning courses</p>
                    </div>
                    <div className="flex items-center space-x-2 mt-4">
                        <img src={tick} className="h-3"></img>
                        <p>Access to Specialised Learning material created by our in-house experts</p>
                    </div>
                    <div className="flex items-center space-x-2 mt-4">
                        <img src={tick} className="h-3"></img>
                        <p>Access to Resume Builder tool</p>
                    </div>
                    <div className="flex items-center space-x-2 mt-4">
                        <img src={tick} className="h-3"></img>
                        <p>Resume Assessment</p>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
  )
}

export default Redeem
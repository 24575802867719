import React, {useState} from 'react'
import interviewguru_logo from '../images/interview_guru_logo.png'
import Entry from '../components/Entry'
import About from '../components/About'
import Pricing from '../components/Pricing'
import {HiOutlineMenuAlt2} from 'react-icons/hi'
import {GrClose} from 'react-icons/gr'
import { Link } from 'react-router-dom'
const Start = () => {
    const [page, setPage]  = useState(0)
    const [open, setOpen] = useState(false)
    return (
        <div className="font-poppins">
        <a href=""></a>
        <nav className="flex py-3 px-12 w-full  items-center justify-between">
            <button onClick={()=>{setPage(0)}}>
            <img src={interviewguru_logo} className="lg:h-24 h-16"></img>
            </button>
            <div className="lg:flex hidden justify-end space-x-8 items-center">
                <button onClick={()=>{setPage(0)}}>Home</button>
                <button onClick={()=>{setPage(1)}}>About Us</button>
                {/* <button onClick={()=>{setPage(2)}}>Pricing</button> */}
                <Link to="/support">
                <button>Support</button>
                </Link>
                <Link to="/signup">
                <button className="">Sign Up</button>
                </Link>
                <Link to="/login">
                <button className="bg-red-500 text-white py-3 px-5 rounded-xl">Sign In</button>
                </Link>
            </div>
                <div className="lg:hidden ">
                    <button onClick={()=>{setOpen(true)}}>
                    <HiOutlineMenuAlt2 className="lg:hidden block text-xl"></HiOutlineMenuAlt2>
                    </button>
                </div>
        </nav>
            <div className="">
            {
                open &&
                <div className=" fixed z-50 h-full top-0 bg-white shadow-2xl w-[14rem] ">
                    {/* <img src={interviewguru_logo} className="h-10 mx-auto  mt-3 pr-8"></img> */}
                {/* <div className="h-[0.1rem] bg-[#D9D9D9]"></div> */}
                <div className="mx-4 flex flex-col mt-8 space-y-6">
                <button onClick={()=>{setPage(0)}}>Home</button>
                <button onClick={()=>{setPage(1)}}>About Us</button>
                {/* <button onClick={()=>{setPage(2)}}>Pricing</button> */}
                <Link to="/support">
                <button className="w-full">Support</button>
                </Link>
                <Link  to="/signup">    
                <button className="w-full">Sign Up</button>
                </Link>
                <Link to="/login">
                <button className="bg-red-500 text-white py-3 px-5 rounded-xl w-full">Sign In</button>
                </Link>
                <button className="w-full" onClick={()=>{setOpen(false)}}>    
                    <GrClose className="text-xl  text-center my-3 mx-auto"></GrClose>
                </button>
                </div>
                </div>
            }
        </div>
        {
            page == 0 &&
            <Entry></Entry>
        }
        {
            page == 1 &&
            <About></About>
        }
        {
            page == 2 &&
            <Pricing></Pricing> 
        }
    </div>
  )
}

export default Start
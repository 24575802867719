import React , {useState} from 'react'
import loginguy from '../images/login_guy.png'
import interviewguru_logo from '../images/interview_guru_logo.png'
import google_logo from '../images/google_logo.png'
import fb_logo from '../images/fb_logo.png'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import {InfinitySpin} from 'react-loader-spinner'
import axios from 'axios'

const Password = () => {
    const [email,setEmail] = useState('')
    const [loader, setLoader] = useState(false)
    const [newpass, setNewPass] = useState('')
    const [verify,setVerify] = useState(false)
    const [otp,setOtp] = useState('')
    const [otp1,setOtp1] = useState('')
    const [otp2,setOtp2] = useState('')
    const [otp3,setOtp3] = useState('')
    const [otp4,setOtp4] = useState('')
    const [isValid, setIsValid] = useState(false)
    const [number, setNumber] = useState('')
    const navigate = useNavigate()

    const handleSubmit = (e) => {
        setLoader(true)
        axios.get(`https://interviewvision.com/users/genemailotp/${email}/${email}`)
        .then((response) => {
            setVerify(true)
            console.log(response)
            setLoader(false)
            
        })
        .catch((error) => {
            console.error(error)
            alert('Something went wrong! Please try again')
        })
    }

    const verifyOTP = () =>{
        // setOtp(otp1+otp2+otp3+otp4)
        setLoader(true)
        axios.get(`https://interviewvision.com/users/verifyemailotp/${email}/${email}/${otp1+otp2+otp3+otp4}`)
        .then((response) => {
            setIsValid(true)
            console.log(response)
            setLoader(false)
        })
        .catch((error) => {
            console.log(error)
            alert('Invalid OTP')
        })
    }

    const register = () => {
        console.log(email)
        console.log(newpass)
        axios.put(`https://interviewvision.com/users/u/${email}`,{
            password:newpass
        })
        .then((response) => {
            console.log(response)
            navigate('/login')
        })
        .catch((error) => {
            alert('Something went wrong! Please try again')
            console.log(error)
        })
    }

  return (
    <div className="">
        {
            isValid &&
            <div className="absolute top-1/2 left-1/2 z-20 -translate-x-1/2 w-1/2 -translate-y-1/2 bg-white p-4 rounded-xl">
                <h1 className="text-xl lg:text-left text-center font-poppins font-semibold">The OTP has been successfully verified. Please enter your new password</h1>
                <input className="w-full my-3 outline-none border rounded-xl px-3 py-2" type="text" value={newpass} onChange={(e)=>{setNewPass(e.target.value)}}></input>
                <div className="text-center">
                <button className="bg-red-500 text-white px-4 py-2 rounded-xl font-poppins text-lg" onClick={register}>Submit</button>
                </div>
            </div>
        }
        {
            loader?
            <div className="absolute left-1/2 top-1/2 -translate-x-1/2  text-center -translate-y-1/2">
                    <InfinitySpin
                    width='200'
                    color='#4899B7'
                    ></InfinitySpin>
                </div>
            :
            <div className={isValid ? "blur-3xl":""}>
        <div className="flex">
            <img src={loginguy} className="h-screen hidden lg:block"></img>
            <div className="w-full">
            <div className="flex justify-between items-center mt-3 mx-4">
                <img src={interviewguru_logo} className="h-20 "></img>
            </div>
            <div className="mt-12 w-full lg:w-1/2 mx-auto">
                {
                    !verify?
                    <div>
                <h1 className="text-xl lg:text-left text-center font-poppins font-semibold">Enter your registered email id and create a new password</h1>
               
                <div className="flex justify-center items-center space-x-3">
                    {/* <div className="bg-[#DCDBDD] px-16 lg:px-32 py-[0.01rem]" ></div> */}
                    {/* <h1 className="text-[#84818A]">or</h1> */}
                    {/* <div className="bg-[#DCDBDD] px-16 lg:px-32 py-[0.01rem]" ></div> */}
                </div>
                <div class="relative my-3 lg:mx-0 mx-4">
                    <input type="email" value={email} autoComplete="off" autoSave="off"  onChange={(e)=>{setEmail(e.target.value)}} id="floating_outlined" class="block px-2.5 border-2 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
                    <label for="floating_outlined"  class="absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">Email ID</label>
                </div>
                <div className="text-center">
                <button className="px-10   bg-red-500 text-white text-lg font-poppins rounded-xl py-3 my-4 " onClick={()=>{
                    setLoader(true) 
                    handleSubmit()}}>Send OTP</button>
                </div>
                    </div>
                    :
                    <div>
                        <h1 className="text-xl lg:text-left text-center font-poppins font-semibold">Enter the four digit email that has been sent to your registered email id</h1>
                        <div className="flex items-center mx-auto w-full justify-center space-x-4 my-4">
                            <input className="border-2 outline-none w-[2rem] px-2 py-2 rounded-lg text-center" maxlength="1" value={otp1} onChange={(e)=>{setOtp1(e.target.value)}}></input>
                            <input className="border-2 outline-none w-[2rem] px-2 py-2 rounded-lg text-center" maxlength="1" value={otp2} onChange={(e)=>{setOtp2(e.target.value)}}></input>
                            <input className="border-2 outline-none w-[2rem] px-2 py-2 rounded-lg text-center" maxlength="1" value={otp3} onChange={(e)=>{setOtp3(e.target.value)}}></input>
                            <input className="border-2 outline-none w-[2rem] px-2 py-2 rounded-lg text-center" maxlength="1" value={otp4} onChange={(e)=>{setOtp4(e.target.value)}}></input>
                        </div>
                        <div className="text-center">
                <button className="px-10   bg-red-500 text-white text-lg font-poppins rounded-xl py-3 my-4 " onClick={()=>{
                    setLoader(true)
                    verifyOTP()}}>Verify</button>
                </div>
                    </div>
                }
                {/* <div className="flex items-center space-x-4 lg:mx-0 mx-4">
                <input type="checkbox" className="accent-[#4899B7] h-5 w-5"></input>
                <p className="text-sm">By clicking Create account, I agree that I have read and accepted the Terms of Use and Privacy Policy.</p>
                </div> */}
                <Link to="/signup">
                <h1 className="font-poppins lg:text-xl text-lg text-center">New User? Create an account</h1>
                </Link>
            </div>

            </div>
        </div>
            </div>
            
        }
    </div>
  )
}

export default Password
const qoutes=[
"Listen actively and attentively to the person speaking.",
"Make eye contact to show that you are interested and engaged in the conversation.",
"Be present and focused in the moment, rather than distracted or preoccupied.",
"Use body language to convey your message effectively.",
"Speak clearly and enunciate your words to ensure that your message is received clearly.",
"Use appropriate tone and inflection to convey the intended message.",
"Be concise and to the point, avoiding unnecessary rambling.",
"Use appropriate vocabulary and grammar to convey your message effectively.",
"Be open-minded and willing to consider the other person's perspective.",
"Be respectful and courteous to the other person, even if you disagree with them.",
"Use humor and light-heartedness to lighten the mood and make the conversation more enjoyable.",
"Use active listening skills, such as summarizing what the other person has said and asking clarifying questions.",
"Avoid interrupting the other person when they are speaking.",
"Use appropriate nonverbal cues, such as nodding and smiling, to show that you are engaged in the conversation.",
"Avoid using jargon or technical terms that the other person may not understand.",
"Use metaphors and analogies to help explain complex concepts.",
"Use storytelling to make the conversation more engaging and memorable.",
"Be patient and understanding, particularly when dealing with difficult or emotional topics.",
"Avoid using negative or judgmental language, which can be off-putting to the other person.",
"Use positive language to frame your message in a more constructive and supportive manner.",
"Be clear and specific about what you are trying to communicate.",
"Use open-ended questions to encourage the other person to elaborate on their thoughts and feelings.",
"Avoid making assumptions about the other person's thoughts or feelings.",
"Use active voice to convey your message more clearly and directly.",
"Be honest and direct when providing feedback, while also being sensitive to the other person's feelings.",
"Focus on the speaker and avoid distractions.",
"Show interest and attention through non-verbal cues like nodding and maintaining eye contact.",
"Avoid interrupting the speaker and allow them to finish their thoughts before responding.",
"Avoid judging or forming opinions before the speaker has finished.",
"Ask questions to clarify and seek understanding.",
"Paraphrase the speaker's message to ensure accurate understanding.",
"Take notes to capture important details and points.",
"Practice empathy to understand the speaker's perspective and feelings.",
"Avoid multitasking while listening.",
"Practice patience and avoid rushing to respond.",
"Practice mindfulness and stay present in the moment.",
"Acknowledge the speaker's thoughts and feelings.",
"Avoid distractions like phones and computers.",
"Avoid interrupting the speaker with your own stories or opinions.",
"Keep an open mind and be willing to consider the speaker's perspective.",
"Avoid making assumptions and seek clarification when necessary.",
"Use positive body language and gestures to show interest and attention.",
"Avoid dominating the conversation and allow others to speak.",
"Avoid being defensive or dismissive of the speaker's message.",
"Practice active listening by summarizing the speaker's message.",
"Focus on the content of the message, rather than the speaker's delivery or appearance.",
"Practice reflective listening by acknowledging the speaker's emotions and feelings.",
"Avoid stereotyping or making generalizations about the speaker.",
"Practice self-awareness and avoid bringing personal biases or prejudices to the conversation.",
"Practice self-control and avoid reacting emotionally to the speaker's message.",
'Identify assumptions and biases that may be present in a situation or argument.',
"Evaluate evidence and sources to determine their credibility and reliability.",
"Analyze and interpret information to understand its meaning and implications.",
"Identify and consider alternative perspectives and viewpoints.",
"Develop clear and concise arguments to support your conclusions.",
"Use logic and reasoning to identify flaws and weaknesses in arguments.",
"Avoid making assumptions and jumping to conclusions without evidence.",
"Consider the context and circumstances surrounding the situation or argument.",
"Evaluate the impact of potential actions or decisions on various stakeholders.",
"Use data and statistics to support your conclusions and arguments.",
"Practice creative problem-solving techniques to generate new solutions and ideas.",
"Break complex problems down into smaller, more manageable parts.",
"Use examples and analogies to clarify complex concepts.",
"Practice active reading by engaging with the material and asking questions.",
"Practice active listening by asking questions and seeking clarification.",
"Consider ethical implications and consequences of potential actions or decisions.",
"Identify and evaluate potential biases and conflicts of interest.",
"Practice metacognition by reflecting on your own thought processes and biases.",
"Consider the long-term implications and consequences of potential actions or decisions.",
"Avoid making assumptions based on limited information or personal opinions.",
"Practice humility by acknowledging the limitations of your own knowledge and understanding.",
"Evaluate the credibility and potential biases of your own sources of information.",
"Practice systems thinking by considering the interconnectedness of various factors and variables.",
"Use visual aids, such as diagrams and charts, to help visualize complex information.",
"Practice self-reflection and seek feedback from others to improve your critical thinking skills.",
"Set clear and specific goals for the team to achieve.",
'Establish open and effective communication channels among team members.',
"Develop a strong team culture that values cooperation and collaboration.",
"Clarify roles and responsibilities to avoid confusion and ensure accountability.",
"Encourage diverse perspectives and ideas to promote creativity and innovation.",
"Practice active listening and empathetic communication to understand team members' concerns and perspectives.",
"Foster trust and respect among team members to create a positive working environment.",
"Encourage constructive feedback and open discussion to promote growth and improvement.",
"Provide opportunities for team members to learn from each other and share knowledge.",
"Celebrate successes and acknowledge achievements to boost morale and motivation.",
"Set realistic deadlines and priorities to manage team workload and productivity.",
"Address conflicts and issues promptly and constructively to prevent escalation.",
"Recognize and appreciate individual contributions to the team's success.",
"Establish clear standards and expectations for the quality of work.",
"Encourage a flexible and adaptable approach to change and unforeseen events.",
"Foster a learning mindset by encouraging experimentation and risk-taking.",
"Practice accountability by taking ownership of mistakes and working to rectify them.",
"Encourage participation and involvement from all team members.",
"Seek consensus and collaboration in decision-making to promote shared ownership and commitment.",
"Set up regular team meetings and check-ins to monitor progress and address issues.",
"Encourage a healthy work-life balance and support individual well-being.",
"Provide opportunities for professional development and growth.",
"Promote a sense of shared purpose and vision among team members.",
"Embrace diversity and inclusion in all aspects of team culture and decision-making.",
"Foster a positive team spirit and culture that promotes creativity, productivity, and success.",
"Embrace challenges and see them as opportunities to learn and grow.",
"View failures as learning opportunities and a natural part of the learning process.",
"Cultivate a sense of curiosity and a willingness to explore new ideas and perspectives.",
"Practice perseverance and persistence in the face of obstacles and setbacks.",
"Focus on the process of learning and personal growth, rather than just achieving outcomes.",
"Believe that one's abilities and talents can be developed through hard work and dedication.",
"Recognize and challenge limiting beliefs and self-talk that may hinder growth.",
"Practice self-reflection to identify areas for improvement and growth.",
"Embrace feedback, both positive and negative, as a means to learn and grow.",
"Surround oneself with individuals who promote growth and support a growth mindset.",
"Cultivate a passion for learning and developing new skills and knowledge.",
"Embrace one's mistakes and use them as a means for growth and learning.",
"Avoid seeking out only easy and comfortable situations, and instead take on new challenges.",
"Set specific and achievable goals to promote growth and progress.",
"Be open to constructive criticism and feedback, even when it is difficult to hear.",
"Encourage a learning mindset in others, and support their growth and development.",
"Avoid comparing oneself to others, and focus instead on personal growth and progress.",
"Practice self-compassion and avoid being overly critical of oneself or one's abilities.",
"Seek out mentors or role models who embody a growth mindset.",
"Experiment with new approaches and strategies, even if they may not immediately succeed.",
"Cultivate a sense of purpose and meaning, and align one's goals with personal values.",
"Engage in activities that promote personal growth, such as meditation or journaling.",
'Practice gratitude and acknowledge the progress made towards personal growth and development.',
'Celebrate small successes and milestones along the journey towards growth.',
'Maintain a positive attitude and outlook towards challenges and setbacks, seeing them as opportunities to grow and learn.'
];

export default qoutes
import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import Course1 from '../images/course_tab_1.png'
import Course2 from '../images/course_tab_2.png'
import Course3 from '../images/course_tab_3.png'
import Course4 from '../images/course_tab_4.png'
import {InfinitySpin} from 'react-loader-spinner'
import axios from 'axios'
import data from '../config'

const Courses = () => {

    
    const [pack, setPack] = useState(null)
    useEffect(()=>{
        window.scrollTo(0,0)
        const email = localStorage.getItem('email')
        axios.get(`https://${data.API}/users/r/${email}`)
        .then((response)=>{
            setPack(response.data.subscribedpack)
            // setLoader(false)
        })
        .catch((error)=>{
            alert('Something went wrong! Please try again')
            console.log(error)
        })

    },[])
  return (
    <div >
            <div className="mt-16 px-5">
       <div className="lg:grid lg:grid-cols-2 gap-4 lg:space-y-0 space-y-6">
        <div className=" bg-white py-5 pl-4 rounded-xl ">
        <div id="wrapper" className="relative py-6 pl-4">
        <div id="left" className="rounded-tl-2xl">

            <p className="ml-4 mt-3 text-white font-bold">FREE</p>
        </div>
        <div id="right">
            <p className="text-center lg:ml-0 ml-10 mt-3 z-50 text-white font-bold">RESOURCES</p>
        </div>
        </div>
        <div className="lg:flex justify-center items-center space-x-5">
            <img src={Course1} className="h-52 mx-auto lg:mx-0"></img>
            <div className="font-poppins text-sm mt-6">
                <ol className="mt-5 list-disc px-4">
                <li className="">Curated expert videos for easy access</li> 
                <li>Covers diverse soft skills and leadership topics</li> 
                <li>Free access</li>
                </ol>
                <Link to="/courses/resources">
                <button className="px-5 py-3 text-white bg-[#E94B60] rounded-xl my-4">Explore Now</button>
                </Link>
            </div>
        </div>
        </div>

        <div className=" bg-white py-5 pl-4 rounded-xl ">
        <div id="wrapper" className="relative py-6 pl-4">
        <div id="left" className="rounded-tl-2xl">

            <p className="ml-4 mt-3 text-white font-bold">PAID</p>
        </div>
        <div id="right">
            <p className="text-center mt-3 lg:ml-0 ml-10 z-50 text-white font-bold">LEARN</p>
        </div>
        </div>
        <div className="lg:flex justify-center items-center space-x-6">
            <img src={Course2} className="h-44 mx-auto lg:mx-0 lg:mt-0 mt-10"></img>
            <div className="font-poppins text-sm mt-6">
                <ol className="mt-5 list-disc px-4">
                <li className="">Expert crafted learning</li> 
                <li>Flexible, self-paced study</li> 
                <li>Access skills, build confidence</li>
                <li>Concise modular videos</li>
                </ol>
                {
                <Link to="/courses/learn">
                <button className="px-5 py-3 text-white bg-[#E94B60] rounded-xl my-4">Explore Now</button>
                </Link>
                }
                
            </div>
        </div>
        </div>


        <div className=" bg-white py-5 pl-4 rounded-xl ">

        <div id="wrapper" className="relative py-9 lg:py-6 pl-4">
        <div id="left" className="rounded-tl-2xl">
            <p className="ml-4 md:mt-3 mt-6 text-white font-bold">FREE</p>
        </div>
        <div id="right">
            <p className="text-center md:ml-36 ml-32 mt-3 z-50 text-white font-bold">WEBINARS & MASTERCLASS</p>
        </div>
        </div>
        <div className="lg:flex justify-center items-center space-x-5">
            <img src={Course3} className="h-52 mx-auto lg:mx-0 lg:mt-0 mt-10"></img>

            <div className="font-poppins text-sm mt-6">
                <ol className="mt-5 list-disc px-5">
                <li className="">Webinars and Seminars conducted by in-house and guest experts</li> 
                <li>Monthly Masterclasses by Industry experts</li> 
                <li>Registration links for upcoming webinars and Masterclasses</li>
                </ol>
                <Link to="/courses/webinars">
                <button className="px-5 py-3 text-white bg-[#E94B60] rounded-xl my-4">Explore Now</button>
                </Link>
            </div>
        </div>
        </div>

        <div className=" bg-white py-5 pl-4 rounded-xl  ">
        <div id="wrapper" className="relative py-6 pl-4">
        <div id="left" className="rounded-tl-2xl">
            <p className="ml-4 mt-3 text-white font-bold">PAID</p>
        </div>
        <div id="right">
            <p className="text-center mt-3 z-50 text-white font-bold">COACH</p>
        </div>
        </div>
        <div className="flex justify-center items-center space-x-3">
            <img src={Course4} className="h-52"></img>
            <div className="font-poppins text-sm mt-6">
                <ol className="mt-5 list-disc px-5">
                <li className="">#1 Coaches for career growth</li> 
                <li>1-on-1 guided online sessions</li> 
                <li>Book at your convenience, on your chosen topic</li>
                </ol>
                <Link to="/courses/coaches">
                <button className="px-5 py-3 text-white bg-[#E94B60] rounded-xl my-4">Explore Now</button>
                </Link>
            </div>
        </div>
        </div>


        </div> 
    </div>
        </div>
    
  )
}

export default Courses